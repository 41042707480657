//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_PAYABLES_DATA,
  GET_PAYABLES_DATA_SUCCESS,
  GET_PAYABLES_DATA_FAILURE,
  SEND_ONE_TIME_PAYMENT,
  SEND_ONE_TIME_PAYMENT_SUCCESS,
  SEND_ONE_TIME_PAYMENT_FAILURE,
  SEND_HOURLY_PAYMENT,
  SEND_HOURLY_PAYMENT_SUCCESS,
  SEND_HOURLY_PAYMENT_FAILURE,
  GET_HOURLY_PAYMENT_HISTORY,
  GET_HOURLY_PAYMENT_HISTORY_SUCCESS,
  GET_HOURLY_PAYMENT_HISTORY_FAILURE,
  GET_ONETIME_PAYMENT_HISTORY,
  GET_ONETIME_PAYMENT_HISTORY_SUCCESS,
  GET_ONETIME_PAYMENT_HISTORY_FAILURE,
  EXPORT_PAYABLES_DATA_SUCCESS,
  EXPORT_PAYABLES_DATA_FAILURE,
  EXPORT_PAYABLES_DATA,
} from "../../modules/constants";

export function* getPayablesData({ payload }) {
  try {
    let url = `payroll/payables/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_PAYABLES_DATA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_PAYABLES_DATA_FAILURE,
      payload: err,
    });
  }
}

export function* exportPayablesData({ payload }) {
  try {
    let url = `payroll/export-payables/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: EXPORT_PAYABLES_DATA_SUCCESS,
      payload: { data: response, start: payload.start, end: payload.end },
    });
  } catch (err) {
    yield put({
      type: EXPORT_PAYABLES_DATA_FAILURE,
      payload: err,
    });
  }
}

export function* sendOneTimePayment({ payload }) {
  try {
    let url = `payroll/makepayment/onetime/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: SEND_ONE_TIME_PAYMENT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: SEND_ONE_TIME_PAYMENT_FAILURE,
      payload: err,
    });
  }
}

export function* sendHourlyPayment({ payload }) {
  try {
    let url = `payroll/makepayment/regular/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: SEND_HOURLY_PAYMENT_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: SEND_HOURLY_PAYMENT_FAILURE,
      payload: err,
    });
  }
}

export function* getHourlyPaymentHistory({ payload }) {
  try {
    let url = `payroll/paymentlogs/timesheet/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_HOURLY_PAYMENT_HISTORY_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_HOURLY_PAYMENT_HISTORY_FAILURE,
      payload: err,
    });
  }
}

export function* getOnetimePaymentHistory({ payload }) {
  try {
    let url = `payroll/paymentlogs/onetime/`;
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_ONETIME_PAYMENT_HISTORY_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ONETIME_PAYMENT_HISTORY_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(EXPORT_PAYABLES_DATA, exportPayablesData),
    takeLatest(GET_PAYABLES_DATA, getPayablesData),
    takeLatest(SEND_ONE_TIME_PAYMENT, sendOneTimePayment),
    takeLatest(SEND_HOURLY_PAYMENT, sendHourlyPayment),
    takeLatest(GET_HOURLY_PAYMENT_HISTORY, getHourlyPaymentHistory),
    takeLatest(GET_ONETIME_PAYMENT_HISTORY, getOnetimePaymentHistory),
  ]);
}
