import { useState, useEffect } from "react";
import moment from "moment";

import {
  SmallCardLabel,
  SmallCardPercentage,
  SmallCardContent,
} from "../../styledComponents/dashboard";
import {
  SmallCardContainer,
  DataDiv,
  HourPercentageContainer,
  BarAndDateDiv,
  TodayActivityProgressBarDiv,
} from "./cardStyles";

import LinearActivityBar from "../ProgressBar/LinearActivityBar";

import upArrowIcon from "../../assets/img/icons/up_arrow.svg";
import downArrowIcon from "../../assets/img/icons/down_arrow.svg";

const SmallStatActivityCard = (props) => {
  const [activity, setActivity] = useState(0);
  const [percentage, setPercentage] = useState("0.00%");
  const [dataIncrease, setDataIncrease] = useState(true);

  useEffect(() => {
    if (props.todaysActivity) {
      if (
        props.todaysActivity.todaysActivity &&
        props.todaysActivity.todaysActivity.average_activity
      ) {
        setActivity(
          parseInt(props.todaysActivity.todaysActivity.average_activity * 100)
        );
      } else {
        setActivity("No Activity");
      }
      if (
        props.todaysActivity.todaysActivity &&
        props.todaysActivity.todaysActivity.average_activity !== null &&
        props.todaysActivity.yesterdaysActivity &&
        props.todaysActivity.yesterdaysActivity.average_activity !== null
      ) {
        if (
          parseInt(props.todaysActivity.todaysActivity.average_activity * 100) <
          parseInt(
            props.todaysActivity.yesterdaysActivity.average_activity * 100
          )
        ) {
          setDataIncrease(false);
        } else {
          setDataIncrease(true);
        }
        let percentage = Math.abs(
          parseInt(props.todaysActivity.todaysActivity.average_activity * 100) -
            parseInt(
              props.todaysActivity.yesterdaysActivity.average_activity * 100
            )
        );
        setPercentage(`${percentage}%`);
      } else {
        setPercentage("0.00%");
      }
    }
  }, [props.todaysActivity]);
  return (
    <SmallCardContainer>
      <DataDiv>
        <HourPercentageContainer>
          <SmallCardLabel>All Projects</SmallCardLabel>
          <SmallCardPercentage increase={dataIncrease} percentage={percentage}>
            <img
              alt=""
              src={dataIncrease ? upArrowIcon : downArrowIcon}
              style={{ width: "10px", height: "14px" }}
            />
            {percentage}
          </SmallCardPercentage>
        </HourPercentageContainer>
        <SmallCardContent>
          {activity === "No Activity" ? "No Activity" : `${activity}%`}
        </SmallCardContent>
      </DataDiv>
      <BarAndDateDiv>
        <TodayActivityProgressBarDiv>
          <LinearActivityBar
            width="75%"
            height="12px"
            borderRadius="6px"
            backgroundColor="#B5B5B5"
            red="#FE5151"
            yellow="#F2C741"
            green="#20bead"
            percent={activity === "No Activity" ? 0 : activity}
          />
        </TodayActivityProgressBarDiv>
        <SmallCardLabel>
          {moment().format("MMMM D, YYYY")}
        </SmallCardLabel>
      </BarAndDateDiv>
    </SmallCardContainer>
  );
};

export default SmallStatActivityCard;
