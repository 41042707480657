import { useState } from "react";
import { DeleteDiv, DeleteButton } from "./profileStyles";
import { ActivityTextSection } from "../../styledComponents/members";
import { CommonText } from "../../styledComponents/common";
import { ColoredButton } from "../../styledComponents/buttons";

import UserProfileDeleteModal from "./UserProfileDeleteModal";

const UserDeleteProfile = (props) => {
  const [showDeleteAccountState, setshowDeleteAccountState] = useState(false);
  const showDeleteModal = () => {
    setshowDeleteAccountState(true);
  };
  const toggle = () => {
    setshowDeleteAccountState(!showDeleteAccountState);
  };
  const deleteAccount = (password, agreed, feedBack) => {
    props.profileDelete({
      password: password,
      agreed: agreed,
      feedback: feedBack,
    });
    setshowDeleteAccountState(false);
  };
  return (
    <>
      <UserProfileDeleteModal
        isOpen={showDeleteAccountState}
        toggle={toggle}
        deleteAccount={deleteAccount}
      />
      <DeleteDiv>
        <ActivityTextSection>
          <CommonText fontSize="16px" title>
            Delete Account?
          </CommonText>
          <CommonText $label fontWeight="500">
            Your account deletion request will be processed after 7 days.
          </CommonText>
        </ActivityTextSection>
        <ColoredButton type="delete" onClick={() => showDeleteModal()}>
          Delete Account
        </ColoredButton>
      </DeleteDiv>
    </>
  );
};
export default UserDeleteProfile;
