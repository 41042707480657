import { useState, useEffect } from "react";

import moment from "moment";
import DatePicker from "../../components/SingleDatePicker/SingleDatePicker";
import { FilterButton } from "../../styledComponents/buttons";
import { TabLeftGrid } from "../../styledComponents/members";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
  CommonFlex,
} from "../../styledComponents/common";
import {
  ColoredButton,
  PrimaryButton,
  WhiteButton,
} from "../../styledComponents/buttons";
import { IconArrow } from "../../styledComponents/Activity";

import ClockInOutTable from "./ClockInOutTable";

import SearchWithButton from "../../components/Search/SearchWithButton";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";
import LeftArrow_3 from "../../assets/img/icons/LeftArrow_3.svg";
import RightArrow_3 from "../../assets/img/icons/RightArrow_3.svg";
import BackendExportModal from "custom_modules/ReportsNew/BackendExportModal";
import ReactDateRangePicker from "components/ReactDateRangePicker/ReactDateRangePicker";
import AddSingleMemberDropdown from "components/CustomDropdown/AddSingleMemberDropdown";
import { Button } from "reactstrap";
import { GrayButton } from "styledComponents/ProjectsNew";

const ClockInOut = ({
  history,
  selectedOrganization,
  isLoading,
  getClockInOutList,
  clockInOutList,
  getOrganizationMembersList,
  organizationMembersList,
  // pagination
  clockInOutListCount,
  clockInOutPageSize,
  exportClockInOutList,
}) => {
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );
  const [dateLabel, setDateLabel] = useState("");
  const [dateChanged, setDateChanged] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");
  const [selectedMember, setSelectedMember] = useState("");
  const [memberOptions, setMemberOptions] = useState([]);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const [timeFormat, setTimeFormat] = useState("1h5m");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const toggle = () => {
    setIsExportModalOpen(!isExportModalOpen);
  };
  const onMemberSelect = (person) => {
    setCurrentPageNumber(1);
    setSelectedMember(person);
  };
  const clearSelectedMember = () => {
    setSelectedMember(null);
  };
  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };

  const handleSearchByName = () => {
    if (searchTerm && searchTerm.trim()) {
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
  };
  const handleClearSearch = () => {
    // if (currentSearchedTerm) {
    //   getData(selectedDate);
    // }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    setCurrentPageNumber(page);
  };

  const getData = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        tz: selectedOrganization.timezone,
      };
      // if (currentSearchedTerm) {
      //   payload.name = currentSearchedTerm.toLocaleLowerCase().trim();
      // }
      if (selectedMember?.id) {
        payload.user_id = selectedMember.id;
      }
      if (currentPageNumber) {
        payload.page = currentPageNumber;
      }
      // get call
      getClockInOutList(payload);
    }
  };
  useEffect(() => {
    getData();
  }, [
    dateChanged,
    currentPageNumber,
    currentSearchedTerm,
    selectedMember,
    selectedOrganization,
  ]);
  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);
  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedMember("");
      getOrganizationMembersList({
        organization_id: selectedOrganization.id,
      });
    }
  }, [selectedOrganization]);
  const handleExportClockInOutList = () => {
    const name = currentSearchedTerm.toLocaleLowerCase().trim();
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        time_format: timeFormat,
        tz: selectedOrganization.timezone,
      };
      if (selectedMember?.id) {
        payload.user_id = selectedMember.id;
      }
      // if (name) {
      //   payload.name = name;
      // }

      exportClockInOutList(payload);
    }
    toggle();
  };

  const onDateSelect = (date) => {
    setSelectedDate(date);
    getData(date);
  };
  const changeDateRange = (dates) => {
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
  };
  const selectToday = () => {
    setStartDate(moment().format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
    setDateChanged(!dateChanged);
  };
  const singleDateChange = (direction) => {
    if (direction === "right") {
      setStartDate(moment(endDate).add(1, "days"));
      setEndDate(moment(endDate).add(1, "days"));
    } else {
      setStartDate(moment(startDate).subtract(1, "days"));
      setEndDate(moment(startDate).subtract(1, "days"));
    }
    setDateChanged(!dateChanged);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  const getDateLabel = () => {
    const diff = moment(endDate).diff(moment(startDate), "days", true);
    if (
      diff === 0 &&
      moment().format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD")
    ) {
      return "Today selected";
    }
    return diff + 1 + " day" + (diff > 0 ? "s" : "") + " " + "selected";
  };

  useEffect(() => {
    if (startDate && endDate) {
      const maxEndDate = moment(startDate).add(3, "months");
      if (moment(endDate).diff(moment(startDate), "months", true) > 3) {
        setEndDate(maxEndDate);
      }
      setDateLabel(getDateLabel());
    }
  }, [startDate, endDate]);
  return (
    <div className="content">
      <BackendExportModal
        isOpen={isExportModalOpen}
        toggle={toggle}
        excelExport={handleExportClockInOutList}
        title="Clock In/Out"
        timeFormat={timeFormat}
        setTimeFormat={setTimeFormat}
      />
      <PageTitle>Clock In/Out</PageTitle>
      <HeaderContainer>
        <CommonFlex alignItems="end">
          {/* <SearchWithButton
            itemName="Member"
            width="270px"
            searchTermName="member name"
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          /> */}
          <InputWithLabelSection>
            <FilterLabel>Member</FilterLabel>
            <div style={{ width: `40px` }}>
              <AddSingleMemberDropdown
                assigneeManage={selectedMember}
                updateState={onMemberSelect}
                membersList={memberOptions}
                clearAssignee={() => clearSelectedMember()}
              />
            </div>
          </InputWithLabelSection>
          <CommonFlex gap="2px" alignItems="end">
            <InputWithLabelSection width="280px">
              <FilterLabel>Date Range {`(${dateLabel})`}</FilterLabel>
              <ReactDateRangePicker
                startDate={startDate ? moment(startDate) : null}
                startDateId="report-date-range-start-date"
                endDate={endDate ? moment(endDate) : null}
                endDateId="report-date-range-end-date"
                onDatesChange={changeDateRange}
                dateDisplayFormat="DD/MM/YYYY"
                onDateSelect={() => {
                  setDateChanged(!dateChanged);
                }}
                selectLastWeek={selectLastWeek}
                selectLastMonth={selectLastMonth}
                rangeRestricted={true}
                disableFutureDates={true}
              />
            </InputWithLabelSection>
            <IconArrow
              onClick={() => {
                singleDateChange("left");
              }}
              padding="5px"
              size="39px"
              src={LeftArrow_3}
            />
            <IconArrow
              onClick={() => {
                moment(endDate).isBefore(moment().subtract(1, "day")) &&
                  singleDateChange("right");
              }}
              disabled={!moment(endDate).isBefore(moment().subtract(1, "day"))}
              padding="5px"
              size="39px"
              src={RightArrow_3}
            />
            <WhiteButton
              type="nav"
              style={{
                height: "38px",
              }}
              onClick={selectToday}
            >
              Today
            </WhiteButton>
          </CommonFlex>
        </CommonFlex>
        <CommonFlex alignItems="end">
          {selectedOrganization && selectedOrganization.role !== "member" && (
            <PrimaryButton
              onClick={() => history.push("/user/reports/time-and-activity")}
            >
              See Today's Report
            </PrimaryButton>
          )}
          {(selectedOrganization?.role === "admin" ||
            selectedOrganization?.role === "owner" ||
            selectedOrganization?.role === "team_leader") &&
            clockInOutList?.length > 0 && (
              <ColoredButton
                style={{
                  marginBottom: "1px",
                }}
                type="gray"
                onClick={() => toggle()}
              >
                Export
              </ColoredButton>
            )}
        </CommonFlex>
      </HeaderContainer>
      {clockInOutListCount && clockInOutListCount > 0 && clockInOutPageSize ? (
        <TopPagination
          itemName="members"
          totalCount={clockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={clockInOutPageSize}
        />
      ) : null}
      <ClockInOutTable clockInOutList={clockInOutList} isLoading={isLoading} />
      {clockInOutListCount && clockInOutListCount > 0 && clockInOutPageSize ? (
        <BottomPagination
          totalCount={clockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={clockInOutPageSize}
        />
      ) : null}
    </div>
  );
};

export default ClockInOut;
