import { useState, useEffect } from "react";

import moment from "moment";

import {
  SummaryContainer,
  SummarySectionContainer,
  SummaryTextSection,
  SummaryText,
  SummaryMultipleTableSection,
  SummaryLabel,
  SelectAllDateMemberSection,
} from "../../styledComponents/payroll";

import { ColoredButton } from "../../styledComponents/buttons";

import { toHHMMSS, getHours } from "../../utils/helper";
import { currencySymbolMap } from "../../utils/currencies";

const SummarySingle = (props) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (props.summaryData) {
      const key = Object.keys(props.summaryData)[0];
      setData({
        currency: key,
        payment: (props.summaryData[key].totalPay / 100).toFixed(2),
        duration: props.summaryData[key].duration || null,
      });
    }
  }, [props.summaryData]);
  return (
    <SummarySectionContainer
      columns={
        props.type && props.type === "fixed" ? "auto auto" : "auto auto auto"
      }
    >
      {data && (
        <>
          <SummaryTextSection>
            <SummaryText>
              {`${moment(props.startDate).format("MMM DD, YYYY")} - ${moment(
                props.endDate
              ).format("MMM DD, YYYY")}`}
            </SummaryText>
            <SummaryLabel>Date Range</SummaryLabel>
          </SummaryTextSection>
          {props.type && props.type === "hourly" && (
            <SummaryTextSection>
              <SummaryText>{getHours(toHHMMSS(data.duration))}</SummaryText>
              <SummaryLabel>Total Time Worked</SummaryLabel>
            </SummaryTextSection>
          )}
          <SummaryTextSection>
            <SummaryText total>
              {data.currency} ({currencySymbolMap[data.currency]}){" "}
              {data.payment}
            </SummaryText>
            <SummaryLabel>Total Payment</SummaryLabel>
          </SummaryTextSection>
        </>
      )}
    </SummarySectionContainer>
  );
};

const SummaryMultiple = (props) => {
  return (
    <SummaryTextSection multiple>
      <SummaryMultipleTableSection
        columns={
          props.type && props.type === "fixed" ? "2fr 1fr" : "2fr 1fr 1fr"
        }
      >
        <SummaryLabel>Date Range</SummaryLabel>
        {props.type && props.type === "hourly" && (
          <SummaryLabel>Total Time Worked</SummaryLabel>
        )}
        <SummaryLabel>Total Payment</SummaryLabel>
      </SummaryMultipleTableSection>
      {props.summaryData &&
        Object.keys(props.summaryData).map((key, index) => (
          <SummaryMultipleTableSection
            key={index}
            columns={
              props.type && props.type === "fixed" ? "2fr 1fr" : "2fr 1fr 1fr"
            }
          >
            <SummaryText multiple>
              {`${moment(props.startDate).format("MMM DD, YYYY")} - ${moment(
                props.endDate
              ).format("MMM DD, YYYY")}`}
            </SummaryText>
            {props.type &&
              props.type === "hourly" &&
              props.summaryData[key] && (
                <SummaryText multiple>
                  {getHours(toHHMMSS(props.summaryData[key].duration))}
                </SummaryText>
              )}
            <SummaryText multiple total>
              {key}{" "}
              {props.summaryData[key] &&
                (props.summaryData[key].totalPay / 100).toFixed(2)}
            </SummaryText>
          </SummaryMultipleTableSection>
        ))}
    </SummaryTextSection>
  );
};

export const PayrollSummaryHistory = (props) => {
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    if (props.paymentData && props.paymentData.length > 0) {
      const data = {};
      if (props.type === "hourly") {
        props.paymentData.map((payment) => {
          if (data[payment.currency]) {
            data[payment.currency].totalPay += payment.total_pay;
            data[payment.currency].duration += payment.working_duration;
          } else {
            data[payment.currency] = {
              totalPay: payment.total_pay,
              duration: payment.working_duration,
            };
          }
        });
      } else if (props.type === "fixed") {
        props.paymentData.map((payment) => {
          if (data[payment.currency]) {
            data[payment.currency].totalPay += payment.paid_amt;
          } else {
            data[payment.currency] = {
              totalPay: payment.paid_amt,
            };
          }
        });
      }

      setSummaryData({ ...data });
    } else if (props.paymentData && props.paymentData.length === 0) {
      setSummaryData({});
    }
  }, [props.paymentData]);

  return summaryData && summaryData !== {} ? (
    <SummaryContainer
      width={props.type && props.type === "fixed" ? "60%" : "65%"}
    >
      {Object.keys(summaryData).length === 1 ? (
        <SummarySingle
          startDate={props.startDate}
          endDate={props.endDate}
          summaryData={summaryData}
          type={props.type}
        />
      ) : (
        <SummaryMultiple
          startDate={props.startDate}
          endDate={props.endDate}
          summaryData={summaryData}
          type={props.type}
        />
      )}
    </SummaryContainer>
  ) : null;
};

export const PayrollSummaryPayables = (props) => {
  const [summaryData, setSummaryData] = useState(null);

  useEffect(() => {
    if (props.paymentData && props.paymentData.length > 0) {
      let duration = 0;
      let totalPay = 0;
      props.paymentData.map((payment) => {
        duration += payment.total_duration;
        totalPay += payment.total_pay;
      });

      setSummaryData({
        currency: props.paymentData[0].currency,
        duration,
        totalPay,
      });
    } else if (props.paymentData && props.paymentData.length === 0) {
      setSummaryData(null);
    }
  }, [props.paymentData]);

  return summaryData ? (
    <SummaryContainer
      width="90%"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <SummarySectionContainer style={{ width: "80%" }}>
        <SummaryTextSection>
          <SummaryText>
            {`${moment(props.startDate).format("MMM DD, YYYY")} - ${moment(
              props.endDate
            ).format("MMM DD, YYYY")}`}
          </SummaryText>
          <SummaryLabel>Date Range</SummaryLabel>
        </SummaryTextSection>
        <SummaryTextSection>
          <SummaryText>{getHours(toHHMMSS(summaryData.duration))}</SummaryText>
          <SummaryLabel>Total Time Worked</SummaryLabel>
        </SummaryTextSection>
        <SummaryTextSection>
          <SummaryText total>
            {summaryData.currency} ({currencySymbolMap[summaryData.currency]}){" "}
            {(summaryData.totalPay / 100).toFixed(2)}
          </SummaryText>
          <SummaryLabel>Total Payment</SummaryLabel>
        </SummaryTextSection>
      </SummarySectionContainer>
      <SelectAllDateMemberSection columns="auto 1fr">
        <ColoredButton
          type="gray"
          padding="0 15px"
          onClick={() => props.toggleConfirmPaymentModal("all")}
        >
          Mark all as paid
        </ColoredButton>
        {/* <ColoredButton  disabled={!selectAllDatesMembers} padding="11px 17px">
            Send Payment
          </ColoredButton> */}
      </SelectAllDateMemberSection>
    </SummaryContainer>
  ) : null;
};
