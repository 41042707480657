import {
  InputWithLabelSection,
  FilterLabel,
  CommonImage,
} from "../../styledComponents/common";

import {
  SearchWithButtonContainer,
  SearchWithButtonInput,
  SearchButton,
  ClearSearchButton,
} from "./searchStyles";

import searchIcon from "../../assets/img/icons/search-white.svg";
import crossWhite from "../../assets/img/icons/remove_assignee.svg";

const SearchWithButton = (props) => {
  const {
    itemName,
    searchTermName,
    searchInput,
    onInputChange,
    handleSearch,
    handleClearSearch,
    noLabel,
    margin,
    padding,
    width,
  } = props;

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <InputWithLabelSection margin={margin} padding={padding} width={width}>
      {!noLabel && <FilterLabel>Search {itemName}</FilterLabel>}
      <SearchWithButtonContainer>
        <SearchWithButtonInput
          value={searchInput}
          onChange={onInputChange}
          onKeyDown={(e) => handleKeyPress(e)}
          placeholder={`Search by ${searchTermName}...`}
        />
        {searchInput && (
          <ClearSearchButton onClick={() => handleClearSearch()}>
            <CommonImage src={crossWhite} alt="clear" />
          </ClearSearchButton>
        )}
        <SearchButton onClick={() => handleSearch()}>
          <CommonImage src={searchIcon} alt="search" />
        </SearchButton>
      </SearchWithButtonContainer>
    </InputWithLabelSection>
  );
};

export default SearchWithButton;
