import { useState, useEffect } from "react";
import moment from "moment";
import Select from "../../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../../components/DropdownStyle/FilterDropDownStyle";

import {
  InvoiceSectionContainer,
  InvoiceSectionTitle,
  ProjectInvoiceTableContainer,
  InvoiceTableItem,
  NoDataGraphContainer,
  NoDataGraph,
  NoDataText,
} from "../../../styledComponents/ProjectsNew";
import { TableText } from "../../../styledComponents/common";
import { StatusBox } from "../../../styledComponents/invoice";

import { currencySymbolMap } from "../../../utils/currencies";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

import RoundImageNameComp from "../../../components/RoundImageName/RoundImageNameComp";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

const InvoiceSection = (props) => {
  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    isLoading,
    selectedOrganization,
    projectId,
    getProjectInvoices,
    invoicesList,
    history,

    // pagination
    invoiceCount,
    invoicePageSize,
  } = props;

  const draftOptions = [
    { value: "edit", label: "Edit Invoice" },
    { value: "preview", label: "Preview Invoice" },
  ];
  const sentOptions = [{ value: "preview", label: "Preview Invoice" }];

  const onActionSelect = (e, invoiceId) => {
    if (e.value === "edit") {
      history.push(`/user/edit-invoice?id=${invoiceId}`);
    } else if (e.value === "preview") {
      history.push(`/user/invoice-preview?id=${invoiceId}`);
    }
  };

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [selectedOrganization]);

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        page,
      };
      getProjectInvoices(payload);
    }
    setCurrentPageNumber(page);
  };

  if (isLoading) {
    return <ComponentCircleLoader />;
  }
  if (!invoicesList || (invoicesList && invoicesList.length === 0)) {
    return (
      <NoDataComponent
        title="No invoice is linked with this project!"
        imageHeight="120px"
        titleFontSize="16px"
      />
    );
  }
  return (
    <>
      {invoiceCount && invoiceCount > 0 && invoicePageSize ? (
        <TopPagination
          itemName="invoices"
          totalCount={invoiceCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={invoicePageSize}
          padding="0 30px"
        />
      ) : null}
      <InvoiceSectionContainer>
        <ProjectInvoiceTableContainer heading>
          <InvoiceTableItem>
            <TableText>Invoice Number</TableText>
          </InvoiceTableItem>
          <InvoiceTableItem>
            <TableText>Client Name</TableText>
          </InvoiceTableItem>
          <InvoiceTableItem>
            <TableText>Issued Date</TableText>
          </InvoiceTableItem>
          <InvoiceTableItem>
            <TableText>Total Amount</TableText>
          </InvoiceTableItem>
          <InvoiceTableItem>
            <TableText>Status</TableText>
          </InvoiceTableItem>
        </ProjectInvoiceTableContainer>
        {invoicesList.map((invoice, index) => (
          <ProjectInvoiceTableContainer key={invoice.id}>
            <InvoiceTableItem>
              <TableText
                name
                hoverPointer
                hoverUnderline
                onClick={() =>
                  history.push(`/user/invoice-preview?id=${invoice.id}`)
                }
              >
                {invoice.invoice_number}
              </TableText>
            </InvoiceTableItem>
            <InvoiceTableItem>
              <TableText
                name
                hoverPointer
                hoverUnderline
                onClick={() =>
                  history.push(`/user/client-details?id=${invoice.client_id}`)
                }
              >
                {invoice.client_name}
              </TableText>
            </InvoiceTableItem>
            <InvoiceTableItem>
              <TableText name>
                {moment(invoice.issued_date).format("MMM DD, YYYY")}
              </TableText>
            </InvoiceTableItem>
            <InvoiceTableItem>
              <TableText name>
                {/* <span style={{ whiteSpace: `nowrap` }}>{`${
              invoice.currency
            } ${invoice.paid_amount.toFixed(2)}`}</span>{" "}
            /{" "}
            <span style={{ whiteSpace: `nowrap` }}>{`${
              invoice.currency
            } ${invoice.discounted_amount.toFixed(2)}`}</span> */}
                {currencySymbolMap[invoice.currency]}{" "}
                {parseFloat(invoice.discounted_amount).toFixed(2)}
              </TableText>
            </InvoiceTableItem>

            <InvoiceTableItem>
              <StatusBox status={invoice.status}>
                {invoice.status === "partial"
                  ? "Partially Paid"
                  : invoice.status}
              </StatusBox>
            </InvoiceTableItem>
            <InvoiceTableItem buttonPadding>
              <Select
                isSearchable={false}
                value={null}
                options={
                  invoice.status === "draft" ? draftOptions : sentOptions
                }
                placeholder="Actions"
                onChange={(e) => {
                  onActionSelect(e, invoice.id);
                }}
                styles={FilterDropDownStyle({
                  height: "39px",
                  width: "150px",
                  menuWidth: "150px",
                })}
              />
            </InvoiceTableItem>
          </ProjectInvoiceTableContainer>
        ))}
      </InvoiceSectionContainer>
      {invoiceCount && invoiceCount > 0 && invoicePageSize ? (
        <BottomPagination
          totalCount={invoiceCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={invoicePageSize}
        />
      ) : null}
    </>
  );
};

export default InvoiceSection;
