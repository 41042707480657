import { useEffect } from "react";
import styled from "styled-components";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import TagManager from "react-gtm-module";

const customCss = css`
  margin: 5px;
  text-align: center;
`;

const VerifyContainer = styled.div`
  height: 100%;
  background: #f7fafb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerifyText = styled.p`
  font-size: 28px;
`;

const TextLoader = (props) => {
  return (
    <div className="sweet-loading">
      <PulseLoader
        size={8}
        color={"#2f394e"}
        loading={props.loading}
        css={customCss}
      />
    </div>
  );
};
const OnboardingLoader = (props) => {
  const tagManagerArgs = {
    gtmId: "GTM-NH9X5RJV",
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    setTimeout(() => {
      props.history.push({
        pathname: "/user/dashboard",
        state: { message: "onboard" },
      });
    }, 5000);
  }, []);
  return (
    <VerifyContainer>
      <VerifyText>Sit tight, we are getting things ready for you</VerifyText>
      <TextLoader loading />
    </VerifyContainer>
  );
};

export default OnboardingLoader;
