import { EXPORT_ATTENDANCE, GET_CLOCK_IN_OUT_LIST } from "../../modules/constants";

export function getClockInOutList(payload) {
  return {
    type: GET_CLOCK_IN_OUT_LIST,
    payload,
  };
}
export function exportClockInOutList(payload) {
  return {
    type: EXPORT_ATTENDANCE,
    payload,
  };
}
