import { connect } from "react-redux";

// Component
import ChangeSubscription from "./ChangeSubscription";

// Actions
import { getOrganizationPlans } from "../../Organization/OrganizationActions";

import {
  getOrganizationSubscription,
  clearCurrentSubscription,
} from "../../Subscription/subscriptionActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.subscription.isLoading,
  subscriptionPlans: state.organization.allPlans,
  currentSubscription: state.subscription.currentSubscription,
  selectedOrganization: state.organization.selectedOrganization,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationPlans: () => dispatch(getOrganizationPlans()),
  getOrganizationSubscription: (payload) =>
    dispatch(getOrganizationSubscription(payload)),
  clearCurrentSubscription: () => dispatch(clearCurrentSubscription()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubscription);
