export const getHours = (time) => {
  const times = time.split(":");
  const hours = parseInt(times[0]);
  const minutes = parseInt(times[1]);
  return `${hours} h ${minutes} m`;
};

export const getTotal = (prev, next) => {
  const splittedPrev = prev.split(":");
  const splittedNext = next.split(":");

  const prevHour = parseInt(splittedPrev[0]);
  const prevMin = parseInt(splittedPrev[1]);
  const prevSec = parseInt(splittedPrev[2]);

  const nextHour = parseInt(splittedNext[0]);
  const nextMin = parseInt(splittedNext[1]);
  const nextSec = parseInt(splittedNext[2]);

  const totalHour = prevHour + nextHour;
  const totalMin = prevMin + nextMin;
  const totalSec = prevSec + nextSec;

  if (totalSec > 60) {
    totalMin += parseInt(totalSec / 60);
    totalSec = parseInt(totalSec % 60);
  }
  if (totalMin >= 60) {
    totalHour += parseInt(totalMin / 60);
    totalMin = parseInt(totalMin % 60);
  }
  return `${totalHour}:${totalMin}:${totalSec}`;
};

export const toHHMMSS = (secs) => {
  const getSeconds = `0${secs % 60}`.slice(-2);
  const minutes = `${Math.floor(secs / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours =
    secs < 36000
      ? `0${Math.floor(secs / 3600)}`.slice(-2)
      : `${Math.floor(secs / 3600)}`;

  return `${getHours}:${getMinutes}:${getSeconds}`;
};

export const getHourMin = (seconds) => {
  let hourMin = ` ${parseInt(seconds / 3600)} h ${parseInt(
    (seconds % 3600) / 60
  )} m`;
  return hourMin;
};

export const toHHMM = (secs) => {
  const minutes = `${Math.floor(secs / 60)}`;
  const getMinutes = `0${minutes % 60}`.slice(-2);
  const getHours = `0${Math.floor(secs / 3600)}`.slice(-2);

  return `${getHours}:${getMinutes}`;
};

export const formatDurationToHoursMinutes = (duration) => {
  let formattedDuration = "";
  let hours = parseInt(duration / 3600);
  let minutes = Math.round((duration / 60) % 60);
  if (duration <= 0) {
    duration = 0;
  }
  if (duration < 60) {
    formattedDuration = `${parseInt(duration)}s`;
  } else {
    if (hours === 0) {
      hours = ``;
    } else {
      hours = `${hours}h`;
    }

    minutes = `${minutes}m`;

    formattedDuration = `${hours} ${minutes}`;
  }
  return formattedDuration;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  let emailRegex =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

// Validate password
export function password_validate(p) {
  return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/.test(p);
}

function isASCII(str) {
  // eslint-disable-next-line
  return /^[\x00-\x7F]*$/.test(str);
}

function isValidPhone(phone) {
  const regex = /^(?:[0-9] ?){6,14}[0-9]$/;
  return regex.test(phone);
}

export function checkUrl(url) {
  const regex = /https?:\/\//gi;
  return regex.test(url);
}

export function checkHtml(value) {
  const regex = /<\/?[a-z][^>]*>/gi;
  return regex.test(value);
}

export function handleValidation(name, type, value, options) {
  let res = { isValid: true, fieldName: name, message: "" };
  if (options && options.required && !value) {
    res.message = "Field is required!";
    res.isValid = false;
    res.required = options.required;
  } else if (
    options &&
    options.required &&
    typeof value !== "undefined" &&
    value.length === 0
  ) {
    res.message = "Field is required!";
    res.isValid = false;
    res.required = options.required;
  } else if (
    name !== "email" &&
    options &&
    options.required &&
    typeof value === "string" &&
    value.toLowerCase().includes("select")
  ) {
    res.message = "Choose Valid Option!";
    res.isValid = false;
    res.required = options.required;
  } else if (
    options &&
    options.required &&
    options.multiSelect &&
    value.length === 0
  ) {
    res.message = "Choose At Least One Option!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.minLength && value.length < options.minLength) {
    res.message = "Minimum length should be " + options.minLength + "!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.maxLength && value.length > options.maxLength) {
    res.message = "Maximum length should be " + options.maxLength + "!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.isASCII && !isASCII(value)) {
    res.message = "Only ASCII characters are allowed!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && options.phone && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
  } else if (options && options.contact && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && name === "phone" && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
    res.required = options.required;
  } else if (options && name === "contact" && !isValidPhone(value)) {
    res.message = "Not valid phone number!";
    res.isValid = false;
    res.required = options.required;
  } else {
    switch (name) {
      case "email":
        if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          res.message = "Not valid email!";
          res.isValid = false;
          res.required = options.required;
        }
        break;
      case "password":
        if (value.length < 6) {
          res.message = "Min 6 characters!";
          res.isValid = false;
          res.required = options.required;
        }
        break;
      default:
        break;
    }
  }
  return res;
}

const rad = (x) => {
  return (x * Math.PI) / 180;
};

export const getDistanceFromCoords = (p1, p2) => {
  const R = 6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

export const getFullName = (user) => {
  const { first_name, last_name } = user;

  let fullName = first_name;

  if (last_name) {
    fullName += ` ${last_name}`;
  }

  return fullName;
};

export const arrayInRange = (start, stop, step) => {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );
};
