import { connect } from "react-redux";

import JobSites from "./JobSites";

import {
  getJobSitesList,
  setJobSiteDetails,
  deleteJobSite,
} from "./jobSitesActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  deleteJobSiteLoading: state.jobSites.deleteJobSiteLoading,

  sitesListLoading: state.jobSites.sitesListLoading,
  jobSitesList: state.jobSites.jobSitesList,
  jobSiteDetails: state.jobSites.jobSiteDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getJobSitesList: (payload) => dispatch(getJobSitesList(payload)),
  setJobSiteDetails: (payload) => dispatch(setJobSiteDetails(payload)),
  deleteJobSite: (payload) => dispatch(deleteJobSite(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSites);
