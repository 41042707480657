
import ContentLoader from "react-content-loader";

const ActivityReportLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={794}
      height={250}
      viewBox="0 0 794 250"
      backgroundColor="#e8eaee"
      foregroundColor="#f3f3f3"
      {...props}
    >
      <rect x="16.56" width="12" height="144.98" rx="6" />
      <rect y="159.41" width="45.13" height="71.49" rx="12" />
      <rect x="128.86" width="12" height="144.98" rx="6" />
      <rect x="112.3" y="159.41" width="45.13" height="71.49" rx="12" />
      <rect x="253.21" width="12" height="144.98" rx="6" />
      <rect x="236.65" y="159.41" width="45.13" height="71.49" rx="12" />
      <rect x="374.36" width="12" height="144.98" rx="6" />
      <rect x="357.8" y="159.41" width="45.13" height="71.49" rx="12" />
      <rect x="501.31" width="12" height="144.98" rx="6" />
      <rect x="484.74" y="159.41" width="45.13" height="71.49" rx="12" />
      <rect x="624.85" width="12" height="144.98" rx="6" />
      <rect x="608.29" y="159.41" width="45.13" height="71.49" rx="12" />
      <rect x="747.6" width="12" height="144.98" rx="6" />
      <rect x="731.03" y="159.41" width="45.13" height="71.49" rx="12" />
    </ContentLoader>
  );
};

export default ActivityReportLoader;
