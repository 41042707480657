import { connect } from "react-redux";

import ManualTimeSettings from "./ManualTimeSettings";

import {
  getTimesheetSettings,
  updateTimesheetSettings,
} from "../timesheetSettingsActions";

const mapStateToProps = (state) => ({
  isLoading: state.timesheetSettings.isLoading,
  allowUsersAddManualTime: state.timesheetSettings.allowUsersAddManualTime,
  selectedOrganization: state.organization.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  getTimesheetSettings: (payload) => dispatch(getTimesheetSettings(payload)),
  updateTimesheetSettings: (payload) =>
    dispatch(updateTimesheetSettings(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualTimeSettings);
