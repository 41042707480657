import { connect } from "react-redux";

// Component
import Dashboard from "./Dashboard";

// Actions
import {
  //new apis
  getDashboardCompetitiveAnalytics,
  getDashboardWeeklyAggregatedData,
  getDashboardLastWeekAggregatedData,
  getDashboardTodaysActivity,
  getDashboardOrganizationAnalytics,
  getDashboardUserAnalytics,
} from "./dashboardActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  dashboardData: state.dashboard,
  selectedOrganization: state.organization.selectedOrganization,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDashboardCompetitiveAnalytics: (details) =>
    dispatch(getDashboardCompetitiveAnalytics({ ...details })),
  getDashboardWeeklyAggregatedData: (details) =>
    dispatch(getDashboardWeeklyAggregatedData({ ...details })),
  getDashboardLastWeekAggregatedData: (details) =>
    dispatch(getDashboardLastWeekAggregatedData({ ...details })),
  getDashboardTodaysActivity: (details) =>
    dispatch(getDashboardTodaysActivity({ ...details })),
  getDashboardOrganizationAnalytics: (details) =>
    dispatch(getDashboardOrganizationAnalytics({ ...details })),
  getDashboardUserAnalytics: (details) =>
    dispatch(getDashboardUserAnalytics({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
