import { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import MembersDropdownStyle from "../../../components/DropdownStyle/MembersDropdownStyle";
import { validateEmail } from "../../../utils/helper";

import {
  MemberInvitationContainer,
  RedStar,
} from "../../../styledComponents/members";
import {
  PageTitle,
  CardTitle,
  Container,
  InputLabel,
  InputField,
  ContentHeader,
  CommonFlex,
  CommonText,
  CommonGrid,
} from "../../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../../styledComponents/buttons";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";

import ProjectDropdown from "../../../components/CommonFilter/ProjectDropdown";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

const ButtonContainer = styled.div`
  display: flex;
`;
const EmailAlert = styled.p`
  margin: 10px 0px;
  color: ${({ theme }) => theme.colors.main.error};
  font-size: ${({ theme }) => theme.font.pageText.size};
`;
const EmailText = styled.div`
  font-size: 13px;
  height: 40px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
`;
const ProjectNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const ProjectNameDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-size: 13px;
  margin-right: 8px;
`;
const InvitationSentText = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  color: ${({ theme }) => theme.colors.main.primary};
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

const UpgradeSectionForSolo = ({ history }) => {
  return (
    <CommonFlex direction="column" alignItems="center" padding="60px 30px">
      <CommonText title name fontSize="24px" margin="0 0 20px">
        Upgrade your plan to invite more members
      </CommonText>
      <CommonText
        $label
        fontWeight="500"
        margin="0 150px 40px"
        fontSize="16px"
        style={{ textAlign: "center" }}
      >
        You can not have more than one member in Solo plan. Please, upgrade your
        plan to add your team members.
      </CommonText>
      <PrimaryButton onClick={() => history.push("/user/subscription")}>
        Upgrade
      </PrimaryButton>
    </CommonFlex>
  );
};

const MemberInvitation = (props) => {
  const [invitationList, setInvitationList] = useState([
    {
      email: "",
      projects: [],
    },
  ]);
  const [saveErrors, setSaveErrors] = useState({});

  const {
    selectedOrganization,
    clearMemberInvited,
    projectList,
    memberInvited,
    inviteMember,
    history,
    projectIsLoading,
    inviteMemberLoading,
  } = props;

  useEffect(() => {
    clearMemberInvited();

    return () => {
      clearMemberInvited();
    };
  }, []);

  useEffect(() => {
    if (memberInvited) {
      let object = {
        email: "",
        projects: [],
      };
      setInvitationList([...invitationList, object]);
    }
  }, [memberInvited]);

  const sendAndAddAnother = () => {
    let list = [...invitationList];
    if (selectedOrganization && selectedOrganization.id) {
      let invitationItem = list[list.length - 1];
      let projectList = [];
      if (invitationItem.projects && invitationItem.projects.length > 0) {
        invitationItem.projects.map((project) => {
          projectList.push(project.value);
        });
      }
      if (checkError(invitationItem)) {
        let payload = {
          organization_id: selectedOrganization.id,
          email: invitationItem.email,
          project_ids: projectList,
        };
        inviteMember(payload);
      }
    }
  };

  const checkError = (item) => {
    let saveErrors = {};
    let isValid = true;
    if (!item.email) {
      saveErrors["email"] = "Please type an email address.";
      isValid = false;
    } else if (!validateEmail(item.email)) {
      saveErrors["email"] = "Please give a valid email address.";
      isValid = false;
    }
    setSaveErrors(saveErrors);
    return isValid;
  };

  const onEmailChange = (e, index) => {
    const { value } = e.target;
    setSaveErrors({});
    let newArr = [...invitationList];
    newArr[index].email = value;
    setInvitationList(newArr);
  };
  const onProjectChange = (e, index) => {
    let newArr = [...invitationList];
    newArr[index].projects = e;
    setInvitationList(newArr);
  };

  const handleFinish = () => {
    const list = [...invitationList];
    if (
      list &&
      list.length > 0 &&
      list[list.length - 1] &&
      list[list.length - 1].email
    ) {
      if (selectedOrganization && selectedOrganization.id) {
        let item = list[list.length - 1];
        const projectList = [];
        if (item.projects && item.projects.length > 0) {
          item.projects.map((project) => {
            projectList.push(project.value);
          });
        }
        if (checkError(item)) {
          const payload = {
            organization_id: selectedOrganization.id,
            email: item.email,
            project_ids: projectList,
          };
          inviteMember(payload);
          setTimeout(() => {
            history.push("/user/members?tab=invited");
          }, 300);
        }
      }
    } else {
      history.push("/user/members?tab=invited");
    }
  };
  return (
    <div className="content">
      <PageTitle>Members</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/members")}
          subTitle="Back to all members"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        {selectedOrganization &&
        selectedOrganization.plan &&
        selectedOrganization.plan.name === "Solo" ? (
          <UpgradeSectionForSolo history={history} />
        ) : projectIsLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CardTitle>Member Invitation</CardTitle>
            <MemberInvitationContainer>
              <InputLabel>
                Email <RedStar>*</RedStar>
              </InputLabel>
              <InputLabel>Projects</InputLabel>
              <div />
              {invitationList.length > 1 &&
                invitationList
                  .slice(0, invitationList.length - 1)
                  .map((item, i) => (
                    <Fragment key={i}>
                      <EmailText>{item.email}</EmailText>
                      <ProjectNameContainer>
                        {item.projects.length > 0 ? (
                          item.projects.map((project, index) => (
                            <ProjectNameDiv key={index}>
                              {project.label.length <= 7
                                ? project.label
                                : `${project.label.slice(0, 8)}...`}
                            </ProjectNameDiv>
                          ))
                        ) : (
                          <EmailText>No Project</EmailText>
                        )}
                      </ProjectNameContainer>
                      <InvitationSentText>Invitation Sent</InvitationSentText>
                    </Fragment>
                  ))}
              {invitationList && invitationList.length > 0 && (
                <>
                  <InputField
                    type="text"
                    placeholder="Write email address here..."
                    value={invitationList[invitationList.length - 1].email}
                    onChange={(e) =>
                      onEmailChange(e, invitationList.length - 1)
                    }
                  />
                  <ProjectDropdown
                    multiple
                    selectedOrganization={selectedOrganization}
                    selectedProject={
                      invitationList[invitationList.length - 1].projects
                    }
                    placeholder="Select Projects"
                    selectProject={(e) => {
                      onProjectChange(e, invitationList.length - 1);
                    }}
                    style={MembersDropdownStyle()}
                  />
                </>
              )}
            </MemberInvitationContainer>
            {saveErrors &&
              Object.keys(saveErrors).length !== 0 &&
              saveErrors.email && <EmailAlert>{saveErrors.email}</EmailAlert>}
            <CommonFlex gap="12px" margin="40px 0 0 0">
              <WhiteButton type="cancel" onClick={() => handleFinish()}>
                I'm Done With Invitation
              </WhiteButton>
              <PrimaryButton
                padding={inviteMemberLoading && "0 25px"}
                onClick={() => sendAndAddAnother()}
              >
                {inviteMemberLoading ? (
                  <ButtonTextLoader
                    loadingText="Sending Invitation"
                    fontSize="13px"
                  />
                ) : (
                  "Send & Add Another"
                )}
              </PrimaryButton>
            </CommonFlex>
          </>
        )}
      </Container>
    </div>
  );
};

export default MemberInvitation;
