import React from "react";

import Map from "../../../custom_modules/Map";
// import ActivityFilter from "../activityContainer";
import ActivityFilter from "../../ActivityNew/activityFilterContainer";

import { PageTitle, CommonGrid } from "../../../styledComponents/common";

import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

class ActivityLocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { locationMap, isLoading } = this.props;
    return (
      <div className="content">
        <CommonGrid alignItem="center">
          <PageTitle>Locations</PageTitle>
        </CommonGrid>
        <ActivityFilter title="Location" />
        <div className="pagecontent ">
          {isLoading ? (
            <ComponentCircleLoader />
          ) : locationMap.length &&
            locationMap.some((item) => {
              return item.locations && item.locations.length;
            }) ? (
            <Map info={locationMap} key={Math.random().toString()} />
          ) : (
            <NoDataComponent title="Sorry! No GPS Tracking data found." />
          )}
        </div>
      </div>
    );
  }
}

export default ActivityLocations;
