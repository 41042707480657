import styled from "styled-components";

export const SettingsTabContainer = styled.div`
  width: max-content;
  padding: 30px 30px 20px;
`;

export const OrgSettingsSection = styled.div`
  padding: ${({ padding }) => padding || "30px"};
`;
export const SettingPropertySection = styled.div`
  margin: ${({ margin }) => margin || "0"};
`;

export const ToggleButton = styled.div`
  display: grid;
  align-items: center;
  width: 31px;
  height: 18px;
  background-color: ${(props) => (props.active ? `#20BEAD` : `#C2CCE1`)};
  padding: 0 4px;
  border-radius: 10px;
  cursor: pointer;
`;
export const ToggleButtonRound = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  margin-left: ${(props) => props.active && `auto`};
`;

export const WarningDiv = styled.div`
  margin-top: 30px;
  width: max-content;
  background: ${({ theme }) => theme.colors.main.white};
  border: 1px solid ${({ theme }) => theme.colors.main.warning};
  border-radius: ${({ theme }) => theme.borderRadii.card};
  display: flex;
  padding: 10px;
  height: 50px;
  align-items: center;
  justify-content: start;
`;

export const ScreenshotFrequencyButton = styled.div`
  width: 120px;
  height: 40px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.main.primary : theme.colors.main.primaryLight};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  cursor: ${({ isActive }) => (isActive ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.main.white : theme.colors.main.textSecondary};
  font-size: 13px;
  &:hover {
    border: ${({ isActive, theme }) =>
      !isActive && `1px solid ${theme.colors.border.primary}`};
  }

  & span {
    color: ${({ theme, isActive }) => !isActive && theme.colors.main.labelText};
    font-size: 11px;
    margin: 3px 0 0 2px;
  }
`;
