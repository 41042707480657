import { useState, useEffect } from "react";
import { planFeatures } from "./pricingPlanData";
import { PricingAccordion } from "./PricingAccordion";
import {
  PageTitle,
  CardTitle,
  CommonGrid,
  CommonText,
} from "../../../styledComponents/common";
import {
  PricingMainContainer,
  ToggleSection,
  ToggleText,
  PricingCardContainer,
  SinglePricingCard,
  PlanNameAndTagContainer,
  PlanName,
  PlanNameTag,
  PlanPriceContainer,
  PlanPrice,
  PlanPriceText,
  PlanDescription,
  PlanFeaturesContainer,
  SinglePlanFeature,
  FeatureText,
  PlanSelectButton,
  EverythingInPrev,
  PlusAddonDiv,
  TickImage,
  SeeFullComparisonDiv,
  SeeFullComparisonButton,
} from "../../../styledComponents/billing";
import {
  ToggleButton,
  ToggleButtonRound,
} from "../../../styledComponents/invoice";
import {
  YearlySaveBadge,
  SubscriptionCurrentPlanBadge,
  CurrentPlanDot,
  CurrentPlanBadgeText,
} from "../subscriptionStyles";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import DowngradeModal from "./DowngradeModal";
import SoloErrorModal from "./SoloErrorModal";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import tickSolo from "../../../assets/img/common/tick-solo.svg";
import tickStandard from "../../../assets/img/common/tick-standard.svg";
import tickPremium from "../../../assets/img/common/tick-premium.svg";
import tickElite from "../../../assets/img/common/tick-elite.svg";
import arrow from "../../../assets/img/common/arrow.svg";

const getIsCurrent = (
  planId,
  planStatus,
  planInterval,
  yearlyId,
  monthlyId
) => {
  if (planId && planStatus) {
    if (planStatus === "active") {
      if (planInterval === "yearly") {
        return planId === yearlyId;
      } else {
        return planId === monthlyId;
      }
    }
  }
  return false;
};

const CurrentPlanBadge = (props) => {
  return (
    <SubscriptionCurrentPlanBadge isCurrent={props.isCurrent} plan={props.plan}>
      <CurrentPlanDot plan={props.plan} />
      <CurrentPlanBadgeText>Current Plan</CurrentPlanBadgeText>
    </SubscriptionCurrentPlanBadge>
  );
};

const ChangeSubscription = (props) => {
  const [planInterval, setPlanInterval] = useState("yearly");
  const [currentPlanId, setCurrentPlanId] = useState(null);
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [currentPlanInterval, setCurrentPlanInterval] = useState(null);
  const [currentPlanStatus, setCurrentPlanStatus] = useState(null);
  const [isPaddleSubscriptionActive, setIsPaddleSubscriptionActive] =
    useState(false);
  const [isDowngrading, setIsDowngrading] = useState(false);
  const [soloError, setSoloError] = useState(false);

  const {
    getOrganizationPlans,
    subscriptionPlans,
    currentSubscription,
    getOrganizationSubscription,
    selectedOrganization,
    clearCurrentSubscription,
  } = props;

  const planPriceMonthly = {
    solo: 4,
    standard: 5,
    premium: 6,
    elite: 7,
  };
  const planPriceYearly = {
    solo: 2,
    standard: 2.5,
    premium: 3,
    elite: 3.5,
  };

  useEffect(() => {
    getOrganizationPlans();
    return () => {
      clearCurrentSubscription();
      setCurrentPlanId(null);
      setCurrentPlanName("");
      setCurrentPlanInterval(null);
      setCurrentPlanStatus(null);
      setIsPaddleSubscriptionActive(false);
      setPlanInterval("yearly");
    };
  }, []);

  const [soloYearly, setSoloYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [soloMonthly, setSoloMonthly] = useState({
    id: "",
    paddle_id: null,
    amount: null,
  });
  const [standardYearly, setStandardYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [premiumYearly, setPremiumYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [eliteYearly, setEliteYearly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [standardMonthly, setStandardMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [premiumMonthly, setPremiumMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });
  const [eliteMonthly, setEliteMonthly] = useState({
    id: "",
    paddleId: null,
    amount: null,
  });

  useEffect(() => {
    if (subscriptionPlans && subscriptionPlans.length > 0) {
      subscriptionPlans.map((item) => {
        if (item.name === "Solo" && item.interval === "yearly") {
          setSoloYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Solo" && item.interval === "monthly") {
          setSoloMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Standard" && item.interval === "yearly") {
          setStandardYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Premium" && item.interval === "yearly") {
          setPremiumYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Elite" && item.interval === "yearly") {
          setEliteYearly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Standard" && item.interval === "monthly") {
          setStandardMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Premium" && item.interval === "monthly") {
          setPremiumMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        } else if (item.name === "Elite" && item.interval === "monthly") {
          setEliteMonthly({
            id: item.id,
            paddleId: item.paddle_id,
            amount: item.amount,
          });
        }
      });
    }
  }, [subscriptionPlans]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      if (
        !currentSubscription ||
        (currentSubscription.organization &&
          currentSubscription.organization !== selectedOrganization.id)
      ) {
        const payload = {
          organization_id: selectedOrganization.id,
        };
        getOrganizationSubscription(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (currentSubscription && currentSubscription.plan) {
      setCurrentPlanId(currentSubscription.plan.id);
      setCurrentPlanName(currentSubscription.plan.name);
      setCurrentPlanInterval(currentSubscription.plan.interval);
      setPlanInterval(currentSubscription.plan.interval);
      setCurrentPlanStatus(currentSubscription.status);
      setIsPaddleSubscriptionActive(
        currentSubscription.paddlesubscription_active
      );

      // This part is for testing purpose
      // setIsPaddleSubscriptionActive(true);
      // setCurrentPlanStatus("active");
      // setCurrentPlanInterval("monthly");
    }
  }, [currentSubscription]);

  const onTogglePlan = () => {
    if (planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  const confirmSelectPlan = (plan) => {
    if (
      currentPlanId &&
      currentPlanStatus &&
      currentPlanStatus === "active" &&
      isPaddleSubscriptionActive
    ) {
      if (currentPlanId !== plan.selectedPlanId) {
        if (currentPlanName) {
          if (currentPlanName === "Standard" && plan.name === "Solo") {
            setIsDowngrading(true);
          } else if (
            currentPlanName === "Premium" &&
            (plan.name === "Standard" || plan.name === "Solo")
          ) {
            setIsDowngrading(true);
          } else if (
            currentPlanName === "Elite" &&
            (plan.name === "Premium" ||
              plan.name === "Standard" ||
              plan.name === "Solo")
          ) {
            setIsDowngrading(true);
          } else if (
            plan.name === "Solo" &&
            currentSubscription &&
            currentSubscription.member_count &&
            currentSubscription.member_count > 1
          ) {
            setSoloError(true);
          } else {
            props.history.push({
              pathname: "/user/upgrade-plan",
              state: {
                plan: {
                  selectedPlanId: plan.selectedPlanId,
                  ids: plan.ids,
                  name: plan.name,
                  paddleIds: plan.paddleIds,
                  amounts: plan.amounts,
                  interval: planInterval,
                  currentUsers:
                    currentSubscription && currentSubscription.member_count,
                  prepurchasedCount:
                    currentSubscription &&
                    currentSubscription.prepurchased_count,
                },
                currentPlan: {
                  id: currentPlanId,
                  name: currentPlanName,
                  interval: currentPlanInterval,
                },
              },
            });
          }
        }
      }
    } else {
      if (
        plan.name === "Solo" &&
        currentSubscription &&
        currentSubscription.member_count &&
        currentSubscription.member_count > 1
      ) {
        setSoloError(true);
      } else {
        props.history.push({
          pathname: "/user/checkout",
          state: {
            plan: {
              selectedPlanId: plan.selectedPlanId,
              ids: plan.ids,
              name: plan.name,
              paddleIds: plan.paddleIds,
              amounts: plan.amounts,
              interval: planInterval,
              currentUsers:
                currentSubscription && currentSubscription.member_count,
            },
          },
        });
      }
    }
  };

  return (
    <div className="content">
      <DowngradeModal
        isOpen={isDowngrading}
        toggle={() => setIsDowngrading(false)}
      />
      <SoloErrorModal isOpen={soloError} toggle={() => setSoloError(false)} />
      <PageTitle marginBottom="30px">Billing - Subscription Plan</PageTitle>
      <BackButtonComponent
        onClick={() => props.history.push("/user/settings")}
        subTitle="Back to settings"
      />
      <PricingMainContainer>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <div>
              <CommonGrid alignItem="flex-start">
                <CardTitle margin="0 0 0 10px">Choose Plan</CardTitle>
                {!(
                  currentPlanInterval &&
                  currentPlanInterval === "yearly" &&
                  currentPlanStatus &&
                  currentPlanStatus === "active"
                ) && (
                  <ToggleSection
                    style={{
                      padding: `20px 0 40px 0`,
                    }}
                  >
                    <ToggleText
                      active={planInterval === "yearly"}
                      onClick={() => setPlanInterval("yearly")}
                    >
                      <YearlySaveBadge isActive={planInterval === "yearly"}>
                        Save 50%
                      </YearlySaveBadge>
                      Yearly
                    </ToggleText>
                    <ToggleButton
                      onClick={() => onTogglePlan()}
                      style={{ backgroundColor: `#20BEAD` }}
                    >
                      <ToggleButtonRound active={planInterval === "monthly"} />
                    </ToggleButton>

                    <ToggleText
                      active={planInterval === "monthly"}
                      onClick={() => setPlanInterval("monthly")}
                    >
                      Monthly
                    </ToggleText>
                  </ToggleSection>
                )}
              </CommonGrid>
              <PricingCardContainer
                isYearly={
                  currentPlanInterval &&
                  currentPlanInterval === "yearly" &&
                  currentPlanStatus &&
                  currentPlanStatus === "active"
                }
              >
                {/* <SinglePricingCard plan="solo">
                  <CurrentPlanBadge
                    isCurrent={
                      currentPlanId
                        ? planInterval === "yearly"
                          ? currentPlanId === soloYearly.id
                            ? true
                            : false
                          : currentPlanId === soloMonthly.id
                          ? true
                          : false
                        : false
                    }
                    plan="Solo"
                  />
                  <div>
                    <PlanName plan="solo">SOLO</PlanName>
                    <PlanPriceContainer>
                      <PlanPrice>
                        $
                        {planInterval === "yearly"
                          ? planPriceYearly.solo
                          : planPriceMonthly.solo}
                      </PlanPrice>
                      <PlanPriceText>one user/month</PlanPriceText>
                    </PlanPriceContainer>
                    <PlanDescription plan="solo">
                      Ideal for freelancers & contractors to track time, keep
                      focus & bill clients.
                    </PlanDescription>
                  </div>
                  <PlanFeaturesContainer margin="15px 0">
                    {planFeatures.solo.map((item, index) => (
                      <SinglePlanFeature key={index}>
                        <TickImage
                          src={tickSolo}
                          alt=""
                          style={{ margin: `2px 0 0 0` }}
                        />
                        <FeatureText>{item}</FeatureText>
                      </SinglePlanFeature>
                    ))}
                  </PlanFeaturesContainer>
                  <PlanSelectButton
                    plan="solo"
                    isCurrent={getIsCurrent(
                      currentPlanId,
                      currentPlanStatus,
                      planInterval,
                      soloYearly.id,
                      soloMonthly.id
                    )}
                    onClick={() =>
                      confirmSelectPlan({
                        selectedPlanId:
                          planInterval === "yearly"
                            ? soloYearly.id
                            : soloMonthly.id,
                        ids: {
                          yearlyId: soloYearly.id,
                          monthlyId: soloMonthly.id,
                        },
                        name: "Solo",
                        paddleIds: {
                          yearlyId: soloYearly.paddleId,
                          monthlyId: soloMonthly.paddleId,
                        },
                        amounts: {
                          yearlyAmount: soloYearly.amount,
                          monthlyAmount: soloMonthly.amount,
                        },
                      })
                    }
                  >
                    {currentPlanId
                      ? planInterval === "yearly"
                        ? currentPlanId === soloYearly.id
                          ? `Continue Current Plan`
                          : `Select SOLO`
                        : currentPlanId === soloMonthly.id
                        ? `Continue Current Plan`
                        : `Select SOLO`
                      : `Select SOLO`}
                  </PlanSelectButton>
                </SinglePricingCard> */}
                <SinglePricingCard plan="standard">
                  <CurrentPlanBadge
                    isCurrent={
                      currentPlanId
                        ? planInterval === "yearly"
                          ? currentPlanId === standardYearly.id
                            ? true
                            : false
                          : currentPlanId === standardMonthly.id
                          ? true
                          : false
                        : false
                    }
                    plan="Standard"
                  />
                  <div>
                    <PlanNameAndTagContainer>
                      <PlanName plan="standard">STANDARD</PlanName>
                      <PlanNameTag plan="standard">Time</PlanNameTag>
                    </PlanNameAndTagContainer>
                    <PlanPriceContainer>
                      <PlanPrice>
                        $
                        {planInterval === "yearly"
                          ? planPriceYearly.standard
                          : planPriceMonthly.standard}
                      </PlanPrice>
                      <PlanPriceText>per user/month</PlanPriceText>
                    </PlanPriceContainer>
                    <PlanDescription plan="standard">
                      Ideal for the in-house teams or general teams of any size
                      & shape.
                    </PlanDescription>
                  </div>
                  <PlanFeaturesContainer margin="15px 0 25px">
                    {planFeatures.standard.map((item, index) => (
                      <SinglePlanFeature key={index}>
                        <TickImage
                          src={tickStandard}
                          alt=""
                          style={{ margin: `2px 0 0 0` }}
                        />
                        <FeatureText>{item}</FeatureText>
                      </SinglePlanFeature>
                    ))}
                  </PlanFeaturesContainer>
                  <PlanSelectButton
                    plan="standard"
                    isCurrent={getIsCurrent(
                      currentPlanId,
                      currentPlanStatus,
                      planInterval,
                      standardYearly.id,
                      standardMonthly.id
                    )}
                    onClick={() =>
                      confirmSelectPlan({
                        selectedPlanId:
                          planInterval === "yearly"
                            ? standardYearly.id
                            : standardMonthly.id,
                        ids: {
                          yearlyId: standardYearly.id,
                          monthlyId: standardMonthly.id,
                        },
                        name: "Standard",
                        paddleIds: {
                          yearlyId: standardYearly.paddleId,
                          monthlyId: standardMonthly.paddleId,
                        },
                        amounts: {
                          yearlyAmount: standardYearly.amount,
                          monthlyAmount: standardMonthly.amount,
                        },
                      })
                    }
                  >
                    {currentPlanId
                      ? planInterval === "yearly"
                        ? currentPlanId === standardYearly.id
                          ? `Continue Current Plan`
                          : `Select STANDARD`
                        : currentPlanId === standardMonthly.id
                        ? `Continue Current Plan`
                        : `Select STANDARD`
                      : `Select STANDARD`}
                  </PlanSelectButton>
                </SinglePricingCard>
                {/* <SinglePricingCard plan="premium">
                  <CurrentPlanBadge
                    isCurrent={
                      currentPlanId
                        ? planInterval === "yearly"
                          ? currentPlanId === premiumYearly.id
                            ? true
                            : false
                          : currentPlanId === premiumMonthly.id
                          ? true
                          : false
                        : false
                    }
                    plan="Premium"
                  />
                  <div>
                    <PlanNameAndTagContainer>
                      <PlanName plan="premium">PREMIUM</PlanName>
                      <PlanNameTag plan="premium">Field</PlanNameTag>
                    </PlanNameAndTagContainer>
                    <PlanPriceContainer>
                      <PlanPrice>
                        $
                        {planInterval === "yearly"
                          ? planPriceYearly.premium
                          : planPriceMonthly.premium}
                      </PlanPrice>
                      <PlanPriceText>per user/month</PlanPriceText>
                    </PlanPriceContainer>
                    <PlanDescription plan="premium">
                      Ideal for Mobile teams or a combination of in-house &
                      Mobile teams.
                    </PlanDescription>
                  </div>
                  <EverythingInPrev plan="premium">
                    <TickImage src={arrow} alt="" style={{ margin: `0` }} />
                    <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
                      Everything in Standard
                    </FeatureText>
                  </EverythingInPrev>
                  <PlusAddonDiv>Plus Field Services Add On:</PlusAddonDiv>
                  <PlanFeaturesContainer>
                    {planFeatures.premium.map((item, index) => (
                      <SinglePlanFeature key={index}>
                        <TickImage
                          src={tickPremium}
                          alt=""
                          style={{ margin: `2px 0 0 0` }}
                        />
                        <FeatureText>{item}</FeatureText>
                      </SinglePlanFeature>
                    ))}
                  </PlanFeaturesContainer>
                  <PlanSelectButton
                    plan="premium"
                    isCurrent={getIsCurrent(
                      currentPlanId,
                      currentPlanStatus,
                      planInterval,
                      premiumYearly.id,
                      premiumMonthly.id
                    )}
                    onClick={() =>
                      confirmSelectPlan({
                        selectedPlanId:
                          planInterval === "yearly"
                            ? premiumYearly.id
                            : premiumMonthly.id,
                        ids: {
                          yearlyId: premiumYearly.id,
                          monthlyId: premiumMonthly.id,
                        },
                        name: "Premium",
                        paddleIds: {
                          yearlyId: premiumYearly.paddleId,
                          monthlyId: premiumMonthly.paddleId,
                        },
                        amounts: {
                          yearlyAmount: premiumYearly.amount,
                          monthlyAmount: premiumMonthly.amount,
                        },
                      })
                    }
                  >
                    {currentPlanId
                      ? planInterval === "yearly"
                        ? currentPlanId === premiumYearly.id
                          ? `Continue Current Plan`
                          : `Select PREMIUM`
                        : currentPlanId === premiumMonthly.id
                        ? `Continue Current Plan`
                        : `Select PREMIUM`
                      : `Select PREMIUM`}
                  </PlanSelectButton>
                </SinglePricingCard> */}
                <SinglePricingCard plan="elite">
                  <CurrentPlanBadge
                    isCurrent={
                      currentPlanId
                        ? planInterval === "yearly"
                          ? currentPlanId === eliteYearly.id
                            ? true
                            : false
                          : currentPlanId === eliteMonthly.id
                          ? true
                          : false
                        : false
                    }
                    plan="Elite"
                  />
                  <div>
                    <PlanNameAndTagContainer>
                      <PlanName plan="elite">ELITE</PlanName>
                      <PlanNameTag plan="elite">Remote & Hybrid</PlanNameTag>
                    </PlanNameAndTagContainer>
                    <PlanPriceContainer>
                      <PlanPrice>
                        $
                        {planInterval === "yearly"
                          ? planPriceYearly.elite
                          : planPriceMonthly.elite}
                      </PlanPrice>
                      <PlanPriceText>per user/month</PlanPriceText>
                    </PlanPriceContainer>
                    <PlanDescription plan="elite">
                      Ideal for Remote Teams or a combination of in-house &
                      remote teams.
                    </PlanDescription>
                  </div>
                  <EverythingInPrev plan="elite">
                    <TickImage src={arrow} alt="" style={{ margin: `0` }} />
                    <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
                      Everything in Standard
                    </FeatureText>
                  </EverythingInPrev>
                  <PlusAddonDiv>Plus RemoteTrack Add On:</PlusAddonDiv>
                  <PlanFeaturesContainer>
                    {planFeatures.elite.map((item, index) => (
                      <SinglePlanFeature key={index}>
                        <TickImage
                          src={tickElite}
                          alt=""
                          style={{ margin: `2px 0 0 0` }}
                        />
                        <FeatureText>{item}</FeatureText>
                      </SinglePlanFeature>
                    ))}
                  </PlanFeaturesContainer>
                  <PlanSelectButton
                    plan="elite"
                    isCurrent={getIsCurrent(
                      currentPlanId,
                      currentPlanStatus,
                      planInterval,
                      eliteYearly.id,
                      eliteMonthly.id
                    )}
                    onClick={() =>
                      confirmSelectPlan({
                        selectedPlanId:
                          planInterval === "yearly"
                            ? eliteYearly.id
                            : eliteMonthly.id,
                        ids: {
                          yearlyId: eliteYearly.id,
                          monthlyId: eliteMonthly.id,
                        },
                        name: "Elite",
                        paddleIds: {
                          yearlyId: eliteYearly.paddleId,
                          monthlyId: eliteMonthly.paddleId,
                        },
                        amounts: {
                          yearlyAmount: eliteYearly.amount,
                          monthlyAmount: eliteMonthly.amount,
                        },
                      })
                    }
                  >
                    {currentPlanId
                      ? planInterval === "yearly"
                        ? currentPlanId === eliteYearly.id
                          ? `Continue Current Plan`
                          : `Select ELITE`
                        : currentPlanId === eliteMonthly.id
                        ? `Continue Current Plan`
                        : `Select ELITE`
                      : `Select ELITE`}
                  </PlanSelectButton>
                </SinglePricingCard>
              </PricingCardContainer>
            </div>
            <SeeFullComparisonDiv>
              <SeeFullComparisonButton
                target="_blank"
                rel="noopener noreferrer"
                href="https://apploye.com/expandplans"
              >
                See Full Pricing Comparison
              </SeeFullComparisonButton>
            </SeeFullComparisonDiv>

            <PricingAccordion />
          </>
        )}
      </PricingMainContainer>
    </div>
  );
};

export default ChangeSubscription;
