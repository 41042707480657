import styled from "styled-components";

import SettingsDisabledArtwork from "../../assets/img/common/settings-disabled.svg";

const SettingsDisabledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10vh 0;
  background-color: #fff;
  border: 1px solid #c2cce1;
  border-radius: 10px;
`;
const ArtworkImage = styled.img`
  height: 60px;
  width: 60px;
`;
const SettingsDisabledText = styled.div`
  font-size: 14px;
  margin-top: 20px;
  color: rgba(47, 57, 78, 0.7);
`;

const SettingsDisabled = (props) => {
  return (
    <SettingsDisabledContainer>
      <ArtworkImage src={SettingsDisabledArtwork} alt="" />
      <SettingsDisabledText subtitle={props.subtitle ? true : false}>
        {`${
          props.feature ? props.feature : "This feature"
        } is currently turned OFF from activity settings`}
      </SettingsDisabledText>
    </SettingsDisabledContainer>
  );
};

export default SettingsDisabled;
