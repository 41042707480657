//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../../utils/axiosApi";

import {
  GET_ROUTE_MAP_LOCATIONS,
  GET_ROUTE_MAP_LOCATIONS_SUCCESS,
  GET_ROUTE_MAP_LOCATIONS_FAILURE,
} from "../../../modules/constants";

export function* getRouteMapLocationsRequest({ payload }) {
  if (!payload) return;

  try {
    const url = `location_activities/`;

    const response = yield defaultApi(url, "POST", payload);

    yield put({
      type: GET_ROUTE_MAP_LOCATIONS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ROUTE_MAP_LOCATIONS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([takeLatest(GET_ROUTE_MAP_LOCATIONS, getRouteMapLocationsRequest)]);
}
