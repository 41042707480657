import { useState, useEffect, forwardRef } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  AddOrganizationContainer,
  OnboardingForm,
  OnboardingFormAlert,
  OnboardingInputContainer,
  OnboardingInputLabel,
  InputRequiredStar,
  OnboardingInput,
  OnboardingNextButton,
  NextButtonText,
  NextButtonIcon,
} from "../onboardingStepsStyles";

import { timezones } from "../../../../utils/timezone";

import PreviousButton from "./PreviousButton";

import Select from "../../../../components/ReactSelectDropdown";
import TimezoneDropdownStyle from "./timezoneDropdownStyle";
import { IndicatorSeparator } from "./DropdownIndicatorSeparator";

import nextIcon from "../../../../assets/img/onboardingImg/right_arrow_green.svg";

const AddOrganization = forwardRef((props, ref) => {
  const [organizationName, setOrganizationName] = useState("");
  const [timezone, setTimezone] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  useEffect(() => {
    setTimezone({
      label: `(UTC${convertOffset(new Date().getTimezoneOffset())}) ${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`,
      value: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    });
  }, []);
  const filterOptions = (options, filterString, values) => {
    return options.filter(
      (x) => x.label.includes(filterString) || x.metadata.includes(filterString)
    );
  };
  const handleNameChange = (e) => {
    setSaveErrors({});
    setOrganizationName(e.target.value);
  };
  const handleTimezoneChange = (e) => {
    setSaveErrors({});
    setTimezone({
      label: e.label,
      value: e.value,
    });
  };
  const handleNext = () => {
    if (checkError()) {
      const orgData = {
        name: organizationName,
        timezone: timezone.value,
        phone: phoneNumber ? `+${phoneNumber}` : null,
      };
      props.setOrganizationData(orgData);
      if (props.activeStep === "organization") {
        props.handleNext("organization");
      } else if (props.activeStep === "project") {
        if (props.projectsData && props.projectsData.length > 0) {
          props.handleNext("project");
        } else {
          props.handleNext("organization");
        }
      } else if (props.activeStep === "pricing") {
        if (props.organizationData && props.organizationData.plan_id) {
          props.handleNext("pricing");
        } else {
          props.handleNext("project");
        }
      } else if (props.activeStep === "invite-member") {
        props.handleNext("pricing");
      }
    }
  };
  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!organizationName) {
      formIsValid = false;
      saveErrors["name"] = "Organization name is required.";
    }
    if (timezone === "") {
      formIsValid = false;
      saveErrors["timezone"] = "Please select a timezone.";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const convertOffset = (offset) => {
    const hour = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;

    return `${offset <= 0 ? "+" : "-"}${hour >= 10 ? hour : `0${hour}`}:${
      minutes >= 10 ? minutes : `0${minutes}`
    }`;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNext();
    }
  };
  return (
    <AddOrganizationContainer ref={ref}>
      <PreviousButton handleClick={props.handleBack} type="organization" />
      <OnboardingForm onKeyDown={handleKeyPress}>
        <OnboardingInputContainer gap="15px">
          <OnboardingInputLabel>
            What's your organization name?{" "}
            <InputRequiredStar>*</InputRequiredStar>
          </OnboardingInputLabel>
          <OnboardingInput
            type="text"
            id="organizationName"
            placeholder="Example: SpaceX"
            defaultValue={organizationName}
            onChange={(e) => handleNameChange(e)}
            autoFocus
            alert={saveErrors && saveErrors !== {} && saveErrors.name}
          />
          {saveErrors && saveErrors !== {} && saveErrors.name && (
            <OnboardingFormAlert>{saveErrors["name"]}</OnboardingFormAlert>
          )}
        </OnboardingInputContainer>
        <OnboardingInputContainer gap="15px">
          <OnboardingInputLabel>
            Your timezone <InputRequiredStar>*</InputRequiredStar>
          </OnboardingInputLabel>
          <Select
            isSearchable
            value={
              timezone && timezone.value
                ? {
                    label: timezone.label,
                    value: timezone.value,
                  }
                : ""
            }
            name="timezone"
            id="timezone"
            placeholder="UTC: Asia - Dhaka"
            options={timezones}
            filterOptions={filterOptions}
            onChange={(e) => handleTimezoneChange(e)}
            components={{ IndicatorSeparator }}
            styles={TimezoneDropdownStyle()}
          />
          {saveErrors && saveErrors !== {} && saveErrors.timezone && (
            <OnboardingFormAlert>{saveErrors["timezone"]}</OnboardingFormAlert>
          )}
        </OnboardingInputContainer>
        <OnboardingInputContainer gap="15px">
          <OnboardingInputLabel>Your phone number</OnboardingInputLabel>
          <PhoneInput
            enableSearch
            country="us"
            value={phoneNumber}
            onChange={(phone) => setPhoneNumber(phone)}
            inputClass="onboarding-phone-number-input"
            buttonClass="onboarding-phone-number-flag-dropdown"
          />
        </OnboardingInputContainer>
        <OnboardingNextButton onClick={() => handleNext()}>
          <NextButtonText>Next</NextButtonText>
          <NextButtonIcon src={nextIcon} alt="" />
        </OnboardingNextButton>
      </OnboardingForm>
    </AddOrganizationContainer>
  );
});

export default AddOrganization;
