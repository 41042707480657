import { useState, useEffect } from "react";
import ActivityFilter from "../activityFilterContainer";
import AppSection from "./AppSection";

import { PageTitle, CommonGrid } from "../../../styledComponents/common";

import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import SettingsDisabled from "../../../components/SettingsDisabled/SettingsDisabled";

const ActivityApps = (props) => {
  const [timesheetHasApps, setTimesheetHasApps] = useState(false);
  const { apps } = props;

  useEffect(() => {
    let hasApps = false;
    if (apps && apps.length > 0) {
      apps.some((appSection) => {
        if (appSection.apps && appSection.apps.length > 0) {
          hasApps = true;
          return true;
        }
        return false;
      });
    }
    setTimesheetHasApps(hasApps);
  }, [apps]);

  return (
    <div className="content">
      <CommonGrid alignItem="center">
        <PageTitle>Apps</PageTitle>
      </CommonGrid>
      <ActivityFilter title="Apps" />
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          {props.selectedOrganization?.configuration?.application_track ? (
            props.apps?.length ? (
              <div style={{ marginTop: "30px" }}>
                {timesheetHasApps ? (
                  props.apps.map((appSection, index) =>
                    appSection.apps?.length ? (
                      <AppSection key={index} appSection={appSection} />
                    ) : null
                  )
                ) : (
                  <NoDataComponent title="Sorry! No apps data found." />
                )}
              </div>
            ) : (
              <NoDataComponent title="Sorry! No apps data found." />
            )
          ) : (
            <SettingsDisabled feature={`'Application Tracking'`} />
          )}
        </>
      )}
    </div>
  );
};

export default ActivityApps;
