import { connect } from "react-redux";

// Component
import RedeemCode from "./RedeemCode";

// Actions
import { redeem } from "./redeemCodeActions";
import { getOrganizationList } from "../Organization/OrganizationActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  redeemLimitedTimeCode: state.redeemLimitedTimeCode,
  organizationList: state.organization.list,
  isLoading: state.redeemLimitedTimeCode.isLoading,
  isOrgListLoading: state.organization.isLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  redeem: (details) => dispatch(redeem({ ...details })),
  getOrganizationList: () => dispatch(getOrganizationList()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(RedeemCode);
