import { useState, useEffect } from "react";
import moment from "moment";
import Select from "../../components/ReactSelectDropdown";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";
import ViewLess from "./ViewLess";
import {
  CommonText,
  Container,
  TableText,
  ColoredText,
  CommonFlex,
} from "../../styledComponents/common";
import { PrimaryButton } from "../../styledComponents/buttons";
import {
  ProjectListSection,
  ProjectListItem,
  ListUpperSection,
  ClientDeadlineGrid,
  ProjectItemCount,
  ProjectTitleDiv,
  ActionSection,
  ProjectDuplicateDiv,
  ProjectDuplicateIcon,
  TableRowAndBudgetCollapseContainer,
  ProjectTableContainer,
  BudgetCollapseSection,
  TableItem,
} from "../../styledComponents/ProjectsNew";
import {
  ProjectListStatusBadge,
  ProjectExpandButton,
  ExpandIcon,
  EditProjectButton,
  DeadlineText,
} from "./projectsStyles";
import {
  IntegrationIconDiv,
  IntegrationIcon,
} from "../../styledComponents/tasks";

import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import DropdownLoader from "../../components/DropdownLoader/DropdownLoader";
import CommonTooltip from "../../components/Tooltip/CommonTooltip";

import arrowWhiteIcon from "../../assets/img/icons/arrow_right_white.svg";

const ProjectsList = (props) => {
  const [duplicateTooltip, setDuplicateTooltip] = useState(null);
  const [integrationTooltip, setIntegrationTooltip] = useState(null);

  const {
    selectedOrganization,
    projectsList,
    expandedProjectId,
    setExpandedProjectId,
    getProjectBudgetAndBillable,
    projectBudgetAndBillableIsLoading,
    projectBudgetAndBillable,
    isLoading,
    projectType,
    history,
    toggleArchiveModal,
    toggleDeleteModal,
    duplicateProject,
  } = props;

  const activeOptions = [
    { value: "details", label: "View Details" },
    { value: "edit", label: "Edit Project" },
    { value: "duplicate", label: "Duplicate Project" },
    { value: "archive", label: "Archive Project" },
  ];

  const archivedOptions = [
    { value: "details", label: "View Details" },
    { value: "unarchive", label: "Unarchive Project" },
    { value: "delete", label: "Delete Project" },
  ];

  useEffect(() => {
    if (projectsList && projectsList.length > 0) {
      setExpandedProjectId(projectsList[0].id);
    }
  }, [projectsList]);

  const handleDuplicateProject = (projectId) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
      };
      duplicateProject(payload);
    }
  };

  const handleArchiveProject = (projectId) => {
    toggleArchiveModal(projectId);
  };

  const handleDeleteProject = (projectId) => {
    toggleDeleteModal(projectId);
  };

  const onActionSelect = (action, projectId) => {
    switch (action && action.value) {
      case "details":
        history.push(
          `/user/project-details?id=${projectId}&type=${projectType}`
        );
        break;
      case "edit":
        history.push(`/user/edit-project?id=${projectId}`);
        break;
      case "duplicate":
        handleDuplicateProject(projectId);
        break;
      case "archive":
      case "unarchive":
        handleArchiveProject(projectId);
        break;
      case "delete":
        handleDeleteProject(projectId);
        break;
      default:
      // do nothing
    }
  };

  const handleExpandBudgetSection = (projectId) => {
    if (expandedProjectId && expandedProjectId === projectId) {
      setExpandedProjectId(null);
    } else {
      setExpandedProjectId(projectId);
    }
  };

  return (
    <Container style={{ paddingBottom: "20px" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        projectsList &&
        projectsList.length > 0 && (
          <>
            <ProjectTableContainer heading>
              <TableItem>
                <TableText>Project Name</TableText>
              </TableItem>
              <TableItem>
                <TableText>Project Code</TableText>
              </TableItem>
              <TableItem>
                <TableText>Client</TableText>
              </TableItem>
              <TableItem>
                <TableText>Deadline</TableText>
              </TableItem>
              <TableItem>
                <TableText>Status</TableText>
              </TableItem>
              <TableItem>{/* <TableText>Actions</TableText> */}</TableItem>
            </ProjectTableContainer>
            {projectsList.map((project, index) => (
              <TableRowAndBudgetCollapseContainer key={project.id}>
                <ProjectTableContainer>
                  <TableItem
                    style={{
                      gridTemplateColumns: "auto auto",
                      alignItems: "center",
                      gridColumnGap: "10px",
                    }}
                  >
                    <CommonFlex gap="14px">
                      <FirstRoundLetterComp
                        text={project.name}
                        backColor={index}
                        color = {project.color || null}
                      />
                      <TableText
                        hoverPointer
                        hoverUnderline
                        name
                        fontWeight={
                          expandedProjectId &&
                          expandedProjectId === project.id &&
                          "600"
                        }
                        onClick={() =>
                          history.push(
                            `/user/project-details?id=${project.id}&type=${projectType}`
                          )
                        }
                      >
                        {project.name}
                      </TableText>
                    </CommonFlex>
                    {project.integrations &&
                      project.integrations.length > 0 &&
                      project.integrations.map((integration, index) => {
                        return (
                          <IntegrationIconDiv
                            key={index}
                            onMouseEnter={() =>
                              setIntegrationTooltip(project.id)
                            }
                            onMouseLeave={() => setIntegrationTooltip(null)}
                          >
                            <IntegrationIcon
                              src={`https://cdn.apploye.com/integrations/icons/${integration}.svg`}
                              alt={integration}
                            />
                            {integrationTooltip &&
                              integrationTooltip === project.id && (
                                <CommonTooltip
                                  tooltipText={`${integration
                                    .charAt(0)
                                    .toUpperCase()}${integration.slice(1)}`}
                                  left="-32px"
                                  top="-30px"
                                  width="82px"
                                  height="26px"
                                />
                              )}
                          </IntegrationIconDiv>
                        );
                      })}
                  </TableItem>
                  <TableItem>
                    <TableText name>{project.code || "N/A"}</TableText>
                  </TableItem>
                  <TableItem>
                    {project.managed && (
                      <TableText name>
                        {project.client ? project.client.name : "N/A"}
                      </TableText>
                    )}
                  </TableItem>
                  <TableItem>
                    <TableText name>
                      {project.deadline
                        ? moment(project.deadline).format("MMM DD, YYYY")
                        : "N/A"}
                    </TableText>
                    {project.deadline &&
                      moment(project.deadline).diff(moment(), "days") <= 7 && (
                        <>
                          {moment(project.deadline).diff(moment(), "days") <=
                          0 ? (
                            <ColoredText type="error" semibold fontSize="12px">
                              Deadline Over
                            </ColoredText>
                          ) : (
                            <ColoredText
                              type="warning"
                              semibold
                              fontSize="12px"
                            >
                              {moment(project.deadline).diff(moment(), "days")}{" "}
                              days left
                            </ColoredText>
                          )}
                        </>
                      )}
                  </TableItem>
                  <TableItem>
                    <ProjectListStatusBadge status={project.status}>
                      {project.status}
                    </ProjectListStatusBadge>
                  </TableItem>
                  {project.managed && (
                    <>
                      <TableItem>
                        <Select
                          isSearchable={false}
                          value={null}
                          options={
                            projectType === "archived"
                              ? selectedOrganization &&
                                (selectedOrganization.role === "owner" ||
                                  selectedOrganization.role === "admin")
                                ? archivedOptions
                                : archivedOptions.filter(
                                    (option) => option.value !== "delete"
                                  )
                              : selectedOrganization &&
                                (selectedOrganization.role === "owner" ||
                                  selectedOrganization.role === "admin")
                              ? activeOptions
                              : activeOptions.filter(
                                  (option) => option.value !== "archive"
                                )
                          }
                          onChange={(e) => onActionSelect(e, project.id)}
                          placeholder="Actions"
                          styles={FilterDropDownStyle({
                            height: "36px",
                            width: `135px`,
                            menuWidth: `150px`,
                          })}
                        />
                      </TableItem>
                      <TableItem>
                        <ProjectExpandButton
                          onClick={() => handleExpandBudgetSection(project.id)}
                        >
                          <ExpandIcon
                            src={arrowWhiteIcon}
                            alt=""
                            open={
                              expandedProjectId &&
                              expandedProjectId === project.id
                            }
                          />
                        </ProjectExpandButton>
                      </TableItem>
                    </>
                  )}
                </ProjectTableContainer>

                {project.managed && (
                  <BudgetCollapseSection
                    open={expandedProjectId && expandedProjectId === project.id}
                  >
                    {expandedProjectId && expandedProjectId === project.id ? (
                      projectBudgetAndBillableIsLoading ? (
                        <DropdownLoader
                          loading
                          size={10}
                          padding="40px 0 0"
                          animated
                        />
                      ) : projectBudgetAndBillable &&
                        projectBudgetAndBillable.id &&
                        projectBudgetAndBillable.id === project.id ? (
                        <ViewLess
                          selectedOrganization={selectedOrganization}
                          project={projectBudgetAndBillable}
                        />
                      ) : null
                    ) : null}
                  </BudgetCollapseSection>
                )}
              </TableRowAndBudgetCollapseContainer>
            ))}
          </>
        )
      )}
      {!isLoading && projectsList && projectsList.length === 0 && (
        <NoDataComponent
          title={
            props.selectedClient && props.selectedClient.value
              ? `No project found with the selected client!`
              : props.searchTerm
              ? `No project found with this name!`
              : `Seems like you do not have any ${projectType} project`
          }
        />
      )}
    </Container>
  );
};

export default ProjectsList;
