import { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { createNotification } from "../../modules/notificationManager";

import { CommonText } from "../../styledComponents/common";
import { RoundImageCross } from "../../styledComponents/createProject";
import {
  UploadImageDiv,
  UpdateImageHoverDiv,
  NoImageContainer,
  CameraIcon,
  DropZoneContainer,
} from "./profileStyles";

import removeAssignee from "../../assets/img/icons/remove_assignee.svg";
import cameraIcon from "../../assets/img/icons/camera.svg";

const thumbsContainer = {
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  objectFit: "cover",
  objectPosition: "center",
};

const dropZoneCss = {
  width: "80px",
  height: "80px",
  objectFit: "cover",
  objectPosition: "center",
  background: "#fff",
  border: "2px dashed #c2cce1",
  borderRadius: "50%",
};
const dropZoneCssNoBorder = {
  width: "80px",
  height: "80px",
  objectFit: "cover",
  objectPosition: "center",
  background: "#fff",
  border: "none",
  borderRadius: "50%",
};

const ImageUpload = (props) => {
  const [warningMsg, setwarningMsg] = useState("");
  const [imageState, setimageState] = useState(null);
  const [filesState, setfilesState] = useState("");

  useEffect(() => {
    handleSave();
  }, [filesState]);

  const onDrop = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      createNotification("error", "Wrong image format", 3000);
    } else if (Number(accepted[0].size) > 2048000) {
      createNotification("error", "Image max size is 2MB", 3000);
    } else {
      props.addFile(accepted);
      setwarningMsg("");
      var blobPromise = new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(accepted[0]);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
      });
      blobPromise.then((value) => {
        setfilesState(accepted[0]);
        let fileTemp = accepted[0];
        fileTemp.base64 = value;
        setimageState(value);
        setfilesState(fileTemp);
      });
    }
  };

  const handleSave = () => {
    const { handleSave } = props;
    let payload = {};
    const files = filesState;
    if (files) {
      payload.image = files.base64;
      handleSave(payload);
    }
  };
  const imageRemove = () => {
    alert("image remove");
  };

  return (
    <DropZoneContainer>
      {/* <RoundImageCross onClick={() => imageRemove}>
        <img
          onClick={() => imageRemove}
          src={removeAssignee}
          style={{ width: "100%", height: "100%" }}
        />
      </RoundImageCross> */}
      <Dropzone
        style={
          filesState.base64 || props.files ? dropZoneCssNoBorder : dropZoneCss
        }
        multiple={false}
        accept="image/*"
        onDrop={(accepted, rejected) => onDrop(accepted, rejected)}
      >
        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
          if (isDragReject) return "Please submit a valid file";
          if (props.files) {
            return (
              <UploadImageDiv>
                <img
                  style={thumbsContainer}
                  src={filesState.base64 ? filesState.base64 : props.files}
                  alt=""
                />
                <UpdateImageHoverDiv>
                  <CameraIcon src={cameraIcon} alt="" />
                  <CommonText fontSize="10px" margin="0" color="#fff">
                    Update
                  </CommonText>
                </UpdateImageHoverDiv>
              </UploadImageDiv>
            );
          } else {
            return (
              <NoImageContainer>
                <CameraIcon src={cameraIcon} alt="" />
                <CommonText fontSize="8px" title margin="0">
                  Select picture
                </CommonText>
                <CommonText fontSize="8px" margin="0">
                  or drag here
                </CommonText>
              </NoImageContainer>
            );
          }
        }}
      </Dropzone>
    </DropZoneContainer>
  );
};
export default ImageUpload;
