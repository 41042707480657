import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../../styledComponents/members";
import { CommonText } from "../../../styledComponents/common";
import { ColoredButton, WhiteButton } from "../../../styledComponents/buttons";

const CancelSubscriptionModal = ({ isOpen, toggle, cancelSubscription }) => {
  const unsubscribe = () => {
    cancelSubscription();
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `10px` }}>
          Are you sure you want to cancel your subscription? You will be
          unsubscribed from your current plan and your organization will be
          archived.
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={() => toggle()}>
            Close
          </WhiteButton>
          <ColoredButton type="delete" onClick={() => unsubscribe()}>
            Cancel Subscription
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default CancelSubscriptionModal;
