//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  REDEEM_CODE,
  REDEEM_CODE_SUCCESS,
  REDEEM_CODE_FAILURE
} from "../../modules/constants";

//Handle reset password request
export function* redeemCode({ payload }) {
  try {
    let url = "stripe/apply_coupon/";
    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: REDEEM_CODE_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: REDEEM_CODE_FAILURE,
      payload: err
    });
  }
}

export default function* root() {
  yield all([takeLatest(REDEEM_CODE, redeemCode)]);
}
