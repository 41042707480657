import { connect } from "react-redux";

// Component
import TimesheetSettings from "./TimesheetSettings";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.timesheetSettings.isLoading,
  selectedOrganization: state.organization.selectedOrganization,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetSettings);
