import {
  GET_LIVE_MEMBERS_LIST,
  GET_LAST_FIVE_MIN_ACTIVE_LIST,
  GET_INSTANT_SCREENSHOTS_LIST,
  TAKE_INSTANT_SCREENSHOT,
} from "../../modules/constants";

export function getLastFiveMinutesActiveList(payload) {
  return {
    type: GET_LAST_FIVE_MIN_ACTIVE_LIST,
    payload,
  };
}

export function getLiveMembersList(payload) {
  return {
    type: GET_LIVE_MEMBERS_LIST,
    payload,
  };
}

export function getInstantScreenshotsList(payload) {
  return {
    type: GET_INSTANT_SCREENSHOTS_LIST,
    payload,
  };
}

export function takeInstantScreenshot(payload) {
  return {
    type: TAKE_INSTANT_SCREENSHOT,
    payload,
  };
}
