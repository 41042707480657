import { useEffect, useState } from "react";

import {
  OrgSettingsSection,
  SettingPropertySection,
  ToggleButton,
  ToggleButtonRound,
} from "../timesheetSettingsStyles";
import {
  CardTitle,
  CommonText,
  Container,
  CommonGrid,
} from "../../../../styledComponents/common";

import ComponentCircleLoader from "../../../../components/Loaders/ComponentCircleLoader";

const ManualTimeSettings = (props) => {
  const [isManualTimeAllowed, setIsManualTimeAllowed] = useState(false);

  const {
    isLoading,
    selectedOrganization,
    updateTimesheetSettings,
    // allowUsersAddManualTime,
  } = props;

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      if (selectedOrganization.configuration.allow_user_add_manual_timesheet) {
        setIsManualTimeAllowed(true);
      } else {
        setIsManualTimeAllowed(false);
      }
    }
  }, [selectedOrganization]);

  const updateTimesheetTracking = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        allow_user_add_manual_timesheet: value,
      };
      updateTimesheetSettings(payload);
    }
  };

  return (
    <OrgSettingsSection padding="10px 30px 30px">
      <CardTitle>Configure manual time settings</CardTitle>
      <Container padding="25px" style={{ margin: "20px 0" }}>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            <CommonText name title margin="0 0 10px 0">
              Manual Time
            </CommonText>
            <SettingPropertySection>
              <CommonGrid columns="45% 55%" gap="2em">
                <CommonText $label fontWeight="500" margin="0">
                  Allow members to add manual time
                </CommonText>
                <CommonGrid
                  columns="36px 30px"
                  gap="10px"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() =>
                      updateTimesheetTracking(!isManualTimeAllowed)
                    }
                    active={isManualTimeAllowed}
                  >
                    <ToggleButtonRound active={isManualTimeAllowed} />
                  </ToggleButton>
                  <CommonText
                    $label={!isManualTimeAllowed}
                    fontWeight="500"
                    fontSize="14px"
                    margin="0"
                  >
                    {isManualTimeAllowed ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </CommonGrid>
            </SettingPropertySection>
          </>
        )}
      </Container>
    </OrgSettingsSection>
  );
};

export default ManualTimeSettings;
