import { connect } from "react-redux";

import WorkNotes from "./WorkNotes";

import { getOrganizationMembersList } from "../Projects/projectsActions";

import { getWorkNotes } from "./workNotesActions";

const mapStateToProps = (state) => ({
  isLoading: state.workNotes.isLoading,

  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,

  workNotes: state.workNotes.workNotes,

  // pagination
  totalNotesCount: state.workNotes.totalNotesCount,
  pageSize: state.workNotes.pageSize,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (payload) =>
    dispatch(getOrganizationMembersList(payload)),
  getWorkNotes: (payload) => dispatch(getWorkNotes(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkNotes);
