import { useState } from "react";
import { NavLink } from "react-router-dom";

import {
  SingleLinkContainer,
  SingleCollapseLinkContainer,
  CollapseArrowIcon,
  SidebarLinkCollapse,
  SidebarListItem,
  SidebarCollapseList,
  SidebarCollapseListItem,
  SidebarLink,
  LinkIcon,
  LinkText,
  NewBadge,
  CollapseLinkIcon,
  CollapseLinkText,
  SeeMoreContainer,
  SeeMoreDiv,
  SeeMoreArrow,
} from "./sidebarStyles";

import DashboardIcon from "assets/img/Sidebar/dashboard.svg";
import RemoteTrackIcon from "assets/img/Sidebar/remote-track.svg";
import TimesheetIcon from "assets/img/Sidebar/timesheet.svg";
import ReportsIcon from "assets/img/Sidebar/reports.svg";

import TasksIcon from "assets/img/Sidebar/tasks.svg";
import ClockInOutIcon from "assets/img/Sidebar/clock-in.svg";
import SchedulesIcon from "assets/img/Sidebar/schedule.svg";
import AttendanceIcon from "assets/img/Sidebar/attendance.svg";
import GPSTrackIcon from "assets/img/Sidebar/gps-track.svg";

import ProjectsIcon from "assets/img/Sidebar/project.svg";
import InvoiceIcon from "assets/img/Sidebar/invoice.svg";
import ClientsIcon from "assets/img/Sidebar/clients.svg";

import TeamsIcon from "assets/img/Sidebar/teams.svg";
import MembersIcon from "assets/img/Sidebar/members.svg";
import PayrollIcon from "assets/img/Sidebar/payroll.svg";
import OrganizationsIcon from "assets/img/Sidebar/organization.svg";
import IntegrationsIcon from "assets/img/Sidebar/integrations.svg";
import SettingsIcon from "assets/img/Sidebar/settings.svg";

import DesktopAppIcon from "assets/img/Sidebar/desktop.svg";
import MobileAppIcon from "assets/img/Sidebar/mobile.svg";
import ReadBlogIcon from "assets/img/Sidebar/read-blog.svg";
import InviteMemberIcon from "assets/img/Sidebar/invite-member.svg";
import PublicApiIcon from "assets/img/Sidebar/public_api_icon.svg";
import HelpIcon from "assets/img/Sidebar/help.svg";

import arrowDown from "assets/img/Sidebar/sidebar-arrow-down.svg";
import arrowUp from "assets/img/Sidebar/sidebar-arrow-up.svg";

import { PulsatingDot } from "../LiveFeed/CurrentlyTracking/CurrentlyTracking";

const seeMoreData = [
  {
    name: "Invite Member",
    link: "/user/member-invitation",
    icon: InviteMemberIcon,
    isExternal: false,
    Highlight: false,
  },
  {
    name: "Desktop App",
    link: "https://apploye.com/download",
    icon: DesktopAppIcon,
    isExternal: true,
    Highlight: false,
  },
  {
    name: "Mobile App",
    link: "https://apploye.com/download",
    icon: MobileAppIcon,
    isExternal: true,
    Highlight: false,
  },
  {
    name: "Public API",
    link: "/user/public-api",
    icon: PublicApiIcon,
    isExternal: false,
    new: true,
    Highlight: true,
  },
  {
    name: "Read Blog",
    link: "https://apploye.com/blog/",
    icon: ReadBlogIcon,
    isExternal: true,
    Highlight: false,
  },
  {
    name: "Help",
    link: "https://apploye.com/help/",
    icon: HelpIcon,
    isExternal: true,
    Highlight: false,
  },
];

const activeRoute = (routeName, location) => {
  if (
    routeName === "/user/settings" &&
    (location.pathname === "/user/current-plan" ||
      location.pathname === "/user/subscription" ||
      location.pathname === "/user/activity-settings" ||
      location.pathname === "/user/timesheet-settings")
  ) {
    return true;
  } else if (
    routeName === "/user/projects" &&
    (location.pathname === "/user/create-project" ||
      location.pathname === "/user/edit-project" ||
      location.pathname === "/user/project-details")
  ) {
    return true;
  } else if (
    routeName === "/user/live-feed" &&
    location.pathname === "/user/instant-screenshots"
  ) {
    return true;
  } else if (
    routeName === "/user/organizations" &&
    location.pathname === "/user/add-organization"
  ) {
    return true;
  } else if (
    routeName === "/user/clients" &&
    (location.pathname === "/user/add-client" ||
      location.pathname === "/user/edit-client" ||
      location.pathname === "/user/client-details")
  ) {
    return true;
  } else if (
    routeName === "/user/members" &&
    (location.pathname === "/user/member-details" ||
      location.pathname === "/user/member-invitation")
  ) {
    return true;
  } else if (
    routeName === "/user/teams" &&
    (location.pathname === "/user/add-team" ||
      location.pathname === "/user/team-details")
  ) {
    return true;
  } else if (
    routeName === "/user/invoices" &&
    (location.pathname === "/user/create-invoice" ||
      location.pathname === "/user/edit-invoice" ||
      location.pathname === "/user/invoice-preview" ||
      location.pathname === "/user/invoice-client-view")
  ) {
    return true;
  } else if (
    routeName === "/user/reports/custom-report" &&
    (location.pathname === "/user/reports/create-custom-report" ||
      location.pathname === "/user/reports/custom-report")
  ) {
    return true;
  } else {
    return location.pathname.indexOf(routeName) > -1;
  }
};

export const generateGroupedLinks = (routes, organization) => {
  const sidebarGroupedLinks = { Analyze: [], Manage: [], Admin: [] };
  const paths = [...routes];
  paths.map((path, key) => {
    if (
      path.redirect ||
      (organization &&
        organization.role === "member" &&
        (path.name === "Invoice" ||
          path.name === "Clients" ||
          path.name === "Organizations" ||
          path.name === "Payroll" ||
          path.name === "Integrations" ||
          path.name === "Reports" ||
          path.name === "Settings" ||
          path.name === "Live Feed")) ||
      (organization &&
        path.name !== "Field Services" &&
        path.monitored &&
        organization.plan &&
        organization.plan.screenshot_track === false) ||
      (organization &&
        organization.role === "team_leader" &&
        (path.name === "Invoice" ||
          path.name === "Clients" ||
          path.name === "Organizations" ||
          path.name === "Integrations"))
    ) {
      return null;
    } else if (path.name === "Field Services") {
      if (
        organization &&
        organization.plan &&
        organization.plan.location_track === false
      ) {
        return null;
      } else if (
        organization &&
        organization.configuration &&
        !organization.configuration.location_track &&
        !organization.configuration.geofence_time_clock
      ) {
        return null;
      }
    } else if (
      organization &&
      organization.plan &&
      organization.plan.name === "Solo" &&
      (path.name === "Live Feed" ||
        path.name === "Teams" ||
        path.name === "Members")
    ) {
      return null;
    } else if (
      path.name === "Settings" &&
      organization &&
      organization.role === "member"
    ) {
      return null;
    }
    sidebarGroupedLinks[path.group].push(path);
  });
  return sidebarGroupedLinks;
};
// this verifies if any of the collapses should be default opened on a rerender of this component
// for example, on the refresh of the page,
// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
const getCollapseInitialState = (routes) => {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
      return true;
    } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
      return true;
    }
  }
  return false;
};

// this creates the intial state of this component based on the collapse routes
// that it gets through this.props.routes
export const getCollapsedStates = (routes) => {
  let initialState = {};
  routes.map((path, key) => {
    if (path.collapse) {
      initialState = {
        [path.state]: getCollapseInitialState(path.views),
        ...getCollapsedStates(path.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
};

const getCollapseLinks = (organization, views, location, sidebarMini) => {
  return views
    .filter((v) => !v.hide)
    .map((view, index) => {
      if (
        organization &&
        organization.plan &&
        organization.plan.screenshot_track === false &&
        view.monitored
      ) {
        return null;
      } else if (
        view.name === "Route Map" &&
        organization &&
        organization.configuration &&
        !organization.configuration.location_track
      ) {
        return null;
      } else if (
        (view.name === "Job Sites" || view.name === "Geofence Clock In/Out") &&
        organization &&
        organization.configuration &&
        !organization.configuration.geofence_time_clock
      ) {
        return null;
      }
      return (
        <SidebarListItem key={index} collapse>
          <NavLink to={view.layout + view.path} activeClassName="">
            <SingleCollapseLinkContainer
              style={{ paddingLeft: "10px" }}
              active={activeRoute(view.layout + view.path, location)}
              sidebarMini={sidebarMini}
            >
              <CollapseLinkIcon>{view.mini}</CollapseLinkIcon>
              <CollapseLinkText>{view.name}</CollapseLinkText>
              {view.new && (
                <NewBadge mini={true}>{view.beta ? "BETA" : "NEW"}</NewBadge>
              )}
            </SingleCollapseLinkContainer>
          </NavLink>
        </SidebarListItem>
      );
    });
};

export const getLinkView = (
  organization,
  path,
  index,
  collapseState,
  setCollapseState,
  location,
  sidebarMini
) => {
  const imageMap = {
    dashboardIcon: DashboardIcon,
    remoteTrackIcon: RemoteTrackIcon,
    timesheetIcon: TimesheetIcon,
    reportsIcon: ReportsIcon,
    clockInOutIcon: ClockInOutIcon,
    tasksIcon: TasksIcon,
    schedulesIcon: SchedulesIcon,
    gpsTrackIcon: GPSTrackIcon,
    projectsIcon: ProjectsIcon,
    invoiceIcon: InvoiceIcon,
    clientsIcon: ClientsIcon,
    teamsIcon: TeamsIcon,
    membersIcon: MembersIcon,
    payrollIcon: PayrollIcon,
    integrationsIcon: IntegrationsIcon,
    organizationsIcon: OrganizationsIcon,
    settingsIcon: SettingsIcon,
  };
  if (path.collapse) {
    let st = {};
    st[path["state"]] = !collapseState[path.state];
    return (
      <SidebarCollapseListItem
        key={index}
        collapse
        active={getCollapseInitialState(path.views)}
      >
        <SidebarLink
          active={getCollapseInitialState(path.views)}
          aria-expanded={collapseState[path.state]}
          onClick={(e) => {
            e.preventDefault();
            setCollapseState(st);
          }}
        >
          {path.icon !== undefined && (
            <SingleLinkContainer sidebarMini={sidebarMini}>
              {path.live ? (
                <PulsatingDot
                  active={getCollapseInitialState(path.views)}
                  size="10px"
                  margin="10px 7px 7px 9px"
                />
              ) : (
                <LinkIcon src={imageMap[path.icon]} alt="" />
              )}

              <LinkText>{path.name}</LinkText>
              {path.new && <NewBadge>{path.beta ? "BETA" : "NEW"}</NewBadge>}
              <CollapseArrowIcon
                src={arrowDown}
                alt=""
                collapsed={!collapseState[path.state]}
              />
            </SingleLinkContainer>
          )}
        </SidebarLink>
        <SidebarLinkCollapse isOpen={collapseState[path.state]}>
          <SidebarCollapseList>
            {getCollapseLinks(
              organization,
              path.views,
              location,
              sidebarMini,
              path.collapse
            )}
          </SidebarCollapseList>
        </SidebarLinkCollapse>
      </SidebarCollapseListItem>
    );
  }
  return (
    <SidebarListItem
      key={index}
      active={activeRoute(path.layout + path.path, location)}
      name={path.name}
    >
      <NavLink to={path.layout + path.path} activeClassName="">
        {path.icon !== undefined && (
          <SingleLinkContainer name={path.name} sidebarMini={sidebarMini}>
            {path.live ? (
              <PulsatingDot
                active={activeRoute(path.layout + path.path, location)}
                size="10px"
                margin="10px 7px 7px 9px"
              />
            ) : (
              <LinkIcon src={imageMap[path.icon]} alt="" />
            )}
            <LinkText>{path.name}</LinkText>
            {path.new && <NewBadge>{path.beta ? "BETA" : "NEW"}</NewBadge>}
          </SingleLinkContainer>
        )}
      </NavLink>
    </SidebarListItem>
  );
};

export const SeeMoreView = (props) => {
  const [isSeeMoreOpen, setIsSeeMoreOpen] = useState(false);
  return (
    <SeeMoreContainer>
      <SeeMoreDiv
        onClick={() => setIsSeeMoreOpen(!isSeeMoreOpen)}
        isActive={isSeeMoreOpen}
      >
        <SeeMoreArrow src={arrowDown} alt="" collapsed={!isSeeMoreOpen} />
        <LinkText>See More</LinkText>
      </SeeMoreDiv>
      <SidebarLinkCollapse isOpen={isSeeMoreOpen}>
        <SidebarCollapseList style={{ marginTop: "5px" }}>
          {seeMoreData.map((item, index) => (
            <SidebarListItem
              key={index}
              active={
                !item.isExternal &&
                activeRoute(item.link, props.location) &&
                item.Highlight
              }
            >
              {item.isExternal ? (
                <SidebarLink
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SingleLinkContainer>
                    <LinkIcon
                      src={item.icon}
                      alt=""
                      style={{ marginLeft: "-3px" }}
                    />
                    <LinkText>{item.name}</LinkText>
                    {item.new && (
                      <NewBadge>{item.beta ? "BETA" : "NEW"}</NewBadge>
                    )}
                  </SingleLinkContainer>
                </SidebarLink>
              ) : props.organization &&
                (props.organization.role === "owner" ||
                  props.organization.role === "admin") ? (
                <NavLink to={item.link} activeClassName="">
                  <SingleLinkContainer>
                    <LinkIcon
                      src={item.icon}
                      alt=""
                      style={{ marginLeft: "-3px" }}
                    />
                    <LinkText>{item.name}</LinkText>
                    {item.new && (
                      <NewBadge>{item.beta ? "BETA" : "NEW"}</NewBadge>
                    )}
                  </SingleLinkContainer>
                </NavLink>
              ) : null}
            </SidebarListItem>
          ))}
        </SidebarCollapseList>
      </SidebarLinkCollapse>
    </SeeMoreContainer>
  );
};
