import { useState, useEffect } from "react";
import {
  ToggleSwitchContainer,
  ToggleSwitchWrapper,
  Slider,
} from "./pricingPlanStyles";

export default function ToggleSwitch(props) {
  const handleToggle = () => {
    if (props.toggleAction) {
      props.toggleAction();
    }
  };
  return (
    <ToggleSwitchContainer>
      <ToggleSwitchWrapper>
        <Slider active={props.checked} onClick={() => handleToggle()} />
      </ToggleSwitchWrapper>
    </ToggleSwitchContainer>
  );
}
