import styled from "styled-components";

export const PrepurchasedSectionContainer = styled.div`
  display: grid;
`;

export const AdditionalSectionContainer = styled.div`
  padding: 10px 12px;
  display: grid;
  grid-row-gap: 8px;
  border: 1px solid ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.borderRadii.input};
`;

export const AdditionalSectionTitle = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.labelText};
  padding: ${({ padding }) => padding || `0`};
`;
