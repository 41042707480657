

import {
  ActivityBarTrack,
  AppsUrlsBarTrain,
} from "../../styledComponents/common";

const AppsUrlsPercentageBar = ({ percent, marginTop }) => (
  <ActivityBarTrack percent={percent} marginTop={marginTop}>
    <AppsUrlsBarTrain percent={percent} />
  </ActivityBarTrack>
);
export default AppsUrlsPercentageBar;
