import {
  GET_SCHEDULE_LIST,
  DEACTIVATE_SCHEDULE,
  ADD_SCHEDULE,
  UPDATE_SCHEDULE_LIST,
  GET_SCHEDULE,
  RESET_SELECTED_SCHEDULE
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getScheduleRequest(value) {
  return {
    type: GET_SCHEDULE_LIST,
    payload: value
  };
}

export function getSelectedScheduleRequest(value) {
  return {
    type: GET_SCHEDULE,
    payload: value
  };
}

export function addScheduleRequest(value) {
  return {
    type: ADD_SCHEDULE,
    payload: value
  };
}

export function updateScheduleRequest(value) {
  return {
    type: UPDATE_SCHEDULE_LIST,
    payload: value
  };
}

export function deactivateScheduleRequest(value) {
  return {
    type: DEACTIVATE_SCHEDULE,
    payload: value
  };
}

export function resetSelectedScheduleRequest() {
  return {
    type: RESET_SELECTED_SCHEDULE
  };
}

export const actions = {
  getScheduleRequest,
  deactivateScheduleRequest,
  addScheduleRequest,
  updateScheduleRequest,
  getSelectedScheduleRequest,
  resetSelectedScheduleRequest
};
