import { connect } from "react-redux";

import RouteMap from "./RouteMap";

import { getRouteMapLocations } from "./routeMapActions";

const mapStateToProps = (state) => ({
  // loading
  locationsIsLoading: state.routeMap.locationsIsLoading,

  selectedOrganization: state.organization.selectedOrganization,

  routeMapLocations: state.routeMap.routeMapLocations,
});

const mapDispatchToProps = (dispatch) => ({
  getRouteMapLocations: (payload) => dispatch(getRouteMapLocations(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteMap);
