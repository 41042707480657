import { useState, useEffect, useRef } from "react";
import { CommonText } from "../../../styledComponents/common";
import {
  TimeSlotCard,
  IdleTimeDetailsDiv,
  IdleTimeDetailsRow,
  IdleTimePopupHeader,
  IdleTimePopupTotalSection,
  TimesheetTooltipContainer,
  IdleDeleteButton,
} from "../../../styledComponents/timesheet";
import { ActivityTextSection } from "../../../styledComponents/members";

import { getDuration, getDurationInMinutes } from "./helperFunctions";

import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

import deleteWhite from "../../../assets/img/icons/delete_white.svg";

export const IdleTimeSlot = ({
  index,
  timesheetIndex,
  timesheetDuration,
  idleTimeData,
  startPosition,
}) => {
  return (
    <TimeSlotCard
      key={index}
      top={idleTimeData.top}
      height={idleTimeData.height}
      index={timesheetIndex}
      startPosition={startPosition}
      bgColor={
        getDurationInMinutes(timesheetDuration) < 20 ? "#FE5969" : "#FAEAEA"
      }
    />
  );
};

const SingleIdleTimeRow = ({
  idleTimeData,
  project,
  task,
  handleDeleteIdleTime,
}) => {
  const { start_timestamp, end_timestamp, duration } = idleTimeData;
  return (
    <IdleTimeDetailsRow>
      <ActivityTextSection>
        <CommonText name fontSize="14px" fontWeight="600">
          {project.name}
        </CommonText>
        <CommonText fontSize="12px" fontWeight="600">
          {task ? task.name : "No task"}
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText name fontSize="14px" fontWeight="600">
          {start_timestamp.format("h:mm A")}
        </CommonText>
        <CommonText fontSize="12px" fontWeight="600">
          Start Time
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText name fontSize="14px" fontWeight="600">
          {end_timestamp.format("h:mm A")}
        </CommonText>
        <CommonText fontSize="12px" fontWeight="600">
          End Time
        </CommonText>
      </ActivityTextSection>
      <ActivityTextSection>
        <CommonText name fontSize="14px" fontWeight="600">
          {getDuration(duration)}
        </CommonText>
        <CommonText fontSize="12px" fontWeight="600">
          Total Idle Time
        </CommonText>
      </ActivityTextSection>
      <IdleDeleteButton
        onClick={() =>
          handleDeleteIdleTime(start_timestamp, end_timestamp, duration)
        }
      >
        <img
          src={deleteWhite}
          style={{ width: "16px", height: "16px" }}
          alt="delete"
        />
        <TimesheetTooltipContainer>
          <CommonTooltip
            tooltipText="Delete"
            left="-50px"
            top="-46px"
            width="85px"
            height="25px"
          />
        </TimesheetTooltipContainer>
      </IdleDeleteButton>
    </IdleTimeDetailsRow>
  );
};

export const IdleTimeDetailsPopup = ({
  index,
  idleTimes,
  timesheetId,
  project,
  task,
  timesheetTop,
  height,
  startPosition,
  toggleDeleteTimeModal,
  setIdleTimePopup,
}) => {
  const [totalIdleTime, setTotalIdleTime] = useState(0);
  const popUpRef = useRef(null);

  // outside click function
  useOutsideClickHandler(popUpRef);

  function useOutsideClickHandler(popUpRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (popUpRef.current && !popUpRef.current.contains(event.target)) {
          setIdleTimePopup(-1);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [popUpRef]);
  }

  useEffect(() => {
    const idleDurations = [];
    idleTimes.forEach((idleTime) => {
      idleDurations.push(idleTime.duration);
    });
    setTotalIdleTime(
      idleDurations.slice(1).reduce((prev, cur) => cur + prev, idleDurations[0])
    );
  }, [idleTimes]);

  const handleDeleteIdleTime = (startTime, endTime, duration) => {
    const deleteTimeData = {
      id: timesheetId,
      start_time: startTime,
      end_time: endTime,
      duration: duration,
    };
    toggleDeleteTimeModal(deleteTimeData);
  };
  return (
    <TimeSlotCard
      index={index}
      top={timesheetTop - height - 15}
      height={height}
      startPosition={startPosition}
      bgColor="#F5F9FD"
      borderColor="#7F9DBF"
      style={{
        left: "200px",
        zIndex: "5",
      }}
      ref={popUpRef}
    >
      <IdleTimePopupHeader>
        <IdleTimePopupTotalSection>
          <CommonText name fontWeight="600">
            Total Idle Time
          </CommonText>
          <CommonText color="#FE5969" fontWeight="600" margin="0 0 0 8px">
            {getDuration(totalIdleTime)}
          </CommonText>
        </IdleTimePopupTotalSection>
      </IdleTimePopupHeader>
      <IdleTimeDetailsDiv>
        {idleTimes.map((idleTimeData, index) => (
          <SingleIdleTimeRow
            key={index}
            idleTimeData={idleTimeData}
            project={project}
            task={task}
            handleDeleteIdleTime={handleDeleteIdleTime}
          />
        ))}
      </IdleTimeDetailsDiv>
    </TimeSlotCard>
  );
};
