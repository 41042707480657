import styled from "styled-components";

export const TasksTableRow = styled.div`
  display: grid;
  grid-template-columns: ${({ projectData }) =>
    projectData
      ? "1.5fr 100px 170px 100px 100px"
      : "1.5fr 1fr 100px 170px 100px 100px"};
  grid-column-gap: 20px;
  justify-content: flex-start;
  padding: ${({ padding }) => padding || "0 30px"};
  border-bottom: ${({ header, theme }) =>
    header && `1px solid ${theme.colors.border.secondary}`};
  :nth-of-type(even) {
    background-color: ${({ header }) => !header && "#f8f9fc"};
  }
`;

export const AddTaskContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "60% 40%"};
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding }) => padding || "15px"};
  border: 1px solid
    ${({ theme, error }) =>
      error ? theme.colors.border.error : theme.colors.border.primary};
  background-color: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ radius, theme }) => radius || theme.borderRadii.button};
`;
export const AddTaskInputAndProjectContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 230px;
  grid-column-gap: 15px;
  justify-content: space-between;
  align-items: center;
`;
export const AddTaskInput = styled.input`
  padding-left: 15px;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
`;
