import styled from "styled-components";
import { Container } from "./common";
import { getRandomColor } from "../utils/getRandomColor";
let dailyTimeSheetColor = [
  {
    main: "#EAF9F7",
    border: "#82C4BB",
  },
  {
    main: "#FBF3DA",
    border: "#FECF34",
  },
  {
    main: "#ECF0FC",
    border: "#9A96F9",
  },
];

//Timesheet Filter
export const FilterContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || `auto 230px`};
  grid-column-gap: 35px;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
  @media screen and (max-width: 1400px) {
    grid-column-gap: 18px;
  }
`;
export const FilterSubContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-column-gap: 20px;
  justify-content: space-between;
  /* max-width: 600px; */
`;
export const TimesheetContainer = styled(Container)`
  padding: 25px 25px 25px;
`;

export const TotalTimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const TimeRangeText = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
`;
export const TotalTimeDiv = styled.div`
  height: 40px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ecf0fc;
  border-radius: 6px;
  margin-left: ${(props) => (props.monthly ? "0" : "40px")};
`;
export const TotalTimeLabel = styled.span`
  font-size: ${({ theme }) => theme.font.otherLabel.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.font.pageText.colorLabel};
`;
export const TotalTimeText = styled(TotalTimeLabel)`
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  margin-left: 15px;
`;

export const TableContainer = styled.div`
  margin-top: 25px;
  min-height: 250px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
`;
export const TableHeadContainer = styled.div`
  display: grid;
  height: 45px;
  grid-template-columns: ${(props) =>
    props.custom
      ? `minmax(115px, 14.285%) minmax(115px, 14.285%) minmax(115px, 14.285%)
    minmax(115px, 14.285%) minmax(115px, 14.285%) minmax(115px, 14.285%) minmax(
      115px,
      14.285%
    )`
      : `minmax(115px, 12.5%) minmax(115px, 12.5%) minmax(115px, 12.5%)
    minmax(115px, 12.5%) minmax(115px, 12.5%) minmax(115px, 12.5%) minmax(
      115px,
      12.5%
    )
    minmax(115px, 12.5%)`};
`;
export const TableBodyContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.custom
      ? `minmax(115px, 14.285%) minmax(115px, 14.285%) minmax(115px, 14.285%)
    minmax(115px, 14.285%) minmax(115px, 14.285%) minmax(115px, 14.285%) minmax(
      115px,
      14.285%
    )`
      : `minmax(115px, 12.5%) minmax(115px, 12.5%) minmax(115px, 12.5%)
    minmax(115px, 12.5%) minmax(115px, 12.5%) minmax(115px, 12.5%) minmax(
      115px,
      12.5%
    )
    minmax(115px, 12.5%)`};
  height: 125px;
`;
export const TableHeadItem = styled.div`
  font-size: ${({ theme }) => theme.font.otherLabel.size};
  color: ${({ theme }) => theme.font.otherLabel.color};
  font-weight: ${({ theme }) => theme.font.otherLabel.weight};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f8f9;
  border: 1px solid #f6f8f9;
  text-transform: ${(props) => (props.total ? "none" : "uppercase")};
`;
export const TableBodyItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f6f8f9;
  &:first-child {
    border-left: 1px solid #f6f8f9;
  }
  &:last-child {
    border-right: 1px solid #f6f8f9;
  }
`;
export const DailyTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 90px;
  border-radius: 6px;
  background: ${({ backgroundColor }) => backgroundColor};
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;
export const TimesheetDate = styled.div`
  font-size: ${({ theme }) => theme.font.otherLabel.size};
  color: ${({ theme }) => theme.font.otherLabel.color};
  margin-top: 8px;
`;
export const WeeklyTotal = styled.div`
  width: 90px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.colors.main.white};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background: ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  margin-bottom: 30px;
`;

//Weekly details
export const WeeklyDetailsTableContainer = styled.div`
  padding-bottom: 25px;
  width: 100%;
  max-height: 70vh;
  overflow-x: auto;
  overflow-y: auto;
  @media screen and (max-width: 1400px) {
    max-height: 60vh;
  }
`;
export const WeeklyDetailsTableHeadContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(50px, 6.5%) minmax(80px, 11.5%) minmax(80px, 11.5%)
    minmax(80px, 11.5%) minmax(80px, 11.5%) minmax(80px, 11.5%) minmax(
      80px,
      11.5%
    )
    minmax(80px, 11.5%) minmax(80px, 11.5%);
  align-items: center;
`;
export const TableHeadText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color, theme }) => color || theme.font.otherLabel.color};
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.otherLabel.size};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight || theme.font.pageText.weight};
  margin: ${({ margin }) => margin || "15px 0 0 0"};
`;
export const TableHeadDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ alignItems }) => alignItems || "center"};
`;
export const TableHeadDayText = styled.div`
  color: ${({ color, theme }) => color || theme.font.otherLabel.color};
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.otherLabel.size};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight || theme.font.pageText.weight};
`;
export const TableHeadDate = styled.div`
  height: 40px;
  width: 40px;
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.borderRadii.input};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.pageText.size};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  background: #fcfdfe;
  margin-top: 5px;
`;

export const WeeklyDetailsTableBodyContainer = styled.div`
  margin-top: 30px;
`;
export const TableBodySingleItemContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(50px, 6.5%) minmax(80px, 11.5%) minmax(80px, 11.5%)
    minmax(80px, 11.5%) minmax(80px, 11.5%) minmax(80px, 11.5%) minmax(
      80px,
      11.5%
    )
    minmax(80px, 11.5%) minmax(80px, 11.5%);
  align-items: center;
  min-height: 60px;
`;
export const SingleItemUserDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
`;
export const SingleItemUserAvatar = styled.img`
  height: ${({ size }) => size || "36px"};
  width: ${({ size }) => size || "36px"};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  border: 1px solid ${({ theme }) => theme.colors.border.secondary};
`;
export const SingleItemUserName = styled.div`
  font-size: ${({ theme }) => theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.font.pageText.weight};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  margin-left: 10px;
`;
export const SingleItemTime = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.pageText.size};
  border: 1px solid #f0f3f7;
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
  height: 60px;
  cursor: ${({ hasTime }) => hasTime && "pointer"};
  &:hover {
    text-decoration: ${({ hasTime }) => hasTime && "underline"};
  }
`;
export const SingleItemTotal = styled.div`
  width: 78px;
  height: 36px;
  background: #ecf0fc;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize, theme }) => fontSize || theme.font.pageText.size};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.font.pageText.colorSecondary};
`;

//TIMESHEET DAILY
export const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-column-gap: 20px;
  position: relative;
`;
export const UpperTotalHour = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "auto auto"};
  grid-column-gap: 15px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  padding: 10px 15px;
  background-color: ${({ bgColor }) => bgColor || "#ecf0fc"};
  margin-top: auto;
  position: relative;
`;
export const TimeText = styled.div`
  font-size: 16px;
  color: #2f394e;
`;
export const TimeSlot = styled.div`
  border-top: ${({ index, startPosition }) =>
    index === startPosition && `1px solid #f0f3f7`};
  border-bottom: 1px solid #f0f3f7;
  height: 75px;
`;
export const TimeSlotCard = styled.div`
  position: absolute;
  top: ${({ top, startPosition }) => `${top - startPosition * 75}px`};
  left: 120px;
  height: ${({ height }) => `${height}px`};
  width: 670px;
  background-color: ${({ small, index, bgColor }) =>
    bgColor
      ? bgColor
      : small
      ? dailyTimeSheetColor[index % 3].border
      : dailyTimeSheetColor[index % 3].main};
  padding: 0 20px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  border-left: ${({ index, borderColor }) =>
    `7px solid ${
      borderColor ? borderColor : dailyTimeSheetColor[index % 3].border
    }`};
`;
export const TimeSlotSmallInfo = styled.div`
  position: absolute;
  left: 660px;
  top: ${({ top }) => `${top - 14}px`};
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  cursor: pointer;
  background: ${({ index }) => dailyTimeSheetColor[index % 3].border};
`;

export const TimeSlotInnerCard = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-column-gap: 15px;
  justify-content: space-around;
  align-content: center;
  text-align: center;
  height: 100%;
`;
export const IdleTimePopupHeader = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e4e7eb;
`;
export const IdleTimePopupTotalSection = styled.div`
  display: flex;
  align-items: center;
`;
export const IdleTimeDetailsDiv = styled.div`
  display: grid;
  grid-row-gap: 25px;
  padding: 25px 0px;
`;
export const IdleTimeDetailsRow = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 15px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  height: 46px;
`;

export const TimesheetTooltipContainer = styled.div`
  position: relative;
  display: none;
`;

export const IdleDeleteButton = styled.div`
  height: 28px;
  width: 28px;
  background-color: #fe5969;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    ${TimesheetTooltipContainer} {
      display: flex;
    }
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  top: -17px;
  right: 0px;
  background-color: ${({ theme }) => theme.colors.main.error};
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  cursor: pointer;

  &:hover {
    ${TimesheetTooltipContainer} {
      display: flex;
    }
  }
`;
export const InfoButton = styled(DeleteButton)`
  background-color: ${({ index, bgColor }) =>
    bgColor ? bgColor : dailyTimeSheetColor[index % 3].border};
  right: ${({ right }) => right || "35px"};

  &:hover {
    filter: brightness(0.98);
  }
`;
