import { useState, useRef, useEffect, Fragment } from "react";
import AssigneeTooltip from "../Tooltip/AssigneeTooltip";
import assign from "../../assets/img/icons/assign2.svg";
import {
  AssigneeRoundImage,
  RoundPersonImage,
  RoundImageCross,
  AssigneeRoundImageSection,
} from "../../styledComponents/createProject";
import {
  AssignListContainer,
  AssignListItem,
  AssignListText,
  SearchBarWithAssignListContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchBarContainer,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  ShowMoreAssigneeList,
} from "../../styledComponents/common";

import searchIcon from "../../assets/img/icons/search.svg";
import removeAssignee from "../../assets/img/icons/remove_assignee.svg";
import DropdownLoader from "../../components/DropdownLoader/DropdownLoader";
import FirstRoundLetterComp from "../FirstRoundLetter/FirstRoundLetter";

const AddMultiMemberDropdown = ({
  selectedMembers,
  updateSelectedMembers,
  membersList,
  clearSelectedMembers,
  numberOfVisibleMembers = 4,
  isLoading,
  assigneeIsLoading,
  visibilityChange = () => true,
}) => {
  const [addAssignee, setAddAssignee] = useState(false);
  const [moreAssignee, setMoreAssignee] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);

  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);

  const [addList, setAddList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = membersList?.filter(
        (item) => !selectedMembers?.some((assignee) => assignee.id === item.id)
      );
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
  }, [membersList, selectedMembers]);
  // useEffect(() => {
  //   membersList && updateSelectedMembers(membersList);
  // }, [membersList]);
  const selectedAllMembers = () => {
    membersList && updateSelectedMembers(membersList);
    setAddAssignee(false);
    visibilityChange(false);
  };

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== "" &&
      membersList &&
      membersList.length > 0
    ) {
      let filteredList = membersList.filter((e) =>
        e.name.toLowerCase().includes(searchTerm.toLowerCase().trim())
      );
      filteredList = filteredList.filter(
        (item) => !selectedMembers?.some((assignee) => assignee.id === item.id)
      );
      setAddList(filteredList);
    } else if (searchTerm === "") {
      let newArr = membersList.filter(
        (item) => !selectedMembers?.some((assignee) => assignee.id === item.id)
      );
      setAddList(newArr);
    }
  }, [searchTerm, membersList, selectedMembers]);

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  useOutsideClickHandler(wrapperRef1, wrapperRef2, moreRef1, moreRef2);

  const showAssignee = () => {
    setAddAssignee(!addAssignee);
  };

  function useOutsideClickHandler(ref1, ref2, moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
          visibilityChange(false);
        }
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2, addAssignee, moreRef1, moreRef2, moreAssignee]);
  }

  return (
    <AssigneeRoundImageSection
      position={selectedMembers.length}
      visibleMembers={numberOfVisibleMembers}
      style={{ marginRight: `auto` }}
    >
      {(selectedMembers?.length !== membersList?.length ||
        membersList?.length === 0) && (
        <AssigneeRoundImage
          index={0}
          onClick={() => {
            showAssignee();
            visibilityChange(true);
          }}
          ref={wrapperRef2}
        >
          <img src={assign} alt="assign" />
        </AssigneeRoundImage>
      )}
      {selectedMembers.length > numberOfVisibleMembers - 1 && (
        <AssigneeRoundImage
          index="more"
          onClick={() => {
            showMoreAssignee();
            visibilityChange(true);
          }}
          ref={moreRef2}
          style={{
            border: `1px solid #fff`,
            paddingLeft: "3px",
          }}
        >
          {`+${selectedMembers.length - (numberOfVisibleMembers - 1)}`}
        </AssigneeRoundImage>
      )}
      {selectedMembers
        .filter((person, fi) => fi < numberOfVisibleMembers - 1)
        .map((filteredPerson, i) => (
          <AssigneeRoundImage
            key={i}
            index={
              selectedMembers.length < numberOfVisibleMembers ? i + 1 : i + 2
            }
          >
            <AssigneeTooltip toolTipText={filteredPerson?.name} />
            {filteredPerson.avatar ? (
              <RoundPersonImage src={filteredPerson.avatar} alt="addPeople" />
            ) : (
              <FirstRoundLetterComp
                text={filteredPerson.name}
                backColor={i}
                size="40px"
                fontSize="11px"
                color={filteredPerson.color || null}
              />
            )}

            <RoundImageCross
              onClick={() =>
                updateSelectedMembers(
                  selectedMembers?.filter(
                    (member) => member.id !== filteredPerson.id
                  )
                )
              }
            >
              <img
                src={removeAssignee}
                style={{ width: "100%", height: "100%" }}
              />
            </RoundImageCross>
          </AssigneeRoundImage>
        ))}
      {moreAssignee && (
        <ShowMoreAssigneeList
          ref={moreRef1}
          // autoPlacement
          left={`${27 * numberOfVisibleMembers}px`}
        >
          {selectedMembers.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage index={0} size="30px">
                {member.avatar ? (
                  <RoundPersonImage
                    src={member.avatar}
                    alt=""
                    size="30px"
                    borderColor="#c2cce1"
                  />
                ) : (
                  <FirstRoundLetterComp
                    text={member.name}
                    backColor={index}
                    size="30px"
                    fontSize="11px"
                    color={member.color || null}
                  />
                )}
                <RoundImageCross
                  onClick={() =>
                    updateSelectedMembers(
                      selectedMembers?.filter(
                        (selectedMember) => selectedMember.id !== member.id
                      )
                    )
                  }
                >
                  <img
                    src={removeAssignee}
                    style={{ width: "100%", height: "100%" }}
                  />
                </RoundImageCross>
              </AssigneeRoundImage>
              <AssignListText>{`${
                member.name ? ` ${member.name}` : ``
              }`}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
      {addAssignee && (
        <AssignListContainer
          ref={wrapperRef1}
          // autoPlacement
          left={`${
            27 *
            (selectedMembers.length > numberOfVisibleMembers - 1
              ? numberOfVisibleMembers
              : selectedMembers.length - 1)
          }px`}
        >
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                  placeholder="Search..."
                  autoFocus
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>
            {membersList?.length > 0 && (
              <AssignListItem
                columns="100%"
                style={{
                  padding: "15px",
                  paddingLeft: "50px",
                }}
                onClick={selectedAllMembers}
              >
                Select All Members
              </AssignListItem>
            )}
            <DropdownLoader loading={isLoading} />
            {addList &&
              addList.length > 0 &&
              addList.map((person, i) => {
                return assigneeIsLoading &&
                  loadingAssigneeId &&
                  loadingAssigneeId === person.id ? (
                  <AssignListItem columns="100%" key={i}>
                    <DropdownLoader loading />
                  </AssignListItem>
                ) : (
                  <AssignListItem
                    key={i}
                    onClick={() => {
                      updateSelectedMembers([...selectedMembers, person]);
                    }}
                    columns="30px auto"
                    padding="7px 8px"
                  >
                    <AssigneeRoundImage index={0} size="30px">
                      {person.avatar ? (
                        <RoundPersonImage
                          src={person.avatar}
                          alt="addPeople"
                          size="30px"
                          borderColor="#c2cce1"
                        />
                      ) : (
                        <FirstRoundLetterComp
                          text={person.name}
                          backColor={i}
                          size="30px"
                          fontSize="11px"
                          color={person.color || null}
                        />
                      )}
                    </AssigneeRoundImage>
                    <AssignListText>{person.name}</AssignListText>
                  </AssignListItem>
                );
              })}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </AssigneeRoundImageSection>
  );
};

export default AddMultiMemberDropdown;
