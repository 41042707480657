import { useState, useEffect } from "react";
import { PictureDiv, PictureParagraph, AvatarDiv } from "./profileStyles";
import ImageUpload from "./ImageUpload";

const ProfilePicture = (props) => {
  const [files, setfile] = useState([]);
  const addFile = (file) => {
    setfile(
      file.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };
  return (
    <PictureDiv>
      <AvatarDiv>
        <ImageUpload
          addFile={addFile}
          files={props.thumbnail}
          handleSave={props.handleSave}
        />
      </AvatarDiv>

      {/* <div>
          <PictureParagraph>
            Choose a photo that helps personalise you
          </PictureParagraph>
        </div> */}
    </PictureDiv>
  );
};
export default ProfilePicture;
