import styled from "styled-components";

export const CustomReportListTableHeadContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 160px 1.5fr 100px;
  /* grid-template-columns: 2fr 100px 200px 1.5fr 100px; */
  justify-content: space-between;
  padding: 0 25px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media screen and (min-width: 1400px) {
    grid-template-columns: 18% 20% 20% 10% 10%;
    justify-content: flex-start;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 220px 180px 250px 150px 150px;
    grid-column-gap: 10px;
  }
`;
export const CustomReportListTableContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 160px 1.5fr 100px;
  /* grid-template-columns: 2fr 100px 200px 1.5fr 100px; */
  padding: 0 25px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  grid-column-gap: 10px;
`;
export const CreateCustomReportDropdowns = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr 270px;
  grid-column-gap: 15px;

  max-width: max-content;
`;

export const CustomReportFeatureButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding || "0 25px"};
  cursor: ${({ cursor }) => cursor || `pointer`};
  border-radius: ${({ borderRadius, theme }) =>
    borderRadius || theme.button.borderRadius};
  margin: ${({ margin }) => margin};
  min-height: ${({ height, theme }) => height || theme.button.height};

  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.colors.main.white};
  color: ${({ selected }) => (selected ? "#3D4D69" : "#3d4d69a1")};
  font-size: ${({ fontSize, theme }) => fontSize || theme.button.fontSize};
  font-weight: ${({ fontWeight, theme }) =>
    fontWeight || theme.fontWeights.regular};
  border: 1px solid ${({ selected }) => (selected ? "#20BEAD" : "#C2CCE1")};

  &:hover {
    border-color: ${({ hoverBorderColor, type, theme }) =>
      hoverBorderColor
        ? hoverBorderColor
        : type && type === "cancel"
        ? theme.button.cancel.hoverBorderColor
        : type && type === "delete"
        ? theme.button.deleteOutline.hoverBorderColor
        : theme.button.cancel.hoverBorderColor};
  }
`;

export const FeatureCheckIconContainer = styled.div`
  position: absolute;
  right: 10px;
  top: -10px;
`;

export const CustomReportCheckboxContainer = styled.div`
  width: 23px;
  height: 23px;
  gap: 0px;
  border: 1px solid #e4e7eb;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
