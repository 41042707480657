import { useState, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { useLoadScript } from "@react-google-maps/api";

import { PageTitle, Container } from "../../../styledComponents/common";
import { DataAndMapContainer } from "../JobSites/jobSitesStyles";
import { MapAndAlertContainer, MapTopAlert } from "./routeMapStyles";

import RouteMapFilter from "./RouteMapFilter/filterContainer";
import TimesheetDataSection from "./TimesheetDataSection";
import RouteMapGoogleMap from "./RouteMapGoogleMap/RouteMapGoogleMap";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import { getDistanceFromCoords } from "../../../utils/helper";

const mapIds = [process.env.REACT_APP_JOB_SITES_MAP_ID];

const RouteMap = (props) => {
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedTimesheet, setSelectedTimesheet] = useState(null);

  const [map, setMap] = useState(null);
  const [timesheetsData, setTimesheetsData] = useState(null);
  const [linePoints, setLinePoints] = useState([]);
  const [markers, setMarkers] = useState([]);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    id: "job-site-details-map-script-loader",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    // libraries: mapLibraries,
    mapIds,
  });

  const {
    selectedOrganization,
    getRouteMapLocations,
    routeMapLocations,
    locationsIsLoading,
  } = props;

  useEffect(() => {
    fetch("https://hutils.loxal.net/whois")
      .then((resp) => resp.json())
      .then((res) => {
        if (res && res.ip) {
          setUserCurrentLocation({
            lat: res.latitude,
            lng: res.longitude,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (map && userCurrentLocation) {
      if (!linePoints || (linePoints && !linePoints.length)) {
        map.panTo(userCurrentLocation);
      }
    }
  }, [map, userCurrentLocation]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: moment().format("YYYY-MM-DD"),
      };

      getRouteMapLocations(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (routeMapLocations && routeMapLocations.length > 0) {
      let totalDuration = 0;
      const userData = routeMapLocations[0].user;
      const timesheets = [];
      routeMapLocations.forEach((timesheet) => {
        const data = {
          id: timesheet.id,
          startTimestamp: timesheet.start_timestamp,
          endTimestamp: timesheet.end_timestamp,
          project: timesheet.project,
          task: timesheet.task,
          locations: timesheet.locations.sort(
            (a, b) => a.timestamp - b.timestamp
          ),
        };

        const duration = timesheet.end_timestamp - timesheet.start_timestamp;
        data.duration = duration;
        totalDuration += duration;

        timesheets.push(data);
      });

      setTimesheetsData({
        userData,
        timesheets,
        totalDuration,
      });
    } else if (routeMapLocations && routeMapLocations.length === 0) {
      setTimesheetsData(null);
      setLinePoints([]);
      setMarkers([]);
    }
  }, [routeMapLocations]);

  useMemo(() => {
    if (selectedTimesheet && selectedTimesheet.id) {
      if (selectedTimesheet.locations && selectedTimesheet.locations.length) {
        const points = [];
        const markers = [];
        selectedTimesheet.locations.forEach((location, index) => {
          const data = {
            id: location.id,
            location: {
              lat: location.latitude,
              lng: location.longitude,
            },
            timestamp: location.timestamp,
          };
          if (index === 0) {
            data.start = true;
            markers.push(data);
          } else if (index === selectedTimesheet.locations.length - 1) {
            data.end = true;
            markers.push(data);
          } else {
            const lastMarkerLoc = markers[markers.length - 1];
            const dist = getDistanceFromCoords(
              lastMarkerLoc.location,
              data.location
            );
            if (dist > 150) {
              markers.push(data);
            }
          }

          if (selectedTimesheet.locations.length > 1) {
            if (
              !points.length ||
              index === 0 ||
              index === selectedTimesheet.locations.length - 1
            ) {
              points.push(data.location);
            } else {
              const lastPointLoc = points[points.length - 1];
              const dist = getDistanceFromCoords(lastPointLoc, data.location);
              if (dist > 20) {
                points.push(data.location);
              }
            }
          }
        });
        setLinePoints(points);
        setMarkers(markers);
      } else if (
        selectedTimesheet.locations &&
        selectedTimesheet.locations.length === 0
      ) {
        setLinePoints([]);
        setMarkers([]);
      }
    }
  }, [selectedTimesheet]);

  return (
    <div className="content">
      <PageTitle>Route Map</PageTitle>
      <RouteMapFilter
        selectedMember={selectedMember}
        setSelectedMember={setSelectedMember}
      />
      <Container>
        <DataAndMapContainer columns="28% 72%">
          <TimesheetDataSection
            timesheetsData={timesheetsData}
            isLoading={locationsIsLoading}
            selectedMember={selectedMember}
            selectedTimesheet={selectedTimesheet}
            setSelectedTimesheet={setSelectedTimesheet}
          />
          <MapAndAlertContainer>
            {!locationsIsLoading &&
            selectedTimesheet &&
            (!selectedTimesheet.locations ||
              (selectedTimesheet.locations &&
                !selectedTimesheet.locations.length)) ? (
              <MapTopAlert>
                There is no location data for this timesheet
              </MapTopAlert>
            ) : null}
            {loadError ? (
              <div>Map cannot be loaded right now! Please try again later</div>
            ) : isLoaded ? (
              <RouteMapGoogleMap
                map={map}
                setMap={setMap}
                linePoints={linePoints}
                markers={markers}
                selectedTimesheet={selectedTimesheet}
              />
            ) : (
              <ComponentCircleLoader />
            )}
          </MapAndAlertContainer>
        </DataAndMapContainer>
      </Container>
    </div>
  );
};

export default RouteMap;
