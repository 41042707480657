import {
  AuthPageLeftContainer,
  LogoImg,
  SloganText,
  AuthPageArtwork,
} from "../../styledComponents/authLayout";

import Logo from "../../assets/img/logo/logo_V3.svg";
import AuthArtwork from "../../assets/img/common/auth_artwork.svg";

const AuthPageLeftSection = () => {
  return (
    <AuthPageLeftContainer>
      <LogoImg src={Logo} alt="" />
      <SloganText>
        #1 Time Tracker App for desk, mobile & remote teams.
      </SloganText>
      <AuthPageArtwork src={AuthArtwork} alt="" />
    </AuthPageLeftContainer>
  );
};

export default AuthPageLeftSection;
