import { connect } from "react-redux";

import RouteMapFilter from "./RouteMapFilter";

import {
  getOrganizationMembersList,
  getProjectAssigneeList,
  getTaskAssigneeList,
} from "../../../Projects/projectsActions";
import { getRouteMapLocations } from "../routeMapActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  projectAssigneeList: state.project.projectAssigneeList,
  taskAssigneeList: state.project.taskAssigneeList,
});

const mapDispatchToProps = (dispatch) => ({
  getRouteMapLocations: (payload) => dispatch(getRouteMapLocations(payload)),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
  getTaskAssigneeList: (details) =>
    dispatch(getTaskAssigneeList({ ...details })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteMapFilter);
