import { useState, useEffect, useRef } from "react";

import {
  RightSlidingPanelWrapper,
  RightSlidingPanelContainer,
  SlidingPanelOverlay,
  RightSlidingPanelContent,
} from "./rightSlidingPanelStyles";

const RightSlidingPanel = (props) => {
  const { isOpen, closePanel, children, width } = props;
  return (
    <RightSlidingPanelWrapper
      role="complementary"
      aria-label="right-panel"
      aria-hidden={!isOpen}
    >
      <SlidingPanelOverlay
        onClick={() => {
          closePanel();
        }}
        isOpen={isOpen}
      />
      <RightSlidingPanelContainer isOpen={isOpen} width={width}>
        <RightSlidingPanelContent>{children}</RightSlidingPanelContent>
      </RightSlidingPanelContainer>
    </RightSlidingPanelWrapper>
  );
};

export default RightSlidingPanel;
