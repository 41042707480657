import { connect } from "react-redux";

import ProjectDetails from "./ProjectDetails";

// Actions
import {
  getProjectDetails,
  getProjectExpenses,
  getProjectYearlyExpenses,
  getProjectInvoices,
  clearProjectAccessDenied,
  getProjectAssigneeList,
  deleteProject,
  updateProject,
  projectAddTask,
  projectEditTask,
  projectDeleteTask,
  addTaskAssignee,
  deleteTaskAssignee,
} from "../projectsActions";
import { getTaskList } from "../../Tasks/tasksActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  // loading
  isLoading: state.project.isLoading,
  projectAssigneeIsLoading: state.project.projectAssigneeIsLoading,
  projectExpensesIsLoading: state.project.projectExpensesIsLoading,
  updateProjectIsLoading: state.project.updateProjectIsLoading,
  deleteProjectIsLoading: state.project.deleteProjectIsLoading,
  projectInvoiceListLoading: state.project.projectInvoiceListLoading,
  taskListLoading: state.task.isLoading,
  taskIsLoading: state.project.taskIsLoading,
  loadingTaskId: state.project.loadingTaskId,
  taskAssigneeIsLoading: state.project.taskAssigneeIsLoading,
  loadingTaskAssigneeId: state.project.loadingTaskAssigneeId,

  organization: state.organization,
  selectedOrganization: state.organization.selectedOrganization,
  projectAssigneeList: state.project.projectAssigneeList,
  projectDetails: state.project.projectDetails,
  projectExpenses: state.project.projectExpenses,
  yearlyExpenses: state.project.yearlyExpenses,
  invoicesList: state.project.projectInvoicesList,
  accessDenied: state.project.accessDenied,

  // tasks
  // projectTaskList: state.project.projectTaskList,
  projectTaskList: state.task.tasks,

  // pagination
  projectTaskCount: state.task.projectTaskCount,
  projectTaskPageSize: state.task.projectTaskPageSize,
  projectInvoiceCount: state.project.projectInvoiceCount,
  projectInvoicePageSize: state.project.projectInvoicePageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getProjectDetails: (details) => dispatch(getProjectDetails({ ...details })),
  getProjectExpenses: (details) => dispatch(getProjectExpenses({ ...details })),
  getProjectInvoices: (details) => dispatch(getProjectInvoices({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
  getProjectYearlyExpenses: (details) =>
    dispatch(getProjectYearlyExpenses({ ...details })),
  updateProject: (details) => dispatch(updateProject({ ...details })),
  deleteProject: (details) => dispatch(deleteProject({ ...details })),
  clearProjectAccessDenied: () => dispatch(clearProjectAccessDenied()),

  // tasks
  getProjectTaskList: (details) => dispatch(getTaskList({ ...details })),
  projectAddTask: (details) => dispatch(projectAddTask({ ...details })),
  projectEditTask: (details) => dispatch(projectEditTask({ ...details })),
  projectDeleteTask: (details) => dispatch(projectDeleteTask({ ...details })),
  addTaskAssignee: (details) => dispatch(addTaskAssignee({ ...details })),
  deleteTaskAssignee: (details) => dispatch(deleteTaskAssignee({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
