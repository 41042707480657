import { useState, useRef, useEffect, Fragment } from "react";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
  AssigneeRoundImageSection,
  AssigneeRoundImage,
  RoundPersonImage,
  RoundImageCross,
} from "../../styledComponents/createProject";
import {
  AssignListContainer,
  AssignListItem,
  AssignListText,
  SearchBarWithAssignListContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchBarContainer,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  ShowMoreAssigneeList,
} from "../../styledComponents/common";

//dropdown
import AssigneeTooltip from "../../components/Tooltip/AssigneeTooltip";
import DropdownLoader from "../../components/DropdownLoader/DropdownLoader";
import Assign from "../../assets/img/icons/assign2.svg";
import Avatar5 from "../../assets/img/avatar_5.svg";
import removeAssignee from "../../assets/img/icons/remove_assignee.svg";
import searchIcon from "../../assets/img/icons/search.svg";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

export const CommDropdownComp = ({
  numberOfVisibleMembers,
  isLoading,
  selectedOrganization,
  assigneeManage,
  index,
  updateState,
  visibilityChange,
  getMembersList,
  membersList,
  assigneeRemove,
  projectId,
  taskId,
  assigneeIsLoading,
  loadingAssigneeId,
}) => {
  const [addAssignee, setAddAssignee] = useState(false);
  const [moreAssignee, setMoreAssignee] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);
  const [addList, setAddList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = [];
      if (membersList && membersList.length > 0) {
        membersList.map((member) => {
          newArr.push({
            id: member.user.id,
            name: `${member.user.first_name}${
              member.user.last_name ? ` ${member.user.last_name}` : ``
            }`,
            avatar: member.user.avatar,
            color: member.user.color || null,
          });
        });
      }
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          newArr = newArr.filter((item) => item.id !== assignee.id);
        });
      }
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
    if (assigneeManage && assigneeManage.length < numberOfVisibleMembers) {
      setMoreAssignee(false);
    }
  }, [membersList, assigneeManage]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== "" &&
      membersList &&
      membersList.length > 0
    ) {
      let originalList = membersList;
      let filteredList = [];
      originalList.forEach((e) => {
        const name = `${e.user.first_name}${
          e.user.last_name ? ` ${e.user.last_name}` : ``
        }`;
        if (
          searchTerm &&
          name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push({
          id: e.user.id,
          name: `${e.user.first_name}${
            e.user.last_name ? ` ${e.user.last_name}` : ``
          }`,
          avatar: e.user.avatar,
          color: e.user.color || null,
        });
      });
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          filteredList = filteredList.filter((item) => item.id !== assignee.id);
        });
      }
      setAddList(filteredList);
    } else if (searchTerm === "") {
      let newArr = [];
      if (membersList && membersList.length > 0) {
        membersList.map((member) => {
          newArr.push({
            id: member.user.id,
            name: `${member.user.first_name}${
              member.user.last_name ? ` ${member.user.last_name}` : ``
            }`,
            avatar: member.user.avatar,
            color: member.user.color || null,
          });
        });
      }
      if (assigneeManage && assigneeManage.length > 0) {
        assigneeManage.map((assignee) => {
          newArr = newArr.filter((item) => item.id !== assignee.id);
        });
      }
      setAddList(newArr);
    }
  }, [searchTerm]);

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2, moreRef1, moreRef2);

  const showAssignee = () => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
      };
      getMembersList(payload);
    }
    setAddAssignee(true);
  };

  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };

  function useOutsideClickHandler(ref1, ref2, moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
          visibilityChange(false);
        }
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2, addAssignee, moreRef1, moreRef2, moreAssignee]);
  }
  return (
    <AssigneeRoundImageSection
      position={assigneeManage.length}
      style={{ marginRight: `auto` }}
    >
      <AssigneeRoundImage
        index={0}
        onClick={() => {
          showAssignee();
          visibilityChange(true);
        }}
        ref={wrapperRef2}
      >
        <img src={Assign} alt="assign" />
      </AssigneeRoundImage>
      {assigneeManage.length > numberOfVisibleMembers - 1 && (
        <AssigneeRoundImage
          index="more"
          onClick={() => {
            showMoreAssignee();
            visibilityChange(true);
          }}
          ref={moreRef2}
          style={{
            border: `1px solid #fff`,
            paddingLeft: "3px",
          }}
        >
          {`+${assigneeManage.length - (numberOfVisibleMembers - 1)}`}
        </AssigneeRoundImage>
      )}
      {assigneeManage
        .filter((person, fi) => fi < numberOfVisibleMembers - 1)
        .map((filteredPerson, i) => (
          <AssigneeRoundImage
            key={i}
            index={
              assigneeManage.length < numberOfVisibleMembers ? i + 1 : i + 2
            }
          >
            <AssigneeTooltip
              toolTipText={`${filteredPerson.first_name}${
                filteredPerson.last_name ? ` ${filteredPerson.last_name}` : ``
              }`}
            />
            {filteredPerson.avatar ? (
              <RoundPersonImage src={filteredPerson.avatar} alt="addPeople" />
            ) : (
              <FirstRoundLetterComp
                text={`${filteredPerson.first_name}${
                  filteredPerson.last_name ? ` ${filteredPerson.last_name}` : ``
                }`}
                backColor={i}
                color = {filteredPerson.color || null}
              />
            )}

            <RoundImageCross
              onClick={() =>
                assigneeRemove(filteredPerson.id, projectId, taskId)
              }
            >
              <img
                src={removeAssignee}
                style={{ width: "100%", height: "100%" }}
              />
            </RoundImageCross>
          </AssigneeRoundImage>
        ))}
      {moreAssignee && (
        <ShowMoreAssigneeList ref={moreRef1}>
          {assigneeManage.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage index={0} size="30px">
                {member.avatar ? (
                  <RoundPersonImage
                    src={member.avatar}
                    alt=""
                    size="30px"
                    borderColor="#c2cce1"
                  />
                ) : (
                  <FirstRoundLetterComp
                    size="30px"
                    text={`${member.first_name}${
                      member.last_name ? ` ${member.last_name}` : ``
                    }`}
                    backColor={index}
                    color = {member.color || null}
                  />
                )}

                <RoundImageCross
                  onClick={() => assigneeRemove(member.id, projectId, taskId)}
                >
                  <img
                    src={removeAssignee}
                    style={{ width: "100%", height: "100%" }}
                  />
                </RoundImageCross>
              </AssigneeRoundImage>
              <AssignListText>{`${member.first_name}${
                member.last_name ? ` ${member.last_name}` : ``
              }`}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
      {addAssignee && (
        <AssignListContainer ref={wrapperRef1}>
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                  placeholder="Search..."
                  autoFocus
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>

            <DropdownLoader loading={isLoading} />
            {addList &&
              addList.length > 0 &&
              addList.map((person, i) => {
                return assigneeIsLoading &&
                  loadingAssigneeId &&
                  loadingAssigneeId === person.id ? (
                  <AssignListItem columns="100%" key={i}>
                    <DropdownLoader loading />
                  </AssignListItem>
                ) : (
                  <AssignListItem
                    key={i}
                    onClick={() => {
                      updateState(person, projectId, taskId);
                    }}
                    columns="30px auto"
                    padding="7px 8px"
                  >
                    <AssigneeRoundImage index={0} size="30px">
                      {person.avatar ? (
                        <RoundPersonImage
                          src={person.avatar}
                          alt="addPeople"
                          size="30px"
                          borderColor="#c2cce1"
                        />
                      ) : (
                        <FirstRoundLetterComp
                          text={person.name}
                          fontSize="12px"
                          size="30px"
                          backColor={i}
                          color = {person.color || color}
                        />
                      )}
                    </AssigneeRoundImage>
                    <AssignListText>{person.name}</AssignListText>
                  </AssignListItem>
                );
              })}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </AssigneeRoundImageSection>
  );
};
