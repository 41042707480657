import { useState, useEffect, Fragment } from "react";
import { Doughnut, Pie } from "react-chartjs-2";

import TopProjectsLoader from "../ContentLoader/TopProjectsLoader";

import { toHHMMSS } from "../../utils/helper";

import {
  TopProjectsCardContainer,
  CardTitle,
  TopProjectsLoaderContainer,
  GraphAndDataContainer,
  DougnutGraphDiv,
  ProjectsDataContainer,
  ProjectInfoDiv,
  ProjectNameDiv,
  ProjectTimeDiv,
  ProjectData,
  ProjectDataEllipse,
  NoDataContainer,
  NoDataImg,
  NoDataText,
} from "./cardStyles";
import {
  PieChartContainer,
  ColorCircle,
} from "../../styledComponents/dashboard";

import { topProjectsCardChartOptions } from "../../utils/chartOptions";

import noDataImg from "../../assets/img/common/nodata_top_sites.svg";

const colors = ["#73A8FC", "#3ECC92", "#B6B4FA", "#F59198", "#F9DD8F"];

const initialChartData = {
  labels: [null],
  datasets: [
    {
      data: [0],
    },
  ],
};

const TopProjectsCard = (props) => {
  const [labelData, setLabelData] = useState([]);
  const [chartData, setChartData] = useState(initialChartData);
  useEffect(() => {
    let tempLabelData = [];
    let tempHours = [];
    let tempBgColors = [];
    let tempChartLabels = [];
    if (props.projectsData && props.projectsData.length > 0) {
      let data = [...props.projectsData];
      // if (data.length > 3) {
      //   data = data.slice(0, 3);
      // }
      data.map((item, index) => {
        tempLabelData.push({
          color: colors[index],
          projectName: item.project_name,
          timeLog: toHHMMSS(item.duration),
        });
        tempHours.push(item.duration);
        tempChartLabels.push(item.project_name);
        tempBgColors.push(colors[index]);
      });
      setLabelData(tempLabelData);
      setChartData({
        labels: tempChartLabels,
        datasets: [
          {
            hoverBorderColor: "#ffffff",
            borderWidth: 0,
            data: tempHours,
            backgroundColor: tempBgColors,
          },
        ],
      });
    } else if (
      (props.projectsData && props.projectsData.length === 0) ||
      !props.projectsData
    ) {
      setLabelData([]);
    }
  }, [props.projectsData]);
  return (
    <TopProjectsCardContainer>
      <CardTitle>Top Assigned Projects</CardTitle>
      <PieChartContainer>
        {props.isLoading && (
          <TopProjectsLoaderContainer>
            <TopProjectsLoader />
          </TopProjectsLoaderContainer>
        )}
        {!props.isLoading && labelData && labelData.length === 0 && (
          <NoDataContainer marginTop="0px">
            <NoDataImg
              height="90px"
              width="120px"
              src={noDataImg}
              alt="no-data"
            />
            <NoDataText>There is no data to be displayed!</NoDataText>
          </NoDataContainer>
        )}
        {!props.isLoading &&
          props.projectsData &&
          props.projectsData.length > 0 && (
            <GraphAndDataContainer>
              <DougnutGraphDiv>
                <Doughnut
                  options={topProjectsCardChartOptions()}
                  data={chartData}
                />
              </DougnutGraphDiv>
              <ProjectsDataContainer>
                {labelData &&
                  labelData.length > 0 &&
                  labelData.map((item, index) => {
                    return (
                      <ProjectInfoDiv key={index}>
                        <ProjectNameDiv>
                          <ColorCircle background={item.color} />
                          <ProjectDataEllipse title={item.projectName}>
                            {item.projectName}
                          </ProjectDataEllipse>
                        </ProjectNameDiv>
                        <ProjectTimeDiv style={{paddingLeft:"10px"}}>
                          <ProjectData>{item.timeLog}</ProjectData>
                        </ProjectTimeDiv>
                      </ProjectInfoDiv>
                    );
                  })}
              </ProjectsDataContainer>
            </GraphAndDataContainer>
          )}
      </PieChartContainer>
    </TopProjectsCardContainer>
  );
};

export default TopProjectsCard;
