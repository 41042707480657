import { useState, useEffect } from "react";
import InfoTooltip from "../../components/Tooltip/InfoTooltip";
import File from "../../assets/img/icons/file.svg";
import Pencil from "../../assets/img/icons/pencil.svg";
import Chat from "../../assets/img/icons/chat.svg";
import Unarchive from "../../assets/img/icons/unarchive.svg";

import { ActivityTextSection } from "../../styledComponents/members";
import {
  Container,
  CardTitle,
  CommonText,
  Plus,
  FirstWordRound,
} from "../../styledComponents/common";
import {
  ClientCardsContainer,
  ClientCard,
  ClientCardInnerContainer,
  InvoicedCapsule,
  HoveredSection,
  RoundHover,
  ToolTipContainer,
  InnerDivTooltip,
  TooltipText,
} from "../../styledComponents/clients";
import DeleteClientModal from "./DeleteClientModal";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import DotLoader from "../../components/DropdownLoader/DropdownLoader";
import FirstRoundLetterComp from "components/FirstRoundLetter/FirstRoundLetter";


const ClientsCards = (props) => {
  const [clientDeleteId, setClientDeleteId] = useState("");
  const [clientList, setClientList] = useState([]);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [toolTip, setToolTip] = useState(-1);

  useEffect(() => {
    if (props.clientList && props.clientList.length > 0) {
      let list = [];
      if (props.clientType === "active") {
        list = props.clientList.filter((client) => client.is_active);
      } else if (props.clientType === "archived") {
        list = props.clientList.filter((client) => !client.is_active);
      }
      setClientList(list);
    } else if (props.clientList && props.clientList.length === 0) {
      setClientList([]);
    }
  }, [props.clientList, props.clientType]);

  useEffect(() => {
    if (!props.isModalOpen) {
      setClientDeleteId("");
    }
  }, [props.isModalOpen]);

  const onHoverChange = (index) => {
    setSelectIndex(index);
  };

  const toolTipChange = (index) => {
    setToolTip(index);
  };

  const toggle = (id) => {
    setClientDeleteId(id);
    props.modalToggle();
  };

  return (
    <Container style={{ padding: `30px` }}>
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          <DeleteClientModal
            id={clientDeleteId ? clientDeleteId : ""}
            isOpen={props.isModalOpen}
            toggle={props.modalToggle}
            handleDelete={props.handleDelete}
          />
          {clientList && clientList.length > 0 && (
            <>
              <CardTitle>
                {props.clientType && props.clientType === "active"
                  ? "All Clients"
                  : "Archived Clients"}
              </CardTitle>
              <ClientCardsContainer>
                {clientList.map((client, index) => (
                  <ClientCard
                    key={index}
                    onMouseEnter={() => onHoverChange(index)}
                    onMouseLeave={() => onHoverChange(-1)}
                  >
                    {(props.clientUpdateLoading &&
                      props.updatingClientId === client.id) ||
                    (props.clientDeleteLoading &&
                      props.deletingClientId === client.id) ? (
                      <ClientCardInnerContainer
                        columns="100%"
                        padding="25px 15px"
                        style={{ justifyContent: "center" }}
                      >
                        <DotLoader loading />
                      </ClientCardInnerContainer>
                    ) : (
                      <>
                        <ClientCardInnerContainer
                          onClick={() =>
                            props.history.push(
                              `/user/client-details?id=${client.id}`
                            )
                          }
                        >
                          <FirstRoundLetterComp
                            text={client.name}
                            backColor={index}
                            color = {client.color || null}
                          />
                          <ActivityTextSection
                            style={{ justifyContent: `flex-start` }}
                          >
                            <CommonText style={{ cursor: "pointer" }}>
                              {client.name}{" "}
                            </CommonText>
                            <InvoicedCapsule
                              invoiced={
                                client.status === "Invoiced Before"
                                  ? true
                                  : false
                              }
                            >
                              {client.status}
                            </InvoicedCapsule>
                          </ActivityTextSection>
                        </ClientCardInnerContainer>
                        {selectIndex === index &&
                          (props.clientType === "active" ? (
                            <HoveredSection>
                              <RoundHover
                                onMouseEnter={() => toolTipChange(1)}
                                onMouseLeave={() => toolTipChange(-1)}
                                onClick={() =>
                                  props.history.push(
                                    `/user/edit-client?id=${client.id}`
                                  )
                                }
                              >
                                <img src={Pencil} alt="pencil" width="11px" />
                                {toolTip === 1 && (
                                  <ToolTipContainer>
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Edit</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </RoundHover>
                              <RoundHover
                                onMouseEnter={() => toolTipChange(2)}
                                onMouseLeave={() => toolTipChange(-1)}
                                onClick={() =>
                                  props.handleArchive(client.id, "archive")
                                }
                              >
                                <img src={File} alt="file" width="9px" />
                                {toolTip === 2 && (
                                  <ToolTipContainer>
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Archive</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </RoundHover>
                              {/* <RoundHover onClick={() => toggle(client.id)}>X</RoundHover> */}
                              <RoundHover
                                onMouseEnter={() => toolTipChange(3)}
                                onMouseLeave={() => toolTipChange(-1)}
                                onClick={() => toggle(client.id)}
                              >
                                X
                                {toolTip === 3 && (
                                  <ToolTipContainer>
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Delete</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </RoundHover>
                            </HoveredSection>
                          ) : (
                            <HoveredSection unarchive>
                              <RoundHover
                                onMouseEnter={() => toolTipChange(2)}
                                onMouseLeave={() => toolTipChange(-1)}
                                onClick={() =>
                                  props.handleArchive(client.id, "unarchive")
                                }
                              >
                                <img src={Unarchive} alt="file" width="12px" />
                                {toolTip === 2 && (
                                  <ToolTipContainer>
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Unarchive</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </RoundHover>
                              <RoundHover
                                onMouseEnter={() => toolTipChange(3)}
                                onMouseLeave={() => toolTipChange(-1)}
                                onClick={() => toggle(client.id)}
                              >
                                X
                                {toolTip === 3 && (
                                  <ToolTipContainer>
                                    <InnerDivTooltip>
                                      <img src={Chat} alt="chat" width="64px" />
                                      <TooltipText>Delete</TooltipText>
                                    </InnerDivTooltip>
                                  </ToolTipContainer>
                                )}
                              </RoundHover>
                            </HoveredSection>
                          ))}
                      </>
                    )}
                  </ClientCard>
                ))}
              </ClientCardsContainer>
            </>
          )}
          {clientList && clientList.length === 0 && (
            <NoDataComponent
              title={
                props.searchTerm
                  ? `No client found with this name!`
                  : `Seems like you do not have any ${props.clientType} client`
              }
            />
          )}
        </>
      )}
    </Container>
  );
};

export default ClientsCards;
