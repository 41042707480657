import { useState, useEffect, useRef } from "react";

import {
  CommonText,
  InputLabel,
  InputField,
  FormAlert,
  InputWithLabelSection,
  CommonFlex,
  ColoredText,
  CommonImage,
  CommonGrid,
} from "../../../../styledComponents/common";
import { PrimaryButton } from "../../../../styledComponents/buttons";
import { RedStar } from "../../../../styledComponents/members";
import { ScreenshotFrequencyButton as RadiusButton } from "../../../Settings/ActivitySettings/activitySettingsStyles";
import { ClearSearchButton } from "../../../../components/Search/searchStyles";
import { JobSiteForm, DetailsPageDeleteButton } from "../jobSitesStyles";

import BackButtonComponent from "../../../../components/BackButtonComponent/BackButtonComponent";
import ButtonTextLoader from "../../../../components/Loaders/ButtonTextLoader";

import MapsAutocompleteDropdown from "../../../../components/MapsAutocompleteDropdown/MapsAutocompleteDropdown";

import AddMultipleAssigneeDropdown from "../../../../components/CustomDropdown/AddMultipleAssigneeDropdown";

import { checkUrl, checkHtml } from "../../../../utils/helper";

import crossWhite from "../../../../assets/img/icons/remove_assignee.svg";
import deleteIcon from "../../../../assets/img/icons/delete.svg";

const radiusOptions = [50, 100, 200];

const JobSiteDetails = (props) => {
  const [jobSiteName, setJobSiteName] = useState("");

  const [saveErrors, setSaveErrors] = useState({});

  const [isCustomRadius, setIsCustomRadius] = useState(false);

  const [action, setAction] = useState("add");

  const {
    handleChangePage,
    siteDetails,
    addressMarker,
    setAddressMarker,
    addressInput,
    setAddressInput,
    radiusInput,
    setRadiusInput,
    // autocomplete and geocoding service
    getGeocode,
    getPlacePredictions,
    placePredictions,
    isPredictionsLoading,

    // api and reducer
    selectedOrganization,
    memberListIsLoading,
    getOrganizationMembersList,
    orgMembersList,
    addJobSite,
    updateJobSite,
    addOrUpdateJobSiteLoading,
    assigneeIsLoading,
    loadingAssigneeId,
    addJobSiteAssignee,
    removeJobSiteAssignee,
    clearJobSiteDetails,
    toggleDeleteModal,
  } = props;

  const clearStateAndProps = () => {
    clearJobSiteDetails();
    setJobSiteName("");
    setAddressInput("");
    setRadiusInput(100);
    setIsCustomRadius(false);
    setAction("add");

    setAddressMarker(null);
  };

  useEffect(() => {
    return () => {
      clearStateAndProps();
    };
  }, []);

  useEffect(() => {
    if (siteDetails && siteDetails.id) {
      setAction("update");
      setJobSiteName(siteDetails.name);
      setAddressInput(siteDetails.address);
      setAddressMarker(siteDetails.location);
      setRadiusInput(siteDetails.radius);

      let customRadius = true;
      radiusOptions.some((option) => {
        if (option === siteDetails.radius) {
          customRadius = false;
          return true;
        }
        return false;
      });
      setIsCustomRadius(customRadius);
    }
  }, [siteDetails]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      getOrganizationMembersList({ organization_id: selectedOrganization.id });
    }
  }, [selectedOrganization]);

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;

    if (checkUrl(jobSiteName)) {
      formIsValid = false;
      saveErrors["name"] = "Job site name can not contain url";
    } else if (checkHtml(jobSiteName)) {
      formIsValid = false;
      saveErrors["name"] = "Invalid job site name";
    }
    if (checkUrl(addressInput)) {
      formIsValid = false;
      saveErrors["address"] = "Address can not contain url";
    } else if (checkHtml(addressInput)) {
      formIsValid = false;
      saveErrors["address"] = "Invalid address input";
    }
    if (!radiusInput) {
      formIsValid = false;
      saveErrors["radius"] = "Radius is required";
    } else if (radiusInput % 1 !== 0) {
      formIsValid = false;
      saveErrors["radius"] = "Fraction is not allowed for radius";
    } else if (radiusInput < 50) {
      formIsValid = false;
      saveErrors["radius"] = "Minimum value for radius is 50";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleAddOrUpdateJobsite = () => {
    if (checkError() && selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        name: jobSiteName,
        address: addressInput,
        radius: radiusInput,
      };

      if (addressMarker && addressMarker.lat) {
        payload.lat =
          typeof addressMarker.lat === "function"
            ? addressMarker.lat()
            : addressMarker.lat;
        payload.long =
          typeof addressMarker.lng === "function"
            ? addressMarker.lng()
            : addressMarker.lng;
      }

      if (action === "update" && siteDetails && siteDetails.id) {
        payload.jobsite_id = siteDetails.id;
        updateJobSite(payload);
      } else {
        addJobSite(payload);
      }
    }
  };

  const handleAssignOrRemoveMember = (user, action) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      siteDetails &&
      siteDetails.id &&
      user &&
      user.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        jobsite_id: siteDetails.id,
        userData: user,
        user_id: user.id,
      };

      if (action === "remove") {
        removeJobSiteAssignee(payload);
      } else {
        addJobSiteAssignee(payload);
      }
    }
  };

  const handleAssignMember = (user) => {
    handleAssignOrRemoveMember(user, "assign");
  };

  const handleRemoveMember = (user) => {
    handleAssignOrRemoveMember(user, "remove");
  };

  return (
    <JobSiteForm>
      <CommonFlex justifyContent="space-between">
        <BackButtonComponent
          onClick={() => handleChangePage("list")}
          size="28px"
          font="11.5px"
          gap="0"
          subTitle="Back to all job sites"
        />
        {siteDetails && siteDetails.id ? (
          <DetailsPageDeleteButton
            onClick={() => toggleDeleteModal(siteDetails.id)}
          >
            <CommonImage src={deleteIcon} alt="" size="13px" />
          </DetailsPageDeleteButton>
        ) : null}
      </CommonFlex>

      <InputWithLabelSection margin="25px 0 0">
        <InputLabel>
          Site Name <RedStar>*</RedStar>
        </InputLabel>
        <InputField
          value={jobSiteName}
          placeholder="Type job site name"
          onChange={(e) => {
            const { value } = e.target;
            setJobSiteName(value);
            if (saveErrors && saveErrors["name"]) {
              delete saveErrors["name"];
            }
          }}
          error={
            saveErrors && saveErrors["name"] && saveErrors["name"] !== undefined
          }
        />
        {saveErrors &&
          saveErrors["name"] &&
          saveErrors["name"] !== undefined && (
            <FormAlert margin="0 0 0 5px">{saveErrors["name"]}</FormAlert>
          )}
      </InputWithLabelSection>
      <InputWithLabelSection style={{ position: "relative" }} margin="20px 0 0">
        <InputLabel>
          Site Address <RedStar>*</RedStar>
        </InputLabel>
        <InputField
          value={addressInput}
          placeholder="Type address to search for a job site"
          onChange={(e) => {
            const { value } = e.target;
            getPlacePredictions({ input: value });
            setAddressInput(value);
            if (saveErrors && saveErrors["address"]) {
              delete saveErrors["address"];
            }
          }}
          error={
            saveErrors &&
            saveErrors["address"] &&
            saveErrors["address"] !== undefined
          }
        />
        {saveErrors &&
          saveErrors["address"] &&
          saveErrors["address"] !== undefined && (
            <FormAlert margin="0 0 0 5px">{saveErrors["address"]}</FormAlert>
          )}
        {!isPredictionsLoading &&
        placePredictions &&
        placePredictions.length &&
        addressInput ? (
          <MapsAutocompleteDropdown
            dropdownOptions={placePredictions}
            handleSelect={getGeocode}
          />
        ) : null}
      </InputWithLabelSection>
      <InputWithLabelSection margin="20px  0 0">
        <CommonFlex justifyContent="space-between">
          <InputLabel>
            Radius <RedStar>*</RedStar>
          </InputLabel>
          {!isCustomRadius && (
            <ColoredText
              type="blue"
              fontSize="12px"
              semibold
              style={{ cursor: "pointer" }}
              onClick={() => setIsCustomRadius(true)}
            >
              Custom Radius
            </ColoredText>
          )}
        </CommonFlex>
        <CommonFlex justifyContent="space-between" gap="12px">
          {isCustomRadius ? (
            <CommonGrid columns="100px auto 20px" gap="5px" alignItem="center">
              <InputField
                value={radiusInput}
                type="number"
                placeholder="Type radius"
                min={50}
                onChange={(e) => {
                  setRadiusInput(parseInt(e.target.value));
                  if (saveErrors && saveErrors["radius"]) {
                    delete saveErrors["radius"];
                  }
                }}
                error={
                  saveErrors &&
                  saveErrors["radius"] &&
                  saveErrors["radius"] !== undefined
                }
              />

              <CommonText>Metres</CommonText>
              <ClearSearchButton
                onClick={() => {
                  setIsCustomRadius(false);
                  if (parseInt(radiusInput) === 50) {
                    setRadiusInput(50);
                  } else if (parseInt(radiusInput) === 200) {
                    setRadiusInput(200);
                  } else {
                    setRadiusInput(100);
                  }
                  if (saveErrors && saveErrors["radius"]) {
                    delete saveErrors["radius"];
                  }
                }}
                style={{ marginLeft: "10px" }}
              >
                <CommonImage src={crossWhite} alt="clear" />
              </ClearSearchButton>
            </CommonGrid>
          ) : (
            radiusOptions.map((option) => {
              return (
                <RadiusButton
                  key={option}
                  fontSize="12px"
                  width="-webkit-fill-available"
                  isActive={radiusInput === option}
                  onClick={() => {
                    if (radiusInput !== option) {
                      setRadiusInput(option);
                      if (saveErrors && saveErrors["radius"]) {
                        delete saveErrors["radius"];
                      }
                    }
                  }}
                >
                  {option} Metres
                </RadiusButton>
              );
            })
          )}
        </CommonFlex>
        {saveErrors &&
          saveErrors["radius"] &&
          saveErrors["radius"] !== undefined && (
            <FormAlert margin="0 0 0 5px">{saveErrors["radius"]}</FormAlert>
          )}
      </InputWithLabelSection>
      <PrimaryButton
        margin="20px 0 0"
        disabled={!jobSiteName || !addressInput}
        onClick={() => handleAddOrUpdateJobsite()}
      >
        {addOrUpdateJobSiteLoading ? (
          <ButtonTextLoader
            loadingText={`${
              action === "update" ? "Updating" : "Adding"
            } Job Site`}
            fontSize="13px"
          />
        ) : (
          `${action === "update" ? "Update" : "Add"} Job Site`
        )}
      </PrimaryButton>
      {siteDetails && siteDetails.id && siteDetails.assignees && (
        <InputWithLabelSection margin="20px 0 0">
          <InputLabel>Assignees</InputLabel>
          <AddMultipleAssigneeDropdown
            numberOfVisibleMembers={8}
            isLoading={memberListIsLoading}
            selectedOrganization={selectedOrganization}
            assigneesList={siteDetails.assignees}
            membersList={orgMembersList}
            assigneeIsLoading={assigneeIsLoading}
            loadingAssigneeId={loadingAssigneeId}
            handleAssign={handleAssignMember}
            handleRemove={handleRemoveMember}
          />
        </InputWithLabelSection>
      )}
    </JobSiteForm>
  );
};

export default JobSiteDetails;
