export default [
  {
    type: "select",
    fieldName: "members",
    options: {
      required: true
    }
  },
  {
    type: "text",
    fieldName: "start_date",
    options: {
      required: true,
      isASCII: true
    }
  },
  {
    type: "text",
    fieldName: "end_date",
    options: {
      required: true,
      isASCII: true
    }
  },
  {
    type: "text",
    fieldName: "start_time",
    options: {
      required: true,
      isASCII: true
    }
  },
  {
    type: "text",
    fieldName: "end_time",
    options: {
      required: true,
      isASCII: true
    }
  }
];
