import { connect } from "react-redux";

// Component
import AppsUrlUsage from "./AppsUrlUsage";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.report.isLoading,
  noData: state.report.noData,
  appReportData: state.report.appReportData,
  urlReportData: state.report.urlReportData,
  appTimeData: state.report.appTimeData,
});

const mapDispatchToProps = (dispatch) => ({});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AppsUrlUsage);
