import { useState } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { RedStar } from "../../../styledComponents/members";
import {
  PageTitle,
  Container,
  CardTitle,
  InputWithLabelSection,
  InputLabel,
  InputField,
  ContentHeader,
  CommonFlex,
  FormAlert,
} from "../../../styledComponents/common";
import { WhiteButton, PrimaryButton } from "../../../styledComponents/buttons";
import { AddClientContainer } from "../../../styledComponents/clients";
import { validateEmail, checkUrl, checkHtml } from "../../../utils/helper";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

const AddClient = (props) => {
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [officeLocation, setOfficeLocation] = useState("");
  const [saveErrors, setSaveErrors] = useState({});

  const onClientNameChange = (e) => {
    const { value } = e.target;
    setClientName(value);
    if (saveErrors && saveErrors["clientName"]) {
      delete saveErrors["clientName"];
    }
  };

  const onEmailChange = (e) => {
    const { value } = e.target;
    setClientEmail(value);
    if (saveErrors && saveErrors["clientEmail"]) {
      delete saveErrors["clientEmail"];
    }
  };

  const onAddressChange = (e) => {
    const { value } = e.target;
    setOfficeLocation(value);
  };

  const cancelFunction = () => {
    props.history.push({
      pathname: "/user/clients",
      state: { from: "create" },
    });
  };

  const handleSave = () => {
    if (checkError()) {
      if (props.selectedOrganization && props.selectedOrganization.id) {
        let payload = {
          organization_id: props.selectedOrganization.id,
          name: clientName,
          phone_number: phoneNumber ? phoneNumber : null,
          email: clientEmail ? clientEmail : null,
          office_location: officeLocation ? officeLocation : null,
        };
        props.createClient(payload);
        setTimeout(() => {
          props.history.push({
            pathname: "/user/clients",
            state: { from: "create" },
          });
        }, 800);
      }
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!clientName.trim()) {
      formIsValid = false;
      saveErrors["clientName"] = "Client name is required";
    } else if (checkUrl(clientName)) {
      formIsValid = false;
      saveErrors["clientName"] = "Client name can not contain url.";
    } else if (checkHtml(clientName)) {
      formIsValid = false;
      saveErrors["clientName"] = "Invalid client name";
    }
    if (!validateEmail(clientEmail)) {
      formIsValid = false;
      saveErrors["clientEmail"] = "Please enter a valid email";
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  return (
    <div className="content">
      <PageTitle>Clients</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => props.history.push("/user/clients")}
          subTitle="Back to all clients"
        />
      </ContentHeader>
      <Container style={{ padding: `30px` }}>
        <CardTitle>Client Details</CardTitle>
        <AddClientContainer>
          <InputWithLabelSection>
            <InputLabel>
              Client Name <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              placeholder="Write client name..."
              value={clientName}
              onChange={(e) => onClientNameChange(e)}
              error={
                saveErrors &&
                saveErrors["clientName"] &&
                saveErrors["clientName"] !== undefined
              }
            />
            {saveErrors &&
              saveErrors["clientName"] &&
              saveErrors["clientName"] !== undefined && (
                <FormAlert margin="0 0 0 5px">
                  {saveErrors["clientName"]}
                </FormAlert>
              )}
          </InputWithLabelSection>
          <InputWithLabelSection>
            <InputLabel>
              Email Address <RedStar>*</RedStar>
            </InputLabel>
            <InputField
              type="text"
              placeholder="Write email..."
              value={clientEmail}
              onChange={(e) => onEmailChange(e)}
              error={
                saveErrors &&
                saveErrors["clientEmail"] &&
                saveErrors["clientEmail"] !== undefined
              }
            />
            {saveErrors &&
              saveErrors["clientEmail"] &&
              saveErrors["clientEmail"] !== undefined && (
                <FormAlert margin="0 0 0 5px">
                  {saveErrors["clientEmail"]}
                </FormAlert>
              )}
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>Phone Number</InputLabel>
            <PhoneInput
              enableSearch
              country={"us"}
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              inputClass="phone-number-input"
              buttonClass="phone-number-flag-dropdown"
            />
          </InputWithLabelSection>

          <InputWithLabelSection>
            <InputLabel>Office Location</InputLabel>
            <InputField
              type="text"
              placeholder="Write official address..."
              value={officeLocation}
              onChange={(e) => onAddressChange(e)}
            />
          </InputWithLabelSection>

          <CommonFlex gap="15px" margin="20px 0 0 0">
            <WhiteButton type="cancel" onClick={() => cancelFunction()}>
              Cancel
            </WhiteButton>
            <PrimaryButton
              padding={props.isLoading ? "0 15px" : "0 35px"}
              onClick={() => handleSave()}
            >
              {props.isLoading ? (
                <ButtonTextLoader loadingText="Saving" fontSize="13px" />
              ) : (
                "Save"
              )}
            </PrimaryButton>
          </CommonFlex>
        </AddClientContainer>
      </Container>
    </div>
  );
};

export default AddClient;
