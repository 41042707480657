import { useState, useEffect } from "react";

import {
  PageTitle,
  ContentHeader,
  Container,
  CardTitle,
  CommonText,
  CommonGrid,
} from "../../../styledComponents/common";
import {
  OrgSettingsSection,
  SettingPropertySection,
  ToggleButton,
  ToggleButtonRound,
} from "../ActivitySettings/activitySettingsStyles";

import MembersTable from "./MembersTable";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

const LocationTrackingSection = (props) => {
  const { locationTrack, handleUpdateLocationSettings } = props;

  return (
    <Container padding="25px" style={{ margin: "20px 0" }}>
      <CommonText name title margin="0 0 10px 0">
        GPS Location Tracking
      </CommonText>
      <SettingPropertySection>
        <CommonGrid columns="45% 55%" gap="2em">
          <CommonText $label fontWeight="500" margin="0">
            Manage location tracking of your organization
          </CommonText>
          <CommonGrid columns="36px 30px" gap="10px" justifyContent="start">
            <ToggleButton
              onClick={() => handleUpdateLocationSettings(!locationTrack)}
              active={locationTrack}
            >
              <ToggleButtonRound active={locationTrack} />
            </ToggleButton>
            <CommonText
              $label={!locationTrack}
              fontWeight="500"
              fontSize="14px"
              margin="0"
            >
              {locationTrack ? "ON" : "OFF"}
            </CommonText>
          </CommonGrid>
        </CommonGrid>
      </SettingPropertySection>
    </Container>
  );
};

const GeofenceSettings = (props) => {
  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    isLoading,
    history,
    selectedOrganization,

    updateLocationSettings,

    getMembersSettingsList,
    updateMembersSettingsList,

    membersListLoading,
    membersSettingsList,

    // pagination
    totalMembersCount,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };

      if (
        !membersSettingsList ||
        (membersSettingsList && membersSettingsList.length === 0)
      ) {
        getMembersSettingsList(payload);
      }
    }
  }, [selectedOrganization]);

  const handleUpdateLocationSettings = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        configuration_id: selectedOrganization.configuration.id,
        location_track: value,
      };
      updateLocationSettings(payload);
    }
  };

  const handleUpdateMemberSettings = (value) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.id &&
      value != null
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        member_id: value.id,
        location_track: !value.settings,
      };
      updateMembersSettingsList(payload);
    }
  };

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page,
      };

      getMembersSettingsList(payload);
    }
    setCurrentPageNumber(page);
  };

  return (
    <div className="content">
      <PageTitle>GPS Location Tracking Settings</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/settings")}
          subTitle="Back to settings"
        />
      </ContentHeader>

      <Container padding="0">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {selectedOrganization &&
            (selectedOrganization.role === "owner" ||
              selectedOrganization.role === "admin") ? (
              <OrgSettingsSection>
                <CardTitle>Configure GPS location tracking settings</CardTitle>
                <LocationTrackingSection
                  locationTrack={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.location_track
                  }
                  handleUpdateLocationSettings={handleUpdateLocationSettings}
                />
              </OrgSettingsSection>
            ) : null}

            {selectedOrganization &&
            selectedOrganization.configuration &&
            selectedOrganization.configuration.location_track ? (
              <MembersTable
                selectedOrganization={selectedOrganization}
                history={history}
                membersListLoading={membersListLoading}
                membersList={membersSettingsList}
                memberSettingsChange={handleUpdateMemberSettings}
                onPageChange={handlePageChange}
                totalMembersCount={totalMembersCount}
                currentPageNumber={currentPageNumber}
              />
            ) : null}
          </>
        )}
      </Container>
    </div>
  );
};

export default GeofenceSettings;
