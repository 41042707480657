import { useState } from "react";
import { Marker, InfoWindow } from "@react-google-maps/api";
import moment from "moment";

import markerStart from "../../assets/img/icons/makerA.png";
import markerEnd from "../../assets/img/icons/markerB.png";
import markerDefault from "../../assets/img/icons/markerIcon.png";

const MapMarker = (props) => {
  const [zoom, setZoom] = useState(11);
  const [isOpen, setIsOpen] = useState(false);

  const { location, takenTime, content, isStart, isEnd } = props;

  const handleToggleOpen = () => {
    setIsOpen(true);
  };

  const handleToggleClose = () => {
    setIsOpen(false);
  };
  let markerIcon = "";

  let start = isStart.find((elem) => elem.id === content.id);
  let end = isEnd.find((elem) => elem.id === content.id);

  if (start) markerIcon = markerStart;
  else if (end) markerIcon = markerEnd;
  else markerIcon = markerDefault;

  return (
    <Marker
      icon={{
        url: markerIcon,
        scale: 10,
      }}
      position={location}
      onClick={handleToggleOpen}
    >
      {isOpen && (
        <InfoWindow onCloseClick={handleToggleClose}>
          <div>
            <div className="calendar-pop-inner">
              <div style={{ fontSize: "18px" }}>{content.orgName}</div>
              <p>{content.projectName}</p>
            </div>
            <div>
              <p>Location Taken Time:</p>
              <p>
                {takenTime &&
                  moment.unix(takenTime).format("dddd, DD MMMM, YYYY hh:mm A ")}
              </p>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MapMarker;
