import { connect } from "react-redux";

import GeofenceClockInOut from "./GeofenceClockInOut";

import { getGeofenceClockInOutList } from "./geofenceClockInOutActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,

  isLoading: state.geofenceClockInOut.isLoading,
  geofenceClockInOutList: state.geofenceClockInOut.geofenceClockInOutList,

  // pagination
  // geofenceClockInOutListCount: state.clockInOut.geofenceClockInOutListCount,
  // geofenceClockInOutPageSize: state.clockInOut.geofenceClockInOutPageSize,
});

const mapDispatchToProps = (dispatch) => ({
  getGeofenceClockInOutList: (payload) =>
    dispatch(getGeofenceClockInOutList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeofenceClockInOut);
