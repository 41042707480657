import { useState, useEffect } from "react";
import moment from "moment";
import { TodayButton } from "../../styledComponents/common";
import Select from "../../components/ReactSelectDropdown";
import ProjectDropdown from "../../components/CommonFilter/ProjectDropdown";
import MembersDropdownStyle from "../../components/DropdownStyle/MembersDropdownStyle";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";
import ReactDateRangePicker from "../../components/ReactDateRangePicker/ReactDateRangePicker";

import {
  InputWithLabelSection,
  FilterLabel,
} from "../../styledComponents/common";
import { SelectSection } from "../../styledComponents/payroll";

import avatar5 from "../../assets/img/avatar_5.svg";

const PayrollFilter = ({
  selectedOrganization,
  getHourlyPaymentHistory,
  getOnetimePaymentHistory,
  getPayablesData,
  startDate,
  endDate,
  tabSelect,
  historyType,
  getOrganizationMembersList,
  organizationMembersList,
  projectAssigneeList,
  getProjectAssigneeList,
  changeDateRange,
  onDateSelect,
  selectLastWeek,
  selectLastMonth,
  changeSortType,
  sortType,
  selectedProject,
  setSelectedProject,
  selectedMember,
  setSelectedMember,
}) => {
  const [memberOptions, setMemberOptions] = useState([]);
  const sortOptions = [
    { value: "oldToNew", label: "Date (Old to New)" },
    { value: "newToOld", label: "Date (New to Old)" },
  ];

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
        start: moment(startDate).format("YYYY-MM-DD"),
        end: moment(endDate).format("YYYY-MM-DD"),
      };
      if (tabSelect === "hourly") {
        getPayablesData(payload);
      } else if (tabSelect === "history") {
        let historyPayload = {
          organization_id: selectedOrganization.id,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        };
        if (historyType && historyType === "hourly_basis") {
          getHourlyPaymentHistory(historyPayload);
        } else if (historyType && historyType === "fixed_amount") {
          getOnetimePaymentHistory(historyPayload);
        }
      }
      let membersListPayload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(membersListPayload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList?.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (projectAssigneeList?.length > 0) {
      let assigneeList = [];
      projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name} ${assignee.user.last_name}`,
          avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
        });
      });
      setMemberOptions(assigneeList);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if ((selectedProject && !selectedProject.value) || !selectedProject) {
      setMemberOptions(organizationMembersList);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (historyType && historyType === "fixed_amount") {
      if (selectedOrganization && selectedOrganization.id) {
        let payload = {
          organization_id: selectedOrganization.id,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        };
        getOnetimePaymentHistory(payload);
      }
    }
  }, [historyType]);

  const onProjectSelect = (e) => {
    const organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      if (tabSelect === "hourly") {
        const payload = {
          organization_id: organizationId,
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(endDate).format("YYYY-MM-DD"),
        };
        if (selectedMember && e && e.value) {
          payload.user_ids = [selectedMember.id];
        }
        if (e && e.value) {
          payload.project_id = e.value;
        }
        getPayablesData(payload);
      } else if (tabSelect === "history") {
        let historyPayload = {
          organization_id: organizationId,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        };
        if (selectedMember && e && e.value) {
          historyPayload.user_ids = [selectedMember.id];
        }
        if (e && e.value) {
          historyPayload.project_id = e.value;
        }
        if (historyType && historyType === "hourly_basis") {
          getHourlyPaymentHistory(historyPayload);
        } else if (historyType && historyType === "fixed_amount") {
          getOnetimePaymentHistory(historyPayload);
        }
      }
      if (e && e.value) {
        const payload = {
          organization_id: selectedOrganization.id,
          project_id: e.value,
        };
        if (selectedMember) {
          payload.user_id = selectedMember.id;
          getProjectAssigneeList(payload);
        } else {
          getProjectAssigneeList(payload);
        }
      }
    }

    if (
      e &&
      e.value &&
      selectedProject &&
      selectedProject.value &&
      e.value !== selectedProject.value
    ) {
      setSelectedProject(e);
    } else if ((e && !e.value) || e == null) {
      setSelectedMember("");
      setSelectedProject(e);
    } else {
      setSelectedProject(e);
    }
  };

  const onMemberSelect = (e) => {
    if (e && e.id) {
      const organizationId = selectedOrganization
        ? selectedOrganization.id
        : "";
      if (organizationId) {
        if (tabSelect === "hourly") {
          const payload = {
            organization_id: organizationId,
            start: moment(startDate).format("YYYY-MM-DD"),
            end: moment(endDate).format("YYYY-MM-DD"),
            user_ids: [e.id],
          };
          if (selectedProject && selectedProject.value) {
            payload.project_id = selectedProject.value;
          }
          getPayablesData(payload);
        } else if (tabSelect === "history") {
          const historyPayload = {
            organization_id: organizationId,
            start_date: moment(startDate).format("YYYY-MM-DD"),
            end_date: moment(endDate).format("YYYY-MM-DD"),
            user_ids: [e.id],
          };
          if (selectedProject && selectedProject.value) {
            historyPayload.project_id = selectedProject.value;
          }
          if (historyType && historyType === "hourly_basis") {
            getHourlyPaymentHistory(historyPayload);
          } else if (historyType && historyType === "fixed_amount") {
            getOnetimePaymentHistory(historyPayload);
          }
        }
        setSelectedMember(e);
      }
    }
  };

  const clearSelectedMember = () => {
    const organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      if (tabSelect === "hourly") {
        const payload = {
          organization_id: organizationId,
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(endDate).format("YYYY-MM-DD"),
        };
        if (selectedProject && selectedProject.value) {
          payload.project_id = selectedProject.value;
        }
        getPayablesData(payload);
      } else if (tabSelect === "history") {
        let historyPayload = {
          organization_id: organizationId,
          start_date: moment(startDate).format("YYYY-MM-DD"),
          end_date: moment(endDate).format("YYYY-MM-DD"),
        };
        if (selectedProject && selectedProject.value) {
          historyPayload.project_id = selectedProject.value;
        }
        if (historyType && historyType === "hourly_basis") {
          getHourlyPaymentHistory(historyPayload);
        } else if (historyType && historyType === "fixed_amount") {
          getOnetimePaymentHistory(historyPayload);
        }
      }
    }
    setSelectedMember("");
  };

  return (
    <SelectSection
      columns={
        historyType && historyType === "hourly_basis"
          ? "40px 280px 90px 230px"
          : "230px 40px 280px 90px 230px"
      }
      gap="20px"
    >
      {(!historyType || historyType !== "hourly_basis") && (
        <InputWithLabelSection>
          <FilterLabel>Projects</FilterLabel>
          <ProjectDropdown
            selectedOrganization={selectedOrganization}
            selectedProject={selectedProject}
            selectProject={onProjectSelect}
            selectedMember={selectedMember}
            clearable
          />
        </InputWithLabelSection>
      )}

      <InputWithLabelSection>
        <FilterLabel>For</FilterLabel>
        <AddSingleMemberDropdown
          assigneeManage={selectedMember}
          updateState={onMemberSelect}
          membersList={memberOptions}
          clearAssignee={() => clearSelectedMember()}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <FilterLabel>Date Range</FilterLabel>
        <ReactDateRangePicker
          startDate={startDate}
          startDateId="custom-date-range-start-date"
          endDate={endDate}
          endDateId="custom-date-range-end-date"
          onDatesChange={changeDateRange}
          dateDisplayFormat="DD/MM/YYYY"
          onDateSelect={onDateSelect}
          selectLastWeek={selectLastWeek}
          selectLastMonth={selectLastMonth}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <div style={{ height: "18px" }} />
        <TodayButton onClick={() => onDateSelect(true)}>Today</TodayButton>
      </InputWithLabelSection>

      <InputWithLabelSection>
        <FilterLabel>Sort By</FilterLabel>
        <Select
          isSearchable
          value={sortType}
          options={sortOptions}
          onChange={(e) => {
            changeSortType(e);
          }}
          styles={MembersDropdownStyle({ minHeight: "40px" })}
        />
      </InputWithLabelSection>
    </SelectSection>
  );
};

export default PayrollFilter;
