import { connect } from "react-redux";

// Component
import CurrentPlan from "./CurrentPlan";

// Actions
import {
  getOrganizationSubscription,
  updateSubscriptionPaymentMethod,
  activateOrganizationSubscription,
  clearPaymentMethodUpdated,
} from "../subscriptionActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.subscription.isLoading,
  activateSubscriptionLoading: state.subscription.activateSubscriptionLoading,
  updatePaymentMethodLoading: state.subscription.updatePaymentMethodLoading,

  selectedOrganization: state.organization.selectedOrganization,
  currentSubscription: state.subscription.currentSubscription,
  updatePaymentMethodSuccess: state.subscription.updatePaymentMethodSuccess,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationSubscription: (payload) =>
    dispatch(getOrganizationSubscription(payload)),
  updateSubscriptionPaymentMethod: (payload) =>
    dispatch(updateSubscriptionPaymentMethod(payload)),
  activateOrganizationSubscription: (payload) =>
    dispatch(activateOrganizationSubscription(payload)),

  clearPaymentMethodUpdated: () => dispatch(clearPaymentMethodUpdated()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CurrentPlan);
