import { useState, useEffect, useRef } from "react";
import { useLoadScript } from "@react-google-maps/api";

import {
  Container,
  PageTitle,
  CommonImage,
} from "../../../styledComponents/common";

import {
  DataAndMapContainer,
  MapBottomInfo,
  MapInfoText,
  InfoCrossButton,
} from "./jobSitesStyles";
import { MapAndAlertContainer } from "../RouteMap/routeMapStyles";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import JobSitesMap from "./JobSitesMap";
import useAutocompleteService from "../../../customHooks/usePlacesAutocompleteService";

import SitesListSection from "./SitesListSection";
import JobSiteDetails from "./JobSiteDetails";

import DeleteJobsiteModal from "./DeleteJobsiteModal";

import crossWhite from "../../../assets/img/icons/remove_assignee.svg";

const mapLibraries = ["places"];
const mapIds = [process.env.REACT_APP_JOB_SITES_MAP_ID];

// const radiusOptions = [50, 100, 200];

const JobSites = (props) => {
  const [currentPage, setCurrentPage] = useState("list");

  // list states
  const [activeSite, setActiveSite] = useState(null);

  // details states
  const [singleAddressMarker, setSingleAddressMarker] = useState(null);
  // const [siteDetails, setSiteDetails] = useState(null);
  const [addressInput, setAddressInput] = useState("");
  const [radiusInput, setRadiusInput] = useState(100);

  // delete
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteJobsiteId, setDeleteJobsiteId] = useState(null);

  // map info
  const [isMapInfoVisible, setIsMapInfoVisible] = useState(true);

  // map states
  const [map, setMap] = useState(null);

  const [userCurrentLocation, setUserCurrentLocation] = useState(null);

  const { isLoaded, loadError } = useLoadScript({
    id: "job-site-details-map-script-loader",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    libraries: mapLibraries,
    mapIds,
  });

  const {
    geocoderService,
    getPlacePredictions,
    placePredictions,
    setPlacePredictions,
    isPredictionsLoading,
    refreshSessionToken,
  } = useAutocompleteService({ map, debounceTimeout: 500 });

  const {
    selectedOrganization,
    getJobSitesList,
    sitesListLoading,
    jobSitesList,
    jobSiteDetails,
    setJobSiteDetails,

    deleteJobSiteLoading,
    deleteJobSite,
  } = props;

  useEffect(() => {
    fetch("https://hutils.loxal.net/whois")
      .then((resp) => resp.json())
      .then((res) => {
        if (res && res.ip) {
          setUserCurrentLocation({
            lat: res.latitude,
            lng: res.longitude,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getJobSitesList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (jobSitesList && jobSitesList.length > 0 && currentPage === "list") {
      // setActiveSite(jobSitesList[0]);
      if (map) {
        handleFitBounds(jobSitesList);
      }
    }
  }, [jobSitesList, map]);

  // useEffect(() => {
  //   if (jobSiteDetails && jobSiteDetails.id) {
  //     setSiteDetails(jobSiteDetails);
  //   }
  // }, [jobSiteDetails]);

  useEffect(() => {
    if (map && userCurrentLocation) {
      if (!jobSitesList || !(jobSitesList && jobSitesList.length)) {
        map.panTo(userCurrentLocation);
      }
    }
  }, [map, userCurrentLocation]);

  const handleFitBounds = (list) => {
    if (map) {
      if (list.length === 1) {
        handleMapPanAndZoomToSingleLocation(list[0].location);
      } else {
        const bounds = new window.google.maps.LatLngBounds();
        list.forEach((place) => {
          const position = new window.google.maps.LatLng(
            place.location.lat,
            place.location.lng
          );
          bounds.extend(position);
        });
        map.fitBounds(bounds, 20);
      }
    }
  };

  const handleGetGeocode = (place) => {
    if (geocoderService && place && place.place_id && map) {
      setAddressInput(place.description);
      geocoderService
        .geocode({ placeId: place.place_id })
        .then(({ results }) => {
          handleSetAddressMarker(results[0].geometry.location);
        })
        .catch((e) => {
          console.log(e);
        });
      setPlacePredictions([]);
    }
    refreshSessionToken();
  };

  const handleReverseGeocode = (coord) => {
    if (geocoderService && coord) {
      setPlacePredictions([]);
      geocoderService
        .geocode({ location: coord })
        .then((results) => {
          if (results.results && results.results.length) {
            setAddressInput(results.results[0].formatted_address);
          } else {
            setAddressInput(`${coord.lat()}, ${coord.lng()}`);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleSelectSite = (site) => {
    if (site && site.id) {
      setActiveSite(site);

      if (map) {
        map.panTo(site.location);
        map.setZoom(17);
      }
    }
  };

  const handleChangePage = (page, siteData) => {
    setCurrentPage(page);

    if (page === "details") {
      if (siteData && siteData.id) {
        setJobSiteDetails(siteData);
      } else {
        setSingleAddressMarker(null);
      }
    } else {
      if (jobSitesList && jobSitesList.length > 0) {
        // setActiveSite(jobSitesList[0]);
        setActiveSite(null);
        if (map) {
          handleFitBounds(jobSitesList);
        }
      }
    }
  };

  const handleSetAddressFromMap = (location) => {
    if (location && location.latLng && currentPage === "details") {
      handleSetAddressMarker(location.latLng);
      handleReverseGeocode(location.latLng);
    }
  };

  const handleSetAddressMarker = (location) => {
    if (location) {
      setSingleAddressMarker(location);

      handleMapPanAndZoomToSingleLocation(location);
    }
  };

  const handleMapPanAndZoomToSingleLocation = (location) => {
    if (map) {
      map.panTo(location);
      map.setZoom(17);
    }
  };

  const handleDeleteJobSite = () => {
    if (selectedOrganization && selectedOrganization.id && deleteJobsiteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        jobsite_id: deleteJobsiteId,
      };
      deleteJobSite(payload);
      setTimeout(() => {
        setIsDeleteModalVisible(false);
        if (currentPage && currentPage === "details") {
          handleChangePage("list");
        }
      }, 600);
    }
  };

  const toggleDeleteModal = (jobsiteId) => {
    if (!isDeleteModalVisible) {
      setDeleteJobsiteId(jobsiteId);
    }
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  return (
    <div className="content">
      <DeleteJobsiteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteJobSite}
        isLoading={deleteJobSiteLoading}
      />
      <PageTitle>Job Sites</PageTitle>
      <Container padding="0">
        <DataAndMapContainer>
          {currentPage === "list" ? (
            <SitesListSection
              handleChangePage={handleChangePage}
              sitesListLoading={sitesListLoading}
              sitesList={jobSitesList}
              activeSite={activeSite}
              selectSite={handleSelectSite}
              toggleDeleteModal={toggleDeleteModal}
            />
          ) : currentPage === "details" ? (
            <JobSiteDetails
              handleChangePage={handleChangePage}
              siteDetails={jobSiteDetails}
              addressMarker={singleAddressMarker}
              setAddressMarker={handleSetAddressMarker}
              addressInput={addressInput}
              setAddressInput={setAddressInput}
              radiusInput={radiusInput}
              setRadiusInput={setRadiusInput}
              // autocomplete service
              getGeocode={handleGetGeocode}
              getPlacePredictions={getPlacePredictions}
              placePredictions={placePredictions}
              isPredictionsLoading={isPredictionsLoading}
              toggleDeleteModal={toggleDeleteModal}
            />
          ) : null}
          {loadError ? (
            <div>Map cannot be loaded right now! Please try again later</div>
          ) : isLoaded ? (
            <MapAndAlertContainer>
              <JobSitesMap
                currentPage={currentPage}
                sitesList={jobSitesList}
                activeSite={activeSite}
                selectSite={handleSelectSite}
                singleAddressMarker={singleAddressMarker}
                setAddressFromMap={handleSetAddressFromMap}
                setMap={setMap}
                radius={radiusInput}
              />
              {isMapInfoVisible && currentPage && currentPage === "details" ? (
                <MapBottomInfo>
                  <MapInfoText>
                    You can search or double click on map to add jobsite. Drag
                    the marker to change jobsite location.
                  </MapInfoText>
                  <InfoCrossButton onClick={() => setIsMapInfoVisible(false)}>
                    <CommonImage src={crossWhite} alt="close" size="10px" />
                  </InfoCrossButton>
                </MapBottomInfo>
              ) : null}
            </MapAndAlertContainer>
          ) : (
            <ComponentCircleLoader />
          )}
        </DataAndMapContainer>
      </Container>
    </div>
  );
};

export default JobSites;
