import { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";

import { ModalButtonSection } from "../../../styledComponents/members";
import { CommonText, CommonFlex } from "../../../styledComponents/common";
import {
  SecondaryButton,
  PrimaryButton,
} from "../../../styledComponents/buttons";
import {
  CheckboxDiv,
  CheckboxTickIcon,
} from "../../../styledComponents/settings";

import tickWhite from "../../../assets/img/icons/tick_2.svg";

const TakeScreenshotPopup = (props) => {
  const {
    isOpen,
    toggle,
    dontShowWarning,
    setDontShowWarning,
    history,
    requestedScreenshotUserId,
    handleInstantScreenshotRequest,
  } = props;

  const handleCheck = () => {
    setDontShowWarning(!dontShowWarning);
  };

  const handleTakeScreenshot = () => {
    if (requestedScreenshotUserId) {
      handleInstantScreenshotRequest(requestedScreenshotUserId);
      toggle();
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `33vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText fontSize="13.5px">
          Your members will be asked to grant access to take instant screenshots
          from their computer. If you do not want to let them know, you can
          change the settings from activity settings.
        </CommonText>
        <ModalButtonSection>
          <SecondaryButton onClick={() => handleTakeScreenshot()}>
            Take Screenshot
          </SecondaryButton>
          <PrimaryButton
            onClick={() => history.push("/user/activity-settings")}
          >
            Change Settings
          </PrimaryButton>
        </ModalButtonSection>
        <CommonFlex margin="20px 0 0 0" gap="10px">
          <CheckboxDiv active={dontShowWarning} onClick={handleCheck}>
            <CheckboxTickIcon src={tickWhite} alt="" />
          </CheckboxDiv>
          <CommonText $label fontWeight="500">
            Don't show this warning message again
          </CommonText>
        </CommonFlex>
      </ModalBody>
    </Modal>
  );
};

export default TakeScreenshotPopup;
