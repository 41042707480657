import { DashboardModeSelect } from "../../styledComponents/dashboard";
import {
  ModeSelectDiv,
  ModeSelectIcon,
  ModeSelectText,
} from "./dashboardStyles";

import meGreyIcon from "../../assets/img/icons/me_grey.svg";
import meWhiteIcon from "../../assets/img/icons/me_white.svg";
import orgGreyIcon from "../../assets/img/icons/organization_grey.svg";
import orgWhiteIcon from "../../assets/img/icons/organization_white.svg";

export default function DashboardModeSelector({
  currentMode,
  onMeModeSelect,
  onOrganizationModeSelect,
  role,
}) {
  return (
    <DashboardModeSelect role={role}>
      <ModeSelectDiv
        active={currentMode && currentMode === "me"}
        onClick={() => onMeModeSelect()}
      >
        <ModeSelectIcon
          src={currentMode && currentMode === "me" ? meWhiteIcon : meGreyIcon}
          alt=""
        />
        <ModeSelectText active={currentMode && currentMode === "me"}>
          Me
        </ModeSelectText>
      </ModeSelectDiv>
      <ModeSelectDiv
        active={currentMode && currentMode === "organization"}
        onClick={() => onOrganizationModeSelect()}
      >
        <ModeSelectIcon
          src={
            currentMode && currentMode === "organization"
              ? orgWhiteIcon
              : orgGreyIcon
          }
          alt=""
        />
        <ModeSelectText active={currentMode && currentMode === "organization"}>
          {role && role === "team_leader" ? "Team" : "Organization"}
        </ModeSelectText>
      </ModeSelectDiv>
    </DashboardModeSelect>
  );
}
