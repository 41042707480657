import { useState, useEffect } from "react";
import moment from "moment";
import AppLists from "./AppLists";
import FirstRoundLetter from "../../../components/ColorLetterRound/FirstRoundLetter";
import { formatDurationToHoursMinutes } from "../../../utils/helper";

import {
  CommonGrid,
  Container,
  CommonText,
} from "../../../styledComponents/common";
import { ActivityTextSection } from "../../../styledComponents/members";
import {
  AppsTimeSection,
  AppTimeItem,
  AppUrlCard,
} from "../../../styledComponents/Activity";
import { ClientCardsContainer } from "../../../styledComponents/clients";

const AppSection = ({ appSection }) => {
  const [haveIcon, setHaveIcon] = useState(false);
  const [timeDuration, setTimeDuration] = useState(0);

  const getAppData = (appList) => {
    AppLists.map((item) => {
      appList.map((app) => {
        if (
          app.app === item.winId ||
          app.app === item.macId ||
          app.app === item.linuxId
        ) {
          app.name = item.name;
          app.icon = item.icon;
        }
      });
    });
    return appList;
  };

  useEffect(() => {
    if (appSection.duration) {
      setTimeDuration(formatDurationToHoursMinutes(appSection.duration));
    }
  }, [appSection]);

  return (
    <Container padding="30px" margin="20px 0 0 0">
      <CommonGrid>
        <ActivityTextSection gap="5px" style={{ alignContent: `start` }}>
          <CommonText name title>
            {appSection.project ? appSection.project.name : "No project"}
          </CommonText>
          <CommonText>
            {(appSection.task && appSection.task.name) || "No task"}
          </CommonText>
        </ActivityTextSection>
        <AppsTimeSection>
          <AppTimeItem index={1}>
            <CommonText name title>
              {appSection.start_timestamp.format("h:mm A")}
            </CommonText>
            <CommonText $label fontWeight="500">
              Start Time
            </CommonText>
          </AppTimeItem>
          <AppTimeItem index={2}>
            <CommonText name title>
              {appSection.end_timestamp.format("h:mm A")}
            </CommonText>
            <CommonText $label fontWeight="500">
              End Time
            </CommonText>
          </AppTimeItem>
          <AppTimeItem index={3}>
            <CommonText name title>
              {timeDuration}
            </CommonText>
            <CommonText $label fontWeight="500">
              Total Time
            </CommonText>
          </AppTimeItem>
        </AppsTimeSection>
      </CommonGrid>
      <ClientCardsContainer style={{ gridGap: `10px` }}>
        {appSection.apps &&
          appSection.apps.length > 0 &&
          getAppData(appSection.apps).map((app, index) => (
            <AppUrlCard key={index}>
              {app.icon && (
                <div>
                  <img src={app.icon} width="100%" alt="item.name" />
                </div>
              )}
              {!app.name && (
                <FirstRoundLetter
                  text={app.app}
                  backColor={index}
                  size="36px"
                  fontSize="16px"
                />
              )}
              <ActivityTextSection
                gap="5px"
                style={{
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                <CommonText name>{app.name ? app.name : app.app}</CommonText>
                <CommonText fontSize="13px">
                  {formatDurationToHoursMinutes(app.time_spent)}
                </CommonText>
              </ActivityTextSection>
            </AppUrlCard>
          ))}
      </ClientCardsContainer>
    </Container>
  );
};

export default AppSection;
