
import styled from "styled-components";

import accessDeniedArtwork from "../../assets/img/common/permission-denied.svg";

const AccessDeniedContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AccessDeniedDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AccessDeniedArtwork = styled.img`
  height: 80px;
  width: 80px;
`;
const AccessDeniedTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: 25px;
`;
const AccessDeniedSubtitle = styled.div`
  font-size: 14px;
  color: rgba(47, 57, 78, 0.7);
  margin-top: 10px;
`;
const GoBackButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: #fff;
  border: 1px solid #20bead;
  font-size: 15px;
  color: #20bead;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 30px;
`;

const AccessDeniedComponent = (props) => {
  return (
    <AccessDeniedContainer>
      <AccessDeniedDiv>
        <AccessDeniedArtwork src={accessDeniedArtwork} alt="" />
        <AccessDeniedTitle>Permission Denied!</AccessDeniedTitle>
        <AccessDeniedSubtitle>
          You do not have permission to access this page.
        </AccessDeniedSubtitle>
        <GoBackButton onClick={() => props.history.goBack()}>
          Go Back
        </GoBackButton>
      </AccessDeniedDiv>
    </AccessDeniedContainer>
  );
};

export default AccessDeniedComponent;
