import { useState, useEffect } from "react";
import { CommonText } from "../../styledComponents/common";
import {
  ViewLessCardContainer,
  ViewLessCard,
  CardTextData,
  CicrularBarSection,
} from "../../styledComponents/ProjectsNew";
import {
  BudgetLeftSection,
  ProjectCardLabel,
  ProjectCardText,
  PercentageBarSection,
  PercentageText,
  ProjectCardNoDataContainer,
  ProjectCardNoDataImg,
  ProjectCardNoDataText,
} from "./projectsStyles";
import LinearActivityBar from "../../components/ProgressBar/LinearActivityBar";

import { currencySymbolMap } from "../../utils/currencies";

import budgetEmpty from "../../assets/img/icons/budget-empty.svg";
import hourEmpty from "../../assets/img/icons/hour-empty.svg";

export const ProjectCardNoData = ({ type, text }) => {
  return (
    <ProjectCardNoDataContainer>
      <ProjectCardNoDataImg
        src={type === "hour" ? hourEmpty : budgetEmpty}
        alt=""
      />
      {text && <ProjectCardNoDataText>{text}</ProjectCardNoDataText>}
    </ProjectCardNoDataContainer>
  );
};

const ViewLess = ({ project, selectedOrganization }) => {
  const [budgetType, setBudgetType] = useState(null);
  const [budgetAmount, setBudgetAmount] = useState(null);
  const [spentHours, setSpentHours] = useState(null);
  const [budgetCost, setBudgetCost] = useState(null);
  const [budgetLeft, setBudgetLeft] = useState(null);
  const [hoursPercentage, setHoursPercentage] = useState(0);
  const [costPercentage, setCostPercentage] = useState(0);
  const [budgetLeftPercentage, setBudgetLeftPercentage] = useState(0);
  const [hasBillable, setHasBillable] = useState(false);
  const [billableAmount, setBillableAmount] = useState(0);
  const [uninvoicedAmount, setUninvoicedAmount] = useState(0);
  const [invoicedAmount, setInvoicedAmount] = useState(0);

  const [currency, setCurrency] = useState("");

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.configuration &&
      selectedOrganization.configuration.currency
    ) {
      setCurrency(selectedOrganization.configuration.currency);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (project) {
      if (project.billable_id !== null) {
        setHasBillable(true);
        setBillableAmount(project.billable_amount.toFixed(2));
        setUninvoicedAmount(project.uninvoiced_amount.toFixed(2));
        setInvoicedAmount(
          (project.billable_amount - project.uninvoiced_amount).toFixed(2)
        );
      } else {
        setHasBillable(false);
      }
      if (project.spent_hours) {
        setSpentHours(getHours(project.spent_hours));
      } else {
        setSpentHours(null);
      }
      if (!project.budget) {
        setBudgetType(null);
        setBudgetAmount(null);
        setBudgetCost(null);
        setBudgetLeft(null);
        return;
      }
      if (project.budget) {
        setBudgetType(project.budget.budget_type);
        if (project.budget.budget_type === "project_fees") {
          if (project.budget.amount) {
            setBudgetAmount((project.budget.amount / 100).toFixed(2));
            if (project.budget.budget_left !== null) {
              let leftBudget = (
                (project.budget.budget_left / project.budget.amount) *
                100
              ).toFixed(1);
              setBudgetLeft((project.budget.budget_left / 100).toFixed(2));
              setBudgetLeftPercentage(leftBudget);
            }
            if (project.budget.cost !== null) {
              let cost = (
                (project.budget.cost / project.budget.amount) *
                100
              ).toFixed(1);
              setBudgetCost(project.budget.cost);
              setCostPercentage(
                project.budget.cost > project.budget.amount ? 100 : cost
              );
            } else if (!project.budget.cost) {
              setCostPercentage(0);
            }
          }
        } else if (project.budget.budget_type === "project_hours") {
          if (project.budget.amount) {
            setBudgetAmount(project.budget.amount);
            if (project.budget.budget_left !== null) {
              let leftBudget = (
                (project.budget.budget_left / project.budget.amount) *
                100
              ).toFixed(1);
              setBudgetLeft(project.budget.budget_left);
              setBudgetLeftPercentage(leftBudget);
            }
            if (project.budget.spent_hours) {
              let hourPercentage = (
                (getTimeInHours(project.budget.spent_hours) /
                  project.budget.amount) *
                100
              ).toFixed(1);
              setHoursPercentage(
                parseInt(project.budget.spent_hours.split(":")[0]) >
                  project.budget.amount
                  ? 100
                  : hourPercentage
              );
            }
          }
        }
      }
    }
  }, [project]);

  const getHours = (time) => {
    let hours = time.split(":");
    return `${hours[0]}h ${hours[1]}m`;
  };

  const getTimeInHours = (time) => {
    let splitTime = time.split(":");

    return parseInt(splitTime[0]);
  };

  return (
    <ViewLessCardContainer>
      <ViewLessCard>
        <div>
          <CommonText fontSize="12px">
            {budgetType && budgetType === "project_hours"
              ? "Budgeted Hours"
              : "Total Budget"}
          </CommonText>
          {budgetAmount ? (
            <CardTextData margin="4px 0 0" fontSize="14px">
              {budgetType && budgetType === "project_fees"
                ? `${currencySymbolMap[currency]} `
                : ``}
              {budgetAmount}
              {budgetType && budgetType === "project_hours" ? `hours` : ``}
            </CardTextData>
          ) : (
            <ProjectCardNoData type="budget" text="Budget not set" />
          )}
        </div>
        {budgetLeft !== null && (
          <BudgetLeftSection>
            <ProjectCardLabel>
              {budgetType && budgetType === "project_fees"
                ? "Left"
                : "Remaining"}
            </ProjectCardLabel>
            <ProjectCardText>
              {budgetType === "project_fees"
                ? `${currencySymbolMap[currency]} ${budgetLeft}`
                : `${budgetLeft} hours`}
            </ProjectCardText>
          </BudgetLeftSection>
        )}
      </ViewLessCard>
      <ViewLessCard>
        <div>
          <CommonText fontSize="12px">
            Spent{" "}
            {budgetType && budgetType === "project_hours" ? "Hours" : "Amount"}
          </CommonText>
          {budgetAmount ? (
            <CardTextData margin="4px 0 0" fontSize="14px">
              {budgetType && budgetType === "project_hours"
                ? spentHours
                  ? spentHours
                  : "0h 00m"
                : budgetCost
                ? `${currencySymbolMap[currency]} ${(budgetCost / 100).toFixed(
                    2
                  )}`
                : budgetAmount
                ? `${currencySymbolMap[currency]} 0.00`
                : "N/A"}
            </CardTextData>
          ) : (
            <ProjectCardNoData type="budget" text="Budget not set" />
          )}
        </div>
        {budgetAmount ? (
          <PercentageBarSection>
            <LinearActivityBar
              width="100%"
              height="8px"
              borderRadius="4px"
              yellow="#20bead"
              red="#20bead"
              green="#20bead"
              percent={
                budgetType === "project_hours" && hoursPercentage
                  ? hoursPercentage
                  : budgetType === "project_fees" && costPercentage
                  ? costPercentage
                  : 0
              }
              marginTop="0"
              backgroundColor="#B5B5B5"
            />
            <PercentageText>
              {budgetType === "project_fees" && costPercentage
                ? costPercentage
                : budgetType === "project_hours" && hoursPercentage
                ? hoursPercentage
                : 0}
              %
            </PercentageText>
          </PercentageBarSection>
        ) : null}
      </ViewLessCard>
      <ViewLessCard>
        <div>
          <CommonText fontSize="12px">
            Spent{" "}
            {budgetType && budgetType === "project_hours" ? "Amount" : "Hours"}
          </CommonText>
          {budgetType && budgetType === "project_hours" ? (
            <ProjectCardNoData type="budget" />
          ) : spentHours && spentHours !== "00h 00m" ? (
            <CardTextData margin="4px 0 0" fontSize="14px">
              {spentHours}
            </CardTextData>
          ) : (
            <ProjectCardNoData type="hour" text="Time not tracked yet" />
          )}
        </div>
      </ViewLessCard>
      <ViewLessCard>
        <div>
          <CommonText fontSize="12px">Billable Amount</CommonText>
          {hasBillable ? (
            <CardTextData margin="4px 0 0" fontSize="14px">
              {currencySymbolMap[currency]} {billableAmount}
            </CardTextData>
          ) : (
            <ProjectCardNoData type="budget" text="Billing not set" />
          )}
        </div>
      </ViewLessCard>
      <ViewLessCard>
        <div>
          <CommonText fontSize="12px">Uninvoiced Amount</CommonText>
          {hasBillable ? (
            <CardTextData
              margin="4px 0 0"
              fontSize="14px"
              textColor={
                uninvoicedAmount && uninvoicedAmount < 0 ? "#FE5969" : "#20bead"
              }
            >
              {currencySymbolMap[currency]} {uninvoicedAmount}
            </CardTextData>
          ) : (
            <ProjectCardNoData type="budget" text="Billing not set" />
          )}
        </div>
        {hasBillable && invoicedAmount && (
          <BudgetLeftSection>
            <ProjectCardLabel>Invoiced</ProjectCardLabel>
            <ProjectCardText>
              {currencySymbolMap[currency]} {invoicedAmount}
            </ProjectCardText>
          </BudgetLeftSection>
        )}
      </ViewLessCard>
    </ViewLessCardContainer>
  );
};

export default ViewLess;
