import { connect } from "react-redux";

// Component
import TimesheetDaily from "./TimesheetDaily";
// Actions
import {
  getDailyTimesheets,
  createDailyTimesheets,
  updateDailyTimesheets,
  deleteDailyTimesheets,
  clearDailyTimesheetAdded,
} from "../timesheetActions";
import {
  getOrganizationMembersList,
  getProjectAssigneeList,
} from "../../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  timesheet: state.timesheet.dailyList,
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.timesheet.dailyTimesheetLoading,
  manualTimeLoading: state.timesheet.manualTimeLoading,
  deleteTimesheetLoading: state.timesheet.deleteTimesheetLoading,
  manualTimeAdded: state.timesheet.manualTimeAdded,
  projectsList: state.project.projectShortList,
  projectAssigneeList: state.project.projectAssigneeList,
  projectTaskList: state.project.projectTaskList,
  membersList: state.project.orgMembersList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDailyTimesheets: (details) => dispatch(getDailyTimesheets({ ...details })),
  createDailyTimesheets: (details) =>
    dispatch(createDailyTimesheets({ ...details })),
  updateDailyTimesheets: (details) =>
    dispatch(updateDailyTimesheets({ ...details })),
  deleteDailyTimesheets: (details) =>
    dispatch(deleteDailyTimesheets({ ...details })),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
  clearDailyTimesheetAdded: () => dispatch(clearDailyTimesheetAdded()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimesheetDaily);
