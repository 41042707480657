import {
  CommonText,
  TableText,
  ColoredText,
  CommonFlex,
} from "styledComponents/common";
import { formatDurationToHoursMinutes } from "../../../../utils/helper";
import {
  TableWeeklyContainer,
  ColoredLine,
  TableItem,
  TableTitleItem,
} from "../../../../styledComponents/reports";
import RoundImageNameComp from "../../../../components/RoundImageName/RoundImageNameComp";

import {
  IdleTimeDurationBox,
  IdleTimeDurationDiv,
} from "custom_modules/TimesheetApproval/AwaitingApprovals/awaitingApprovalStyles";
import { CustomReportTableRow } from "styledComponents/reports";

const CustomReportTable = ({ report, plan, features }) => {
  return (
    <div style={{ marginTop: `30px` }}>
      <TableWeeklyContainer>
        <CommonFlex
          style={{
            overflow: "auto",
          }}
        >
          <CommonFlex direction="column" alignItems="start">
            <CustomReportTableRow title plan={plan}>
              <TableTitleItem width="250px">
                <TableText>Member Name</TableText>
              </TableTitleItem>
              <TableTitleItem width="250px">
                <TableText>Member Email</TableText>
              </TableTitleItem>
              <TableTitleItem width="120px">
                <TableText>Member Role</TableText>
              </TableTitleItem>
              {features?.includes("project") && (
                <TableTitleItem width="200px">
                  <TableText>Project</TableText>
                </TableTitleItem>
              )}
              {features?.includes("total_time") && (
                <TableTitleItem width="155px">
                  <TableText>Total Time Worked</TableText>
                </TableTitleItem>
              )}
              {features?.includes("idle_time") && (
                <TableTitleItem width="120px">
                  <TableText>Idle Time</TableText>
                </TableTitleItem>
              )}
              {features?.includes("idle_time") && (
                <TableTitleItem width="120px">
                  <TableText>Idle Time %</TableText>
                </TableTitleItem>
              )}
              {features?.includes("average_activity") && (
                <TableTitleItem width="200px">
                  <TableText>Average Activity</TableText>
                </TableTitleItem>
              )}
              {features?.includes("active_time") && (
                <TableTitleItem width="120px">
                  <TableText>Active Time</TableText>
                </TableTitleItem>
              )}
              {features?.includes("neutral_time") && (
                <TableTitleItem width="120px">
                  <TableText>Neutral Time</TableText>
                </TableTitleItem>
              )}
              {features?.includes("manual_time") && (
                <TableTitleItem width="120px">
                  <TableText>Manual time</TableText>
                </TableTitleItem>
              )}
              {features?.includes("manual_time") && (
                <TableTitleItem width="130px">
                  <TableText>Manual time %</TableText>
                </TableTitleItem>
              )}
              {/* <TableTitleItem>
              <TableText>Mobile time</TableText>
            </TableTitleItem>
            <TableTitleItem>
              <TableText>Mobile time %</TableText>
            </TableTitleItem> */}
              {features?.includes("notes") && (
                <TableTitleItem width="300px">
                  <TableText>Notes</TableText>
                </TableTitleItem>
              )}
            </CustomReportTableRow>
            <ColoredLine
              style={{
                width: "100%",
              }}
            />

            {report?.map((rep, index) => (
              <CustomReportTableRow plan={plan} key={index}>
                <TableItem width="250px">
                  <RoundImageNameComp
                    imgSource={rep.avatar}
                    name={rep.memberName}
                    imageSize="36px"
                    color={rep.memberColor}
                  />
                </TableItem>
                <TableItem width="250px">
                  <CommonText>
                    {rep.memberEmail ? rep.memberEmail : "-"}
                  </CommonText>
                </TableItem>
                <TableItem width="120px">
                  <CommonText>
                    {rep.memberRole ? rep.memberRole : "-"}
                  </CommonText>
                </TableItem>
                {features?.includes("project") && (
                  <TableItem width="200px">
                    <CommonText>{rep.projects ? rep.projects : "-"}</CommonText>
                  </TableItem>
                )}
                {features?.includes("total_time") && (
                  <TableItem width="155px">
                    <CommonText>
                      {formatDurationToHoursMinutes(rep.totalTimeWorked)}
                    </CommonText>
                  </TableItem>
                )}
                {features?.includes("idle_time") && (
                  <TableItem width="120px">
                    {rep?.idleTime > 0 ? (
                      <IdleTimeDurationDiv>
                        <IdleTimeDurationBox>
                          {formatDurationToHoursMinutes(rep.idleTime)}
                        </IdleTimeDurationBox>
                        {/* <CenteredTooltip
                      right="calc(100% + 4px)"
                      top="0"
                      left="unset"
                    >
                      Idle Time
                    </CenteredTooltip> */}
                      </IdleTimeDurationDiv>
                    ) : (
                      ""
                    )}
                  </TableItem>
                )}
                {features?.includes("idle_time") && (
                  <TableItem width="120px">
                    <CommonText>{rep.idleTimePercentage}%</CommonText>
                  </TableItem>
                )}
                {features?.includes("average_activity") && (
                  <TableItem width="200px">
                    {/* <CommonGrid
                      justifyContent="start"
                      columns="minmax(auto , 170px) auto"
                    > */}
                    {/* <LinearActivityBar
                        percent={parseInt(rep.averageActivity)}
                      /> */}
                    <CommonText>{rep.averageActivity}%</CommonText>
                    {/* </CommonGrid> */}
                  </TableItem>
                )}
                {features?.includes("active_time") && (
                  <TableItem width="120px">
                    <ColoredText color="#20BEAD" fontSize="14px">
                      {formatDurationToHoursMinutes(rep.activeTime)}
                    </ColoredText>
                  </TableItem>
                )}
                {features?.includes("neutral_time") && (
                  <TableItem width="120px">
                    <CommonText>
                      {formatDurationToHoursMinutes(rep.neutralTime)}
                    </CommonText>
                  </TableItem>
                )}
                {features?.includes("manual_time") && (
                  <TableItem width="120px">
                    <CommonText>
                      {formatDurationToHoursMinutes(rep.manualTime)}
                    </CommonText>
                  </TableItem>
                )}
                {features?.includes("manual_time") && (
                  <TableItem width="130px">
                    <CommonText>{rep.manualTimePercentage}%</CommonText>
                  </TableItem>
                )}
                {features?.includes("notes") && (
                  <TableItem width="300px">
                    {rep.notes ? rep.notes : "-"}
                  </TableItem>
                )}
              </CustomReportTableRow>
            ))}
          </CommonFlex>
        </CommonFlex>
      </TableWeeklyContainer>
    </div>
  );
};

export default CustomReportTable;
