import styled from "styled-components";
const ChangeButtonContainer = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${({ theme }) => theme.colors.main.gray};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.gray};
  }
`;

const ChangeIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const ChangedButton = ({ icon, onClick }) => {
  return (
    <ChangeButtonContainer onClick={onClick}>
      <ChangeIcon src={icon} alt="" />
    </ChangeButtonContainer>
  );
};

export default ChangedButton;
