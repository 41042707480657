//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";
//Constants
import {
  GET_ALL_TASK_LIST,
  GET_ALL_TASK_LIST_SUCCESS,
  GET_ALL_TASK_LIST_FAILURE,
} from "../../modules/constants";

export function* getTaskList({ payload }) {
  if (!payload) return;
  const { organization_id, page, name, project_id, user_id, status } = payload;
  try {
    const url = `${organization_id}/projects/tasks/all/?status=${status}${
      page ? `&page=${page}` : ""
    }${name ? `&name=${name}` : ""}${
      project_id ? `&project_id=${project_id}` : ""
    }${user_id ? `&members=${user_id}` : ""}`;

    const response = yield defaultApi(url, "GET", payload);

    yield put({
      type: GET_ALL_TASK_LIST_SUCCESS,
      payload: { data: response, project_id },
    });
  } catch (err) {
    yield put({
      type: GET_ALL_TASK_LIST_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([takeLatest(GET_ALL_TASK_LIST, getTaskList)]);
}
