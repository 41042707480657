import { useState, useRef, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";

import {
  SmallCardLabel,
  SmallCardPercentage,
  SmallCardContent,
} from "../../styledComponents/dashboard";
import {
  SmallCardContainer,
  DataDiv,
  HourPercentageContainer,
  PercentageArrowIcon,
  BarChartCanvasContainer,
} from "./cardStyles";

import {
  totalHourCardChartOptions,
  drawBlankBarPlugin,
} from "../../utils/chartOptions";

import upArrowIcon from "../../assets/img/icons/up_arrow.svg";
import downArrowIcon from "../../assets/img/icons/down_arrow.svg";

const getDays = (startDate, endDate) => {
  const days = [];
  while (moment(startDate).isSameOrBefore(endDate)) {
    days.push(startDate.format("dd"));
    startDate = moment(startDate).add(1, "days");
  }
  return days;
};
const chartLabels = getDays(
  moment().startOf("isoWeek"),
  moment().endOf("isoWeek")
);

const initialChartData = {
  labels: chartLabels,
  datasets: [
    {
      label: "Hour Logged",
      // barThickness: "flex",
      maxBarThickness: 24,
      borderWidth: 1,
      borderRadius: 4,
      borderSkipped: false,
      backgroundColor: "#E9E9E9",
      borderColor: "#E9E9E9",
      hoverBackgroundColor: "#E9E9E9",
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

const getTime = (time) => {
  let timeInSeconds = parseInt(time);
  let minutes = Math.floor(timeInSeconds / 60) % 60;
  let hours = Math.floor(timeInSeconds / 3600);

  return `${hours}h ${minutes}m`;
};

const TotalHourCard = (props) => {
  const [cardValueOrg, setCardValueOrg] = useState("0h 00m");
  const [cardValueUser, setCardValueUser] = useState("0h 00m");
  const [cardPercentageOrg, setCardPercentageOrg] = useState("0.00%");
  const [cardPercentageUser, setCardPercentageUser] = useState("0.00%");
  const [dataIncreaseOrg, setDataIncreaseOrg] = useState(true);
  const [dataIncreaseUser, setDataIncreaseUser] = useState(true);

  const [barChartData, setBarChartData] = useState(initialChartData);

  const {
    organization,
    chartData,
    chartOptions,
    chartConfig,
    value,
    label,
    currentMode,
    variation,
  } = props;

  const clearState = () => {
    setCardValueOrg("0h 00m");
    setCardValueUser("0h 00m");
    setCardPercentageOrg("0.00%");
    setCardPercentageUser("0.00%");
    setDataIncreaseOrg(true);
    setDataIncreaseUser(true);
  };
  useEffect(() => {
    if (chartData && chartData.length > 0) {
      const data = { ...initialChartData };
      setBarChartData({
        labels: chartLabels,
        datasets: [
          {
            ...data.datasets[0],
            data: chartData,
            backgroundColor: "#73A8FC",
            hoverBackgroundColor: "#73A8FC",
            borderColor: "#73A8FC",
          },
        ],
      });
    } else if (chartData && chartData.length === 0) {
      setBarChartData(initialChartData);
    }
  }, [chartData]);

  useEffect(() => {
    if (value) {
      if (value.orgThisWeek && value.orgThisWeek.total_time_this_week) {
        setCardValueOrg(getTime(value.orgThisWeek.total_time_this_week));
      }
      if (value.userThisWeek) {
        setCardValueUser(getTime(value.userThisWeek));
      }

      if (
        value.orgThisWeek &&
        value.orgThisWeek.total_time_this_week &&
        value.orgLastWeek &&
        value.orgLastWeek.total_time_this_week
      ) {
        if (
          parseInt(value.orgThisWeek.total_time_this_week) <
          parseInt(value.orgLastWeek.total_time_this_week)
        ) {
          setDataIncreaseOrg(false);
        }
        let percentage = Math.abs(
          (parseInt(value.orgThisWeek.total_time_this_week) /
            parseInt(value.orgLastWeek.total_time_this_week)) *
            100 -
            100
        ).toFixed(1);
        setCardPercentageOrg(`${percentage}%`);
      }

      if (value.userThisWeek && value.userLastWeek) {
        if (parseInt(value.userThisWeek) < parseInt(value.userLastWeek)) {
          setDataIncreaseUser(false);
        }
        let percentage = Math.abs(
          (parseInt(value.userThisWeek) / parseInt(value.userLastWeek)) * 100 -
            100
        ).toFixed(1);
        setCardPercentageUser(`${percentage}%`);
      }
    } else if (value === null) {
      clearState();
    }
  }, [value]);

  return (
    <SmallCardContainer>
      <DataDiv>
        <HourPercentageContainer>
          <SmallCardLabel>{label}</SmallCardLabel>
          <SmallCardPercentage
            increase={
              currentMode && currentMode === "organization"
                ? dataIncreaseOrg
                : dataIncreaseUser
            }
            percentage={
              currentMode && currentMode === "organization"
                ? cardPercentageOrg
                : cardPercentageUser
            }
          >
            <PercentageArrowIcon
              alt=""
              src={
                currentMode && currentMode === "organization"
                  ? dataIncreaseOrg
                    ? upArrowIcon
                    : downArrowIcon
                  : dataIncreaseUser
                  ? upArrowIcon
                  : downArrowIcon
              }
            />
            {currentMode && currentMode === "organization"
              ? cardPercentageOrg
              : cardPercentageUser}
          </SmallCardPercentage>
        </HourPercentageContainer>
        <SmallCardContent>
          {currentMode && currentMode === "organization"
            ? cardValueOrg
            : cardValueUser}
        </SmallCardContent>
      </DataDiv>
      <BarChartCanvasContainer>
        <Bar
          options={totalHourCardChartOptions({
            tick: true,
            activity: false,
          })}
          data={barChartData}
          width={100}
          plugins={[drawBlankBarPlugin()]}
        />
      </BarChartCanvasContainer>
    </SmallCardContainer>
  );
};

export default TotalHourCard;
