import { connect } from "react-redux";

// Component
import ActivityURLs from "./ActivityURLs";

import { getOrganizationMembersList } from "../../Projects/projectsActions";

// Actions
import { getUrlList } from "../activityActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  urls: state.activity.urls,
  isLoading: state.activity.isLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getUrlList: (payload) => dispatch(getUrlList(payload)),
  getOrganizationMembersList: (payload) =>
    dispatch(getOrganizationMembersList(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ActivityURLs);
