import { connect } from "react-redux";

// Component
import Clients from "./Clients";

// Actions
import {
  getClientList,
  createClient,
  updateClient,
  deleteClient,
} from "./clientsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  clientList: state.client.clientList,
  isLoading: state.client.isLoading,
  clientUpdateLoading: state.client.clientUpdateLoading,
  updatingClientId: state.client.updatingClientId,
  clientDeleteLoading: state.client.clientDeleteLoading,
  deletingClientId: state.client.deletingClientId,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClientList: (details) => dispatch(getClientList({ ...details })),
  createClient: (details) => dispatch(createClient({ ...details })),
  updateClient: (details) => dispatch(updateClient({ ...details })),
  deleteClient: (details) => dispatch(deleteClient({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Clients);
