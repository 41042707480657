
import ContentLoader from "react-content-loader";

const OngoingTasksLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={610}
      height={217}
      viewBox="0 0 610 217"
      backgroundColor="#e8eaee"
      foregroundColor="#f3f3f3"
      {...props}
    >
      <circle cx="12" cy="94.8" r="12" />
      <circle cx="12" cy="12.8" r="12" />
      <rect x="34.1" width="400" height="11.8" rx="5.9" />
      <rect x="34.1" y="87.5" width="400" height="11.8" rx="5.9" />
      <rect x="34.1" y="124.5" width="280" height="11.8" rx="5.9" />
      <rect x="34.1" y="36.5" width="280" height="11.8" rx="5.9" />
    </ContentLoader>
  );
};

export default OngoingTasksLoader;
