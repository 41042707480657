import { useState, useEffect } from "react";
import moment from "moment-timezone";

import { RouteMapFilterContainer } from "./filterStyles";
import {
  InputWithLabelSection,
  FilterLabel,
} from "../../../../styledComponents/common";
import { SecondaryButton } from "../../../../styledComponents/buttons";

import ProjectDropdown from "../../../../components/CommonFilter/ProjectDropdown";
import DatePicker from "../../../../components/SingleDatePicker/SingleDatePicker";
import AddSingleMemberDropdown from "../../../../components/CustomDropdown/AddSingleMemberDropdown";
import FilterDropDownStyle from "../../../../components/DropdownStyle/FilterDropDownStyle";

import avatar5 from "../../../../assets/img/avatar_5.svg";

const RouteMapFilter = (props) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedProject, setSelectedProject] = useState(null);
  const [memberOptions, setMemberOptions] = useState([]);

  const {
    selectedOrganization,
    getOrganizationMembersList,

    organizationMembersList,
    getProjectAssigneeList,
    projectAssigneeList,

    selectedMember,
    setSelectedMember,

    getRouteMapLocations,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      let assigneeList = [];
      projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: `${assignee.user.first_name}${
            assignee.user.last_name ? ` ${assignee.user.last_name}` : ``
          }`,
          avatar: assignee.user.avatar ? assignee.user.avatar : avatar5,
          timezone: assignee.user.timezone || null,
        });
      });
      setMemberOptions(assigneeList);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if ((selectedProject && !selectedProject.value) || !selectedProject) {
      setMemberOptions(organizationMembersList);
    }
  }, [selectedProject]);

  const selectProject = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      // call api
      const payload = {
        organization_id: selectedOrganization.id,
      };

      if (e && e.value) {
        payload.project_id = e.value;
        getProjectAssigneeList(payload);
      }

      payload.date = moment(selectedDate).format("YYYY-MM-DD");
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      getRouteMapLocations(payload);
    }

    setSelectedProject(e);
  };

  const selectMember = (member) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        user_id: member.id,
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      getRouteMapLocations(payload);
    }
    setSelectedMember(member);
  };
  const clearSelectedMember = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      getRouteMapLocations(payload);
    }
    setSelectedMember(null);
  };

  const selectDate = (date) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format("YYYY-MM-DD"),
      };
      if (selectedProject && selectedProject.value) {
        payload.project_id = selectedProject.value;
      }
      if (selectedMember && selectedMember.id) {
        payload.user_id = selectedMember.id;
      }
      getRouteMapLocations(payload);
    }
    setSelectedDate(date);
  };

  const selectToday = () => {
    selectDate(moment());
  };

  return (
    <RouteMapFilterContainer>
      <InputWithLabelSection>
        <FilterLabel>Projects</FilterLabel>
        <ProjectDropdown
          selectedOrganization={selectedOrganization}
          selectedProject={selectedProject}
          selectProject={selectProject}
          selectedMember={selectedMember}
          clearable
          style={FilterDropDownStyle({ height: "40px" })}
        />
      </InputWithLabelSection>

      <InputWithLabelSection>
        <FilterLabel>Date</FilterLabel>

        <DatePicker
          id="routeMapFilterDate"
          date={selectedDate}
          onDateChange={selectDate}
          dateDisplayFormat={"DD/MM/YYYY"}
          placeholder={"Select date..."}
          allowPreviousDates
        />
      </InputWithLabelSection>
      <InputWithLabelSection>
        <FilterLabel>Member</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={selectMember}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </div>
      </InputWithLabelSection>
      <InputWithLabelSection>
        <div style={{ height: "21px" }} />
        <SecondaryButton onClick={() => selectToday()}>Today</SecondaryButton>
      </InputWithLabelSection>
    </RouteMapFilterContainer>
  );
};

export default RouteMapFilter;
