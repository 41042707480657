import { useState, useEffect, useRef } from "react";

import styled from "styled-components";

import { password_validate } from "../../utils/helper";

import {
  AuthPageContainer,
  AuthPageRightContainer,
  AuthCard,
  AuthCardTitle,
  AuthForm,
  AuthInputContainer,
  ForgotPasswordDiv,
  ForgotPasswordText,
  ShowPasswordIcon,
  AuthSubmitButton,
} from "../../styledComponents/authLayout";

import TextInput from "../../components/FloatingLabelInput/FloatingLabelInput";

import AuthPageLeftSection from "../../components/AuthLayout/AuthPageLeftSection";
import PasswordStrengthChecker from "../../components/PasswordStrengthChecker/PasswordStrengthChecker";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

import viewPassword from "../../assets/img/common/password_show.svg";
import hidePassword from "../../assets/img/common/password_hide.svg";

const Card = styled(AuthCard)`
  min-height: 50vh;
  padding: 40px 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Form = styled(AuthForm)`
  margin-top: 20px;
`;

const ResetPassword = ({
  history,
  match,
  resetPassword,
  resetRedirectReset,
  reset,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [confirmationKey, setConfirmationKey] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const [confirmPasswordIsFocused, setConfirmPasswordIsFocused] =
    useState(false);
  const passwordFieldRef = useRef(null);
  const confirmPasswordFieldRef = useRef(null);
  const methods = {
    setPassword,
    setConfirmPassword,
  };
  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (token != null) {
      history.push("/user/dashboard");
    }
    const {
      params: { confirmation_key },
    } = match;
    setConfirmationKey(confirmation_key);
    return () => {
      document.body.classList.toggle("login-page");
    };
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (resetPassword.redirect === true) {
      resetRedirectReset();
      history.push("/auth/login");
    }
    // eslint-disable-next-line
  }, [resetPassword]);

  useEffect(() => {
    // Moving cursor to the end
    passwordFieldRef.current.selectionStart =
      passwordFieldRef.current.value.length;
    passwordFieldRef.current.selectionEnd =
      passwordFieldRef.current.value.length;

    confirmPasswordFieldRef.current.selectionStart =
      confirmPasswordFieldRef.current.value.length;
    confirmPasswordFieldRef.current.selectionEnd =
      confirmPasswordFieldRef.current.value.length;
  }, [showPassword]);

  useEffect(() => {
    if (confirmPassword === password) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [password, confirmPassword]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    methods[name](value);
  };
  const handleReset = (e) => {
    e.preventDefault();
    let payload = {
      confirmation_key: confirmationKey,
      password,
    };
    reset(payload);
  };
  const toggleShowPassword = (item) => {
    setShowPassword(!showPassword);
    if (item === "password") {
      passwordFieldRef.current.focus();
    } else if (item === "confirmPassword") {
      confirmPasswordFieldRef.current.focus();
    }
  };
  return (
    <AuthPageContainer>
      <AuthPageLeftSection />
      <AuthPageRightContainer>
        <Card>
          <AuthCardTitle>Reset your password here</AuthCardTitle>
          <Form onSubmit={(e) => handleReset(e)}>
            <AuthInputContainer>
              <TextInput
                refs={passwordFieldRef}
                id="password"
                name="setPassword"
                label="Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => onTextChange(e)}
                value={password}
                onFocus={() => setPasswordIsFocused(true)}
                onBlur={() => setPasswordIsFocused(false)}
                autoFocus
              />
              <ShowPasswordIcon
                src={showPassword ? hidePassword : viewPassword}
                alt=""
                active={passwordIsFocused}
                onClick={(e) => toggleShowPassword("password")}
              />
            </AuthInputContainer>
            <AuthInputContainer>
              <TextInput
                refs={confirmPasswordFieldRef}
                id="confirmPassword"
                name="setConfirmPassword"
                label="Confirm Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => onTextChange(e)}
                value={confirmPassword}
                onFocus={() => setConfirmPasswordIsFocused(true)}
                onBlur={() => setConfirmPasswordIsFocused(false)}
                validationBorder
                isValid={passwordMatch}
              />
              <ShowPasswordIcon
                src={showPassword ? hidePassword : viewPassword}
                alt=""
                active={confirmPasswordIsFocused}
                onClick={(e) => toggleShowPassword("confirmPassword")}
              />
            </AuthInputContainer>
            {((password && password.length > 0) || passwordIsFocused) && (
              <PasswordStrengthChecker password={password} />
            )}
            <AuthSubmitButton
              type="submit"
              disabled={!password_validate(password) || !confirmPassword}
              isDisabled={!password_validate(password) || !confirmPassword}
            >
              {resetPassword.isLoading ? (
                <ButtonTextLoader loadingText="Saving New Password" />
              ) : (
                "Confirm"
              )}
            </AuthSubmitButton>
            <ForgotPasswordDiv style={{ justifyContent: "center" }}>
              <ForgotPasswordText
                to={{
                  pathname: "/auth/login",
                  state: {
                    from: "resetPassword",
                  },
                }}
              >
                Back to sign in
              </ForgotPasswordText>
            </ForgotPasswordDiv>
          </Form>
        </Card>
      </AuthPageRightContainer>
    </AuthPageContainer>
  );
};

export default ResetPassword;
