import {
  GET_ALL_TASK_LIST,
  } from "../../modules/constants";
  
  // ------------------------------------
  // Actions
  // ------------------------------------
  export function getTaskList(value) {
    return {
      type: GET_ALL_TASK_LIST,
      payload: value
    };
  }
  
  export const actions = {
    getTaskList,
  };
  