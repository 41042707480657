import { CommonFlex, PageTitle } from "../../../styledComponents/common";
import { PrimaryButton } from "styledComponents/buttons";

import NoDataComponent from "components/NoDataComponent/NoDataComponent";
import { useEffect, useState } from "react";
import CustomReportListTable from "./CustomReportListTable";
import { Container } from "styledComponents/createProject";
import ComponentCircleLoader from "components/Loaders/ComponentCircleLoader";
const CustomReport = ({
  history,
  organization,
  customReportList,
  getCustomReportList,
  deleteCustomReports,
  isLoading,
  isDeleteLoading,
}) => {
  const [selectedReports, setSelectedReports] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("user_id") && organization?.id)
      getCustomReportList({
        organization_id: organization.id,
        user_id: localStorage.getItem("user_id"),
      });
  }, [organization]);
  return (
    <div className="content">
      <CommonFlex justifyContent="space-between">
        <PageTitle>Custom Report</PageTitle>
        <PrimaryButton
          fontSize="14px"
          style={{ marginTop: `auto` }}
          onClick={() => {
            history?.push("/user/reports/create-custom-report");
          }}
        >
          Create
        </PrimaryButton>
      </CommonFlex>
      <Container
        style={{
          padding: `0px`,
          marginTop: `20px`,
          background: `#FCFDFE`,
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {customReportList?.length > 0 ? (
              <CustomReportListTable
                data={customReportList}
                selectedReports={selectedReports}
                setSelectedReports={setSelectedReports}
                deleteCustomReports={deleteCustomReports}
                organization={organization}
                history={history}
                isLoading={isDeleteLoading}
              />
            ) : (
              <>
                <NoDataComponent title="Seems like you haven’t experience custom report yet. Try now!" />
                <CommonFlex justifyContent="center" margin="0 0 40px 0">
                  <PrimaryButton
                    fontSize="14px"
                    style={{ marginTop: `auto` }}
                    onClick={() => {
                      history?.push("/user/reports/create-custom-report");
                    }}
                  >
                    Create
                  </PrimaryButton>
                </CommonFlex>
              </>
            )}{" "}
          </>
        )}
      </Container>
    </div>
  );
};

export default CustomReport;
