import { useMemo, useState } from "react";

import Select from "../../ReactSelectDropdown";
import FilterDropDownStyle from "../../DropdownStyle/FilterDropDownStyle";

import {
  TopPaginationContainer,
  DisplayCountTextSection,
  TopPaginationSection,
  PaginationButton,
} from "../paginationStyles";

import { CommonText, CommonImage } from "../../../styledComponents/common";

import arrowIcon from "../../../assets/img/icons/arrow-right-black.svg";

const TopPagination = (props) => {
  const {
    totalCount,
    currentPage,
    onPageChange,
    pageSize,
    itemName,
    padding,
    margin,
  } = props;

  const totalPages = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    return totalPageCount;
  }, [totalCount, pageSize]);

  const pageOptions = useMemo(() => {
    const length = totalPages;

    return Array.from({ length }, (_, idx) => {
      return { label: idx + 1, value: idx + 1 };
    });
  }, [totalPages]);

  if (currentPage === 0 || totalPages < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <TopPaginationContainer padding={padding} margin={margin}>
      <DisplayCountTextSection>
        <CommonText>
          Displaying {(currentPage - 1) * pageSize + 1}-
          {currentPage * pageSize < totalCount
            ? currentPage * pageSize
            : totalCount}{" "}
          of {totalCount} {itemName}
        </CommonText>
      </DisplayCountTextSection>
      <TopPaginationSection>
        <PaginationButton
          onClick={onPrevious}
          previous
          disabled={currentPage === 1}
          size="28px"
        >
          <CommonImage src={arrowIcon} alt="previous" />
        </PaginationButton>
        <CommonText $label fontWeight="500">
          Showing page
        </CommonText>
        <Select
          isSearchable
          value={{ label: currentPage, value: currentPage }}
          options={pageOptions}
          onChange={(e) => {
            if (e.value !== currentPage) {
              onPageChange(e.value);
            }
          }}
          styles={FilterDropDownStyle({
            height: "28px",
            width: "50px",
            menuWidth: "50px",
            indicatorWidth: "32px",
            valuePadding: "0 8px",
          })}
          indicatorIconSize="18px"
        />
        <CommonText $label fontWeight="500">
          of {totalPages}
        </CommonText>
        <PaginationButton
          onClick={onNext}
          disabled={currentPage === totalPages}
          size="28px"
        >
          <CommonImage src={arrowIcon} alt="next" />
        </PaginationButton>
      </TopPaginationSection>
    </TopPaginationContainer>
  );
};

export default TopPagination;
