import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";

import {
  Container,
  TableText,
  RoundMiniImage,
  CommonText,
  CommonFlex,
} from "../../../styledComponents/common";
import { TableItem } from "../../../styledComponents/teams";
import {
  InstantScreenshotsTableHeader,
  InstantScreenshotTableRow,
  InstantScreenshotStatus,
  InstantScreenshotThumbnail,
} from "./instantScreenshotsStyles";

import PhotoItem from "./PhotoItem";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import NoImage from "../../../assets/img/instant-screenshot-missing.svg";
import monitor from "../../../assets/img/icons/monitor.svg";

// export const UserSection = (props) => {
//   return (
//     <AssigneesManageImgTextGrid>
//       <AssigneesManageRoundImage
//         imageSize={props.size}
//         style={{ position: "relative" }}
//       >
//         <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
//         <UserDotDiv>
//           <UserDotCircle />
//         </UserDotDiv>
//       </AssigneesManageRoundImage>
//       <TableText name>{props.name}</TableText>
//     </AssigneesManageImgTextGrid>
//   );
// };

const ScreenshotsList = (props) => {
  const [list, setList] = useState([]);

  const { screenshotsList, isLoading, selectedOrganization } = props;

  useEffect(() => {
    if (screenshotsList && screenshotsList.length > 0) {
      const tempList = [];
      screenshotsList.forEach((screenshot) => {
        tempList.push({
          id: screenshot.id,
          member_name: `${screenshot.for_user.first_name}${
            screenshot.for_user.last_name
              ? ` ${screenshot.for_user.last_name}`
              : ``
          }`,
          requested_by: `${screenshot.requested_by.first_name}${
            screenshot.requested_by.last_name
              ? ` ${screenshot.requested_by.last_name}`
              : ""
          }`,
          status: screenshot.status,
          screenshots: screenshot.screenshots,
          taken_time: moment.unix(screenshot.timestamp).format("LT"),
        });
      });
      setList(tempList);
    } else if (screenshotsList && screenshotsList.length === 0) {
      setList([]);
    }
  }, [screenshotsList]);

  return (
    <Container style={{ paddingBottom: "20px" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : list && list.length > 0 ? (
        <>
          <InstantScreenshotsTableHeader>
            <TableItem>
              <TableText>Screenshot</TableText>
            </TableItem>

            <TableItem>
              <TableText>Member Name</TableText>
            </TableItem>
            <TableItem>
              <TableText>Screenshot Taken Time</TableText>
            </TableItem>
            <TableItem>
              <TableText>Status</TableText>
            </TableItem>
          </InstantScreenshotsTableHeader>
          <LightgalleryProvider
            lightgallerySettings={{
              width: "100%",
              height: "100%",
              hideControlOnEnd: true,
              loop: true,
              download: false,
              hideBarsDelay: 1000,
              mousewheel: true,
            }}
          >
            {list.map((screenshotData, index) => (
              <InstantScreenshotTableRow key={index}>
                <TableItem>
                  {/* <UserSection
                  imgSource={trackingData.avatar || avatar5}
                  name={trackingData.name}
                  size="36px"
                /> */}
                  <CommonFlex gap="10px">
                    <PhotoItem group="group" item={screenshotData} />
                    {screenshotData.screenshots &&
                      screenshotData.screenshots.length > 0 && (
                        <CommonFlex gap="3px">
                          <img src={monitor} alt="" width="16px" />
                          <CommonText fontSize="12px" name>
                            {` *${screenshotData.screenshots.length}`}
                          </CommonText>
                        </CommonFlex>
                      )}
                  </CommonFlex>
                </TableItem>
                <TableItem>
                  <TableText name>{screenshotData.member_name}</TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {screenshotData.taken_time &&
                    screenshotData.status === "responded"
                      ? screenshotData.taken_time
                      : "-"}
                  </TableText>
                </TableItem>
                <TableItem>
                  <InstantScreenshotStatus status={screenshotData.status}>
                    {screenshotData.status}
                  </InstantScreenshotStatus>
                </TableItem>
              </InstantScreenshotTableRow>
            ))}
          </LightgalleryProvider>
        </>
      ) : (
        <NoDataComponent title={"No instant screenshot requests found"} />
      )}
    </Container>
  );
};

export default ScreenshotsList;
