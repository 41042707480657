import BackButtonComponent from "components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "components/Loaders/ComponentCircleLoader";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  CardTitle,
  CommonFlex,
  CommonGrid,
  CommonText,
  ContentHeader,
  PageTitle,
} from "styledComponents/common";
import { Container } from "styledComponents/createProject";
import CustomReportTable from "./CustomReportTable";
import { ActivityTextSection } from "styledComponents/members";
import { ColoredButton, WhiteButton } from "styledComponents/buttons";
import ReportDeleteModal from "./ReportDeleteModal";
import ExportModal from "custom_modules/ReportsNew/ExportModal";
import NoDataComponent from "components/NoDataComponent/NoDataComponent";

const CustomReportDetails = ({
  history,
  customReportDetails,
  getCustomReportDetails,
  organization,
  isLoading,
  deleteCustomReports,
  isDeleteLoading,
  deletedReports,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const reportId = new URLSearchParams(useLocation().search).get("id");

  const handleDeleteReport = () => {
    deleteCustomReports({
      organization_id: organization.id,
      report_ids: [reportId],
    });
  };
  const popupToggle = () => {
    setExportModalOpen(!exportModalOpen);
  };
  useEffect(() => {
    if (organization?.id && reportId)
      getCustomReportDetails({
        organization_id: organization.id,
        report_id: reportId,
      });
  }, [organization]);
  const [processedReport, setProcessedReport] = useState(null);
  const [processedReportSums, setProcessedReportSums] = useState({});

  useEffect(() => {
    if (deletedReports?.includes(reportId)) {
      history.push("/user/reports/custom-report");
      setIsDeleteModalVisible(false);
    }
  }, [deletedReports]);

  useEffect(() => {
    if (
      customReportDetails &&
      customReportDetails.timesheets &&
      customReportDetails.timesheets.length > 0
    ) {
      const processReport = () => {
        const userMap = new Map();
        customReportDetails.timesheets.forEach((timesheet) => {
          if (!userMap.has(timesheet.user_id)) {
            userMap.set(timesheet.user_id, {
              userId: timesheet.user_id,
              memberName: timesheet.member_name,
              memberEmail: timesheet.member_email,
              memberRole: timesheet.role,
              memberColor: timesheet.member_color,
              avatar: timesheet.avatar,
              totalTimeWorked: 0,
              idleTime: 0,
              averageActivity: 0,
              cumulativeAverageActivity: 0,
              manualTime: 0,
              projectList: new Set(),
              notes: [],
            });
          }

          const userData = userMap.get(timesheet.user_id);
          userData.totalTimeWorked +=
            timesheet.end_timestamp - timesheet.start_timestamp;
          userData.idleTime +=
            timesheet.idle_times?.reduce(
              (total, idle) =>
                total + idle.end_timestamp - idle.start_timestamp,
              0
            ) || 0;

          userData.manualTime +=
            timesheet.manual_times?.reduce(
              (total, manual) => total + manual.duration,
              0
            ) || 0;

          userData.projectList.add(timesheet.project_name);

          if (timesheet.notes.length > 0) {
            userData.notes = timesheet.notes.map((note) => note.content);
          }
          if (timesheet.activities && timesheet.activities.length > 0) {
            userData.cumulativeAverageActivity += timesheet.activities.reduce(
              (sum, activity) =>
                sum +
                activity.activity *
                  (activity.end_timestamp - activity.start_timestamp),
              0
            );
          }
        });

        return Array.from(userMap.values())
          .map((userData) => ({
            ...userData,
            projects: Array.from(userData.projectList).join(", "),
            idleTimePercentage: (
              (userData.idleTime / userData.totalTimeWorked) *
              100
            ).toFixed(2),
            manualTimePercentage: (
              (userData.manualTime / userData.totalTimeWorked) *
              100
            ).toFixed(2),
            activeTime: userData.cumulativeAverageActivity / 100,
            neutralTime:
              userData.totalTimeWorked -
              userData.cumulativeAverageActivity / 100,
            averageActivity: (
              userData.cumulativeAverageActivity / userData.totalTimeWorked
            ).toFixed(2),
          }))
          .sort((a, b) => a.memberName.localeCompare(b.memberName));
      };

      setProcessedReport(processReport());
    }
  }, [customReportDetails]);

  useEffect(() => {
    if (processedReport?.length > 0) {
      let totalNotes = 0;
      let totalTime = 0;
      let totalIdleTime = 0;
      let totalManualTime = 0;
      let totalNeutralTime = 0;
      let totalActiveTime = 0;
      const allProjects = new Set();

      processedReport.forEach((report) => {
        totalTime += report.totalTimeWorked;
        totalIdleTime += report.idleTime;
        totalManualTime += report.manualTime;
        totalNeutralTime += report.neutralTime;
        totalActiveTime += report.activeTime;
        report.projectList.forEach((project) => {
          allProjects.add(project);
        });
        totalNotes += report.notes.length ? report.notes.length : 0;
      });
      setProcessedReportSums({
        totalTime,
        totalNotes,
        totalIdleTime,
        totalManualTime,
        uniqueProjects: Array.from(allProjects).length,
        totalActiveTime,
        totalNeutralTime,
        manualTimePercentage: ((totalManualTime / totalTime) * 100).toFixed(2),
        idleTimePercentage: ((totalIdleTime / totalTime) * 100).toFixed(2),
      });
    } else setProcessedReportSums({});
  }, [processedReport]);

  return (
    <div className="content">
      <PageTitle>Custom Report Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          onClick={() => history.push("/user/reports/custom-report")}
          subTitle="Back to Custom Reports"
        />
      </ContentHeader>
      <Container
        style={{
          padding: `20px`,
          marginTop: `20px`,
          background: `#FCFDFE`,
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {customReportDetails ? (
              <>
                <ExportModal
                  isOpen={exportModalOpen}
                  toggle={popupToggle}
                  title="customReport"
                  reportName={customReportDetails?.name}
                  startDate={customReportDetails?.start_date}
                  endDate={customReportDetails?.end_date}
                  customReportData={processedReport}
                  customReportSums={processedReportSums}
                />
                <ReportDeleteModal
                  isOpen={isDeleteModalVisible}
                  toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
                  handleDelete={handleDeleteReport}
                  isLoading={isDeleteLoading}
                />
                <CommonGrid alignItem="center">
                  <ActivityTextSection
                    gap="5px"
                    style={{ alignContent: `start` }}
                  >
                    <CommonFlex>
                      {" "}
                      <CardTitle>{customReportDetails?.name}</CardTitle>
                      <CommonText>
                        {" "}
                        {moment(customReportDetails?.start_date).format(
                          "D MMMM, YYYY"
                        )}
                        {" - "}
                        {moment(customReportDetails?.end_date).format(
                          "D MMMM, YYYY"
                        )}
                      </CommonText>{" "}
                    </CommonFlex>
                  </ActivityTextSection>
                  <CommonGrid>
                    {processedReport && (
                      <ColoredButton type="gray" onClick={() => popupToggle()}>
                        Export
                      </ColoredButton>
                    )}
                    <WhiteButton
                      type="delete"
                      // height="40px"
                      // width="40px"
                      padding="0 20px"
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      Delete
                    </WhiteButton>
                  </CommonGrid>
                </CommonGrid>
                {customReportDetails?.timesheets?.length > 0 ? (
                  <CustomReportTable
                    report={processedReport}
                    features={customReportDetails?.features}
                  />
                ) : (
                  <NoDataComponent title="No Data In Selected Date Range" />
                )}
              </>
            ) : (
              <NoDataComponent title="Report not found" />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default CustomReportDetails;
