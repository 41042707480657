import { connect } from "react-redux";

// Component
import UpgradePlan from "./UpgradePlan";

// Actions
import {
  updateOrganizationSubscription,
  clearSubscriptionUpdated,
  clearCurrentSubscription,
} from "../subscriptionActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  updateSubscriptionIsLoading: state.subscription.updateSubscriptionIsLoading,
  selectedOrganization: state.organization.selectedOrganization,
  subscriptionUpdated: state.subscription.subscriptionUpdated,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  updateOrganizationSubscription: (payload) =>
    dispatch(updateOrganizationSubscription(payload)),
  clearSubscriptionUpdated: () => dispatch(clearSubscriptionUpdated()),
  clearCurrentSubscription: () => dispatch(clearCurrentSubscription()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(UpgradePlan);
