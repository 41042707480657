import { useState, useEffect, useRef } from "react";
import queryString from "query-string";

import api from "../../utils/api";

import {
  AuthPageContainer,
  AuthPageRightContainer,
  AuthCard,
  LoginTabContainer,
  LoginActiveTab,
  LoginInactiveTab,
  AuthForm,
  AuthFormAlert,
  AuthInputContainer,
  ShowPasswordIcon,
  ForgotPasswordDiv,
  ForgotPasswordText,
  AuthSubmitButton,
  GoogleSignInButton,
  GoogleIcon,
  GoogleButtonText,
} from "../../styledComponents/authLayout";

import { LoginLoaderContainer } from "../../styledComponents/login";

import TextInput from "../../components/FloatingLabelInput/FloatingLabelInput";

import AuthPageLeftSection from "../../components/AuthLayout/AuthPageLeftSection";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import LoginLoader from "./LoginLoader";

import GoogleLogo from "../../assets/img/icons/google_logo.svg";
import viewPassword from "../../assets/img/common/password_show.svg";
import hidePassword from "../../assets/img/common/password_hide.svg";

const Login = ({ login, history, location, auth, loginRedirectReset }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [saveErrors, setSaveErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const [redirectLocation, setRedirectLocation] = useState(null);
  const [fromLocation, setFromLocation] = useState(null);
  const [isLoginLoaderOpen, setIsLoginLoaderOpen] = useState(false);
  const passwordFieldRef = useRef(null);
  const methods = {
    setEmail,
    setPassword,
  };
  useEffect(() => {
    let token = localStorage.getItem("access_token");
    let redirect;
    if (location.search) {
      redirect = queryString.parse(location.search);
      setRedirectLocation(redirect);
    }
    if (token != null) {
      setIsLoginLoaderOpen(true);
      if (redirect && redirect.redirect !== null) {
        if (redirect.redirect === "appsumo") {
          history.push(`/appsumo`);
        } else {
          history.push(`/user/${redirect.redirect}`);
        }
      } else {
        if (window.innerWidth < 992) {
          document.documentElement.classList.remove("nav-open");
        }
        api({
          url: `onboarding/check/`,
          method: "GET",
        })
          .then((response) => {
            // console.log(response);
            if (response.status === 200) {
              if (response.data.show_onboard) {
                history.push("/setup/add-or-join");
              } else {
                history.push("/user/dashboard");
              }
            } else if (response.status === 401) {
              localStorage.clear();
              history.push("/auth/login");
            }
            setIsLoginLoaderOpen(false);
          })
          .catch((err) => {
            // console.log(err);
            history.push("/user/dashboard");
            setIsLoginLoaderOpen(false);
          });
      }
    }
    if (location.state && location.state.from) {
      setFromLocation(location.state.from);
    }
    return () => {
      //document.body.classList.toggle("login-page");
      setFromLocation(null);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Moving cursor to the end
    passwordFieldRef.current.selectionStart =
      passwordFieldRef.current.value.length;
    passwordFieldRef.current.selectionEnd =
      passwordFieldRef.current.value.length;
  }, [showPassword]);

  useEffect(() => {
    const saveErrors = {};
    if (auth.errorMessage) {
      if (
        auth.errorMessage === "Account with this email/username does not exists"
      ) {
        saveErrors["email"] = "Couldn't find your account!";
      } else if (
        auth.errorMessage === "Unable to log in with provided credentials."
      ) {
        saveErrors["password"] = "Wrong password!";
      }
      if (auth.errorMessage === "provided email address is not verified") {
        saveErrors["email"] = "Provided email address is not verified!";
      }
    }
    setSaveErrors(saveErrors);
    if (auth.redirect) {
      if (auth.notVerified) {
        const params = `?email=${auth.auth.email}&name=${auth.auth.user_profile.first_name}`;
        history.push({
          pathname: "/auth/verify-code",
          search: params,
          state: { from: 'login' },
        });
        loginRedirectReset();
      } else if (auth.auth.user_profile !== "undefined") {
        localStorage.setItem("access_token", auth.auth.token);
        localStorage.setItem("user_id", auth.auth.user_id);
        localStorage.setItem("user_profile", auth.auth.user_profile.id);
        localStorage.setItem("user_profile_color", auth.auth.user_profile.color);
        localStorage.setItem(
          "user_fullName",
          `${auth.auth.user_profile.first_name}${auth.auth.user_profile.last_name
            ? ` ${auth.auth.user_profile.last_name}`
            : ``
          }`
        );
        localStorage.setItem("email", auth.auth.email);
        localStorage.setItem("created_at", auth.auth.created_at);

        if (
          auth.auth.user_profile.timezone &&
          auth.auth.user_profile.timezone.tz
        ) {
          localStorage.setItem(
            "user_timezone",
            JSON.stringify(auth.auth.user_profile.timezone)
          );
        }

        if (auth.auth.organizations) {
          // eslint-disable-next-line
          auth.auth.organizations.map((organization) => {
            if (
              organization.role === "owner" &&
              organization.is_active === true
            ) {
              localStorage.setItem("is_owner", true);
            }
          });
        }

        if (auth.auth.show_onboard) {
          history.push("/setup/add-or-join");
        } else {
          if (
            redirectLocation &&
            redirectLocation !== null &&
            redirectLocation.redirect !== null
          ) {
            if (redirectLocation.redirect === "appsumo") {
              history.push(`/appsumo`);
            } else {
              history.push(`/user/${redirectLocation.redirect}`);
            }
          } else {
            if (window.innerWidth < 992) {
              document.documentElement.classList.remove("nav-open");
            }
            history.push("/user/dashboard");
          }
        }
        loginRedirectReset();
      } else {
        history.push("/user/user-profile");
        loginRedirectReset();
      }
    }
  }, [auth, history, loginRedirectReset]);

  const onTextChange = (e) => {
    const { name, value } = e.target;
    setSaveErrors({});
    methods[name](value);
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    if (!email || !password) {
      if (!email) {
        formIsValid = false;
        saveErrors["email"] = "Email Required";
      }
      if (!password) {
        formIsValid = false;
        saveErrors["password"] = "Password Required";
      }
    }
    setSaveErrors(saveErrors);
    return formIsValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (checkError()) {
      let payload = {
        username: email,
        password,
      };
      login(payload);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
    passwordFieldRef.current.focus();
  };

  if (isLoginLoaderOpen) {
    return (
      <LoginLoaderContainer>
        <LoginLoader />
      </LoginLoaderContainer>
    );
  } else {
    return (
      <AuthPageContainer>
        <AuthPageLeftSection />
        <AuthPageRightContainer>
          <AuthCard from={fromLocation}>
            <LoginTabContainer>
              <LoginActiveTab>Sign In</LoginActiveTab>
              <LoginInactiveTab to="/auth/register">Sign Up</LoginInactiveTab>
            </LoginTabContainer>
            <AuthForm onSubmit={(e) => handleLogin(e)}>
              <AuthInputContainer>
                <TextInput
                  id="email"
                  name="setEmail"
                  label="Email"
                  type="text"
                  onChange={(e) => onTextChange(e)}
                  value={email}
                  autoFocus
                />
              </AuthInputContainer>
              <AuthInputContainer>
                <TextInput
                  refs={passwordFieldRef}
                  id="password"
                  name="setPassword"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => onTextChange(e)}
                  onFocus={() => setPasswordIsFocused(true)}
                  onBlur={() => setPasswordIsFocused(false)}
                  value={password}
                />
                <ShowPasswordIcon
                  src={showPassword ? hidePassword : viewPassword}
                  alt=""
                  active={passwordIsFocused}
                  onClick={(e) => toggleShowPassword(e)}
                />
              </AuthInputContainer>
              <ForgotPasswordDiv
                alert={
                  saveErrors &&
                  saveErrors !== {} &&
                  (saveErrors.email || saveErrors.password)
                }
              >
                {saveErrors &&
                  saveErrors !== {} &&
                  (saveErrors.email || saveErrors.password) && (
                    <AuthFormAlert>
                      {saveErrors.email
                        ? saveErrors.email
                        : saveErrors.password}
                    </AuthFormAlert>
                  )}
                <ForgotPasswordText to="/auth/forgot-password">
                  Forgot Password?
                </ForgotPasswordText>
              </ForgotPasswordDiv>
              <AuthSubmitButton
                type="submit"
                disabled={!email || !password}
                isDisabled={!email || !password}
              >
                {auth.isLoading ? (
                  <ButtonTextLoader loadingText="Logging You In" />
                ) : (
                  "Let Me In"
                )}
              </AuthSubmitButton>
              {/* <GoogleSignInButton>
              <GoogleIcon src={GoogleLogo} alt="" />
              <GoogleButtonText>Sign In With Google</GoogleButtonText>
            </GoogleSignInButton> */}
            </AuthForm>
          </AuthCard>
        </AuthPageRightContainer>
      </AuthPageContainer>
    );
  }
};

export default Login;
