import { useState, useEffect, forwardRef, createRef } from "react";
import useDynamicRefs from "use-dynamic-refs";

import {
  AddProjectContainer,
  OnboardingForm,
  OnboardingFormAlert,
  OnboardingInputContainer,
  OnboardingInputLabel,
  InputRequiredStar,
  InputSubLabel,
  OnboardingInputWithCrossButton,
  InputCrossIconDiv,
  InputCrossIcon,
  OnboardingInput,
  OnboardingNextButton,
  NextButtonText,
  NextButtonIcon,
  AddMoreProjectsDiv,
  PlusIcon,
  AddMoreProjectsText,
} from "../onboardingStepsStyles";

import PreviousButton from "./PreviousButton";

import nextIcon from "../../../../assets/img/onboardingImg/right_arrow_green.svg";
import plusIcon from "../../../../assets/img/icons/plus-2.svg";
import crossIcon from "../../../../assets/img/icons/cross_black.svg";

const AddProject = forwardRef((props, ref) => {
  const [saveErrors, setSaveErrors] = useState({});
  const [projectNames, setProjectNames] = useState([
    {
      value: "Management",
      error: false,
    },
    { value: "", error: false },
  ]);
  const [getRef, setRef] = useDynamicRefs();

  useEffect(() => {
    if (props.activeStep === "project") {
      setTimeout(() => {
        if (
          getRef(`input-${projectNames.length - 1}`) &&
          getRef(`input-${projectNames.length - 1}`).current
        ) {
          getRef(`input-${projectNames.length - 1}`).current.focus();
        }
      }, 450);
    }
  }, [props.activeStep]);

  const handleProjectNameChange = (i, event) => {
    const names = [...projectNames];
    const { value } = event.target;
    setSaveErrors({});
    if (value === null || value === "") {
      names[i].error = true;
    } else {
      names[i].error = false;
    }
    names[i].value = value;
    setProjectNames(names);
  };

  const handleProjectNameAdd = () => {
    const names = [...projectNames];
    let invalidProjectName = false;
    let nameLengthError = false;
    setSaveErrors({});
    // eslint-disable-next-line
    names.map((projectName) => {
      if (!projectName.value || projectName.error) {
        invalidProjectName = true;
      } else if (projectName.value.length < 3) {
        nameLengthError = true;
      }
    });
    if (invalidProjectName) {
      setSaveErrors({
        project:
          "Invalid or empty project names exist. Please discard them first.",
      });
    } else if (nameLengthError) {
      setSaveErrors({
        project: "Project name must contain at least 3 characters.",
      });
    } else {
      names.push({ value: "", error: false });
      setProjectNames(names);
      setTimeout(() => {
        if (
          getRef(`input-${projectNames.length}`) &&
          getRef(`input-${projectNames.length}`).current
        ) {
          getRef(`input-${projectNames.length}`).current.focus();
        }
      }, 100);
    }
  };

  const handleProjectNameRemove = (i) => {
    const names = [...projectNames];
    setSaveErrors({});
    if (names.length > 1) {
      names.splice(i, 1);
      setProjectNames(names);
    } else {
      setSaveErrors({
        project: "There must be at least one project name.",
      });
    }
  };

  const checkError = () => {
    let saveErrors = {};
    let formIsValid = true;
    const names = [...projectNames];
    if (
      (props.activeStep && props.activeStep === "organization") ||
      !props.organization
    ) {
      props.handleBack("project");
      return false;
    } else {
      if (names && names.length === 1 && !names[0].value) {
        saveErrors["project"] = "There must be at least one project name.";
        formIsValid = false;
      } else {
        const count = {};
        // eslint-disable-next-line
        names.map((projectName, index) => {
          if (projectName.error) {
            saveErrors["project"] =
              "Invalid or empty project names exist. Please discard them first.";
            formIsValid = false;
          } else if (projectName.value && projectName.value.length < 3) {
            saveErrors["project"] =
              "Project name must contain at least 3 characters.";
            formIsValid = false;
          } else {
            if (count[projectName.value]) {
              count[projectName.value] += 1;
              return;
            }
            count[projectName.value] = 1;
          }
        });
        if (count && count !== {}) {
          let duplicate = false;
          Object.keys(count).map((item) => {
            if (count[item] > 1) {
              duplicate = true;
            }
          });
          if (duplicate) {
            saveErrors["project"] =
              "Duplicate project names exist. Please discard one.";
            formIsValid = false;
          }
        }
      }

      setSaveErrors(saveErrors);
      return formIsValid;
    }
  };

  const handleNext = () => {
    if (checkError() && projectNames && projectNames.length > 0) {
      const projects = [];
      projectNames.map((name) => {
        if (name.value) {
          projects.push({ name: name.value });
        }
      });
      props.setProjectsData(projects);
      if (props.activeStep === "project") {
        props.handleNext("project");
      } else if (props.activeStep === "pricing") {
        if (props.organization && props.organization.plan_id) {
          props.handleNext("pricing");
        } else {
          props.handleNext("project");
        }
      } else if (props.activeStep === "invite-member") {
        props.handleNext("pricing");
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleNext();
    }
  };
  return (
    <AddProjectContainer ref={ref}>
      <PreviousButton handleClick={props.handleBack} type="project" />
      <OnboardingForm onKeyDown={handleKeyPress}>
        <OnboardingInputContainer>
          <OnboardingInputLabel>
            Let's create your first project.{" "}
            <InputRequiredStar>*</InputRequiredStar>
            <InputSubLabel>Give it a short name</InputSubLabel>
          </OnboardingInputLabel>
          {projectNames &&
            projectNames.length > 0 &&
            projectNames.map((name, index) => (
              <OnboardingInputWithCrossButton key={index}>
                <OnboardingInput
                  placeholder="example: Marketing Automation"
                  type="text"
                  ref={setRef(`input-${index}`)}
                  value={name.value}
                  onChange={(e) => handleProjectNameChange(index, e)}
                  style={{ width: "85%" }}
                  alert={name.error}
                />
                {projectNames && projectNames.length > 1 && (
                  <InputCrossIconDiv
                    onClick={() => handleProjectNameRemove(index)}
                  >
                    <InputCrossIcon src={crossIcon} alt="" />
                  </InputCrossIconDiv>
                )}
              </OnboardingInputWithCrossButton>
            ))}
          {saveErrors && saveErrors !== {} && saveErrors.project && (
            <OnboardingFormAlert>{saveErrors.project}</OnboardingFormAlert>
          )}
        </OnboardingInputContainer>
        {!projectNames ||
          (projectNames && projectNames.length < 3 && (
            <AddMoreProjectsDiv
              onClick={() => {
                handleProjectNameAdd();
              }}
            >
              <PlusIcon src={plusIcon} alt="" />
              <AddMoreProjectsText>Add More Projects</AddMoreProjectsText>
            </AddMoreProjectsDiv>
          ))}
        <OnboardingNextButton onClick={() => handleNext()}>
          <NextButtonText>Next</NextButtonText>
          <NextButtonIcon src={nextIcon} alt="" />
        </OnboardingNextButton>
      </OnboardingForm>
    </AddProjectContainer>
  );
});

export default AddProject;
