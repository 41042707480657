import { useState, useEffect } from "react";
import GeneralDetails from "./GeneralDetails";
import AddPeoplePermissions from "./AddPeoplePermissions";
import AddBiilingHours from "./AddBillingsHours";
import AddTasks from "./AddTasksNew";
import {
  SaveAndProceedContainer,
  SaveAndProceedButton,
} from "../../../styledComponents/createProject";
import {
  Container,
  PageTitle,
  ContentHeader,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";

import queryString from "query-string";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import AccessDeniedComponent from "../../../components/AccessDenied/AccessDenied";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

const EditProject = (props) => {
  const [projectId, setProjectId] = useState(null);
  const [generalDetailsActive, setGeneralDetailsActive] = useState(true);
  const [addPeopleActive, setAddPeopleActive] = useState(false);
  const [billingsActive, setBillingsActive] = useState(false);
  const [addTasksActive, setAddTasksActive] = useState(false);

  const [currency, setCurrency] = useState("");

  useEffect(() => {
    return () => {
      props.clearEditedProject();
      props.clearProjectAccessDenied();
    };
  }, []);

  useEffect(() => {
    if (
      props.selectedOrganization &&
      props.selectedOrganization.configuration &&
      props.selectedOrganization.configuration.currency
    ) {
      setCurrency(props.selectedOrganization.configuration.currency);
    }
  }, [props.selectedOrganization]);

  const toggleGeneralDetails = () => {
    setGeneralDetailsActive(!generalDetailsActive);
    setAddPeopleActive(false);
    setBillingsActive(false);
    setAddTasksActive(false);
  };
  const toggleAddPeople = () => {
    setAddPeopleActive(!addPeopleActive);
    // setGeneralDetailsActive(false);
    setBillingsActive(false);
    setAddTasksActive(false);
  };
  const toggleBillings = () => {
    setBillingsActive(!billingsActive);
    // setGeneralDetailsActive(false);
    setAddPeopleActive(false);
    setAddTasksActive(false);
  };
  const toggleAddTasks = () => {
    setAddTasksActive(!addTasksActive);
    // setGeneralDetailsActive(false);
    setAddPeopleActive(false);
    setBillingsActive(false);
  };

  useEffect(() => {
    if (props.selectedOrganization && props.selectedOrganization.id) {
      if (props.location.search) {
        const queryParsed = queryString.parse(props.location.search);
        if (queryParsed.id) {
          setProjectId(queryParsed.id);
          let payload = {
            organization_id: props.selectedOrganization.id,
            project_id: queryParsed.id,
          };
          props.getProjectDetails(payload);
        }
      }
      let payload = {
        organization_id: props.selectedOrganization.id,
      };
      props.getOrganizationMembersList(payload);
      props.getTeamList(payload);
    }
  }, [props.selectedOrganization]);

  // close general details when project is saved
  useEffect(() => {
    if (props.generalDetailsUpdated) {
      setGeneralDetailsActive(false);
    }
  }, [props.generalDetailsUpdated]);

  return (
    <div className="content">
      <PageTitle>Projects</PageTitle>
      <ContentHeader margin="30px 0 0 0">
        <BackButtonComponent
          onClick={() => props.history.push("/user/projects")}
          subTitle="Back to all projects"
        />
      </ContentHeader>
      {props.accessDenied ? (
        <Container padding="20px 0">
          <AccessDeniedComponent history={props.history} />
        </Container>
      ) : (
        <Container padding="20px 0">
          {props.isLoading ? (
            <ComponentCircleLoader />
          ) : (
            <>
              <GeneralDetails
                updateProjectIsLoading={props.updateProjectIsLoading}
                projectDetails={props.projectDetails}
                updateProject={props.updateProject}
                selectedOrganization={props.selectedOrganization}
                getClientList={props.getClientList}
                clientList={props.clientList}
                history={props.history}
                generalDetailsActive={generalDetailsActive}
                toggleGeneralDetails={toggleGeneralDetails}
                toggleAddPeople={toggleAddPeople}
                generalDetailsUpdated={props.generalDetailsUpdated}
              />
              <AddPeoplePermissions
                getMembersList={props.getOrganizationMembersList}
                membersList={props.orgMembersList}
                getTeamList={props.getTeamList}
                teamList={props.teamList}
                projectId={projectId}
                selectedOrganization={props.selectedOrganization}
                getProjectAssigneeList={props.getProjectAssigneeList}
                projectAssigneeList={props.projectAssigneeList}
                addProjectAssignee={props.addProjectAssignee}
                updateProjectAssignee={props.updateProjectAssignee}
                projectAssigneeUpdated={props.projectAssigneeUpdated}
                updatedAssigneeData={props.updatedAssigneeData}
                deleteProjectAssignee={props.deleteProjectAssignee}
                addTeamToProject={props.addTeamToProject}
                addPeopleActive={addPeopleActive}
                toggleAddPeople={toggleAddPeople}
                projectAssigneeIsLoading={props.projectAssigneeIsLoading}
                loadingAssigneeId={props.loadingAssigneeId}
              />
              <AddBiilingHours
                getProjectAssigneeList={props.getProjectAssigneeList}
                projectAssigneeList={props.projectAssigneeList}
                selectedOrganization={props.selectedOrganization}
                currency={currency}
                getProjectBilling={props.getProjectBilling}
                projectBilling={props.projectBilling}
                getProjectBudget={props.getProjectBudget}
                projectBudget={props.projectBudget}
                projectId={projectId}
                addProjectBilling={props.addProjectBilling}
                updateProjectBilling={props.updateProjectBilling}
                clearProjectBilling={props.clearProjectBilling}
                addProjectBudget={props.addProjectBudget}
                updateProjectBudget={props.updateProjectBudget}
                clearProjectBudget={props.clearProjectBudget}
                setPaymentConfiguration={props.setPaymentConfiguration}
                billingsActive={billingsActive}
                setBillingsActive={setBillingsActive}
                toggleBillings={toggleBillings}
                toggleAddTasks={toggleAddTasks}
                getBudgetLoading={props.getBudgetLoading}
                getBillingLoading={props.getBillingLoading}
                saveBudgetLoading={props.saveBudgetLoading}
                saveBillingLoading={props.saveBillingLoading}
              />
              <AddTasks
                selectedOrganization={props.selectedOrganization}
                projectId={projectId}
                projectName={props.projectDetails && props.projectDetails.name}
                getProjectTaskList={props.getProjectTaskList}
                projectTaskList={props.projectTaskList}
                getProjectAssigneeList={props.getProjectAssigneeList}
                projectAssigneeList={props.projectAssigneeList}
                projectAssigneeIsLoading={props.projectAssigneeIsLoading}
                addTask={props.projectAddTask}
                editTask={props.projectEditTask}
                deleteTask={props.projectDeleteTask}
                addTaskAssignee={props.addTaskAssignee}
                deleteTaskAssignee={props.deleteTaskAssignee}
                addTasksActive={addTasksActive}
                toggleAddTasks={toggleAddTasks}
                taskListLoading={props.taskListLoading}
                taskIsLoading={props.taskIsLoading}
                loadingTaskId={props.loadingTaskId}
                taskAssigneeIsLoading={props.taskAssigneeIsLoading}
                loadingTaskAssigneeId={props.loadingTaskAssigneeId}
                // pagination
                taskCount={props.projectTaskCount}
                taskPageSize={props.projectTaskPageSize}
              />
              <SaveAndProceedContainer>
                <PrimaryButton
                  padding="0 25px"
                  onClick={() => props.history.push("/user/projects")}
                >
                  Save & Proceed
                </PrimaryButton>
              </SaveAndProceedContainer>
            </>
          )}
        </Container>
      )}
    </div>
  );
};

export default EditProject;
