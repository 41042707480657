import { useState, useEffect } from "react";

import {
  OrgSettingsSection,
  SettingPropertySection,
  ToggleButton,
  ToggleButtonRound,
} from "../timesheetSettingsStyles";
import {
  CardTitle,
  CommonText,
  Container,
  CommonGrid,
  CommonFlex,
} from "../../../../styledComponents/common";
import {
  WhiteButton,
  PrimaryButton,
} from "../../../../styledComponents/buttons";

import ComponentCircleLoader from "../../../../components/Loaders/ComponentCircleLoader";
import ButtonTextLoader from "../../../../components/Loaders/ButtonTextLoader";

import MembersTable from "./MembersTable";

const TimesheetApprovalSettings = (props) => {
  const [membersList, setMembersList] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    isLoading,
    orgApprovalTurnOnLoading,
    orgApprovalTurnOffLoading,
    history,
    selectedOrganization,

    getMemberwiseTimesheetApprovalSettings,
    memberwiseTimesheetApprovalSettings,
    updateMemberwiseTimesheetApprovalSettings,
    updateOrganizationTimesheetApprovalSettings,

    // pagination
    totalMembersCount,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = { organization_id: selectedOrganization.id };
      getMemberwiseTimesheetApprovalSettings(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (
      memberwiseTimesheetApprovalSettings &&
      memberwiseTimesheetApprovalSettings.length > 0
    ) {
      const members = [];
      memberwiseTimesheetApprovalSettings.forEach((settings) => {
        members.push({
          settings_id: settings.id,
          name: `${settings.user.first_name}${
            settings.user.last_name ? ` ${settings.user.last_name}` : ``
          }`,
          avatar: settings.user?.avatar ?? null,
          color: settings.user?.color ?? null,
          settings: settings.require_timesheet_approval,
        });
      });
      setMembersList(members);
    }
  }, [memberwiseTimesheetApprovalSettings]);

  const handleUpdateOrganizationTimesheetApproval = ({ turnOn }) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        require_timesheet_approval: turnOn,
      };
      updateOrganizationTimesheetApprovalSettings(payload);
    }
  };

  const handleUpdateMemberwiseTimesheetApproval = (member) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        settings_id: member.settings_id,
        require_timesheet_approval: !member.settings,
      };
      updateMemberwiseTimesheetApprovalSettings(payload);
    }
  };

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page,
      };

      getMemberwiseTimesheetApprovalSettings(payload);
    }
    setCurrentPageNumber(page);
  };

  return (
    <OrgSettingsSection padding="10px 0 30px">
      <CardTitle
        margin={
          selectedOrganization?.role &&
          selectedOrganization.role !== "owner" &&
          selectedOrganization.role !== "admin"
            ? "20px 30px 0"
            : "0 30px"
        }
      >
        Configure timesheet approval
      </CardTitle>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        <>
          {selectedOrganization?.role &&
          (selectedOrganization.role === "owner" ||
            selectedOrganization.role === "admin") ? (
            <Container padding="25px" style={{ margin: "20px 30px 40px" }}>
              <SettingPropertySection>
                <CommonGrid columns="45% 55%" gap="2em">
                  <CommonGrid columns="100%">
                    <CommonText name title margin="0">
                      Timesheet Approval
                    </CommonText>
                    <CommonText $label fontWeight="500" margin="0">
                      Turn ON/OFF timesheet approval for all members
                    </CommonText>
                  </CommonGrid>
                  {/* <CommonGrid
                  columns="36px 30px"
                  gap="10px"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() => handleUpdateOrganizationTimesheetApproval()}
                    active={isTimesheetApprovalOn}
                  >
                    <ToggleButtonRound active={isTimesheetApprovalOn} />
                  </ToggleButton>
                  <CommonText
                    $label={!isTimesheetApprovalOn}
                    fontWeight="500"
                    fontSize="14px"
                    margin="0"
                  >
                    {isTimesheetApprovalOn ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid> */}
                  <CommonFlex gap="15px">
                    <PrimaryButton
                      width="150px"
                      padding={orgApprovalTurnOnLoading && "0 15px"}
                      onClick={() =>
                        handleUpdateOrganizationTimesheetApproval({
                          turnOn: true,
                        })
                      }
                    >
                      {orgApprovalTurnOnLoading ? (
                        <ButtonTextLoader
                          loadingText="Turning On"
                          fontSize="13px"
                        />
                      ) : (
                        "Turn On"
                      )}
                    </PrimaryButton>
                    <WhiteButton
                      width="150px"
                      type="delete"
                      fontWeight="600"
                      padding={orgApprovalTurnOffLoading && "0 15px"}
                      onClick={() =>
                        handleUpdateOrganizationTimesheetApproval({
                          turnOn: false,
                        })
                      }
                    >
                      {orgApprovalTurnOffLoading ? (
                        <ButtonTextLoader
                          loadingText="Turning Off"
                          fontSize="13px"
                          color="#fe5969"
                        />
                      ) : (
                        "Turn Off"
                      )}
                    </WhiteButton>
                  </CommonFlex>
                </CommonGrid>
              </SettingPropertySection>
            </Container>
          ) : null}

          <MembersTable
            selectedOrganization={selectedOrganization}
            membersListLoading={isLoading}
            membersList={membersList}
            history={history}
            memberSettingsChange={handleUpdateMemberwiseTimesheetApproval}
            onPageChange={handlePageChange}
            totalMembersCount={totalMembersCount}
            currentPageNumber={currentPageNumber}
          />
        </>
      )}
    </OrgSettingsSection>
  );
};

export default TimesheetApprovalSettings;
