export const timeRangeList = [
  {
    id: 0,
    startTimeRange: `0:00`,
    endTimeRange: `1:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 1,
    startTimeRange: `1:00`,
    endTimeRange: `2:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 2,
    startTimeRange: `2:00`,
    endTimeRange: `3:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 3,
    startTimeRange: `3:00`,
    endTimeRange: `4:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 4,
    startTimeRange: `4:00`,
    endTimeRange: `5:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 5,
    startTimeRange: `5:00`,
    endTimeRange: `6:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 6,
    startTimeRange: `6:00`,
    endTimeRange: `7:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 7,
    startTimeRange: `7:00`,
    endTimeRange: `8:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 8,
    startTimeRange: `8:00`,
    endTimeRange: `9:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 9,
    startTimeRange: `9:00`,
    endTimeRange: `10:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 10,
    startTimeRange: `10:00`,
    endTimeRange: `11:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 11,
    startTimeRange: `11:00`,
    endTimeRange: `12:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 12,
    startTimeRange: `12:00`,
    endTimeRange: `13:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 13,
    startTimeRange: `13:00`,
    endTimeRange: `14:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 14,
    startTimeRange: `14:00`,
    endTimeRange: `15:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 15,
    startTimeRange: `15:00`,
    endTimeRange: `16:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 16,
    startTimeRange: `16:00`,
    endTimeRange: `17:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 17,
    startTimeRange: `17:00`,
    endTimeRange: `18:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 18,
    startTimeRange: `18:00`,
    endTimeRange: `19:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 19,
    startTimeRange: `19:00`,
    endTimeRange: `20:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 20,
    startTimeRange: `20:00`,
    endTimeRange: `21:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 21,
    startTimeRange: `21:00`,
    endTimeRange: `22:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 22,
    startTimeRange: `22:00`,
    endTimeRange: `23:00`,
    screenshots: [],
    loggedTime: "",
  },
  {
    id: 23,
    startTimeRange: `23:00`,
    endTimeRange: `24:00`,
    screenshots: [],
    loggedTime: "",
  },
];
