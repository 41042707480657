import { useState, useEffect } from "react";
import moment from "moment-timezone";
import {
  CommonFlex,
  CommonText,
  ColoredText,
  CommonImage,
  RoundMiniImage,
} from "../../../styledComponents/common";
import {
  TimesheetDataSectionContainer,
  UserDataContainer,
  SingleUserDataSection,
  ArrowIcon,
  UserDataCollapseSection,
  UserTimesheetDataSection,
  TimesheetDiv,
} from "./routeMapStyles";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import { toHHMMSS, getHours } from "../../../utils/helper";

import avatar5 from "../../../assets/img/avatar_5.svg";
import arrowDown from "../../../assets/img/Sidebar/sidebar-arrow-down.svg";

const TimesheetDataSection = (props) => {
  const {
    isLoading,
    timesheetsData,
    selectedMember,
    setSelectedMember,
    selectedTimesheet,
    setSelectedTimesheet,
  } = props;

  useEffect(() => {
    if (
      timesheetsData &&
      timesheetsData.timesheets &&
      timesheetsData.timesheets.length > 0
    ) {
      setSelectedTimesheet(timesheetsData.timesheets[0]);
    }
  }, [timesheetsData]);

  // const handleSelectMember = (member) => {
  //   if (member && member.id) {
  //     if (
  //       selectedMember &&
  //       selectedMember.id &&
  //       selectedMember.id === member.id
  //     ) {
  //       setSelectedMember(null);
  //     } else {
  //       setSelectedMember(member.user);
  //     }
  //   }
  // };

  const handleSelectTimesheet = (timesheet) => {
    if (timesheet && timesheet.id) {
      setSelectedTimesheet(timesheet);
    }
  };

  return (
    <TimesheetDataSectionContainer>
      <UserDataContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : timesheetsData && timesheetsData.userData ? (
          <SingleUserDataSection>
            <CommonFlex padding="8px 15px 0px" justifyContent="space-between">
              <CommonFlex gap="12px">
                <RoundMiniImage
                  src={timesheetsData.userData.avatar || avatar5}
                  alt=""
                  imageSize="32px"
                />
                <CommonText>{timesheetsData.userData.fullname}</CommonText>
              </CommonFlex>
            </CommonFlex>
            {timesheetsData.timesheets &&
            timesheetsData.timesheets.length > 0 ? (
              <UserTimesheetDataSection>
                <ColoredText
                  primary
                  fontSize="13px"
                  semibold
                  margin="0"
                  style={{ padding: "10px 0" }}
                >
                  Total Time: {getHours(toHHMMSS(timesheetsData.totalDuration))}
                </ColoredText>
                {timesheetsData.timesheets.map((timesheet) => {
                  return (
                    <TimesheetDiv
                      key={timesheet.id}
                      selected={
                        selectedTimesheet &&
                        selectedTimesheet.id === timesheet.id
                      }
                      onClick={() => handleSelectTimesheet(timesheet)}
                    >
                      <CommonText fontSize="13px">
                        {getHours(toHHMMSS(timesheet.duration))}
                      </CommonText>
                      <CommonText $label fontSize="13px" fontWeight="500">
                        {moment.unix(timesheet.startTimestamp).format("h:mm A")}{" "}
                        - {moment.unix(timesheet.endTimestamp).format("h:mm A")}
                      </CommonText>
                    </TimesheetDiv>
                  );
                })}
              </UserTimesheetDataSection>
            ) : (
              <NoDataComponent
                title="This user has no timesheet on this date!"
                padding="14vh 25px"
                imageWidth="150px"
                imageHeight="140px"
                titleFontSize="13px"
                textMarginTop="15px"
                textAlign="center"
              />
            )}
          </SingleUserDataSection>
        ) : (
          <NoDataComponent
            title="This user has no timesheet on this date!"
            padding="16vh 40px"
            imageWidth="150px"
            imageHeight="140px"
            titleFontSize="13px"
            textMarginTop="15px"
            textAlign="center"
          />
        )}
      </UserDataContainer>
    </TimesheetDataSectionContainer>
  );
};

export default TimesheetDataSection;
