import { connect } from "react-redux";

// Component
import Teams from "./Teams";

// Actions
import {
  getTeamList,
  addTeam,
  updateTeam,
  deleteTeam,
  addTeamMember,
  removeTeamMember,
} from "./teamsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.team.isLoading,
  deleteTeamLoading: state.team.deleteTeamLoading,
  selectedOrganization: state.organization.selectedOrganization,
  teamsList: state.team.teamsList,

  // pagination
  totalTeamsCount: state.team.totalTeamsCount,
  teamPageSize: state.team.teamPageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getTeamList: (details) => dispatch(getTeamList({ ...details })),
  addTeam: (details) => dispatch(addTeam({ ...details })),
  updateTeam: (details) => dispatch(updateTeam({ ...details })),
  deleteTeam: (details) => dispatch(deleteTeam({ ...details })),
  addTeamMember: (details) => dispatch(addTeamMember({ ...details })),
  removeTeamMember: (details) => dispatch(removeTeamMember(...details)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Teams);
