import { connect } from "react-redux";

// Component
import ActivityApps from "./ActivityApps";

import { getOrganizationMembersList } from "../../Projects/projectsActions";

// Actions
import { getAppsList } from "../activityActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  apps: state.activity.apps,
  isLoading: state.activity.isLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getAppsList: (payload) => dispatch(getAppsList(payload)),
  getOrganizationMembersList: (payload) =>
    dispatch(getOrganizationMembersList(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ActivityApps);
