import {
  GET_ORGANIZATION_SUBSCRIPTION,
  GET_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  GET_ORGANIZATION_SUBSCRIPTION_FAILURE,
  UPDATE_ORGANIZATION_SUBSCRIPTION,
  UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
  ACTIVATE_ORGANIZATION_SUBSCRIPTION,
  ACTIVATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
  ACTIVATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS,
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE,
  APPLY_COUPON,
  APPLY_COUPON_SUCCESS,
  APPLY_COUPON_FAILURE,
  CLEAR_CURRENT_SUBSCRIPTION,
  CLEAR_SUBSCRIPTION_UPDATED,
  CLEAR_PAYMENT_METHOD_UPDATED,
  CLEAR_DISCOUNT_INFO,
  CLEAR_DISCOUNT_ERROR_MESSAGE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";

import { createNotification } from "../../modules/notificationManager";

export const getOrganizationSubscriptionRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const getOrganizationSubscriptionRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    let discount = null;
    if (data.data.discount && data.data.organization) {
      discount = { ...data.data.discount };
      discount.organization_id = data.data.organization;
    }
    return {
      ...state,
      isLoading: false,
      currentSubscription: data.data,
      discountInfo: discount,
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const getOrganizationSubscriptionRequestFailureHandler = (
  state,
  action
) => {
  const {
    payload: { response },
  } = action;
  return {
    ...state,
    isLoading: false,
    currentSubscription: null,
  };
};

export const updateOrganizationSubscriptionRequestHandler = (state, action) => {
  return {
    ...state,
    updateSubscriptionIsLoading: true,
    subscriptionUpdated: false,
  };
};
export const updateOrganizationSubscriptionRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      updateSubscriptionIsLoading: false,
      currentSubscription: data.data,
      subscriptionUpdated: true,
    };
  }
  return {
    ...state,
    updateSubscriptionIsLoading: false,
  };
};
export const updateOrganizationSubscriptionRequestFailureHandler = (
  state,
  action
) => {
  const {
    payload: { response },
  } = action;
  createNotification(
    "error",
    "Could not process the request. Please try again!",
    3000
  );
  return {
    ...state,
    updateSubscriptionIsLoading: false,
    currentSubscription: null,
  };
};

export const updateSubscriptionPaymentMethodRequestHandler = (
  state,
  action
) => {
  return {
    ...state,
    updatePaymentMethodLoading: true,
  };
};
export const updateSubscriptionPaymentMethodRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      updatePaymentMethodLoading: false,
      updatePaymentMethodSuccess: true,
    };
  }
  return {
    ...state,
    updatePaymentMethodLoading: false,
    updatePaymentMethodSuccess: false,
  };
};
export const updateSubscriptionPaymentMethodRequestFailureHandler = (
  state,
  action
) => {
  return {
    ...state,
    updatePaymentMethodLoading: false,
    updatePaymentMethodSuccess: false,
  };
};

export const activateOrganizationSubscriptionRequestHandler = (
  state,
  action
) => {
  return {
    ...state,
    activateSubscriptionLoading: true,
  };
};
export const activateOrganizationSubscriptionRequestSuccessHandler = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    const subscription = JSON.parse(JSON.stringify(state.currentSubscription));
    if (subscription && subscription.id) {
      subscription.status = "active";
    }
    return {
      ...state,
      activateSubscriptionLoading: false,
      currentSubscription: subscription,
    };
  }

  return {
    ...state,
    activateSubscriptionLoading: false,
  };
};
export const activateOrganizationSubscriptionRequestFailureHandler = (
  state,
  action
) => {
  return {
    ...state,
    activateSubscriptionLoading: false,
  };
};

export const applyCouponRequestHandler = (state, action) => {
  return {
    ...state,
    applyCouponLoading: true,
  };
};
export const applyCouponRequestSuccessHandler = (state, action) => {
  const {
    payload: {
      data,
      selected_plan_id,
      selected_plan_interval,
      organization_id,
    },
  } = action;
  if (data.status === 201) {
    if (
      data.data &&
      data.data.plan &&
      selected_plan_id &&
      selected_plan_id !== data.data.plan.id
    ) {
      return {
        ...state,
        applyCouponLoading: false,
        discountInfo: null,
        discountErrorMessage: `This code does not belong to "${
          data.data.plan.name
        }${selected_plan_interval ? `-${selected_plan_interval}` : ""}" plan.`,
      };
    } else {
      return {
        ...state,
        applyCouponLoading: false,
        discountInfo: { ...data.data, organization_id },
        discountErrorMessage: "",
      };
    }
  }
  return {
    ...state,
    applyCouponLoading: false,
  };
};
export const applyCouponRequestFailureHandler = (state, action) => {
  const {
    payload: { response },
  } = action;

  if (
    response &&
    response.data &&
    response.data.codes &&
    response.data.codes.length > 0
  ) {
    let errorMessage = response.data.codes[0];
    if (
      errorMessage ===
      "cannot redeem percentage type of  platform coupon when there is an existing discount"
    ) {
      errorMessage = "You already have a discount in this organization.";
    } else if (errorMessage.indexOf("the coupon code:") !== -1) {
      errorMessage = "Invalid coupon code";
    }
    return {
      ...state,
      applyCouponLoading: false,
      discountInfo: null,
      discountErrorMessage: errorMessage,
    };
  }
  return {
    ...state,
    applyCouponLoading: false,
    discountInfo: null,
    discountErrorMessage: "Please Try Again!",
  };
};

export const clearCurrentSubscription = (state, action) => {
  return {
    ...state,
    currentSubscription: null,
  };
};

export const clearSubscriptionUpdated = (state, action) => {
  return {
    ...state,
    subscriptionUpdated: false,
  };
};
export const clearPaymentMethodUpdated = (state, action) => {
  return {
    ...state,
    updatePaymentMethodSuccess: false,
  };
};
export const clearDiscountInfo = (state, action) => {
  return {
    ...state,
    discountInfo: null,
    discountErrorMessage: "",
  };
};
export const clearDiscountErrorMessage = (state, action) => {
  return {
    ...state,
    discountErrorMessage: "",
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_ORGANIZATION_SUBSCRIPTION]: getOrganizationSubscriptionRequestHandler,
  [GET_ORGANIZATION_SUBSCRIPTION_SUCCESS]:
    getOrganizationSubscriptionRequestSuccessHandler,
  [GET_ORGANIZATION_SUBSCRIPTION_FAILURE]:
    getOrganizationSubscriptionRequestFailureHandler,

  [UPDATE_ORGANIZATION_SUBSCRIPTION]:
    updateOrganizationSubscriptionRequestHandler,
  [UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS]:
    updateOrganizationSubscriptionRequestSuccessHandler,
  [UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE]:
    updateOrganizationSubscriptionRequestFailureHandler,

  [UPDATE_SUBSCRIPTION_PAYMENT_METHOD]:
    updateSubscriptionPaymentMethodRequestHandler,
  [UPDATE_SUBSCRIPTION_PAYMENT_METHOD_SUCCESS]:
    updateSubscriptionPaymentMethodRequestSuccessHandler,
  [UPDATE_SUBSCRIPTION_PAYMENT_METHOD_FAILURE]:
    updateSubscriptionPaymentMethodRequestFailureHandler,

  [ACTIVATE_ORGANIZATION_SUBSCRIPTION]:
    activateOrganizationSubscriptionRequestHandler,
  [ACTIVATE_ORGANIZATION_SUBSCRIPTION_SUCCESS]:
    activateOrganizationSubscriptionRequestSuccessHandler,
  [ACTIVATE_ORGANIZATION_SUBSCRIPTION_FAILURE]:
    activateOrganizationSubscriptionRequestFailureHandler,

  [APPLY_COUPON]: applyCouponRequestHandler,
  [APPLY_COUPON_SUCCESS]: applyCouponRequestSuccessHandler,
  [APPLY_COUPON_FAILURE]: applyCouponRequestFailureHandler,

  [CLEAR_CURRENT_SUBSCRIPTION]: clearCurrentSubscription,

  [CLEAR_SUBSCRIPTION_UPDATED]: clearSubscriptionUpdated,

  [CLEAR_PAYMENT_METHOD_UPDATED]: clearPaymentMethodUpdated,

  [CLEAR_DISCOUNT_INFO]: clearDiscountInfo,

  [CLEAR_DISCOUNT_ERROR_MESSAGE]: clearDiscountErrorMessage,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  updateSubscriptionIsLoading: false,
  updatePaymentMethodLoading: false,
  activateSubscriptionLoading: false,
  applyCouponLoading: false,
  redirect: false,
  currentSubscription: null,
  subscriptionUpdated: false,
  updatePaymentMethodSuccess: false,
  discountInfo: null,
  discountErrorMessage: "",
};

export default function subscriptionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
