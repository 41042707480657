import {
  GET_DAILY_TIMESHEETS_LIST,
  GET_OTHER_TIMESHEETS_LIST,
  GET_WEEKLY_TIMESHEET_DETAILS,
  CREATE_DAILY_TIMESHEETS_LIST,
  UPDATE_DAILY_TIMESHEETS_LIST,
  DELETE_DAILY_TIMESHEETS_LIST,
  CLEAR_DAILY_TIMESHEETS_LIST,
  CLEAR_TIMESHEET_LIST,
  CLEAR_MANUAL_TIME_ADDED,
  EXPORT_WEEKLY_TIMESHEET,
  EXPORT_MONTHLY_TIMESHEET,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getDailyTimesheets(value) {
  return {
    type: GET_DAILY_TIMESHEETS_LIST,
    payload: value,
  };
}

export function createDailyTimesheets(value) {
  return {
    type: CREATE_DAILY_TIMESHEETS_LIST,
    payload: value,
  };
}

export function updateDailyTimesheets(value) {
  return {
    type: UPDATE_DAILY_TIMESHEETS_LIST,
    payload: value,
  };
}

export function deleteDailyTimesheets(value) {
  return {
    type: DELETE_DAILY_TIMESHEETS_LIST,
    payload: value,
  };
}

export function getOtherTimesheets(value) {
  return {
    type: GET_OTHER_TIMESHEETS_LIST,
    payload: value,
  };
}

export function getWeeklyTimesheetDetails(value) {
  return {
    type: GET_WEEKLY_TIMESHEET_DETAILS,
    payload: value,
  };
}

export function clearDailyTimesheetsList() {
  return {
    type: CLEAR_DAILY_TIMESHEETS_LIST,
  };
}

export function clearTimesheetList() {
  return {
    type: CLEAR_TIMESHEET_LIST,
  };
}

export function clearDailyTimesheetAdded() {
  return {
    type: CLEAR_MANUAL_TIME_ADDED,
  };
}
export function exportWeeklyTimeSheet(value) {
  return {
    type: EXPORT_WEEKLY_TIMESHEET,
    payload: value,
  };
}
export function exportMonthlyTimeSheet(value) {
  return {
    type: EXPORT_MONTHLY_TIMESHEET,
    payload: value,
  };
}
