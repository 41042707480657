import styled from "styled-components";

export const ContentContainer = styled.div`
  display: grid;
  justify-items: start;
  align-content: start;
  gap: 40px;
  padding: 30px;
  min-height: 400px;
`;
export const KeyCopyContainer = styled.div`
  display: grid;
  grid-template-columns: 280px 100px;
  border: 1px solid ${({ theme }) => theme.input.borderColor.default};
  min-height: ${({ theme }) => theme.input.minHeight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
`;
export const CopyButton = styled.div`
  border-radius: 0 4px 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.main.white};
  font-size: ${({ theme }) => theme.button.fontSize};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  background-color: ${({ theme }) => theme.button.grayMediumButton.bgColor};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) =>
      theme.button.grayMediumButton.hoverBgColor};
  }
`;
export const KeyCodeSection = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 5px 15px;
  color: ${({ theme }) => theme.input.textColor};
  font-size: ${({ theme }) => theme.font.inputLabel.size};
`;
