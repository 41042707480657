import { connect } from "react-redux";

// Component
import ActivityLocations from "./ActivityLocations";

// Actions
// import {getTaskList} from "./TaskActions";

// Store props to map with current state
const mapStateToProps = state => ({
  locationMap: state.activity.locationsMap,
  isLoading: state.activity.isLoading
});

// Actions prop to dispatch
const mapDispatchToProps = dispatch => ({
  // getTaskList: () => dispatch(getTaskList()),
});

// connect states and dispatchers with components
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityLocations);
