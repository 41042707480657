import { Modal, ModalBody } from "reactstrap";
import { CSVLink } from "react-csv";
import moment from "moment";
import styled from "styled-components";

import ExportPDF from "./ExportPDF";
import AppLists from "../ActivityNew/Apps/AppLists";
import UrlLists from "../ActivityNew/URLs/UrlLists";

import {
  toHHMM,
  formatDurationToHoursMinutes,
  getHourMin,
} from "../../utils/helper";

import {
  CommonIconWhButton,
  CommonGrid,
  CommonText,
} from "../../styledComponents/common";

import csv from "../../assets/img/icons/csv.svg";
import pdf from "../../assets/img/icons/pdf.svg";
import crossIcon from "../../assets/img/icons/cross_black.svg";

const ModalCrossIcon = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  right: 15px;
  top: 15px;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
    transition: transform 0.15s ease;
  }
`;

const IconButton = styled(CommonIconWhButton)`
  border-color: #e4e7eb;
  padding: 14px 25px;
  grid-column-gap: 13px;
  &:hover {
    border-color: #20bead;
  }
`;
const CSVLinkMod = styled(CSVLink)`
  &:hover {
    text-decoration: none;
  }
`;

const ExportModal = ({
  isOpen,
  toggle,
  title,
  appsUrlState,
  averageActivity,
  manualTimeData,
  selectedProject,
  selectedTab,
  weeklyDates,
  customReportData,
  customReportSums,
  reportName,
  startDate,
  endDate,
}) => {
  const togglePdfExport = () => {
    ExportPDF(
      title,
      appsUrlState,
      averageActivity,
      manualTimeData,
      selectedProject,
      selectedTab,
      weeklyDates
    );
    toggle();
  };

  const getAppData = (appList) => {
    AppLists.map((item) => {
      appList.map((app) => {
        if (
          app.app === item.winId ||
          app.app === item.macId ||
          app.app === item.linuxId
        ) {
          app.name = item.name;
          app.icon = item.icon;
        }
      });
    });
    return appList;
  };

  const getUrlData = (urlList) => {
    UrlLists.map((item) => {
      urlList.map((url) => {
        if (trimUrl(url.url) === item.name) {
          url.name = item.name;
          url.icon = item.icon;
        }
      });
    });
    return urlList;
  };

  const trimUrl = (url) => {
    return url.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];
  };

  const getCSVData = () => {
    if (
      title === "timeActivity" &&
      averageActivity &&
      averageActivity.length > 0
    ) {
      if (selectedTab === "weekly") {
        let csvData = [
          selectedProject && selectedProject !== "All Projects"
            ? [
                `Member`,
                `Project Name`,
                `Total Time Worked`,
                `Total Idle Time`,
                `Average Activity`,
                `Total Active Time`,
                `Total Neutral Time`,
              ]
            : [
                `Member`,
                `Total Time Worked`,
                `Total Idle Time`,
                `Average Activity`,
                `Total Active Time`,
                `Total Neutral Time`,
              ],
        ];
        averageActivity.forEach((info, i) => {
          const newArr =
            selectedProject && selectedProject !== "All Projects"
              ? [
                  info.member_name,
                  selectedProject,
                  formatDurationToHoursMinutes(info.time_worked_in_seconds),
                  info.idle_time
                    ? formatDurationToHoursMinutes(info.idle_time)
                    : "",
                  `${parseInt(info.average_weekly_activity)}%`,
                  formatDurationToHoursMinutes(info.active_time),
                  formatDurationToHoursMinutes(info.neutral_time),
                ]
              : [
                  info.member_name,
                  formatDurationToHoursMinutes(info.time_worked_in_seconds),
                  info.idle_time
                    ? formatDurationToHoursMinutes(info.idle_time)
                    : "",
                  `${parseInt(info.average_weekly_activity)}%`,
                  formatDurationToHoursMinutes(info.active_time),
                  formatDurationToHoursMinutes(info.neutral_time),
                ];
          csvData.push(newArr);
        });
        return csvData;
      } else {
        let csvData = [
          [
            `Date`,
            `Member`,
            `Time Worked`,
            `Idle Time`,
            `Average Activity`,
            `Active Time`,
            `Neutral Time`,
          ],
        ];
        averageActivity.forEach((report, index) => {
          let date = report.date;
          report.data.forEach((info, i) => {
            const newArr = [
              date,
              info.member_name,
              toHHMM(info.time_worked_in_seconds),
              toHHMM(info.idle_time),
              `${parseInt(info.member_average_activity)}%`,
              formatDurationToHoursMinutes(
                info.time_worked_in_seconds *
                  (info.member_average_activity / 100)
              ),
              formatDurationToHoursMinutes(
                info.time_worked_in_seconds *
                  (1 - info.member_average_activity / 100) -
                  60
              ),
            ];
            csvData.push(newArr);
          });
        });
        return csvData;
      }
    } else if (
      title === "manualTime" &&
      manualTimeData &&
      manualTimeData.length > 0
    ) {
      let csvData = [
        [
          `Date`,
          `Member`,
          `Edited By`,
          `Project`,
          `Task`,
          `Change in TimeSheet`,
          `Reason`,
        ],
      ];
      manualTimeData.forEach((report, index) => {
        let date = Object.keys(report)[0];
        report[date].forEach((info, i) => {
          const newArr = [
            date,
            info.member_name,
            info.member_name === info.edited_by_name
              ? `member on ${moment(info.edited_time).format("D-M-YYYY")}`
              : `${info.edited_by_name} on ${moment(info.edited_time).format(
                  "D-M-YYYY"
                )}`,
            info.project_name,
            info.task_name === null ? `not set` : `${info.task_name}`,
            info.type_of_change === "add"
              ? `added ${getHourMin(info.old_time)}`
              : info.type_of_change === ("delete" || "update")
              ? `${info.type_of_change}d ${getHourMin(info.old_time)}`
              : `${info.type_of_change}ted ${getHourMin(info.deleted_time)}`,
            info.reason,
          ];
          csvData.push(newArr);
        });
      });
      return csvData;
    } else if (
      title === "appUrl" &&
      appsUrlState &&
      Object.keys(appsUrlState).length > 0
    ) {
      let csvData = [
        [`Date`, `Member`, `Apps or Site`, `time spent (hrs)`, `Percent used`],
      ];

      for (let date in appsUrlState) {
        const report = appsUrlState[date];
        if (report.hasData) {
          for (let memberId in report) {
            if (
              report[memberId].app_data &&
              report[memberId].app_data.length > 0
            ) {
              getAppData(report[memberId].app_data).forEach((app, index) => {
                const newArr = [
                  date,
                  report[memberId].member_name,
                  app.name ? app.name : app.app,
                  moment.utc(app.time * 1000).format("HH:mm:ss"),
                  `${parseInt((app.time / report[memberId].duration) * 100)}%`,
                ];
                csvData.push(newArr);
              });
            }

            if (
              report[memberId].url_data &&
              report[memberId].url_data.length > 0
            ) {
              getUrlData(report[memberId].url_data).forEach((url, index) => {
                const newArr = [
                  date,
                  report[memberId].member_name,
                  url.name ? url.name : trimUrl(url.url),
                  moment.utc(url.time * 1000).format("HH:mm:ss"),
                  `${parseInt((url.time / report[memberId].duration) * 100)}%`,
                ];
                csvData.push(newArr);
              });
            }
          }
        }
      }

      return csvData;
    } else if (title === "customReport" && customReportData?.length > 0) {
      let csvData = [
        [
          "Member Name",
          "Member Email",
          "Member Role",
          "Project",
          "Total Time Worked",
          "Idle Time",
          "Idle Time %",
          "Average Activity",
          "Active Time",
          "Neutral Time",
          "Manual Time",
          "Manual Time %",
          "Notes",
        ],
      ];

      customReportData.forEach((info) => {
        const newRow = [
          info.memberName,
          info.memberEmail,
          info.memberRole,
          info.projects,
          formatDurationToHoursMinutes(info.totalTimeWorked),
          info.idleTime ? formatDurationToHoursMinutes(info.idleTime) : "",
          info.idleTimePercentage ? info.idleTimePercentage : "",
          `${parseFloat(info.averageActivity).toFixed(2)}%`,
          formatDurationToHoursMinutes(info.activeTime),
          formatDurationToHoursMinutes(info.neutralTime),
          formatDurationToHoursMinutes(info.manualTime),
          info.manualTimePercentage,
          info.notes.join(", "),
        ];
        csvData.push(newRow);
      });
      csvData.push(["", "", "", "", "", "", "", "", "", "", "", "", ""]);

      csvData.push([
        `${customReportData?.length ? customReportData?.length : 0} Members`,
        "",
        "",
        `${
          customReportSums.uniqueProjects ? customReportSums.uniqueProjects : 0
        } Projects`,
        formatDurationToHoursMinutes(
          customReportSums.totalTime ? customReportSums.totalTime : 0
        ),
        customReportSums.totalIdleTime
          ? formatDurationToHoursMinutes(
              customReportSums.totalIdleTime
                ? customReportSums.totalIdleTime
                : 0
            )
          : "",
        customReportSums.idleTimePercentage
          ? customReportSums.idleTimePercentage
          : "",
        "-",
        formatDurationToHoursMinutes(
          customReportSums.totalActiveTime
            ? customReportSums.totalActiveTime
            : 0
        ),
        formatDurationToHoursMinutes(
          customReportSums.totalNeutralTime
            ? customReportSums.totalNeutralTime
            : 0
        ),
        formatDurationToHoursMinutes(
          customReportSums.totalManualTime
            ? customReportSums.totalManualTime
            : 0
        ),
        customReportSums.manualTimePercentage,
        `${
          customReportSums.totalNotes ? customReportSums.totalNotes : 0
        } Notes`,
      ]);
      return csvData;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `30vh`, maxWidth: `450px` }}
    >
      <ModalBody style={{ padding: `35px 0 15px 0`, position: "relative" }}>
        <ModalCrossIcon src={crossIcon} alt="" onClick={() => toggle()} />
        <div style={{ padding: `0 30px` }}>
          <CommonText fontSize="18px" fontWeight="600" name="true">
            Export {title === "customReport" ? "Custom" : "Time & Activity"}{" "}
            Report
          </CommonText>

          <CommonText style={{ marginTop: `30px` }} fontWeight="600">
            Choose Format
          </CommonText>
          <CommonGrid columns="1fr 1fr" padding="20px 0 25px 0">
            {title !== "customReport" && (
              <IconButton onClick={() => togglePdfExport()}>
                <div>
                  <img src={pdf} alt="pdf" width="28px" />
                </div>
                <div>PDF</div>
              </IconButton>
            )}
            <CSVLinkMod
              data={getCSVData()}
              filename={`${
                title === "timeActivity"
                  ? selectedTab === "weekly" && weeklyDates
                    ? `Time_activity_report_${weeklyDates.firstDay}_${weeklyDates.lastDay} `
                    : "Time_activity_report"
                  : title === "manualTime"
                  ? "Manual_time_report"
                  : title === "customReport"
                  ? `${reportName}_${startDate}_${endDate}`
                  : "Apps_URLs_usage_report"
              }.csv`}
            >
              <IconButton onClick={() => toggle()}>
                <div>
                  <img src={csv} alt="excel" width="28px" padding="12px 25px" />
                </div>
                <div>CSV</div>
              </IconButton>
            </CSVLinkMod>
          </CommonGrid>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExportModal;
