import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../styledComponents/createProject";
import { RoundMiniImage, CommonText } from "../../styledComponents/common";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

const RoundImageNameComp = ({
  imgSource,
  imageSize,
  name,
  index,
  size,
  fontSize,
  fontWeight,
  color,
}) => {
  return (
    <AssigneesManageImgTextGrid>
      <AssigneesManageRoundImage imageSize={imageSize}>
        {imgSource !== null ? (
          <RoundMiniImage src={imgSource} alt={name} imageSize={imageSize} />
        ) : (
          <FirstRoundLetterComp
            text={name}
            backColor={index}
            size={imageSize || `40px`}
            fontSize={size || `14px`}
            color={color || null}
          />
        )}
      </AssigneesManageRoundImage>
      <CommonText
        // name={props.fill}
        fontSize={fontSize || `14px`}
        fontWeight={fontWeight}
      >
        {name}
      </CommonText>
    </AssigneesManageImgTextGrid>
  );
};

export default RoundImageNameComp;
