import {
  GET_SCHEDULE_LIST,
  GET_SCHEDULE_LIST_SUCCESS,
  GET_SCHEDULE_LIST_FAILURE,
  DEACTIVATE_SCHEDULE,
  DEACTIVATE_SCHEDULE_SUCCESS,
  DEACTIVATE_SCHEDULE_FAILURE,
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_LIST,
  UPDATE_SCHEDULE_LIST_SUCCESS,
  UPDATE_SCHEDULE_LIST_FAILURE,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE,
  RESET_SELECTED_SCHEDULE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const scheduleListRequestHandler = (state, action) => {
  state.isLoading = true;
  return {
    ...state,
  };
};

export const scheduleListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    state.list = data.data;
  }
  state.isLoading = false;
  return {
    ...state,
  };
};

export const scheduleListRequestFailureHandler = (state, action) => {
  state.isLoading = false;
  return {
    ...state,
  };
};

export const getScheduleRequestHandler = (state, action) => {
  state.isLoading = true;
  return {
    ...state,
  };
};

export const getScheduleRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    state.selectedSchedule = data.data;
  }
  state.isLoading = false;
  return {
    ...state,
  };
};

export const getScheduleRequestFailureHandler = (state, action) => {
  state.isLoading = false;
  return {
    ...state,
  };
};

export const deactivateScheduleRequestHandler = (state, action) => {
  state.isLoading = true;
  return {
    ...state,
  };
};

export const deactivateScheduleRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Schedule removed successfully", 2000);
    state.callback = true;
  }
  state.isLoading = false;
  return {
    ...state,
  };
};

export const deactivateScheduleRequestFailureHandler = (state, action) => {
  state.isLoading = false;
  return {
    ...state,
  };
};

export const addScheduleRequestHandler = (state, action) => {
  state.isLoading = true;
  return {
    ...state,
  };
};

export const addScheduleRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 201) {
    createNotification("success", "Schedule added successfully", 2000);
    state.callback = true;
  }
  state.isLoading = false;
  return {
    ...state,
  };
};

export const addScheduleRequestFailureHandler = (state, action) => {
  state.isLoading = false;
  return {
    ...state,
  };
};

export const updateScheduleRequestHandler = (state, action) => {
  state.isLoading = true;
  return {
    ...state,
  };
};

export const updateScheduleRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    createNotification("success", "Schedule updated successfully", 2000);
    state.callback = true;
  }
  state.isLoading = false;
  return {
    ...state,
  };
};

export const updateScheduleRequestFailureHandler = (state, action) => {
  state.isLoading = false;
  return {
    ...state,
  };
};

export const selectedScheduleResetHandler = (state, action) => {
  state.selectedSchedule = {};
  return {
    ...state,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_SCHEDULE_LIST]: scheduleListRequestHandler,
  [GET_SCHEDULE_LIST_SUCCESS]: scheduleListRequestSuccessHandler,
  [GET_SCHEDULE_LIST_FAILURE]: scheduleListRequestFailureHandler,
  [DEACTIVATE_SCHEDULE]: deactivateScheduleRequestHandler,
  [DEACTIVATE_SCHEDULE_SUCCESS]: deactivateScheduleRequestSuccessHandler,
  [DEACTIVATE_SCHEDULE_FAILURE]: deactivateScheduleRequestFailureHandler,
  [ADD_SCHEDULE]: addScheduleRequestHandler,
  [ADD_SCHEDULE_SUCCESS]: addScheduleRequestSuccessHandler,
  [ADD_SCHEDULE_FAILURE]: addScheduleRequestFailureHandler,
  [UPDATE_SCHEDULE_LIST]: updateScheduleRequestHandler,
  [UPDATE_SCHEDULE_LIST_SUCCESS]: updateScheduleRequestSuccessHandler,
  [UPDATE_SCHEDULE_LIST_FAILURE]: updateScheduleRequestFailureHandler,
  [GET_SCHEDULE]: getScheduleRequestHandler,
  [GET_SCHEDULE_SUCCESS]: getScheduleRequestSuccessHandler,
  [GET_SCHEDULE_FAILURE]: getScheduleRequestFailureHandler,
  [RESET_SELECTED_SCHEDULE]: selectedScheduleResetHandler,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  list: [],
  selectedSchedule: {},
};

export default function scheduleReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
