import { connect } from "react-redux";

// Component
import Tasks from "./Tasks";
import {
  getOrganizationMembersList,
  addTaskAssignee,
  deleteTaskAssignee,
  projectAddTask,
  projectEditTask,
  projectDeleteTask,
  getProjectAssigneeList,
} from "../Projects/projectsActions";

// Actions
import { getTaskList } from "./tasksActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  tasks: state.task.tasks,
  projectsList: state.project.projectShortList,
  projectAssigneeList: state.project.projectAssigneeList,
  isLoading: state.task.isLoading,
  projectIsLoading: state.project.isLoading,
  projectAssigneeIsLoading: state.project.projectAssigneeIsLoading,
  taskIsLoading: state.project.taskIsLoading,
  loadingTaskId: state.project.loadingTaskId,
  loadingTaskProjectId: state.project.loadingTaskProjectId,
  taskAssigneeIsLoading: state.project.taskAssigneeIsLoading,
  loadingTaskAssigneeId: state.project.loadingTaskAssigneeId,

  // pagination
  totalTasksCount: state.task.totalTasksCount,
  pageSize: state.task.pageSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (params) =>
    dispatch(getOrganizationMembersList(params)),
  getTaskList: (params) => dispatch(getTaskList(params)),
  projectAddTask: (details) => dispatch(projectAddTask({ ...details })),
  projectEditTask: (details) => dispatch(projectEditTask({ ...details })),
  projectDeleteTask: (details) => dispatch(projectDeleteTask({ ...details })),
  addTaskAssignee: (details) => dispatch(addTaskAssignee({ ...details })),
  deleteTaskAssignee: (details) => dispatch(deleteTaskAssignee({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
