
import ContentLoader from "react-content-loader";

export const WeeklyTimesheetLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={966}
      height={210}
      viewBox="0 0 966 210"
      backgroundColor="#e8eaee"
      foregroundColor="#f3f3f3"
      {...props}
    >
      <rect id="Rectangle_23705" data-name="Rectangle 23705"  x="0.5" y="34.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23706" data-name="Rectangle 23706"  x="0.5" y="152.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23707" data-name="Rectangle 23707"  x="257.5" y="34.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23708" data-name="Rectangle 23708" x="257.5" y="152.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23709" data-name="Rectangle 23709" x="515.5" y="34.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23710" data-name="Rectangle 23710" x="515.5" y="152.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23711" data-name="Rectangle 23711" x="772.5" y="34.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23712" data-name="Rectangle 23712" x="772.5" y="152.62" width="192" height="56" rx="12" />
      <rect id="Rectangle_23713" data-name="Rectangle 23713" x="2.79" width="53.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23714" data-name="Rectangle 23714" x="2.79" y="118" width="53.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23715" data-name="Rectangle 23715" x="55.79" y="55" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23716" data-name="Rectangle 23716" x="55.79" y="173" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23717" data-name="Rectangle 23717" x="580.79" y="55" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23718" data-name="Rectangle 23718" x="580.79" y="173" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23719" data-name="Rectangle 23719" x="324.79" y="55" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23720" data-name="Rectangle 23720" x="324.79" y="173" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23721" data-name="Rectangle 23721" x="839.79" y="55" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23722" data-name="Rectangle 23722" x="839.79" y="173" width="68.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23723" data-name="Rectangle 23723" x="258.79" width="53.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23724" data-name="Rectangle 23724" x="258.79" y="118" width="53.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23725" data-name="Rectangle 23725" x="517.79" width="53.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23726" data-name="Rectangle 23726" x="517.79" y="118" width="53.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23727" data-name="Rectangle 23727" x="773.79" width="53.23" height="12.08" rx="5.02" />
      <rect id="Rectangle_23728" data-name="Rectangle 23728" x="773.79" y="118" width="53.23" height="12.08" rx="5.02" />
    </ContentLoader>
  );
};

export default WeeklyTimesheetLoader;
