import {
  GET_ALL_TASK_LIST,
  GET_ALL_TASK_LIST_SUCCESS,
  GET_ALL_TASK_LIST_FAILURE,
  PROJECT_ADD_TASK_SUCCESS,
  PROJECT_EDIT_TASK_SUCCESS,
  PROJECT_DELETE_TASK_SUCCESS,
  ADD_TASK_ASSIGNEE_SUCCESS,
  DELETE_TASK_ASSIGNEE_SUCCESS,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleTasksRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const handleTasksRequestSuccess = (state, action) => {
  const {
    payload: { data, project_id },
  } = action;
  if (data.status === 200) {
    let projectTasksCount = null;
    let projectTasksPageSize = null;
    if (project_id) {
      projectTasksCount = data.data.count;
      projectTasksPageSize = data.data.page_size;
    }
    return {
      ...state,
      isLoading: false,
      tasks: data.data.results,
      totalTasksCount: data.data.count,
      pageSize: data.data.page_size,
      projectTaskCount: projectTasksCount,
      projectTaskPageSize: projectTasksPageSize,
    };
  }
  return {
    ...state,
    isLoading: false,
    tasks: [],
    totalTasksCount: null,
    pageSize: null,
    projectTaskCount: null,
    projectTaskPageSize: null,
  };
};
export const handleTasksRequestFailure = (state, action) => {
  createNotification("error", "Something went wrong!", 3000);
  return {
    ...state,
    isLoading: false,
    totalTasksCount: null,
    pageSize: null,
    projectTaskCount: null,
    projectTaskPageSize: null,
  };
};

export const updateTaskListOnTaskCreate = (state, action) => {
  const {
    payload: { data, project, position },
  } = action;

  if (data && data.id && project) {
    const taskData = {
      ...data,
      project,
      time_spent: 0,
    };
    return {
      ...state,
      tasks:
        position && position === "bottom"
          ? [...state.tasks, taskData]
          : [taskData, ...state.tasks],
    };
  }

  return {
    ...state,
  };
};
export const updateTaskListOnTaskEdit = (state, action) => {
  const {
    payload: { data, taskStatus },
  } = action;
  if (data && data.status === 200) {
    if (data.data && state.tasks && state.tasks.length > 0) {
      const tasksList = [...state.tasks];
      tasksList.some((item, index) => {
        if (item.id === data.data.id) {
          item.name = data.data.name;
          item.status = data.data.status;
          if (data.data.status !== taskStatus) {
            tasksList.splice(index, 1);
          }
          return true;
        }
        return false;
      });
      return {
        ...state,
        isLoading: false,
        tasks: tasksList,
      };
    }
  }
  return {
    ...state,
    isLoading: false,
  };
};
export const updateTaskListOnTaskDelete = (state, action) => {
  const {
    payload: { data, taskId },
  } = action;
  if (data && data.status === 204 && taskId) {
    const tasksList = [...state.tasks];
    return {
      ...state,
      isLoading: false,
      tasks: tasksList.filter((task) => task.id !== taskId),
    };
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const updateTaskAssigneeListOnAssigneeAdd = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data && data.id && data.task && data.task.id) {
    const tasksList = [...state.tasks];
    tasksList.some((task) => {
      if (task.id === data.task.id) {
        const assigneeItem = {
          id: data.id,
          first_name: data.first_name,
          last_name: data.last_name,
          avatar: data.avatar,
          color: data.color || null,
          username: data.username,
        };
        task.members = [...task.members, assigneeItem];
        return true;
      }
      return false;
    });
    return {
      ...state,
      tasks: tasksList,
    };
  }
  return {
    ...state,
  };
};
export const updateTaskAssigneeListOnAssigneeDelete = (state, action) => {
  const {
    payload: { data, taskId, userId },
  } = action;
  if (data && data.status === 204 && taskId && userId) {
    const tasksList = [...state.tasks];
    tasksList.some((task) => {
      if (task.id === taskId) {
        const filteredMembers = task.members.filter(
          (member) => member.id !== userId
        );
        task.members = [...filteredMembers];
        return true;
      }
      return false;
    });
    return {
      ...state,
      tasks: tasksList,
    };
  }
  return {
    ...state,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_ALL_TASK_LIST]: handleTasksRequest,
  [GET_ALL_TASK_LIST_SUCCESS]: handleTasksRequestSuccess,
  [GET_ALL_TASK_LIST_FAILURE]: handleTasksRequestFailure,

  [PROJECT_ADD_TASK_SUCCESS]: updateTaskListOnTaskCreate,
  [PROJECT_EDIT_TASK_SUCCESS]: updateTaskListOnTaskEdit,
  [PROJECT_DELETE_TASK_SUCCESS]: updateTaskListOnTaskDelete,

  [ADD_TASK_ASSIGNEE_SUCCESS]: updateTaskAssigneeListOnAssigneeAdd,
  [DELETE_TASK_ASSIGNEE_SUCCESS]: updateTaskAssigneeListOnAssigneeDelete,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  tasks: [],
  isLoading: false,

  // pagination
  totalTasksCount: null,
  pageSize: null,
  projectTaskCount: null,
  projectTaskPageSize: null,
};

export default function tasksReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
