import React from "react";
import { GoogleMap, useLoadScript, Polyline } from "@react-google-maps/api";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

const options = {
  // zoomControlOptions: {
  //   position: google.maps.ControlPosition.RIGHT_CENTER, // ,
  //   // ...otherOptions
  // },
  gestureHandling: "cooperative",
};

const containerStyle = {
  width: "100%",
  height: "72vh",
  border: "1px solid #c2cce1",
  borderRadius: "6px",
  marginTop: "10px",
};

const center = {
  lat: 23.8371525,
  lng: 90.367087,
};

function MapWrapper(props) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY,
    // ...otherOptions
  });
  const onLoad = React.useCallback(function onLoad(mapInstance) {
    // do something with map Instance
    props.onMapMounted(mapInstance);
  });
  // const renderMap = () => {
  //   // wrapping to a function is useful in case you want to access `window.google`
  //   // to eg. setup options or create latLng object, it won't be available otherwise
  //   // feel free to render directly if you don't need that

  // };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }
  return isLoaded ? (
    <GoogleMap
      options={options}
      mapContainerStyle={containerStyle}
      center={props.center}
      zoom={15}
      onLoad={onLoad}
    >
      {props.markers ? props.markers : null}
      {props.polyline.map((elem, index) => {
        return (
          <Polyline
            key={index}
            path={elem}
            options={{
              strokeColor: "#13a6ac",
              trokeOpacity: 1,
              strokeWeight: 4,
              offset: "0%",
            }}
          />
        );
      })}
    </GoogleMap>
  ) : (
    <ComponentCircleLoader />
  );
}

export default MapWrapper;
