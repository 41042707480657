import {
  GET_JOB_SITES_LIST,
  ADD_JOB_SITE,
  UPDATE_JOB_SITE,
  DELETE_JOB_SITE,
  ADD_JOB_SITE_ASSIGNEE,
  REMOVE_JOB_SITE_ASSIGNEE,
  SET_JOB_SITE_DETAILS,
  CLEAR_JOB_SITE_DETAILS,
} from "../../../modules/constants";

export function getJobSitesList(payload) {
  return {
    type: GET_JOB_SITES_LIST,
    payload,
  };
}
export function addJobSite(payload) {
  return {
    type: ADD_JOB_SITE,
    payload,
  };
}
export function updateJobSite(payload) {
  return {
    type: UPDATE_JOB_SITE,
    payload,
  };
}
export function deleteJobSite(payload) {
  return {
    type: DELETE_JOB_SITE,
    payload,
  };
}
export function addJobSiteAssignee(payload) {
  return {
    type: ADD_JOB_SITE_ASSIGNEE,
    payload,
  };
}
export function removeJobSiteAssignee(payload) {
  return {
    type: REMOVE_JOB_SITE_ASSIGNEE,
    payload,
  };
}
export function setJobSiteDetails(payload) {
  return {
    type: SET_JOB_SITE_DETAILS,
    payload,
  };
}
export function clearJobSiteDetails() {
  return {
    type: CLEAR_JOB_SITE_DETAILS,
  };
}
