import { connect } from "react-redux";

// Component
import ActivityFilter from "./ActivityFilter";
import { getClockInOutList } from "../ClockInOut/clockInOutActions";
//Actions
import {
  getScreenshotList,
  sortScreenshots,
  getUserActivity,
  getAppsList,
  getUrlList,
  getAllNotes,
  getLocationList,
} from "./activityActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  //sortType: state.activity.sortType,
  isLoading: state.activity.isLoading,
  reloadScreenshotList: state.activity.getScreenshotList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClockInOutList: (payload) => dispatch(getClockInOutList(payload)),
  getScreenshotList: (params) => dispatch(getScreenshotList(params)),
  getUserActivity: (params) => dispatch(getUserActivity(params)),
  sortScreenshots: (params) => dispatch(sortScreenshots(params)),
  getAppsList: (params) => dispatch(getAppsList(params)),
  getUrlList: (params) => dispatch(getUrlList(params)),
  getAllNotes: (params) => dispatch(getAllNotes(params)),
  getLocationList: (params) => dispatch(getLocationList(params)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ActivityFilter);
