import { useState, useRef, useEffect, Fragment } from "react";
import {
  AssigneeRoundImageSection,
  AssigneeRoundImage,
  RoundPersonImage,
  RoundImageCross,
} from "../../styledComponents/createProject";
import {
  CommonText,
  AssignListContainer,
  AssignListItem,
  AssignListText,
  SearchBarWithAssignListContainer,
  AssigneeSearchBarWrapper,
  AssigneeSearchBarContainer,
  AssigneeSearchIcon,
  AssigneeSearchInput,
  ShowMoreAssigneeList,
} from "../../styledComponents/common";

import DropdownLoader from "../../components/DropdownLoader/DropdownLoader";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";
//dropdown
import AssigneeTooltip from "../../components/Tooltip/AssigneeTooltip";
import Assign from "../../assets/img/icons/assign2.svg";
import Avatar5 from "../../assets/img/avatar_5.svg";
import removeAssignee from "../../assets/img/icons/remove_assignee.svg";
import searchIcon from "../../assets/img/icons/search.svg";

export const AddAssigneeDropdown = ({
  isLoading,
  assignees,
  index,
  updateState,
  visibilityChange,
  membersList,
  assigneeRemove,
  getMembersList,
  selectedOrganization,
  teamMemberIsLoading,
  loadingMemberId,
}) => {
  const [addAssignee, setAddAssignee] = useState(false);
  const [moreAssignee, setMoreAssignee] = useState(false);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);
  const moreRef1 = useRef(null);
  const moreRef2 = useRef(null);
  const [addList, setAddList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      let newArr = membersList;
      if (assignees && assignees.length > 0) {
        assignees.map((assignee) => {
          newArr = newArr.filter((item) => item.id !== assignee.userId);
        });
      }
      setAddList(newArr);
    } else if (membersList && membersList.length === 0) {
      setAddList([]);
    }
    if (assignees && assignees.length < 4) {
      setMoreAssignee(false);
    }
  }, [membersList, assignees]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm !== "" &&
      membersList &&
      membersList.length > 0
    ) {
      let originalList = membersList;
      let filteredList = [];
      originalList.forEach((e) => {
        if (
          searchTerm &&
          e.name.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) === -1
        ) {
          return;
        }
        filteredList.push(e);
      });
      if (assignees && assignees.length > 0) {
        assignees.map((assignee) => {
          filteredList = filteredList.filter(
            (item) => item.id !== assignee.userId
          );
        });
      }
      setAddList(filteredList);
    } else if (searchTerm === "") {
      let newArr = membersList;
      if (assignees && assignees.length > 0) {
        assignees.map((assignee) => {
          newArr = newArr.filter((item) => item.id !== assignee.userId);
        });
      }
      setAddList(newArr);
    }
  }, [searchTerm]);

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2, moreRef1, moreRef2);

  const showAssignee = () => {
    if (selectedOrganization && selectedOrganization.id) {
      let payload = {
        organization_id: selectedOrganization.id,
      };
      getMembersList(payload);
    }
    setAddAssignee(true);
  };

  const showMoreAssignee = () => {
    setMoreAssignee(true);
  };

  const updateAssingeeList = (person) => {
    setAddList(addList.filter((item) => item.id !== person.id));
  };

  function useOutsideClickHandler(ref1, ref2, moreRef1, moreRef2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setAddAssignee(false);
          visibilityChange(false);
          setSearchTerm("");
        }
        if (
          moreRef1.current &&
          !moreRef1.current.contains(event.target) &&
          moreRef2.current &&
          !moreRef2.current.contains(event.target)
        ) {
          setMoreAssignee(false);
          visibilityChange(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2, addAssignee, moreRef1, moreRef2, moreAssignee]);
  }

  return (
    <AssigneeRoundImageSection
      position={assignees.length}
      style={{ marginRight: `auto` }}
    >
      <AssigneeRoundImage
        index={0}
        onClick={() => {
          showAssignee();
          visibilityChange(true);
        }}
        ref={wrapperRef2}
      >
        <img src={Assign} alt="assign" />
      </AssigneeRoundImage>
      {assignees.length > 3 && (
        <AssigneeRoundImage
          index="more"
          onClick={() => {
            showMoreAssignee();
            visibilityChange(true);
          }}
          ref={moreRef2}
          style={{
            border: `1px solid #fff`,
            paddingLeft: "3px",
          }}
        >
          {`+${assignees.length - 3}`}
        </AssigneeRoundImage>
      )}
      {assignees
        .filter((person, index) => index < 3)
        .map((filteredPerson, index) => (
          <AssigneeRoundImage
            key={index}
            index={assignees.length < 4 ? index + 1 : index + 2}
          >
            <AssigneeTooltip toolTipText={filteredPerson.name} />
            {filteredPerson.avatar ? (
              <RoundPersonImage src={filteredPerson.avatar} alt="" />
            ) : (
              <FirstRoundLetterComp
                text={filteredPerson.name}
                backColor={index}
              />
            )}

            <RoundImageCross onClick={() => assigneeRemove(filteredPerson.id)}>
              <img
                src={removeAssignee}
                style={{ width: "100%", height: "100%" }}
              />
            </RoundImageCross>
          </AssigneeRoundImage>
        ))}
      {moreAssignee && (
        <ShowMoreAssigneeList ref={moreRef1}>
          {assignees.map((member, index) => (
            <Fragment key={index}>
              <AssigneeRoundImage index={0} size="30px">
                {member.avatar ? (
                  <RoundPersonImage
                    src={member.avatar}
                    alt=""
                    size="30px"
                    borderColor="#c2cce1"
                  />
                ) : (
                  <FirstRoundLetterComp
                    size="30px"
                    text={member.name}
                    backColor={index}
                    style={{ border: "1px solid #c2cce1" }}
                  />
                )}

                <RoundImageCross onClick={() => assigneeRemove(member.id)}>
                  <img
                    src={removeAssignee}
                    style={{ width: "100%", height: "100%" }}
                  />
                </RoundImageCross>
              </AssigneeRoundImage>
              <AssignListText>{member.name}</AssignListText>
            </Fragment>
          ))}
        </ShowMoreAssigneeList>
      )}
      {addAssignee && (
        <AssignListContainer ref={wrapperRef1}>
          <SearchBarWithAssignListContainer>
            <AssigneeSearchBarWrapper>
              <AssigneeSearchBarContainer>
                <AssigneeSearchIcon src={searchIcon} alt="" />
                <AssigneeSearchInput
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                  placeholder="Search..."
                  autoFocus
                />
              </AssigneeSearchBarContainer>
            </AssigneeSearchBarWrapper>

            <DropdownLoader loading={isLoading} />
            {addList &&
              addList.length > 0 &&
              addList.map((person, i) => {
                return teamMemberIsLoading &&
                  loadingMemberId &&
                  loadingMemberId === person.id ? (
                  <AssignListItem columns="100%" key={i}>
                    <DropdownLoader loading />
                  </AssignListItem>
                ) : (
                  <AssignListItem
                    key={i}
                    onClick={() => {
                      updateState(person, index);
                    }}
                    columns="30px auto"
                    padding="7px 8px"
                  >
                    <AssigneeRoundImage index={0} size="30px">
                      {person.avatar ? (
                        <RoundPersonImage
                          src={person.avatar}
                          alt=""
                          size="30px"
                          borderColor="#c2cce1"
                        />
                      ) : (
                        <FirstRoundLetterComp
                          size="30px"
                          style={{ border: "1px solid #c2cce1" }}
                          text={person.name}
                          backColor={i}
                        />
                      )}
                    </AssigneeRoundImage>
                    <AssignListText>{person.name}</AssignListText>
                  </AssignListItem>
                );
              })}
          </SearchBarWithAssignListContainer>
        </AssignListContainer>
      )}
    </AssigneeRoundImageSection>
  );
};
