import { useState, useEffect } from "react";
import { useExpanded } from "react-table";
import { Modal, ModalBody } from "reactstrap";
import {
  CardTitle,
  CommonText,
  InputLabel,
  CommonGrid,
  StyledLink,
} from "../../styledComponents/common";
import {
  SendMailDetails,
  CopyURLSection,
  SendEmailSubmitSection,
  SendMailText,
} from "../../styledComponents/invoice";
import {
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
  ColoredButton,
} from "../../styledComponents/buttons";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

const SendMailPopup = ({
  isOpen,
  isLoading,
  toggle,
  organization_id,
  invoice_id,
  senderName,
  invoiceNumber,
  issuedDate,
  dueDate,
  email,
  log,
  createInvoiceUpdateSendLog,
  sendInvoiceEmail,
  onSend,
  createInvoice,
  history,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  // const [viewURL, setViewURL] = useState("");
  const [URL, setURL] = useState("");

  useEffect(() => {
    if (log && log.log_id) {
      setURL(`https://extras.apploye.com/docs/preview?Invoice=${log.log_id}`);
    }
  }, [log]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  useEffect(() => {
    if (isOpen) {
      const payload = {
        organization_id: organization_id,
        invoice_id: invoice_id,
      };
      createInvoiceUpdateSendLog(payload);
    }
  }, [isOpen]);

  const cancelFunction = () => {
    toggle();
    if (createInvoice) {
      history.push(`/user/invoice-preview?id=${invoice_id}`);
    }
  };

  const copyTextFromElement = () => {
    setIsCopied(true);
    let element = document.getElementById("copyContent"); //select the element
    let elementText = element.textContent; //get the text content from the element
    copyText(elementText); //use the copyText function below
  };

  //If you only want to put some Text in the Clipboard just use this function
  // and pass the string to copied as the argument.
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  const handleSend = () => {
    if (log && log.log_id) {
      const payload = {
        organization_id: organization_id,
        invoice_id: invoice_id,
        log_id: log.log_id,
      };
      sendInvoiceEmail(payload);
      onSend();
      setTimeout(() => {
        toggle();
      }, 600);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `5vh`, maxWidth: `600px` }}
    >
      <ModalBody style={{ padding: `35px 0 15px 0` }}>
        <CommonGrid columns="1fr" padding="0 30px" gap="15px">
          <CardTitle fontSize="16px" margin="0 0 15px 0">
            Send Invoice To Your Client
          </CardTitle>
          <div id="copyContent" style={{ display: `none` }}>
            {URL}
          </div>
          <div>
            <InputLabel style={{ marginBottom: `6px` }}>
              Email Subject
            </InputLabel>
            <SendMailText>
              {" "}
              {`Invoice ${invoiceNumber} from ${senderName} (via Apploye)`}{" "}
            </SendMailText>
          </div>
          <div>
            <InputLabel style={{ marginBottom: `6px` }}>To</InputLabel>
            <SendMailText> {email} </SendMailText>
          </div>
          <SendMailDetails>
            <CommonText
              title
              style={{ gridColumn: `1 / span 2`, marginBottom: `10px` }}
            >
              Invoice Details
            </CommonText>
            <CommonText $label>Invoice No:</CommonText>
            <CommonText> {invoiceNumber} </CommonText>
            <CommonText $label>Issued Date:</CommonText>
            <CommonText> {issuedDate} </CommonText>
            {dueDate !== null && dueDate !== "" && (
              <>
                <CommonText name>Due Date:</CommonText>
                <CommonText name> {dueDate} </CommonText>
              </>
            )}
          </SendMailDetails>

          <div>
            <InputLabel>Invoice shareable URL</InputLabel>
            <CopyURLSection>
              <CommonText style={{ marginLeft: `15px`, overflowX: `hidden` }}>
                {URL}
              </CommonText>
              <ColoredButton
                type="grayMedium"
                padding="0 20px"
                onClick={() => copyTextFromElement()}
              >
                {isCopied ? `COPIED` : `COPY`}
              </ColoredButton>
            </CopyURLSection>
          </div>
        </CommonGrid>
        <SendEmailSubmitSection>
          <StyledLink
            to={`/user/invoice-client-view?id=${invoice_id}`}
            target="_blank"
            style={{ color: `#2F394E` }}
          >
            <SecondaryButton>Preview</SecondaryButton>
          </StyledLink>
          <WhiteButton
            type="cancel"
            margin="0 0 0 auto"
            onClick={() => cancelFunction()}
          >
            Cancel
          </WhiteButton>
          <PrimaryButton
            padding={isLoading ? "0 25px" : "0 40px"}
            onClick={() => handleSend()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Sending" fontSize="13px" />
            ) : (
              "Send"
            )}
          </PrimaryButton>
        </SendEmailSubmitSection>
      </ModalBody>
    </Modal>
  );
};

export default SendMailPopup;
