
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import { CommonText } from "../../../styledComponents/common";

import crossIcon from "../../../assets/img/icons/cross_black.svg";
import alertIcon from "../../../assets/img/icons/alert.svg";

const CrossIcon = styled.img`
  width: 25px;
  height: 25px;
`;
const AlertIcon = styled.img``;
const CrossButton = styled.div`
  position: absolute;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #e4e7eb;
  top: -18px;
  right: -18px;
  &:hover {
    border: 1px solid #c2cce1;
    ${CrossIcon} {
      transform: scale(1.1);
      transition: transform 0.2s ease;
    }
  }
`;

const SoloErrorModal = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `33vh` }}>
      <ModalBody
        style={{ padding: `20px`, position: "relative", display: "flex" }}
      >
        <CrossButton onClick={() => toggle()}>
          <CrossIcon src={crossIcon} alt="close" />
        </CrossButton>
        <AlertIcon src={alertIcon} alt="" />
        <CommonText style={{ paddingLeft: `15px` }}>
          You can not upgrade to "SOLO" plan when you have more than 1 members.
          Please remove the extra members before you convert to "SOLO" plan. For
          any further assistance regarding this, please contact our support
          team.
        </CommonText>
      </ModalBody>
    </Modal>
  );
};

export default SoloErrorModal;
