import { useState, useEffect } from "react";
import moment from "moment-timezone";

import {
  Container,
  TableText,
  RoundMiniImage,
} from "../../../styledComponents/common";
import { ColoredButtonWithIcon } from "../../../styledComponents/buttons";
import { TableItem } from "../../../styledComponents/teams";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../../styledComponents/createProject";
import {
  LiveMembersTableHeadContainer,
  LiveMembersTableContainer,
  UserDotDiv,
  UserDotCircle,
} from "./currentlyTrackingStyles";

import { PulsatingDot } from "./CurrentlyTracking";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import avatar5 from "../../../assets/img/avatar_5.svg";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";

export const UserSection = (props) => {
  return (
    <AssigneesManageImgTextGrid>
      {props.imgSource ? (
        <AssigneesManageRoundImage
          imageSize={props.size}
          style={{ position: "relative" }}
        >
          <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
          {/* <UserDotDiv>
          <UserDotCircle />
        </UserDotDiv> */}
        </AssigneesManageRoundImage>
      ) : (
        <FirstRoundLetterComp
          size="36px"
          text={props.name}
          backColor={props.index}
          color={props.color || null}
        />
      )}

      <TableText name>{props.name}</TableText>
    </AssigneesManageImgTextGrid>
  );
};

const LastFiveMinActiveList = (props) => {
  const [membersList, setMembersList] = useState([]);

  const { liveMembersList, isLoading, selectedOrganization, searchTerm } =
    props;

  useEffect(() => {
    if (liveMembersList && liveMembersList.length > 0) {
      const list = [];
      liveMembersList.forEach((member) => {
        list.push({
          name: `${member.user.first_name}${
            member.user.last_name ? ` ${member.user.last_name}` : ``
          }`,
          avatar: member.user.avatar,
          color: member.user.color,
          project: member.project,
          task: member.task,
          start_timestamp: member.start_timestamp,
        });
      });
      setMembersList(list);
    } else if (liveMembersList && liveMembersList.length === 0) {
      setMembersList([]);
    }
  }, [liveMembersList]);

  return (
    <Container style={{ paddingBottom: "20px" }}>
      {isLoading ? (
        <ComponentCircleLoader />
      ) : membersList && membersList.length > 0 ? (
        <>
          <LiveMembersTableHeadContainer>
            <TableItem>
              <TableText>Member</TableText>
            </TableItem>

            <TableItem>
              <TableText>Project</TableText>
            </TableItem>
            <TableItem>
              <TableText>Task</TableText>
            </TableItem>
            <TableItem>
              <TableText>Latest Start Time</TableText>
            </TableItem>
            {/* <TableItem>
              <TableText></TableText>
            </TableItem> */}
          </LiveMembersTableHeadContainer>
          {membersList.map((trackingData, index) => (
            <LiveMembersTableContainer key={index}>
              <TableItem>
                <UserSection
                  imgSource={trackingData.avatar || null}
                  name={trackingData.name}
                  size="36px"
                  index={index}
                  color={trackingData.color || null }
                />
              </TableItem>
              <TableItem>
                <TableText name>{trackingData.project.name}</TableText>
              </TableItem>
              <TableItem>
                <TableText name>
                  {trackingData.task && trackingData.task.id
                    ? trackingData.task.name
                    : "-"}
                </TableText>
              </TableItem>
              <TableItem>
                <TableText name>
                  {moment.unix(trackingData.start_timestamp).format("hh:mm a")}
                </TableText>
              </TableItem>

              {/* <TableItem>
                {selectedOrganization &&
                  selectedOrganization.configuration &&
                  selectedOrganization.configuration.screenshot_track && (
                    <ColoredButtonWithIcon type="blue">
                      Take Screenshot
                    </ColoredButtonWithIcon>
                  )}
              </TableItem> */}
            </LiveMembersTableContainer>
          ))}
        </>
      ) : (
        <NoDataComponent
          title={
            searchTerm
              ? "No member was tracking in last 5 minutes with this name"
              : "Seems no one was tracking in last 5 minutes"
          }
        />
      )}
    </Container>
  );
};

export default LastFiveMinActiveList;
