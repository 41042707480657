import { connect } from "react-redux";

import ApprovalHistory from "./ApprovalHistory";

import { clearLoadMoreTimesheetApprovalList } from "../timesheetApprovalActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,

  historyList: state.timesheetApproval.historyList,

  // loading
  isLoading: state.timesheetApproval.isLoading,
  seeMoreIsLoading: state.timesheetApproval.seeMoreIsLoading,

  // pagination
  paginationSize: state.timesheetApproval.paginationSize,
  allLoadedDates: state.timesheetApproval.allLoadedDates,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  clearLoadMoreTimesheetApprovalList: () =>
    dispatch(clearLoadMoreTimesheetApprovalList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalHistory);
