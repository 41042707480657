import { useState, useEffect, useRef, useMemo } from "react";
import moment from "moment-timezone";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Polyline,
} from "@react-google-maps/api";

import {
  LabelWithTextSection,
  CommonFlex,
  CommonText,
  DividerLine,
} from "../../../../styledComponents/common";
import { MarkerInfoContainer, TimeInfoDiv } from "../routeMapStyles";

import routeStart from "../../../../assets/img/icons/route-start.svg";
import routeEnd from "../../../../assets/img/icons/route-end.svg";
import routeMarker from "../../../../assets/img/icons/route-marker.svg";

const options = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  disableDoubleClickZoom: true,
  gestureHandling: "cooperative",
  mapId: process.env.REACT_APP_JOB_SITES_MAP_ID,
};

const containerStyle = {
  width: "100%",
  height: "80vh",
  borderLeft: "1px solid #e4e7eb",
  borderRadius: "0 10px 10px 0",
  // marginTop: "10px",
};
const center = {
  lat: 40.749933,
  lng: -73.98633,
};

const getMarkerIcon = (marker) => {
  const icon = {
    url: marker.start ? routeStart : marker.end ? routeEnd : routeMarker,
  };
  if (marker.start || marker.end) {
    icon.size = new window.google.maps.Size(20, 20);
    icon.anchor = new window.google.maps.Point(10, 10);
  }

  return icon;
};

const RouteMapMarker = (props) => {
  const { marker, timesheet, activeMarkerId, setActiveMarkerId } = props;

  const handleMarkerClick = () => {
    if (!activeMarkerId || (activeMarkerId && activeMarkerId !== marker.id)) {
      setActiveMarkerId(marker.id);
    } else {
      setActiveMarkerId(null);
    }
  };

  return (
    <Marker
      key={marker.id}
      icon={getMarkerIcon(marker)}
      position={marker.location}
      onClick={() => handleMarkerClick()}
    >
      {activeMarkerId && activeMarkerId === marker.id && (
        <InfoWindow onCloseClick={() => setActiveMarkerId(null)}>
          <MarkerInfoContainer>
            <LabelWithTextSection padding="15px 15px 10px" gap="8px">
              <CommonText fontSize="13px">{timesheet.project.name}</CommonText>
              {timesheet.task && <CommonText>{timesheet.task.name}</CommonText>}
            </LabelWithTextSection>

            <DividerLine />
            <LabelWithTextSection padding="10px 15px 15px" gap="8px">
              <CommonText $label fontSize="12px" fontWeight="500">
                {moment.unix(marker.timestamp).format("ddd, MMMM DD, YYYY")}
              </CommonText>
              <TimeInfoDiv>
                {moment.unix(marker.timestamp).format("h:mm A")}
              </TimeInfoDiv>
            </LabelWithTextSection>
          </MarkerInfoContainer>
        </InfoWindow>
      )}
    </Marker>
  );
};

const RouteMapGoogleMap = (props) => {
  const [activeMarkerId, setActiveMarkerId] = useState(null);

  const { markers, linePoints, setMap, map, selectedTimesheet } = props;

  useMemo(() => {
    if (map && linePoints && linePoints.length) {
      const bounds = new window.google.maps.LatLngBounds();
      linePoints.forEach((line) => {
        const position = new window.google.maps.LatLng(
          parseFloat(line.lat),
          parseFloat(line.lng)
        );
        bounds.extend(position);
      });
      map.fitBounds(bounds);
    }
  }, [map, markers]);

  return (
    <GoogleMap
      options={options}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      onLoad={(map) => setMap(map)}
      clickableIcons={false}
    >
      {markers && markers.length
        ? markers.map((marker) => {
            return (
              <RouteMapMarker
                marker={marker}
                timesheet={selectedTimesheet}
                activeMarkerId={activeMarkerId}
                setActiveMarkerId={setActiveMarkerId}
              />
            );
          })
        : null}
      {linePoints && linePoints.length ? (
        <Polyline
          path={linePoints}
          options={{
            geodesic: true,
            strokeColor: "#4d94fb",
            strokeOpacity: 1.0,
            strokeWeight: 4,
          }}
        />
      ) : null}
    </GoogleMap>
  );
};

export default RouteMapGoogleMap;
