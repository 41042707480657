import { connect } from "react-redux";

// Component
import AddClient from "./AddClient";

// Actions
import { createClient } from "../clientsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.client.isLoading,
  selectedOrganization: state.organization.selectedOrganization,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  createClient: (details) => dispatch(createClient({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AddClient);
