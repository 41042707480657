import { useState, useEffect } from "react";
import classnames from "classnames";
import styled, { keyframes } from "styled-components";
import {
  PageTitle,
  CommonText,
  CommonGrid,
  FirstWordRound,
} from "../../../styledComponents/common";
import {
  UpgradeUpperSection,
  BillingContainer,
  UpgradeLowerSection,
} from "../../../styledComponents/billing";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

const BillingUpperSection = styled(UpgradeUpperSection)`
  grid-template-columns: 200px auto 200px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;
const BillingLowerSection = styled(UpgradeLowerSection)`
  grid-template-columns: 400px;
  grid-gap: 0;
  padding-top: 15px;

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const PaddlePaymentContainer = styled.div`
  width: 100%;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const spin = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;
const LoaderSpinner = styled.div`
  width: 40px;
  height: 40px;
  margin: 0;
  background: transparent;
  border-top: 3px solid #20bead;
  border-right: 3px solid transparent;
  border-radius: 50%;
  -webkit-animation: 1s spin linear infinite;
  animation: 1s ${spin} linear infinite;
`;
const LoaderText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  margin-top: 30px;
`;

const RoundLetter = ({ text, color, isActive }) => {
  return (
    <FirstWordRound
      backColor={isActive ? color : "#84919e"}
      size="65px"
      fontSize="30px"
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};

const PaymentLoader = () => (
  <LoaderContainer>
    {/* <LoaderSpinner /> */}
    <ComponentCircleLoader padding="0" />
    <LoaderText>Complete your order by entering the details here...</LoaderText>
  </LoaderContainer>
);

const PaddleCheckout = (props) => {
  const [isPaddleLoading, setIsPaddleLoading] = useState(true);

  const Paddle = window.Paddle;

  const loadCallback = () => {
    setIsPaddleLoading(false);
  };

  const successCallback = () => {
    setTimeout(() => {
      props.history.push("/user/dashboard");
    }, 2000);
  };

  useEffect(() => {
    if (
      props.location.state &&
      props.location.state.plan &&
      props.location.state.plan.organizationId
    ) {
      if (props.location.state.plan.paddleId) {
        const email = localStorage.getItem("email");
        Paddle.Checkout.open({
          method: "inline",
          product: props.location.state.plan.paddleId,
          email: email || "",
          passthrough: props.location.state.plan.organizationId,
          allowQuantity: false,
          disableLogout: true,
          loadCallback: loadCallback,
          successCallback: successCallback,
          frameTarget: "paddle-checkout",
          frameInitialHeight: 416,
          frameStyle:
            "width:100%; min-width:312px; background-color: transparent; border: none;",
        });
      }
    } else {
      props.history.goBack();
    }
  }, []);

  return (
    <div className="content">
      <PageTitle>Billing - Checkout</PageTitle>

      <BillingContainer style={{ gridTemplateColumns: "90%" }}>
        <div>
          <BillingUpperSection alignItems="center">
            <BackButtonComponent
              onClick={() => props.history.goBack()}
              subTitle="Go back to payment"
            />
            <CommonGrid
              columns="auto"
              gap="15px"
              justifyContent="center"
              style={{ justifyItems: `center` }}
            >
              {/* {props.location.state &&
                props.location.state.plan &&
                props.location.state.plan.organizationName && (
                  <RoundLetter
                    text={props.location.state.plan.organizationName}
                    color={props.location.state.plan.organizationColor}
                    isActive={props.location.state.plan.isOrgActive}
                  />
                )} */}
              <CommonText name title fontSize="20px">
                Payment Details
              </CommonText>
            </CommonGrid>
            <div />
          </BillingUpperSection>
          <BillingLowerSection>
            <PaddlePaymentContainer>
              {isPaddleLoading && <PaymentLoader />}
              <div
                className={classnames(
                  "paddle-checkout",
                  !isPaddleLoading && "loaded"
                )}
              ></div>
            </PaddlePaymentContainer>
          </BillingLowerSection>
        </div>
      </BillingContainer>
    </div>
  );
};

export default PaddleCheckout;
