import { connect } from "react-redux";

// Component
import Settings from "./Settings";

// Actions
import {
  getOrganizationSubscription,
  updateSubscriptionPaymentMethod,
  clearPaymentMethodUpdated,
} from "../Subscription/subscriptionActions";
import {
  getWeeklyEmailSettings,
  updateWeeklyEmailSettings,
} from "./settingsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.subscription.isLoading,
  settingsIsLoading: state.settings.isLoading,
  updatePaymentMethodLoading: state.subscription.updatePaymentMethodLoading,

  selectedOrganization: state.organization.selectedOrganization,
  currentPlan: state.subscription.currentSubscription,
  updatePaymentMethodSuccess: state.subscription.updatePaymentMethodSuccess,
  weeklyEmail: state.settings.weeklyEmail,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationSubscription: (payload) =>
    dispatch(getOrganizationSubscription(payload)),
  updateSubscriptionPaymentMethod: (payload) =>
    dispatch(updateSubscriptionPaymentMethod(payload)),
  getWeeklyEmailSettings: (payload) =>
    dispatch(getWeeklyEmailSettings(payload)),
  updateWeeklyEmailSettings: (payload) =>
    dispatch(updateWeeklyEmailSettings(payload)),

  clearPaymentMethodUpdated: () => dispatch(clearPaymentMethodUpdated()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Settings);
