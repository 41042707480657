import { useState } from "react";

import {
  CardTitle,
  CommonText,
  InputWithLabelSection,
  InputLabel,
  InputField,
  InputTextArea,
  CommonFlex,
} from "../../styledComponents/common";
import { WhiteButton, ModalDeleteButton } from "../../styledComponents/buttons";
import {
  ProfileModalBody,
  ProfileModal,
  ModalDiv,
  Linediv,
} from "./profileStyles";

import {
  ShowPasswordIcon,
  DivPassword,
} from "../ChangePassword/changePasswordStyle";
import { CheckboxDiv, CheckboxTickIcon } from "../../styledComponents/settings";

import tickWhite from "../../assets/img/icons/tick_2.svg";
import viewPassword from "../../assets/img/common/password_show.svg";
import hidePassword from "../../assets/img/common/password_hide.svg";

const UserProfileDeleteModal = (props) => {
  const [passwordState, setPasswordSate] = useState("");
  const [agreedState, setAgreedSate] = useState(false);
  const [feedBackState, setFeedBackState] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPasswordSate(value);
  };
  const handleFeedBackChange = (e) => {
    const { value } = e.target;
    setFeedBackState(value);
  };

  const handleCheck = () => {
    setAgreedSate(!agreedState);
  };

  const handleDelete = () => {
    props.deleteAccount(passwordState, agreedState, feedBackState);
    setPasswordSate("");
    setAgreedSate(false);
  };

  const toggle = () => {
    setPasswordSate("");
    setAgreedSate(false);
    props.toggle();
  };
  const toggleShowPassword = (item) => {
    if (item === "password") {
      setShowPassword(!showPassword);
    }
  };
  return (
    <ProfileModal isOpen={props.isOpen} toggle={toggle} marginTop="2vh">
      <ProfileModalBody>
        <ModalDiv>
          <CardTitle>Thank you for using Apploye!</CardTitle>
          <InputTextArea
            id="comment"
            placeholder="We appreciate your feedback…"
            value={feedBackState}
            onChange={(e) => {
              handleFeedBackChange(e);
            }}
            height="120px"
          />
          <Linediv></Linediv>
          <CommonText title>
            Your account deletion request will be processed after 7 days. If you
            Change your mind, contact support to stop deletion process.
          </CommonText>
          <InputWithLabelSection>
            <InputLabel>Your Current Password</InputLabel>
            <DivPassword>
              <InputField
                type={showPassword ? "text" : "password"}
                value={passwordState}
                onChange={(e) => {
                  handlePasswordChange(e);
                }}
              />
              <ShowPasswordIcon
                right={"255px"}
                src={showPassword ? hidePassword : viewPassword}
                alt=""
                onClick={(e) => toggleShowPassword("password")}
              />
            </DivPassword>
          </InputWithLabelSection>
          <CommonFlex margin="10px 0 0 0" gap="10px">
            <CheckboxDiv active={agreedState} onClick={handleCheck}>
              <CheckboxTickIcon src={tickWhite} alt="" />
            </CheckboxDiv>
            <CommonText $label fontWeight="500">
              I agree to continue
            </CommonText>
          </CommonFlex>
          <CommonFlex margin="20px 0 0 auto" gap="8px">
            <WhiteButton type="cancel" onClick={toggle}>
              Cancel
            </WhiteButton>
            <ModalDeleteButton
              type="delete"
              onClick={() => handleDelete()}
              disabled={!agreedState || passwordState.length < 1}
            >
              Delete Account
            </ModalDeleteButton>
          </CommonFlex>
        </ModalDiv>
      </ProfileModalBody>
    </ProfileModal>
  );
};

export default UserProfileDeleteModal;
