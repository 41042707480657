import { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  Circle,
  OverlayView,
} from "@react-google-maps/api";

import jobSiteIcon from "../../../assets/img/icons/jobsite.svg";
import { MapRadiusInfoDiv, MapRadiusInfoText } from "./jobSitesStyles";

const options = {
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  disableDoubleClickZoom: true,
  gestureHandling: "cooperative",
  mapId: process.env.REACT_APP_JOB_SITES_MAP_ID,
};

const containerStyle = {
  width: "100%",
  height: "85vh",
  borderLeft: "1px solid #e4e7eb",
  borderRadius: "0 10px 10px 0",
  // marginTop: "10px",
};
const center = {
  lat: 40.749933,
  lng: -73.98633,
};
const circleOptions = {
  strokeColor: "#6F7AFF",
  strokeWeight: 2,
  fillColor: "#6F7AFF",
  fillOpacity: 0.2,
};

const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
  return {
    x: labelAnchor.x,
    // x: offsetWidth + labelAnchor.x,
    y: offsetHeight + labelAnchor.y,
  };
};

const JobSitesMap = (props) => {
  const [showMarkerInfo, setShowMarkerInfo] = useState(false);

  const {
    currentPage,
    sitesList,
    activeSite,
    selectSite,
    singleAddressMarker,
    setAddressFromMap,
    setMap,
    radius,
  } = props;

  return (
    <GoogleMap
      options={options}
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      onLoad={(map) => setMap(map)}
      clickableIcons={false}
      onDblClick={(location) => setAddressFromMap(location)}
    >
      {currentPage === "list" ? (
        <>
          {sitesList && sitesList.length
            ? sitesList.map((site) => {
                return (
                  <>
                    <Marker
                      key={site.id}
                      position={site.location}
                      icon={{
                        url: jobSiteIcon,
                        scaledSize: {
                          height: 55,
                          width: 55,
                        },
                      }}
                      onClick={(e) => {
                        selectSite(site);
                      }}
                    />
                    {activeSite &&
                    activeSite.id &&
                    activeSite.id === site.id ? (
                      <OverlayView
                        key={`ov-${site.id}`}
                        position={site.location}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={(x, y) =>
                          getPixelPositionOffset(x, y, { x: -30, y: -20 })
                        }
                      >
                        <MapRadiusInfoDiv>
                          <MapRadiusInfoText>
                            {activeSite.radius} m
                          </MapRadiusInfoText>
                        </MapRadiusInfoDiv>
                      </OverlayView>
                    ) : null}
                  </>
                );
              })
            : null}
          {activeSite && activeSite.id ? (
            <Circle
              center={activeSite.location}
              radius={activeSite.radius}
              options={circleOptions}
            />
          ) : null}
        </>
      ) : currentPage === "details" ? (
        singleAddressMarker ? (
          <>
            <Marker
              position={singleAddressMarker}
              icon={{
                url: jobSiteIcon,
                scale: 20,
              }}
              draggable
              onDragEnd={(e) => setAddressFromMap(e)}
            />
            <OverlayView
              key={`ovd`}
              position={singleAddressMarker}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={(x, y) =>
                getPixelPositionOffset(x, y, { x: -30, y: -20 })
              }
            >
              <MapRadiusInfoDiv>
                <MapRadiusInfoText>{radius} m</MapRadiusInfoText>
              </MapRadiusInfoDiv>
            </OverlayView>
            <Circle
              center={singleAddressMarker}
              radius={radius}
              options={circleOptions}
            />
          </>
        ) : null
      ) : null}

      {/* {addressMarker && (
        <>
          <Marker
            position={addressMarker}
            icon={{
              url: jobSiteIcon,
              scale: 20,
            }}
            draggable
            onClick={(e) => {
              console.log(e);
              setShowMarkerInfo(true);
            }}
            onDragStart={() => {
              if (showMarkerInfo) {
                setShowMarkerInfo(false);
              }
            }}
            onDragEnd={(e) => handleSetAddressFromMapClickOrMarkerDrag(e)}
          >
            {showMarkerInfo && (
              <InfoWindow onCloseClick={() => setShowMarkerInfo(false)}>
                <div>test</div>
              </InfoWindow>
            )}
          </Marker>

          <Circle
            center={addressMarker}
            radius={radius}
            options={circleOptions}
          />
        </>
      )} */}
    </GoogleMap>
  );
};

export default JobSitesMap;
