import { connect } from "react-redux";

import InstantScreenshots from "./InstantScreenshots";

import { getInstantScreenshotsList } from "../liveFeedActions";

const mapStateToProps = (state) => ({
  isLoading: state.liveFeed.isLoading,
  buttonLoading: state.liveFeed.buttonLoading,
  instantScreenshotsList: state.liveFeed.instantScreenshotsList,
  selectedOrganization: state.organization.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  getInstantScreenshotsList: (payload) =>
    dispatch(getInstantScreenshotsList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstantScreenshots);
