import { useState, useEffect } from "react";
import {
  PageTitle,
  CommonText,
  CommonGrid,
  InputLabel,
  FirstWordRound,
  ColoredText,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";
import {
  UpgradeUpperSection,
  BillingContainer,
  ToggleSection,
  ToggleText,
  SaveTag,
  UpgradeLowerSection,
  PaymentCard,
  UserNumberBox,
  AdditionalUserInputSection,
  AdditionalUserInput,
  InputButton,
  OrderSummarySection,
  ColoredDot,
  LinkSpan,
} from "../../../styledComponents/billing";
import {
  ToggleButton,
  ToggleButtonRound,
} from "../../../styledComponents/invoice";
import { YearlySaveBadge } from "../subscriptionStyles";
import {
  PrepurchasedSectionContainer,
  AdditionalSectionContainer,
  AdditionalSectionTitle,
} from "./upgradePlanStyles";

import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

import minus from "../../../assets/img/icons/minus.svg";
import plus from "../../../assets/img/icons/plus_black_2.svg";

const RoundLetter = ({ text, color, isActive }) => {
  return (
    <FirstWordRound
      backColor={isActive ? color : "#84919e"}
      size="50px"
      fontSize="26px"
    >
      {" "}
      {text.slice(0, 1).toUpperCase()}{" "}
    </FirstWordRound>
  );
};

const PrepurchasedSection = ({ seats, amount }) => {
  return (
    <PrepurchasedSectionContainer>
      <AdditionalSectionTitle padding="0px 30px">
        Pre-Purchased Seats
      </AdditionalSectionTitle>
      <CommonGrid
        padding="10px 30px 20px 30px"
        style={{ borderBottom: `1px solid #EAEEF7` }}
      >
        <CommonText name>
          {seats} Seats X ${amount}/seat/month X 12
        </CommonText>
        <CommonText name title>
          $ {(amount * seats * 12).toFixed(2)}
        </CommonText>
      </CommonGrid>
    </PrepurchasedSectionContainer>
  );
};

const AdditionalSection = ({ seats, amount }) => {
  return (
    <AdditionalSectionContainer>
      <AdditionalSectionTitle>Additional Users</AdditionalSectionTitle>
      <CommonGrid padding="0">
        <CommonText name>
          {seats} Users X ${amount}/user/month
        </CommonText>
        <CommonText name title>
          $ {(amount * seats).toFixed(2)}/ Month
        </CommonText>
      </CommonGrid>
    </AdditionalSectionContainer>
  );
};

const UpgradePlan = (props) => {
  const [planInterval, setPlanInterval] = useState("yearly");
  const [planData, setPlanData] = useState(null);
  const [usersNumber, setUsersNumber] = useState(0);
  // const [prepurchasedCount, setPrepurchasedCount] = useState(0);
  const [usersAmount, setUsersAmount] = useState(0);

  const [totalSeats, setTotalSeats] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // const [prepurchasedSeats, setPrepurchasedSeats] = useState(0);
  // const [additionalSeats, setAdditionalSeats] = useState(0);

  const [currentPlan, setCurrentPlan] = useState(null);

  const { subscriptionUpdated, selectedOrganization } = props;

  useEffect(() => {
    if (
      props.location.state &&
      props.location.state.plan &&
      props.location.state.currentPlan
    ) {
      const planInfo = { ...props.location.state.plan };
      setPlanInterval(planInfo.interval);
      setPlanData(planInfo);
      setUsersNumber(planInfo.currentUsers);
      // if (
      //   planInfo.interval === "yearly" &&
      //   planInfo.prepurchasedCount &&
      //   planInfo.prepurchasedCount > 0 &&
      //   planInfo.currentUsers > 0
      // ) {
      //   if (planInfo.currentUsers === 1) {
      //     if (planInfo.prepurchasedCount > planInfo.currentUsers) {
      //       setPrepurchasedSeats(
      //         planInfo.prepurchasedCount - planInfo.currentUsers
      //       );
      //     }
      //   } else if (planInfo.prepurchasedCount > planInfo.currentUsers - 1) {
      //     setPrepurchasedSeats(
      //       planInfo.prepurchasedCount - planInfo.currentUsers + 1
      //     );
      //   }
      //   if (planInfo.currentUsers > planInfo.prepurchasedCount + 1) {
      //     const seats = planInfo.currentUsers - planInfo.prepurchasedCount - 1;

      //     setAdditionalSeats(seats);
      //   }
      // }
      setCurrentPlan({ ...props.location.state.currentPlan });
    } else {
      props.history.goBack();
    }

    return () => {
      props.clearCurrentSubscription();
      props.clearSubscriptionUpdated();
      setCurrentPlan(null);
      // setPrepurchasedSeats(0);
      // setAdditionalSeats(0);
    };
  }, []);

  useEffect(() => {
    if (planData && planData.interval) {
      let planInfo = { ...planData };
      if (planInterval === "yearly") {
        planInfo.interval = "yearly";
      } else {
        planInfo.interval = "monthly";
      }
      setPlanData({ ...planInfo });
    }
  }, [planInterval]);

  useEffect(() => {
    if (planData && planData.amounts) {
      let seats = usersNumber;
      let total = 0.0;
      let usersTotal = 0.0;
      if (seats > 1) {
        seats -= 1;
      }
      if (planInterval === "yearly") {
        // if (seats > 1) {
        //   seats += prepurchasedCount - 1;
        // } else {
        //   if (prepurchasedCount > 0) {
        //     seats += prepurchasedCount - 1;
        //   }
        // }
        total = (seats * planData.amounts.yearlyAmount).toFixed(2);
      } else if (planInterval === "monthly") {
        // if (seats > 1) {
        //   seats -= 1;
        // }
        total = (seats * planData.amounts.monthlyAmount).toFixed(2);
      }
      usersTotal = total;
      // if (
      //   prepurchasedSeats &&
      //   prepurchasedSeats > 0 &&
      //   planInterval === "yearly"
      // ) {
      //   const prepurchasedAmount =
      //     prepurchasedSeats * planData.amounts.yearlyAmount;
      //   total = (parseFloat(total) + prepurchasedAmount).toFixed(2);
      // }
      // if (additionalSeats && additionalSeats > 0 && planInterval === "yearly") {
      //   total = (parseFloat(total) - planData.amounts.yearlyAmount).toFixed(2);
      //   usersTotal = total;
      //   seats -= 1;
      // }
      setUsersAmount(usersTotal);
      setTotalSeats(seats);
      setTotalAmount(total);
    }
  }, [
    planInterval,
    planData,
    usersNumber,
    // prepurchasedCount,
    // prepurchasedSeats,
    // additionalSeats,
  ]);

  useEffect(() => {
    if (
      subscriptionUpdated &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      props.history.push("/user/current-plan");
      setTimeout(() => {
        props.clearSubscriptionUpdated();
      }, 200);
    }
  }, [subscriptionUpdated]);

  const onToggleInterval = () => {
    if (planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };

  // const onUsersNumberChange = (e) => {
  //   setPrepurchasedCount(e.target.value);
  // };

  // const decreaseUsers = () => {
  //   if (parseInt(prepurchasedCount) > 0 && prepurchasedCount !== "") {
  //     setPrepurchasedCount(parseInt(prepurchasedCount) - 1);
  //   }
  // };

  // const increaseUsers = () => {
  //   if (prepurchasedCount !== "") {
  //     setPrepurchasedCount(parseInt(prepurchasedCount) + 1);
  //   } else {
  //     setPrepurchasedCount(0);
  //   }
  // };

  const confirmUpgrade = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      planData &&
      planData.ids
    ) {
      let payload = {
        organization_id: selectedOrganization.id,
        plan_id:
          planData.interval === "yearly"
            ? planData.ids.yearlyId
            : planData.ids.monthlyId,
      };
      if (currentPlan) {
        // if (currentPlan.interval === "monthly") {
        //   payload.prepurchased_count = totalSeats;
        // } else if (
        //   planData.interval === "yearly" &&
        //   planData.prepurchasedCount
        // ) {
        //   payload.prepurchased_count = planData.prepurchasedCount;
        // }
        // console.log(payload);
        props.updateOrganizationSubscription(payload);
      }
    }
  };

  return (
    <div className="content">
      <PageTitle>Billing - Upgrade</PageTitle>
      <BillingContainer>
        <div>
          <UpgradeUpperSection>
            <BackButtonComponent
              onClick={() => props.history.goBack()}
              subTitle="Go back to plans"
            />
            <CommonGrid
              columns="auto"
              gap="15px"
              justifyContent="center"
              style={{ justifyItems: `center` }}
            >
              {/* {selectedOrganization && selectedOrganization.name && (
                <RoundLetter
                  text={selectedOrganization.name}
                  color={
                    selectedOrganization.color
                      ? selectedOrganization.color
                      : "#9A96F9"
                  }
                  isActive={selectedOrganization.is_active}
                />
              )} */}
              <CommonText name title fontSize="20px">
                {planData ? `Upgrade to ${planData.name}` : `Upgrade Plan`}
              </CommonText>
              {currentPlan &&
                currentPlan.interval &&
                currentPlan.interval === "monthly" &&
                planData &&
                planData.name !== "Solo" && (
                  <ToggleSection isCheckout>
                    <ToggleText
                      active={planInterval === "yearly"}
                      onClick={() => setPlanInterval("yearly")}
                    >
                      <YearlySaveBadge isActive={planInterval === "yearly"}>
                        Save 50%
                      </YearlySaveBadge>
                      Yearly
                    </ToggleText>
                    <ToggleButton
                      onClick={() => onToggleInterval()}
                      style={{ backgroundColor: `#20BEAD` }}
                    >
                      <ToggleButtonRound active={planInterval === "monthly"} />
                    </ToggleButton>

                    <ToggleText
                      active={planInterval === "monthly"}
                      onClick={() => setPlanInterval("monthly")}
                    >
                      Monthly
                    </ToggleText>
                  </ToggleSection>
                )}
            </CommonGrid>
            <div />
          </UpgradeUpperSection>
          <UpgradeLowerSection>
            <div>
              <InputLabel fontSize="14px">Current Users</InputLabel>
              <CommonText fontSize="13px" style={{ marginTop: `10px` }}>
                Current number of active members in your organization
              </CommonText>
              <UserNumberBox>
                {usersNumber > 1 ? usersNumber - 1 : usersNumber}
              </UserNumberBox>
              {/* {planInterval === "yearly" &&
                currentPlan &&
                currentPlan.interval === "monthly" && (
                  <>
                    <InputLabel fontSize="14px" margin="25px 0 0 0">
                      Pre-Purchase Additional Seats
                    </InputLabel>
                    <CommonText fontSize="13px" style={{ marginTop: `10px` }}>
                      Save 50 % (yearly discount) per user by pre purchasing
                      seats. Users added afterwards won’t be entitled for yearly
                      discount until plan renews.
                    </CommonText>
                    <AdditionalUserInputSection>
                      <AdditionalUserInput
                        type="number"
                        min="0"
                        placeholder="type users number"
                        value={prepurchasedCount}
                        onChange={(e) => onUsersNumberChange(e)}
                      />
                      <InputButton onClick={() => decreaseUsers()}>
                        <img src={minus} alt="minus" />
                      </InputButton>
                      <InputButton
                        onClick={() => increaseUsers()}
                        style={{ borderRadius: `0 6px 6px 0` }}
                      >
                        <img src={plus} alt="plus" />
                      </InputButton>
                    </AdditionalUserInputSection>
                  </>
                )} */}
              <InputLabel fontSize="14px" margin="25px 0 0 0">
                While you upgrade to a new plan in the middle of a billing cycle
              </InputLabel>
              <OrderSummarySection>
                <ColoredDot color="#9A96F9" />
                <CommonText fontSize="13px">
                  The unused portion of your current billing cycle will be
                  applied as a credit
                </CommonText>
                <ColoredDot />
                <CommonText fontSize="13px">
                  The cost of the new plan for the remaining billing cycle will
                  be added as debit.
                </CommonText>
                <ColoredDot color="#4D94FB" />
                <CommonText fontSize="13px">
                  Your payment method will be charged accordingly once you
                  confirm the upgrade. Please contact support if your need any
                  help of clarification.
                </CommonText>
              </OrderSummarySection>
            </div>
            <PaymentCard>
              <CommonGrid
                padding="30px 30px 40px 30px"
                style={{
                  borderBottom: `1px solid #EAEEF7`,
                }}
              >
                <CommonText name>
                  {totalSeats} Users X $
                  {planData && planData.amounts && planInterval === "yearly"
                    ? (planData.amounts.yearlyAmount / 12).toFixed(1)
                    : planData &&
                      planData.amounts &&
                      planData.amounts.monthlyAmount}
                  /seat/month{planInterval === "yearly" && ` X 12`}
                </CommonText>
                <CommonText name title>
                  $ {usersAmount}
                </CommonText>
              </CommonGrid>
              {/* {prepurchasedSeats &&
              prepurchasedSeats > 0 &&
              planInterval === "yearly" ? (
                <PrepurchasedSection
                  seats={prepurchasedSeats}
                  amount={
                    planData &&
                    planData.amounts &&
                    (planData.amounts.yearlyAmount / 12).toFixed(1)
                  }
                />
              ) : null} */}
              <CommonGrid columns="1fr" padding="30px" gap="30px">
                <CommonGrid>
                  <CommonText name title>
                    Total{" "}
                    <span style={{ color: `#fe5969` }}>(Excluding VAT)</span>
                  </CommonText>
                  <CommonText name title>
                    $ {totalAmount}/{" "}
                    {planInterval === "yearly" ? "Year" : "Month"}
                  </CommonText>
                </CommonGrid>
                {/* {additionalSeats &&
                additionalSeats > 0 &&
                planInterval === "yearly" ? (
                  <AdditionalSection
                    seats={additionalSeats}
                    amount={
                      planData &&
                      planData.amounts &&
                      planData.amounts.monthlyAmount
                    }
                  />
                ) : null} */}
                <ColoredText type="blue" fontSize="13px" fontWeight="600">
                  👉 VAT/ Sales taxes will be added in payment based on
                  location.
                </ColoredText>
                <PrimaryButton
                  fontSize="16px"
                  height="46px"
                  onClick={() => confirmUpgrade()}
                >
                  {props.updateSubscriptionIsLoading ? (
                    <ButtonTextLoader loadingText="Processing" />
                  ) : (
                    "Confirm Upgrade"
                  )}
                </PrimaryButton>
              </CommonGrid>
            </PaymentCard>
          </UpgradeLowerSection>
        </div>
      </BillingContainer>
    </div>
  );
};

export default UpgradePlan;
