import { useState, useEffect } from "react";

import {
  CommonText,
  ColoredText,
  CommonFlex,
  CommonImage,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";
import {
  SitesListContainer,
  ListHeaderContainer,
  ListItemsContainer,
  SingleSiteItem,
  NoDataContainer,
  NoDataArtwork,
  HoveredSection,
  EditButton,
  DeleteButton,
} from "./jobSitesStyles";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";

import jobsiteIcon from "../../../assets/img/icons/jobsite.svg";
import editIcon from "../../../assets/img/icons/edit.svg";
import deleteWhiteIcon from "../../../assets/img/icons/delete_white.svg";
import editWhiteIcon from "../../../assets/img/icons/edit-white.svg";

const SitesListSection = (props) => {
  const [hoveredId, setHoveredId] = useState(null);

  const {
    handleChangePage,
    sitesList,
    activeSite,
    selectSite,
    sitesListLoading,
    toggleDeleteModal,
  } = props;

  const onHoverChange = (id) => {
    setHoveredId(id);
  };

  return (
    <SitesListContainer>
      {sitesListLoading ? (
        <ComponentCircleLoader />
      ) : sitesList && sitesList.length ? (
        <>
          <ListHeaderContainer>
            <CommonFlex justifyContent="space-between">
              <CommonText fontSize="16px" fontWeight="600">
                All Sites
              </CommonText>
              <ColoredText
                primary
                fontSize="13px"
                semibold
                style={{ cursor: "pointer" }}
                onClick={() => handleChangePage("details", null)}
              >
                Add Job Site
              </ColoredText>
            </CommonFlex>
          </ListHeaderContainer>
          <ListItemsContainer>
            {sitesList.map((site, index) => {
              return (
                <SingleSiteItem
                  key={site.id}
                  isActive={
                    activeSite && activeSite.id && activeSite.id === site.id
                  }
                  onClick={() => selectSite(site)}
                  onMouseEnter={() => onHoverChange(site.id)}
                  onMouseLeave={() => onHoverChange(null)}
                >
                  <CommonFlex justifyContent="space-between">
                    <CommonFlex gap="10px">
                      <CommonImage src={jobsiteIcon} alt="" size="35px" />
                      <CommonText fontWeight="600">{site.name}</CommonText>
                    </CommonFlex>
                    {/* <CommonImage
                      src={editIcon}
                      alt="edit"
                      style={{ zIndex: "2", cursor: "pointer" }}
                      onClick={() => handleChangePage("details", site)}
                    /> */}
                  </CommonFlex>
                  <CommonText $label fontWeight="500" margin="5px 0 0 45px">
                    {site.address}
                  </CommonText>
                  {hoveredId && hoveredId === site.id ? (
                    <HoveredSection>
                      <EditButton
                        onClick={() => handleChangePage("details", site)}
                      >
                        <CommonImage src={editWhiteIcon} alt="" size="26px" />
                      </EditButton>
                      <DeleteButton onClick={() => toggleDeleteModal(site.id)}>
                        <CommonImage src={deleteWhiteIcon} alt="" size="16px" />
                      </DeleteButton>
                    </HoveredSection>
                  ) : null}
                </SingleSiteItem>
              );
            })}
          </ListItemsContainer>
        </>
      ) : (
        <NoDataContainer>
          <CommonImage src={jobsiteIcon} alt="" size="35px" />
          <CommonText fontSize="15px" fontWeight="600" margin="20px 0 0">
            No Job Sites!
          </CommonText>
          <CommonText $label fontSize="13px" fontWeight="500" margin="15px 0 0">
            Add your very first job site
          </CommonText>
          <PrimaryButton
            margin="30px 0 0"
            onClick={() => handleChangePage("details", null)}
          >
            Add Job Site
          </PrimaryButton>
        </NoDataContainer>
      )}
    </SitesListContainer>
  );
};

export default SitesListSection;
