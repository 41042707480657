import { connect } from "react-redux";

import PayrollFilter from "./PayrollFilter";

//Actions
import {
  getPayablesData,
  getHourlyPaymentHistory,
  getOnetimePaymentHistory,
} from "./payrollActions";
import {
  getOrganizationMembersList,
  getProjectAssigneeList,
} from "../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,
  projectsList: state.project.projectShortList,
  projectAssigneeList: state.project.projectAssigneeList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getPayablesData: (details) => dispatch(getPayablesData({ ...details })),
  getHourlyPaymentHistory: (details) =>
    dispatch(getHourlyPaymentHistory({ ...details })),
  getOnetimePaymentHistory: (details) =>
    dispatch(getOnetimePaymentHistory({ ...details })),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollFilter);
