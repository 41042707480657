import chrome from "../../../assets/img/appIcons/chrome.svg";
import slack from "../../../assets/img/appIcons/slack.svg";
import skype from "../../../assets/img/appIcons/skype.svg";

const AppLists = [
  {
    winId: "chrome.exe",
    macId: "Google Chrome",
    linuxId: "chrome",
    name: "Chrome",
    icon: chrome,
  },
  {
    winId: "slack.exe",
    macId: "Slack",
    linuxId: "slack",
    name: "Slack",
    icon: slack,
  },
  // {
  //   winId: "Explorer.EXE",
  //   macId: "",
  //   linuxId: "nautilus",
  //   name: "Explorer",
  //   icon: chrome,
  // },
  // {
  //   winId: "javaw.exe",
  //   macId: "Apploye",
  //   linuxId: "Apploye",
  //   name: "Apploye",
  //   icon: chrome,
  // },
  // {
  //   winId: "LockApp.exe",
  //   macId: "",
  //   linuxId: "",
  //   name: "Lock Screen",
  //   icon: chrome,
  // },
  // {
  //   winId: "SearchUI.exe",
  //   macId: "",
  //   linuxId: "",
  //   name: "Search Bar",
  //   icon: chrome,
  // },
  // {
  //   winId: "",
  //   macId: "Safari",
  //   linuxId: "",
  //   name: "Safari",
  //   icon: chrome,
  // },
  // {
  //   winId: "Code.exe",
  //   macId: "",
  //   linuxId: "code-insiders",
  //   name: "VS Code",
  //   icon: chrome,
  // },
  // {
  //   winId: "",
  //   macId: "Terminal",
  //   linuxId: "gnome-terminal-",
  //   name: "Terminal/Console",
  //   icon: chrome,
  // },
  // {
  //   winId: "WINWORD.EXE",
  //   macId: null,
  //   linuxId: null,
  //   name: "MS Word",
  //   icon: chrome,
  // },
  // {
  //   winId: "Illustrator.exe",
  //   macId: "",
  //   linuxId: "",
  //   name: "Adobe Illustrator",
  //   icon: chrome,
  // },
  // {
  //   winId: "",
  //   macId: "pycharm",
  //   linuxId: "",
  //   name: "Pycharm",
  //   icon: chrome,
  // },
  // {
  //   winId: null,
  //   macId: "Finder",
  //   linuxId: null,
  //   name: "Finder",
  //   icon: chrome,
  // },
  // {
  //   winId: "firefox.exe",
  //   macId: "Firefox",
  //   linuxId: "firefox",
  //   name: "Firefox",
  //   icon: chrome,
  // },
  // {
  //   winId: null,
  //   macId: "VirtualBox",
  //   linuxId: null,
  //   name: "Virtual Box",
  //   icon: chrome,
  // },
  // {
  //   winId: "",
  //   macId: "WeChat",
  //   linuxId: null,
  //   name: "We Chat",
  //   icon: chrome,
  // },
  {
    winId: "SkypeApp.exe",
    macId: "Skype",
    linuxId: "skypeforlinux",
    name: "Skype",
    icon: skype,
  },
];

export default AppLists;
