import { connect } from "react-redux";

// Component
import Checkout from "./Checkout";

// Actions
import {
  updateOrganizationSubscription,
  applyCoupon,
  clearCurrentSubscription,
  clearSubscriptionUpdated,
  clearDiscountInfo,
  clearDiscountErrorMessage,
} from "../subscriptionActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.subscription.isLoading,
  updateSubscriptionIsLoading: state.subscription.updateSubscriptionIsLoading,
  applyCouponLoading: state.subscription.applyCouponLoading,
  selectedOrganization: state.organization.selectedOrganization,
  subscriptionUpdated: state.subscription.subscriptionUpdated,
  discountInfo: state.subscription.discountInfo,
  discountErrorMessage: state.subscription.discountErrorMessage,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  updateOrganizationSubscription: (payload) =>
    dispatch(updateOrganizationSubscription(payload)),
  applyCoupon: (payload) => dispatch(applyCoupon(payload)),
  clearCurrentSubscription: () => dispatch(clearCurrentSubscription()),
  clearSubscriptionUpdated: () => dispatch(clearSubscriptionUpdated()),
  clearDiscountInfo: () => dispatch(clearDiscountInfo()),
  clearDiscountErrorMessage: () => dispatch(clearDiscountErrorMessage()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
