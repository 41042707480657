import { useState, useEffect } from "react";

import moment from "moment";
import DatePicker from "../../../components/SingleDatePicker/SingleDatePicker";
import { TabLeftGrid } from "../../../styledComponents/members";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../../styledComponents/common";
import { PrimaryButton } from "../../../styledComponents/buttons";

import ClockInOutTable from "./ClockInOutTable";

import SearchWithButton from "../../../components/Search/SearchWithButton";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

const GeofenceClockInOut = (props) => {
  const [selectedDate, setSelectedDate] = useState(moment());

  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    history,
    selectedOrganization,
    isLoading,
    getGeofenceClockInOutList,
    geofenceClockInOutList,

    // pagination
    // geofenceClockInOutListCount,
    // geofenceClockInOutPageSize,
  } = props;

  useEffect(() => {
    setCurrentPageNumber(1);
    getData(selectedDate);
  }, [selectedOrganization]);

  const handleSearchByName = () => {
    let name = "";
    if (searchTerm && searchTerm.trim()) {
      name = searchTerm.toLocaleLowerCase().trim();
      setCurrentSearchedTerm(searchTerm);
    } else {
      setCurrentSearchedTerm("");
    }
    getData(selectedDate, name);
  };
  const handleClearSearch = () => {
    if (currentSearchedTerm) {
      getData(selectedDate);
    }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    let name = null;
    if (currentSearchedTerm) {
      name = currentSearchedTerm.toLocaleLowerCase().trim();
    }
    getData(selectedDate, name, page);
    setCurrentPageNumber(page);
  };

  const getData = (date, name, page) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        date: date.format("YYYY-MM-DD"),
      };
      if (name) {
        payload.name = name;
      }
      if (page) {
        payload.page = page;
      }
      // get call
      getGeofenceClockInOutList(payload);
    }
  };

  const onDateSelect = (date) => {
    setSelectedDate(date);
    getData(date);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  return (
    <div className="content">
      <PageTitle>Geofence Clock In/Out</PageTitle>
      <HeaderContainer>
        <TabLeftGrid
          // columns="58% 42%"
          columns="80%"
        >
          {/* <SearchWithButton
            itemName="Member"
            searchTermName="member name"
            searchInput={searchTerm}
            onInputChange={onSearchTermChange}
            handleSearch={handleSearchByName}
            handleClearSearch={handleClearSearch}
          /> */}
          <InputWithLabelSection>
            <FilterLabel>Date</FilterLabel>
            <DatePicker
              id="clockInOutDate"
              date={selectedDate}
              onDateChange={onDateSelect}
              dateDisplayFormat="DD/MM/YYYY"
              placeholder="Select date..."
              disableFutureDates
            />
          </InputWithLabelSection>
        </TabLeftGrid>
        {/* {selectedOrganization && selectedOrganization.role !== "member" && (
          <PrimaryButton
            onClick={() => history.push("/user/reports/time-and-activity")}
          >
            See Today's Report
          </PrimaryButton>
        )} */}
      </HeaderContainer>
      {/* {geofenceClockInOutListCount &&
      geofenceClockInOutListCount > 0 &&
      geofenceClockInOutPageSize ? (
        <TopPagination
          itemName="members"
          totalCount={geofenceClockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={geofenceClockInOutPageSize}
        />
      ) : null} */}
      <ClockInOutTable
        clockInOutList={geofenceClockInOutList}
        isLoading={isLoading}
      />
      {/* {geofenceClockInOutListCount &&
      geofenceClockInOutListCount > 0 &&
      geofenceClockInOutPageSize ? (
        <BottomPagination
          totalCount={geofenceClockInOutListCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={geofenceClockInOutPageSize}
        />
      ) : null} */}
    </div>
  );
};

export default GeofenceClockInOut;
