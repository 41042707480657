import { connect } from "react-redux";

// Component
import ClockInOut from "./ClockInOut";

// Actions
import { exportClockInOutList, getClockInOutList } from "./clockInOutActions";
import { getOrganizationMembersList } from "custom_modules/Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  clockInOutList: state.clockInOut.clockInOutList,
  isLoading: state.clockInOut.isLoading,

  // pagination
  clockInOutListCount: state.clockInOut.clockInOutListCount,
  clockInOutPageSize: state.clockInOut.clockInOutPageSize,
  organizationMembersList: state.project.orgMembersList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getClockInOutList: (payload) => dispatch(getClockInOutList(payload)),
  exportClockInOutList: (payload) => dispatch(exportClockInOutList(payload)),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ClockInOut);
