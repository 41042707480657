import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Button,
} from "reactstrap";
import ScheduleForm from "./ScheduleForm";
import { handleValidation } from "../../utils/helper";
import ScheduleFormFields from "./ScheduleFormFields";
import moment from "moment";

const initialState = {
  selectedProject: { label: "Select project", value: "" },
  members: [],
  selectedTask: { label: "Select task", value: "" },
  projectAssignees: [],
  checkedAllWeek: false,
  checked: {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  },
  validationMessages: {},
  alreadyUpdated: false,
  start_date: "",
  start_time: "",
  end_date: "",
  end_time: "",
};

class ScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
    this.handleDate = this.handleDate.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.selectProject = this.selectProject.bind(this);
    this.selectAssignees = this.selectAssignees.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidUpdate() {
    const { selectedSchedule, isOpen, mode } = this.props;
    if (
      mode === "update" &&
      selectedSchedule &&
      selectedSchedule.id &&
      isOpen &&
      !this.state.alreadyUpdated
    ) {
      const checked = {
        monday: selectedSchedule.monday,
        saturday: selectedSchedule.saturday,
        friday: selectedSchedule.friday,
        sunday: selectedSchedule.sunday,
        thursday: selectedSchedule.thursday,
        tuesday: selectedSchedule.tuesday,
        wednesday: selectedSchedule.wednesday,
      };

      let checkedAllWeek = false;
      const checkedValues = Object.values(checked);
      if (checkedValues.every((value) => value === true)) {
        checkedAllWeek = true;
      }

      const members =
        selectedSchedule.members.length > 0 &&
        selectedSchedule.members.map((item) => ({
          value: item.id,
          label: `${item.first_name}${
            item.last_name ? ` ${item.last_name}` : ``
          }`,
        }));
      this.setState({
        members,
        selectedProject: selectedSchedule.project
          ? {
              value: selectedSchedule.project.id,
              label: selectedSchedule.project.name,
            }
          : initialState.selectedProject,
        selectedTask: selectedSchedule.task
          ? {
              value: selectedSchedule.task.id,
              label: selectedSchedule.task.name,
            }
          : initialState.selectedTask,
        checkedAllWeek,
        checked,
        end_date: selectedSchedule.end_date,
        end_time: selectedSchedule.end_time,
        start_date: selectedSchedule.start_date,
        start_time: selectedSchedule.start_time,
        alreadyUpdated: true,
      });
    } else if (
      !isOpen &&
      JSON.stringify(this.state) !== JSON.stringify(initialState)
    ) {
      this.setState({
        ...initialState,
      });
    }
  }

  handleValidation = (name, value) => {
    const { validationMessages } = this.state;
    //start validation
    const el = ScheduleFormFields.find((v) => {
      return v.fieldName === name;
    });
    const result = handleValidation(name, el.type, value, el.options);
    let validationMessagesLast = { ...validationMessages };

    if (result.isValid) {
      delete validationMessagesLast[name];
    } else {
      validationMessagesLast = { ...validationMessagesLast, [name]: result };
    }
    //end validation
    this.setState({
      validationMessages: validationMessagesLast,
    });
  };

  selectProject = (e) => {
    const { projectList, organizationId, getTaskList } = this.props;
    const selectedProject = projectList.find(
      (project) => project.id === e.value
    );

    const projectAssignees =
      selectedProject && selectedProject.assignees
        ? selectedProject.assignees.map((assignee) => ({
            label: assignee.name,
            value: assignee.id,
          }))
        : [];
    this.setState({
      selectedProject: e,
      projectAssignees,
      members: [],
      selectedTask: initialState.selectedTask,
    });

    if (e.value) {
      const details = {
        organization_id: organizationId,
        project_id: e.value,
      };
      getTaskList(details);
    }
  };

  selectTask = (e) => {
    const { taskList, projectList } = this.props;

    const selectedTask = taskList.find((task) => task.id === e.value);
    const selectedProject = projectList.find(
      (project) => project.id === this.state.selectedProject.value
    );

    const projectAssignees =
      selectedTask && selectedTask.assignees
        ? selectedTask.assignees.map((assignee) => ({
            label: assignee.name,
            value: assignee.id,
          }))
        : selectedProject && selectedProject.assignees
        ? selectedProject.assignees.map((assignee) => ({
            label: assignee.name,
            value: assignee.id,
          }))
        : [];

    this.setState({
      selectedTask: e.value ? e : initialState.selectedTask,
      projectAssignees,
      members: [],
    });
  };

  selectAssignees = (e) => {
    const members = [];
    e.forEach((user) => {
      members.push({ label: user.label, value: user.value });
    });
    this.setState({
      members,
    });
  };

  handleDate(name, date) {
    let date_time;
    if (name === "start_time" || name === "end_time") {
      date_time = date.value;
    } else if (name === "start_date" || name === "end_date") {
      date_time = moment(date).format("YYYY-MM-DD");
    }
    this.setState({
      [name]: date_time,
    });
  }

  handleChange = (value, name) => {
    const { checked, checkedAllWeek } = this.state;
    if (name === "allWeek") {
      const newValue = {};
      Object.entries(checked).forEach(
        ([key, value]) => (newValue[key] = !checkedAllWeek)
      );
      this.setState((prevState) => ({
        checked: newValue,
        checkedAllWeek: !prevState.checkedAllWeek,
      }));
    } else {
      let checkedValue = checked[name];
      checked[name] = !checkedValue;
      this.setState({
        checked,
      });
    }
  };

  handleSave = () => {
    const {
      members,
      selectedProject,
      start_date,
      end_date,
      start_time,
      end_time,
      selectedTask,
      checked,
      checkedAllWeek,
    } = this.state;
    const fieldsToCheck = {
      members,
      start_date,
      end_date,
      start_time,
      end_time,
    };

    for (const [key, value] of Object.entries(fieldsToCheck)) {
      setTimeout(() => {
        this.handleValidation(key, value);
      }, 0);
    }
    let checkedDay = false;
    if (!checkedAllWeek) {
      if (Object.values(checked).every((day) => day === false)) {
        const checkbox = {
          isValid: false,
          fieldName: "checkbox",
          message: "One of the fields is required!",
        };
        this.setState((prevState) => ({
          validationMessages: { ...prevState.validationMessages, checkbox },
        }));
      } else {
        checkedDay = true;
      }
    } else {
      checkedDay = true;
    }

    if (
      members.length > 0 &&
      start_time &&
      start_date &&
      end_date &&
      end_time &&
      checkedDay
    ) {
      const { members } = fieldsToCheck;
      const memberIds = members.map((member) => member.value);
      fieldsToCheck.members = memberIds;

      let start = moment(start_time, "hh:mm A").format("HH:mm:ss");
      let end = moment(end_time, "hh:mm A").format("HH:mm:ss");

      fieldsToCheck.start_time = start;
      fieldsToCheck.end_time = end;

      const payload = {
        ...fieldsToCheck,
        monday: checked.monday,
        tuesday: checked.tuesday,
        wednesday: checked.wednesday,
        thursday: checked.thursday,
        friday: checked.friday,
        saturday: checked.saturday,
        sunday: checked.sunday,
      };

      if (this.props.mode !== "update") {
        if (selectedTask && selectedTask.value) {
          payload["task"] = selectedTask.value;
        }
        if (selectedProject && selectedProject.value) {
          payload["project"] = selectedProject.value;
        }
        this.props.handleSave(payload);
      } else {
        if (selectedTask && selectedTask !== undefined && selectedTask.value) {
          payload["task"] = selectedTask.value;
        }
        if (selectedProject && selectedProject.value) {
          payload["project"] = selectedProject.value;
        }

        this.props.handleUpdate(payload);
      }
      this.setState({
        ...initialState,
      });
    }
  };

  render() {
    const {
      isOpen,
      toggle,
      mode,
      projectList,
      taskList,
      userList,
      deactivateSchedule,
      selectedSchedule,
    } = this.props;
    const {
      projectAssignees,
      checked,
      checkedAllWeek,
      members,
      selectedTask,
      validationMessages,
      selectedProject,
      start_date,
      start_time,
      end_date,
      end_time,
    } = this.state;
    const title = mode === "update" ? "Edit Schedule" : "Add Schedule";

    const projects = [{ label: "Select project", value: "" }];
    projectList &&
      projectList
        .filter((project) => project.is_active === true)
        .map((project) =>
          projects.push({ label: project.name, value: project.id })
        );

    const tasks = [{ label: "Select task", value: "" }];
    taskList &&
      taskList.map((task) => tasks.push({ label: task.name, value: task.id }));

    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="addMember-modal schedule-modal"
      >
        <ModalHeader toggle={toggle}>
          <Row style={{ display: "flex", alignItems: "center" }}>
            <div className="col-md-6 text-left">{title}</div>
            {mode === "update" && (
              <div className="col-md-6 text-right">
                <Button
                  style={{ backgroundColor: "#F74866" }}
                  onClick={() => deactivateSchedule(selectedSchedule.id)}
                >
                  Delete
                </Button>
              </div>
            )}
          </Row>
        </ModalHeader>
        <ModalBody>
          <ScheduleForm
            projectAssignees={
              (projectAssignees && projectAssignees.length > 0) ||
              (selectedTask && selectedTask.value)
                ? projectAssignees
                : userList
            }
            projects={projects}
            selectProject={this.selectProject}
            selectedProject={selectedProject}
            selectAssignees={this.selectAssignees}
            handleDate={this.handleDate}
            handleChange={this.handleChange}
            selectTask={this.selectTask}
            checked={checked}
            taskList={tasks}
            checkedAllWeek={checkedAllWeek}
            selectedAssignees={members}
            selectedTask={selectedTask}
            validationMessages={validationMessages}
            handleValidation={this.handleValidation}
            start_date={start_date}
            start_time={start_time}
            end_date={end_date}
            end_time={end_time}
          />
        </ModalBody>
        <ModalFooter className="add-time-footer">
          <Button
            color="secondary"
            onClick={toggle}
            style={{ fontSize: "14px" }}
          >
            Cancel
          </Button>
          <Button
            outline
            className={`custom-header-button today-button-mt border-none pull-right btn-spec-hover`}
            style={{
              fontSize: "14px",
              padding: "15px",
              marginTop: "10px",
            }}
            onClick={this.handleSave}
          >
            {mode === "update" ? "Edit Schedule" : "Add Schedule"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ScheduleModal;
