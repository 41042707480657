import {
  GET_PUBLIC_API_KEY,
  CREATE_PUBLIC_API_KEY,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getPublicApiKey(value) {
  return {
    type: GET_PUBLIC_API_KEY,
    payload: value,
  };
}

export function createPublicApiKey(value) {
  return {
    type: CREATE_PUBLIC_API_KEY,
    payload: value,
  };
}