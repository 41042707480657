import { connect } from "react-redux";

// Component
import CustomReport from "./CustomReport";

import {
  deleteCustomReports,
  getCustomReportList,
  getReportNotes,
} from "../reportsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  organization: state.organization.selectedOrganization,
  customReportList: state.report.customReportList,
  profile: state.profile,
  isLoading: state.report.isLoading,
  isDeleteLoading: state.report.isDeleteLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomReportList: (payload) => dispatch(getCustomReportList(payload)),
  deleteCustomReports: (payload) => dispatch(deleteCustomReports(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(CustomReport);
