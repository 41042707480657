import React from "react";

import Marker from "./MarkerNew";
import MapWrapper from "./MapWrapper";

let markerArray = [];
// let polylineArr = [];
let polylineStartIcons = [];
let polylineEndIcons = [];

class FullScreenMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: null,
      polylines: [],
    };
  }

  componentDidMount() {
    if (this.props.info && this.props.info.length > 0) {
      const polylineArr = [];
      const mks = this.props.info
        .filter((info) => info.locations && info.locations.length)
        .map((inf, index) => {
          if (inf.locations.length > 1) {
            polylineArr.push(
              inf.locations.map((elem, index) => {
                if (index === 0) {
                  polylineStartIcons.push(elem);
                }
                if (index === inf.locations.length - 1) {
                  polylineEndIcons.push(elem);
                }
                return {
                  lat: parseFloat(elem.latitude),
                  lng: parseFloat(elem.longitude),
                };
              })
            );
          }
          inf.locations.forEach((item, i) => {
            item.projectName = inf.project.name;
            item.orgName = inf.organization.name;
            item.parentId = index;
          });
          return inf.locations;
        })
        .reduce((acc, curr, i, arr) => {
          return acc.concat(curr);
        }, [])
        .map((marker, i, arr) => {
          return (
            <Marker
              isStart={polylineStartIcons}
              isEnd={polylineEndIcons}
              key={i}
              content={marker}
              location={{
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude),
              }}
              takenTime={marker.timestamp}
            />
          );
        });
      this.setState({
        markers: mks,
        polylines: polylineArr,
      });
    }
  }

  getCenter() {
    if (this.props.info.length) {
      // eslint-disable-next-line
      this.props.info.map((item) => {
        item.locations.length &&
          item.locations.forEach((i, k) => {
            markerArray.push({
              lat: parseFloat(i.latitude),
              lng: parseFloat(i.longitude),
            });
          });
      });
    }
    this._bounds = new window.google.maps.LatLngBounds();
    if (markerArray && markerArray.length) {
      markerArray.forEach((marker, index) => {
        const position = new window.google.maps.LatLng(marker.lat, marker.lng);
        this._bounds.extend(position);
      });
      return this._bounds;
    }
    const position = new window.google.maps.LatLng(23.8371525, 90.367087);
    this._bounds.extend(position);
    return this._bounds.getCenter();
  }

  componentDidUpdate(prevProps) {
    if (this._map) {
      this._map && this._map.fitBounds(this._bounds);
    }
  }

  handleMapMounted = async (map) => {
    this.getCenter();
    this._map = map;
    this._map && (await this._map.fitBounds(this._bounds));
  };

  render() {
    return (
      <div id="map" className="map-content">
        {/* <MapWrapper
          markers={this.state.markers}
          info={this.props.info}
          polyline={polylineArr}
          // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDf-Ac8lc5Bm8yeryjvlL5mnLlm5fq3Stg" //old
          // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAzyN_HiOvMdWW-LU0CmalO9nOMnXdEHB0"
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBV6w1WXwK7hcdkZI89T8BcbwTHD10mSiQ" // paid
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          center={this.getCenter}
          onMapMounted={(map) => this.handleMapMounted(map)}
        /> */}
        <MapWrapper
          markers={this.state.markers}
          info={this.props.info}
          polyline={this.state.polylines}
          center={this.getCenter}
          onMapMounted={(map) => this.handleMapMounted(map)}
        />
      </div>
    );
  }
}

export default FullScreenMap;
