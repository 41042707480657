import React from "react";

// reactstrap components
import { Button, Input, Col, Row, Alert, Form, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";

import dealImage from "assets/img/LTD-deal.svg";
import logo from "assets/img/logo/logo.png";
import Loader from "../../modules/loader";
import { createNotification } from "../../modules/notificationManager";
import { validateEmail } from "../../utils/helper";

class RedeemCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: { value: "", error: true, message: "Empty email", show: false },
      coupons: [{ coupon: "", error: false }]
    };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCouponChange = this.handleCouponChange.bind(this);
    this.handleCouponRemove = this.handleCouponRemove.bind(this);
    this.handleCouponAdd = this.handleCouponAdd.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    document.body.classList.toggle("login-page");
    const { match } = this.props;
    const {
      params: { confirmation_key }
    } = match;
    this.setState({
      confirmation_key: confirmation_key
    });
  }

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  componentDidUpdate(prevProps) {
    const { redeemCode } = this.props;
    if (
      redeemCode.registerNeeded !== prevProps.redeemCode.registerNeeded &&
      redeemCode.registerNeeded === true
    ) {
      let tempEmail = this.state.email;
      tempEmail.message = "Need to register first";
      tempEmail.error = true;
      tempEmail.show = true;
      this.setState({
        email: tempEmail
      });
    }
    if (
      redeemCode.invalidCodes !== prevProps.redeemCode.invalidCodes &&
      redeemCode.invalidCodes
    ) {
      let tempCoupons = this.state.coupons;
      // eslint-disable-next-line
      redeemCode.invalidCodes.map(invalid => {
        // eslint-disable-next-line
        tempCoupons.map(coupon => {
          if (invalid === coupon.coupon) {
            coupon.error = true;
          }
        });
      });
      this.setState({ coupons: tempCoupons });
    }
  }

  handleEmailChange(event) {
    let tempMail = event.target.value;
    let tempError = false;
    let tempMessage = "";
    if (event.target.value === "" || event.target.value === null) {
      tempError = true;
      tempMessage = "Empty email";
    } else if (!validateEmail(event.target.value)) {
      tempError = true;
      tempMessage = "Invalid email";
    } else {
      tempError = false;
      tempMessage = "";
    }
    this.setState({
      email: {
        value: tempMail,
        error: tempError,
        message: tempMessage,
        show: false
      }
    });
  }

  onDismiss(value) {
    let tempEmail = this.state.email;
    tempEmail.show = false;
    if (value === "email") {
      this.setState({ email: tempEmail });
    }
  }

  handleCouponChange(i, event) {
    const tempCoupons = this.state.coupons;
    if (
      event.target.value === null ||
      event.target.value === "" ||
      event.target.value.length !== 16
    ) {
      tempCoupons[i].error = true;
    } else {
      tempCoupons[i].error = false;
    }
    tempCoupons[i].coupon = event.target.value;
    this.setState({
      coupons: tempCoupons
    });
  }

  handleCouponAdd() {
    const tempCoupons = this.state.coupons;
    let invalidCoupon = false;
    // eslint-disable-next-line
    tempCoupons.map(coupon => {
      if (coupon.coupon === null || coupon.error || coupon.coupon === "") {
        invalidCoupon = true;
      }
    });
    if (invalidCoupon) {
      createNotification("error", "Invalid or empty coupon exists", 3000);
    } else {
      tempCoupons.push({ coupon: "", error: false });
      this.setState({
        coupons: tempCoupons
      });
    }
  }

  handleCouponRemove(i) {
    const tempCoupons = this.state.coupons;
    if (tempCoupons.length > 1) {
      tempCoupons.splice(i, 1);
      this.setState({
        coupons: tempCoupons
      });
    } else {
      createNotification("error", "There must be at least one coupon", 3000);
    }
  }

  handleSave = e => {
    e.preventDefault();
    let invalid = false;
    if (this.state.email.error) {
      let tempEmail = this.state.email;
      tempEmail.show = true;
      this.setState({ email: tempEmail });
      invalid = true;
    }
    if (
      this.state.coupons.length === 1 &&
      this.state.coupons[0].coupon === ""
    ) {
      // eslint-disable-next-line
      this.state.coupons[0].error = true;
      invalid = true;
    }
    let codes = [];
    // eslint-disable-next-line
    this.state.coupons.map((code, index) => {
      if (code && code.coupon !== "" && !code.error) {
        codes.push(code.coupon);
      } else if (index !== this.state.coupons.length - 1 || code.error) {
        invalid = true;
      }
    });
    if (invalid) {
      return;
    } else {
      const payload = {
        email: this.state.email.value,
        codes: codes
      };
      this.props.redeem(payload);
    }
  };

  render() {
    return (
      <div className="login-page">
        <Loader open={this.props.redeemCode.isLoading} />
        <Row className="align-items-center">
          <Col md="6" style={{ borderRight: "3px solid #E8E8E8" }}>
            <img
              src={dealImage}
              style={{ height: "400px" }}
              className="login-img"
              alt="ltd"
            />
          </Col>
          <Col className="mr-auto ml-auto" md="6">
            <Row className="justify-content-sm-center">
              <Col lg="7" md="11" sm="10">
                <div className="card-login">
                  <Row className="text-center">
                    <Col lg="12" md="12">
                      <img src={logo} alt="" />
                    </Col>
                  </Row>
                  {this.props.redeemCode.redirect ? (
                    <>
                      <h1>Coupon added successfully.</h1>
                      <div className="text-center">
                        <Link
                          className="link-forgot text-center"
                          target="_blank"
                          style={{ color: "#51cbce", fontSize: "16px" }}
                          to="/auth/login"
                        >
                          Go to website
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <h2>
                        Please{" "}
                        <Link target="_blank" to="/auth/register">
                          Register
                        </Link>{" "}
                        before redeeming coupon code.
                      </h2>
                      <Form onSubmit={e => this.handleSave(e)}>
                        <FormGroup>
                          <Row className="text-center">
                            <Col md="12" sm="12">
                              <Input
                                type="email"
                                style={{ background: "#fff", padding: "10px" }}
                                placeholder="Email address"
                                onChange={e => this.handleEmailChange(e)}
                                value={this.state.email.value}
                                name="email"
                              />
                              <Alert
                                color="danger"
                                isOpen={this.state.email.show}
                                toggle={e => this.onDismiss("email")}
                                className="alert-login-page"
                              >
                                {this.state.email.message}
                              </Alert>
                            </Col>
                            <Col md={12} sm={12} xs={12}>
                              {this.state.coupons.map((field, idx) => {
                                return (
                                  <Row key={idx}>
                                    <Col md={10} sm={10} xs={10}>
                                      <Input
                                        type="text"
                                        style={{
                                          backgroundColor: "white",
                                          border: field.error
                                            ? "1px solid red"
                                            : "none",
                                          marginTop: "10px",
                                          padding: "8px",
                                          height: "38px"
                                        }}
                                        placeholder="Enter coupon"
                                        value={field.coupon || ""}
                                        onChange={e => {
                                          this.handleCouponChange(idx, e);
                                        }}
                                      />
                                      {field.error ? (
                                        <p style={{ color: "red" }}>
                                          Please enter a valid coupon
                                        </p>
                                      ) : null}
                                    </Col>
                                    <Col
                                      md={2}
                                      sm={2}
                                      xs={2}
                                      style={{ padding: 0 }}
                                    >
                                      <Button
                                        className="onboarding-cross-button"
                                        onClick={() =>
                                          this.handleCouponRemove(idx)
                                        }
                                      >
                                        X
                                      </Button>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Col>
                          </Row>

                          <Button
                            className="custom-header-button"
                            style={{
                              background: "#66615b",
                              marginTop: "10px"
                            }}
                            onClick={() => {
                              this.handleCouponAdd();
                            }}
                          >
                            <i className="fa fa-plus" /> Add more coupon
                          </Button>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Input
                              style={{ marginLeft: "14px" }}
                              type="checkbox"
                              required
                            />
                            <Col
                              className="text-left"
                              style={{ marginLeft: "23px" }}
                            >
                              By applying coupon, You are agreeing to our
                              <a
                                href="https://apploye.com/lifetime-deal-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-color"
                              >
                                Lifetime Deal Policy.
                              </a>
                              *Applicable For lifetime customers only*
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="pull-right">
                              <Button
                                type="submit"
                                className="custom-header-button btn-spec-hover"
                                style={{
                                  marginTop: "10px"
                                }}
                              >
                                {this.state.coupons.length === 1
                                  ? "Apply Coupon"
                                  : "Apply Coupons"}
                              </Button>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Form>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RedeemCode;
