import { connect } from "react-redux";

import ApprovalFilter from "./ApprovalFilter";

import { getOrganizationMembersList } from "../../Projects/projectsActions";

import {
  getTimesheetApprovalAwaitingList,
  getTimesheetApprovalHistory,
} from "../timesheetApprovalActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  organizationMembersList: state.project.orgMembersList,

  // pagination
  paginationSize: state.timesheetApproval.paginationSize,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (payload) =>
    dispatch(getOrganizationMembersList(payload)),
  getTimesheetApprovalAwaitingList: (payload) =>
    dispatch(getTimesheetApprovalAwaitingList(payload)),
  getTimesheetApprovalHistory: (payload) =>
    dispatch(getTimesheetApprovalHistory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalFilter);
