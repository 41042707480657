//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  //New Api
  GET_DASHBOARD_COMPETITIVE_ANALYTICS,
  GET_DASHBOARD_COMPETITIVE_ANALYTICS_SUCCESS,
  GET_DASHBOARD_COMPETITIVE_ANALYTICS_FAILURE,
  GET_DASHBOARD_ORGANIZATION_ANALYTICS,
  GET_DASHBOARD_ORGANIZATION_ANALYTICS_SUCCESS,
  GET_DASHBOARD_ORGANIZATION_ANALYTICS_FAILURE,
  GET_DASHBOARD_WEEKLY_AGGREGATED_DATA,
  GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_SUCCESS,
  GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_FAILURE,
  GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA,
  GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_SUCCESS,
  GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_FAILURE,
  GET_DASHBOARD_TODAYS_ACTIVITY,
  GET_DASHBOARD_TODAYS_ACTIVITY_SUCCESS,
  GET_DASHBOARD_TODAYS_ACTIVITY_FAILURE,
  GET_DASHBOARD_USER_ANALYTICS,
  GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
  GET_DASHBOARD_USER_ANALYTICS_FAILURE,
} from "../../modules/constants";

//New Api calls
export function* dashboardCompetitiveAnalyticsRequest({ payload }) {
  const { organization_id, start_date, end_date } = payload;
  try {
    const url = `${organization_id}/dashboard/competetive-analytics/?start_date=${start_date}&end_date=${end_date}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_COMPETITIVE_ANALYTICS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_COMPETITIVE_ANALYTICS_FAILURE,
      payload: err,
    });
  }
}

export function* dashboardWeeklyAggregatedDataRequest({ payload }) {
  const { organization_id, start_date, end_date } = payload;
  try {
    const url = `${organization_id}/dashboard/weekly-aggregated-data/?start_date=${start_date}&end_date=${end_date}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_FAILURE,
      payload: err,
    });
  }
}
export function* dashboardLastWeekAggregatedDataRequest({ payload }) {
  const { organization_id, start_date, end_date } = payload;
  try {
    const url = `${organization_id}/dashboard/weekly-aggregated-data/?start_date=${start_date}&end_date=${end_date}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_FAILURE,
      payload: err,
    });
  }
}
export function* dashboardTodaysActivityDataRequest({ payload }) {
  const { organization_id, date } = payload;
  try {
    const url = `${organization_id}/dashboard/today-activity/?date=${date}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_TODAYS_ACTIVITY_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_TODAYS_ACTIVITY_FAILURE,
      payload: err,
    });
  }
}
export function* dashboardOrganizationAnalyticsDataRequest({ payload }) {
  const { organization_id, start_date, end_date } = payload;
  try {
    const url = `${organization_id}/dashboard/organization-analytics/?start_date=${start_date}&end_date=${end_date}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_ORGANIZATION_ANALYTICS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_ORGANIZATION_ANALYTICS_FAILURE,
      payload: err,
    });
  }
}
export function* dashboardUserAnalyticsDataRequest({ payload }) {
  const { organization_id, start_date, end_date } = payload;
  try {
    const url = `${organization_id}/dashboard/user-analytics/?start_date=${start_date}&end_date=${end_date}`;
    let method = "GET";
    const response = yield defaultApi(url, method);
    yield put({
      type: GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_DASHBOARD_USER_ANALYTICS_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    //new api
    takeLatest(
      GET_DASHBOARD_COMPETITIVE_ANALYTICS,
      dashboardCompetitiveAnalyticsRequest
    ),
    takeLatest(
      GET_DASHBOARD_WEEKLY_AGGREGATED_DATA,
      dashboardWeeklyAggregatedDataRequest
    ),
    takeLatest(
      GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA,
      dashboardLastWeekAggregatedDataRequest
    ),
    takeLatest(
      GET_DASHBOARD_TODAYS_ACTIVITY,
      dashboardTodaysActivityDataRequest
    ),
    takeLatest(
      GET_DASHBOARD_ORGANIZATION_ANALYTICS,
      dashboardOrganizationAnalyticsDataRequest
    ),
    takeLatest(GET_DASHBOARD_USER_ANALYTICS, dashboardUserAnalyticsDataRequest),
  ]);
}
