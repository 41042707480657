import styled from "styled-components";

export const InstantScreenshotsTableHeader = styled.div`
  display: grid;
  grid-template-columns: 25% 20% 22% 15%;
  grid-column-gap: 8px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 200px 150px;
    grid-column-gap: 10px;
  }
`;

export const InstantScreenshotTableRow = styled.div`
  display: grid;
  grid-template-columns: 25% 20% 22% 15%;
  grid-column-gap: 8px;
  justify-content: start;
  padding: 0 20px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 200px 150px;
    grid-column-gap: 10px;
  }
`;

export const InstantScreenshotStatus = styled.span`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ color, theme, status }) =>
    color
      ? color
      : status === "responded"
      ? theme.colors.main.success
      : status === "pending"
      ? theme.colors.main.warning
      : status === "denied" || status === "timeout"
      ? theme.colors.main.error
      : theme.colors.main.error};
  text-transform: capitalize;
`;

export const InstantScreenshotThumbnail = styled.img`
  width: 72px;
  height: 40px;
`;
