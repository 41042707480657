import React from "react";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "../../components/ReactSelectDropdown";
import moment from "moment";
import { times } from "../../utils/times";
import DropdownStyles from "../../components/DropdownStyle/DropdownStyle";

const ScheduleForm = props => (
  <Form className="scheduleForm" onSubmit={props.handleSave}>
    <FormGroup row className="inline-form-view">
      <Label for="project" sm={2} className="inline-form-label">
        Project
      </Label>
      <Col md={6}>
        <Select
          name="project"
          id="project"
          className="text-left"
          isSearchable
          value={props.selectedProject ? props.selectedProject : ""}
          options={props.projects}
          onChange={e => props.selectProject(e)}
          styles={DropdownStyles()}
        />
      </Col>
    </FormGroup>
    <FormGroup row className="inline-form-view">
      <Label for="task" sm={2} className="inline-form-label">
        Task
      </Label>
      <Col md={6}>
        <Select
          name="task"
          id="task"
          className="text-left"
          isSearchable
          value={props.selectedTask ? props.selectedTask : ""}
          options={props.taskList}
          onChange={e => props.selectTask(e)}
          styles={DropdownStyles()}
        />
      </Col>
    </FormGroup>
    <FormGroup row className="inline-form-view">
      <Label for="members" sm={2} className="inline-form-label">
        Assignee*
      </Label>
      <Col md={6}>
        <Select
          name="members"
          id="members"
          isMulti
          isSearchable
          value={props.selectedAssignees ? props.selectedAssignees : []}
          options={props.projectAssignees}
          onChange={e => props.selectAssignees(e)}
          styles={DropdownStyles()}
        />
        {props.validationMessages.members && (
          <FormFeedback>
            {props.validationMessages.members.message}
          </FormFeedback>
        )}
      </Col>
    </FormGroup>
    <FormGroup row className="inline-form-view">
      <Label for="start_date" sm={2} className="inline-form-label">
        Start Date
      </Label>
      <Col md={6}>
        <ReactDatetime
          inputProps={{
            className: "form-control timesheet_time_picker",
            placeholder: "Start Date Here",
            value: props.start_date
              ? moment(props.start_date).format("MM/DD/YYYY")
              : "",
            readOnly: true,
            style: { cursor: "pointer" }
          }}
          id="start_date"
          name="start_date"
          timeFormat={false}
          closeOnSelect
          onChange={e => props.handleDate("start_date", e)}
        />
        {props.validationMessages.start_date && (
          <FormFeedback>
            {props.validationMessages.start_date.message}
          </FormFeedback>
        )}
      </Col>
    </FormGroup>
    <FormGroup row className="inline-form-view">
      <Label for="start_time" sm={2} className="inline-form-label">
        Schedule Time
      </Label>
      <Col md={6}>
        <Row>
          <Col md={5}>
            <Select
              name="start_time"
              id="start_time"
              isSearchable
              value={
                props.start_time
                  ? { label: props.start_time, value: props.start_time }
                  : ""
              }
              options={times}
              onChange={e => props.handleDate("start_time", e)}
              styles={DropdownStyles()}
            />
            {props.validationMessages.start_time && (
              <FormFeedback>
                {props.validationMessages.start_time.message}
              </FormFeedback>
            )}
          </Col>
          <Col
            md={2}
            style={{ marginTop: "5px", fontSize: "16px", paddingLeft: "20px" }}
          >
            To
          </Col>
          <Col md={5}>
            <Select
              name="end_time"
              id="end_time"
              isSearchable
              value={
                props.end_time
                  ? { label: props.end_time, value: props.end_time }
                  : ""
              }
              options={times}
              onChange={e => props.handleDate("end_time", e)}
              styles={DropdownStyles()}
            />
            {props.validationMessages.end_time && (
              <FormFeedback>
                {props.validationMessages.end_time.message}
              </FormFeedback>
            )}
          </Col>
        </Row>
      </Col>
    </FormGroup>
    <FormGroup row className="inline-form-view">
      <Label for="end_date" sm={2} className="inline-form-label">
        End Date
      </Label>
      <Col md={6}>
        <ReactDatetime
          inputProps={{
            className: "form-control timesheet_time_picker",
            placeholder: "End Date Here",
            value: props.end_date
              ? moment(props.end_date).format("MM/DD/YYYY")
              : "",
            readOnly: true,
            style: { cursor: "pointer" }
          }}
          id="end_date"
          name="end_date"
          timeFormat={false}
          closeOnSelect
          onChange={e => props.handleDate("end_date", e)}
        />
        {props.validationMessages.end_date && (
          <FormFeedback>
            {props.validationMessages.end_date.message}
          </FormFeedback>
        )}
      </Col>
    </FormGroup>
    <FormGroup row className="inline-form-view">
      <Label for="schedule_days" sm={2} className="inline-form-label">
        Schedule Days
      </Label>
      <Col md={6}>
        <div className="allWeek-checkbox">
          <div>
            <Input
              id="allWeek"
              type="checkbox"
              checked={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "allWeek")}
            />
            <Label for="allWeek">Select All</Label>
          </div>
        </div>
        {props.validationMessages.checkbox && (
          <FormFeedback>
            {props.validationMessages.checkbox.message}
          </FormFeedback>
        )}
        <div style={{ display: "flex" }}>
          <div className="weekday-checkbox">
            <Label for="sunday">Sun</Label>
            <Input
              id="sunday"
              type="checkbox"
              name="checkbox"
              checked={
                props.checked.sunday
                  ? props.checked.sunday
                  : props.checkedAllWeek
              }
              disabled={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "sunday")}
            />
          </div>
          <div className="weekday-checkbox">
            <Label for="monday">Mon</Label>
            <Input
              id="monday"
              type="checkbox"
              name="checkbox"
              checked={
                props.checked.monday
                  ? props.checked.monday
                  : props.checkedAllWeek
              }
              disabled={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "monday")}
            />
          </div>
          <div className="weekday-checkbox">
            <Label for="tuesday">Tues</Label>
            <Input
              id="tuesday"
              type="checkbox"
              name="checkbox"
              checked={
                props.checked.tuesday
                  ? props.checked.tuesday
                  : props.checkedAllWeek
              }
              disabled={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "tuesday")}
            />
          </div>
          <div className="weekday-checkbox">
            <Label for="wednesday">Wed</Label>
            <Input
              id="wednesday"
              type="checkbox"
              name="checkbox"
              checked={
                props.checked.wednesday
                  ? props.checked.wednesday
                  : props.checkedAllWeek
              }
              disabled={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "wednesday")}
            />
          </div>
          <div className="weekday-checkbox">
            <Label for="thursday">Thu</Label>
            <Input
              id="thursday"
              type="checkbox"
              name="checkbox"
              checked={
                props.checked.thursday
                  ? props.checked.thursday
                  : props.checkedAllWeek
              }
              disabled={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "thursday")}
            />
          </div>
          <div className="weekday-checkbox">
            <Label for="friday">Fri</Label>
            <Input
              id="friday"
              type="checkbox"
              name="checkbox"
              checked={
                props.checked.friday
                  ? props.checked.friday
                  : props.checkedAllWeek
              }
              disabled={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "friday")}
            />
          </div>
          <div className="weekday-checkbox">
            <Label for="saturday">Sat</Label>
            <Input
              id="saturday"
              type="checkbox"
              name="checkbox"
              checked={
                props.checked.saturday
                  ? props.checked.saturday
                  : props.checkedAllWeek
              }
              disabled={props.checkedAllWeek}
              onChange={e => props.handleChange(e, "saturday")}
            />
          </div>
        </div>
      </Col>
    </FormGroup>
  </Form>
);

export default ScheduleForm;
