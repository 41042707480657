import {
  //new apis
  GET_DASHBOARD_COMPETITIVE_ANALYTICS,
  GET_DASHBOARD_COMPETITIVE_ANALYTICS_SUCCESS,
  GET_DASHBOARD_COMPETITIVE_ANALYTICS_FAILURE,
  GET_DASHBOARD_ORGANIZATION_ANALYTICS,
  GET_DASHBOARD_ORGANIZATION_ANALYTICS_SUCCESS,
  GET_DASHBOARD_ORGANIZATION_ANALYTICS_FAILURE,
  GET_DASHBOARD_WEEKLY_AGGREGATED_DATA,
  GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_SUCCESS,
  GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_FAILURE,
  GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA,
  GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_SUCCESS,
  GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_FAILURE,
  GET_DASHBOARD_TODAYS_ACTIVITY,
  GET_DASHBOARD_TODAYS_ACTIVITY_SUCCESS,
  GET_DASHBOARD_TODAYS_ACTIVITY_FAILURE,
  GET_DASHBOARD_USER_ANALYTICS,
  GET_DASHBOARD_USER_ANALYTICS_SUCCESS,
  GET_DASHBOARD_USER_ANALYTICS_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";

// ------------------------------------
// Action handler methods
// ------------------------------------

//New Apis
export const handleDashboardCompetitiveAnalyticsRequest = (state, action) => {
  return {
    ...state,
    orgCompetitiveAnalyticsIsLoading: true,
  };
};
export const handleDashboardCompetitiveAnalyticsRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      orgCompetitiveAnalyticsIsLoading: false,
      orgCompetitiveAnalytics: data.data,
    };
  }
  return {
    ...state,
    orgCompetitiveAnalyticsIsLoading: false,
  };
};
export const handleDashboardCompetitiveAnalyticsRequestFailure = (
  state,
  action
) => {
  return {
    ...state,
    orgCompetitiveAnalyticsIsLoading: false,
    orgCompetitiveAnalytics: {
      top_5_active_user: [],
      top_5_logged_user: [],
    },
  };
};

export const handleDashboardWeeklyAggregatedDataRequest = (state, action) => {
  return {
    ...state,
    orgWeeklyAggregatedDataIsLoading: true,
  };
};
export const handleDashboardWeeklyAggregatedDataRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      orgWeeklyAggregatedDataIsLoading: false,
      orgWeeklyAggregatedData: data.data,
    };
  }
  return {
    ...state,
    orgWeeklyAggregatedDataIsLoading: false,
  };
};
export const handleDashboardWeeklyAggregatedDataRequestFailure = (
  state,
  action
) => {
  return {
    ...state,
    orgWeeklyAggregatedDataIsLoading: false,
    orgWeeklyAggregatedData: null,
  };
};

export const handleDashboardLastWeekAggregatedDataRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleDashboardLastWeekAggregatedDataRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      orgLastWeekAggregatedData: data.data,
    };
  }
  return {
    ...state,
  };
};
export const handleDashboardLastWeekAggregatedDataRequestFailure = (
  state,
  action
) => {
  return {
    ...state,
    orgLastWeekAggregatedData: null,
  };
};

export const handleDashboardOrgTodaysActivityRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleDashboardOrgTodaysActivityRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      orgTodaysActivity: data.data,
    };
  }
  return {
    ...state,
  };
};
export const handleDashboardOrgTodaysActivityRequestFailure = (
  state,
  action
) => {
  return {
    ...state,
    orgTodaysActivity: null,
  };
};

export const handleDashboardOrganizationAnalyticsRequest = (state, action) => {
  return {
    ...state,
    orgAnalyticsIsLoading: true,
  };
};
export const handleDashboardOrganizationAnalyticsRequestSuccess = (
  state,
  action
) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      orgAnalytics: data.data,
      orgAnalyticsIsLoading: false,
    };
  }
  return {
    ...state,
    orgAnalyticsIsLoading: false,
  };
};
export const handleDashboardOrganizationAnalyticsRequestFailure = (
  state,
  action
) => {
  return {
    ...state,
    orgAnalyticsIsLoading: false,
    orgAnalytics: null,
  };
};

export const handleDashboardUserAnalyticsRequest = (state, action) => {
  return {
    ...state,
    userAnalyticsIsLoading: true,
  };
};
export const handleDashboardUserAnalyticsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 200) {
    return {
      ...state,
      userAnalytics: data.data,
      userAnalyticsIsLoading: false,
    };
  }
  return {
    ...state,
    userAnalyticsIsLoading: false,
  };
};
export const handleDashboardUserAnalyticsRequestFailure = (state, action) => {
  return {
    ...state,
    userAnalyticsIsLoading: false,
    userAnalytics: null,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  //new apis
  [GET_DASHBOARD_COMPETITIVE_ANALYTICS]:
    handleDashboardCompetitiveAnalyticsRequest,
  [GET_DASHBOARD_COMPETITIVE_ANALYTICS_SUCCESS]:
    handleDashboardCompetitiveAnalyticsRequestSuccess,
  [GET_DASHBOARD_COMPETITIVE_ANALYTICS_FAILURE]:
    handleDashboardCompetitiveAnalyticsRequestFailure,

  [GET_DASHBOARD_WEEKLY_AGGREGATED_DATA]:
    handleDashboardWeeklyAggregatedDataRequest,
  [GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_SUCCESS]:
    handleDashboardWeeklyAggregatedDataRequestSuccess,
  [GET_DASHBOARD_WEEKLY_AGGREGATED_DATA_FAILURE]:
    handleDashboardWeeklyAggregatedDataRequestFailure,

  [GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA]:
    handleDashboardLastWeekAggregatedDataRequest,
  [GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_SUCCESS]:
    handleDashboardLastWeekAggregatedDataRequestSuccess,
  [GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA_FAILURE]:
    handleDashboardLastWeekAggregatedDataRequestFailure,

  [GET_DASHBOARD_TODAYS_ACTIVITY]: handleDashboardOrgTodaysActivityRequest,
  [GET_DASHBOARD_TODAYS_ACTIVITY_SUCCESS]:
    handleDashboardOrgTodaysActivityRequestSuccess,
  [GET_DASHBOARD_TODAYS_ACTIVITY_FAILURE]:
    handleDashboardOrgTodaysActivityRequestFailure,

  [GET_DASHBOARD_ORGANIZATION_ANALYTICS]:
    handleDashboardOrganizationAnalyticsRequest,
  [GET_DASHBOARD_ORGANIZATION_ANALYTICS_SUCCESS]:
    handleDashboardOrganizationAnalyticsRequestSuccess,
  [GET_DASHBOARD_ORGANIZATION_ANALYTICS_FAILURE]:
    handleDashboardOrganizationAnalyticsRequestFailure,

  [GET_DASHBOARD_USER_ANALYTICS]: handleDashboardUserAnalyticsRequest,
  [GET_DASHBOARD_USER_ANALYTICS_SUCCESS]:
    handleDashboardUserAnalyticsRequestSuccess,
  [GET_DASHBOARD_USER_ANALYTICS_FAILURE]:
    handleDashboardUserAnalyticsRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  //New Apis
  orgCompetitiveAnalytics: null,
  orgWeeklyAggregatedData: null,
  orgLastWeekAggregatedData: null,
  orgTodaysActivity: null,
  orgAnalytics: null,
  userAnalytics: null,
  orgCompetitiveAnalyticsIsLoading: true,
  orgWeeklyAggregatedDataIsLoading: true,
  orgAnalyticsIsLoading: true,
  userAnalyticsIsLoading: true,
};

export default function authReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
