import { connect } from "react-redux";

import MemberInvitation from "./MemberInvitation";

// Actions
import {
  inviteMember,
  deleteMemberInvitation,
  clearMemberInvited,
} from "../membersActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  projectList: state.project.projectShortList,
  isLoading: state.member.isLoading,
  projectIsLoading: state.project.shortListIsLoading,
  inviteMemberLoading: state.member.inviteMemberLoading,
  memberInvited: state.member.memberInvited,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  inviteMember: (details) => dispatch(inviteMember({ ...details })),
  deleteMemberInvitation: (details) =>
    dispatch(deleteMemberInvitation({ ...details })),
  clearMemberInvited: () => dispatch(clearMemberInvited()),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(MemberInvitation);
