import { useEffect, useState } from "react";

import {
  PricingPlanContainer,
  PricingPlanHeader,
  PricingCardContainer,
  SinglePricingCard,
  TopSectionContainer,
  PlanNameAndTagContainer,
  PlanName,
  PlanNameTag,
  PlanPriceContainer,
  PlanPrice,
  PlanPriceText,
  PlanDescription,
  PlanFeaturesContainer,
  SinglePlanFeature,
  FeatureTickIcon,
  FeatureText,
  TypeTogglerContainer,
  TypeText,
  PlanSelectButton,
  PlusAddonDiv,
  EverythingInPrev,
  ArrowLeft,
} from "./pricingPlanStyles";
import { CommonText } from "../../../../../styledComponents/common";
import { YearlySaveBadge } from "../../../../Subscription/subscriptionStyles";

import { planFeatures } from "./planFeatures";

import PlanToggleSwitch from "./PlanToggleSwitch";

import tickGreen from "../../../../../assets/img/onboardingImg/tick-green.svg";
import tickBlack from "../../../../../assets/img/onboardingImg/tick-black.svg";
import tickPurple from "../../../../../assets/img/onboardingImg/tick-purple.svg";
import tickBlue from "../../../../../assets/img/onboardingImg/tick-blue.svg";

import arrowLeft from "../../../../../assets/img/onboardingImg/left-arrow.svg";

const planPriceMonthly = {
  solo: 4,
  standard: 5,
  premium: 6,
  elite: 7,
};
const planPriceYearly = {
  solo: 2,
  standard: 2.5,
  premium: 3,
  elite: 3.5,
};

const PlanTypeToggler = (props) => {
  return (
    <TypeTogglerContainer>
      <TypeText
        active={props.planType && props.planType === "yearly"}
        onClick={() => props.setPlanInterval("yearly")}
      >
        <YearlySaveBadge
          isActive={props.planType && props.planType === "yearly"}
        >
          Save 50%
        </YearlySaveBadge>
        Yearly
      </TypeText>
      <PlanToggleSwitch
        checked={props.planType && props.planType === "monthly"}
        toggleAction={props.togglePlanType}
      />
      <TypeText
        active={props.planType && props.planType === "monthly"}
        onClick={() => props.setPlanInterval("monthly")}
      >
        Monthly
      </TypeText>
    </TypeTogglerContainer>
  );
};

const PricingPlan = (props) => {
  const [planInterval, setPlanInterval] = useState("yearly");

  const [soloYearly, setSoloYearly] = useState({
    id: "",
    paddleId: null,
  });
  const [soloMonthly, setSoloMonthly] = useState({
    id: "",
    paddle_id: null,
  });
  const [standardYearly, setStandardYearly] = useState({
    id: "",
    paddleId: null,
  });
  const [premiumYearly, setPremiumYearly] = useState({
    id: "",
    paddleId: null,
  });
  const [eliteYearly, setEliteYearly] = useState({
    id: "",
    paddleId: null,
  });
  const [standardMonthly, setStandardMonthly] = useState({
    id: "",
    paddleId: null,
  });
  const [premiumMonthly, setPremiumMonthly] = useState({
    id: "",
    paddleId: null,
  });
  const [eliteMonthly, setEliteMonthly] = useState({
    id: "",
    paddleId: null,
  });

  useEffect(() => {
    if (props.plans && props.plans.length > 0) {
      props.plans.map((item) => {
        if (item.name === "Solo" && item.interval === "yearly") {
          setSoloYearly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Solo" && item.interval === "monthly") {
          setSoloMonthly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Standard" && item.interval === "yearly") {
          setStandardYearly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Premium" && item.interval === "yearly") {
          setPremiumYearly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Elite" && item.interval === "yearly") {
          setEliteYearly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Standard" && item.interval === "monthly") {
          setStandardMonthly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Premium" && item.interval === "monthly") {
          setPremiumMonthly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        } else if (item.name === "Elite" && item.interval === "monthly") {
          setEliteMonthly({
            id: item.id,
            paddleId: item.paddle_id,
          });
        }
      });
    }
  }, [props.plans]);

  const checkError = () => {
    if (
      (props.activeStep && props.activeStep === "organization") ||
      !props.organization
    ) {
      props.handleBack("project");
      return false;
    } else if (props.activeStep && props.activeStep === "project") {
      props.handleBack("pricing");
      return false;
    } else {
      return true;
    }
  };

  const selectPlan = (plan, interval) => {
    if (checkError() && props.organization) {
      let planDetails = {
        name: plan,
      };
      if (plan === "Solo") {
        if (interval === "monthly" && soloMonthly.id) {
          planDetails.id = soloMonthly.id;
        } else if (interval === "yearly" && soloYearly.id) {
          planDetails.id = soloYearly.id;
        }
      } else if (plan === "Standard") {
        if (interval === "monthly" && standardMonthly.id) {
          planDetails.id = standardMonthly.id;
        } else if (interval === "yearly" && standardYearly.id) {
          planDetails.id = standardYearly.id;
        }
      } else if (plan === "Premium") {
        if (interval === "monthly" && premiumMonthly.id) {
          planDetails.id = premiumMonthly.id;
        } else if (interval === "yearly" && premiumYearly.id) {
          planDetails.id = premiumYearly.id;
        }
      } else if (plan === "Elite") {
        if (interval === "monthly" && eliteMonthly.id) {
          planDetails.id = eliteMonthly.id;
        } else if (interval === "yearly" && eliteYearly.id) {
          planDetails.id = eliteYearly.id;
        }
      }
      if (planDetails.id) {
        props.setSelectedPlan(planDetails);
        if (plan !== "Solo") {
          props.handleNext("pricing");
        }
      }
    }
  };

  const togglePlanInterval = () => {
    if (planInterval === "monthly") {
      setPlanInterval("yearly");
    } else {
      setPlanInterval("monthly");
    }
  };
  return (
    <PricingPlanContainer>
      <PricingPlanHeader>
        <CommonText fontSize="24px" mobileFontSize="16px" name title>
          Choose a plan. Don't worry you won't be charged now!
        </CommonText>
        <PlanTypeToggler
          planType={planInterval}
          togglePlanType={togglePlanInterval}
          setPlanInterval={setPlanInterval}
        />
      </PricingPlanHeader>
      <PricingCardContainer>
        {/* <SinglePricingCard plan="solo">
          <TopSectionContainer>
            <PlanName plan="solo">SOLO</PlanName>
            <PlanPriceContainer>
              <PlanPrice>
                $
                {planInterval === "yearly"
                  ? planPriceYearly.solo
                  : planPriceMonthly.solo}
              </PlanPrice>
              <PlanPriceText>one user/month</PlanPriceText>
            </PlanPriceContainer>
            <PlanDescription plan="solo">
              Ideal for freelancers & contractors to track time, keep focus &
              bill clients.
            </PlanDescription>
            <PlanSelectButton
              plan="solo"
              onClick={() => selectPlan("Solo", planInterval)}
            >
              Try Free For 10 Days
            </PlanSelectButton>
          </TopSectionContainer>
          <PlanFeaturesContainer margin="15px 0">
            {planFeatures.solo.map((item, index) => (
              <SinglePlanFeature key={index}>
                <FeatureTickIcon
                  src={tickGreen}
                  alt=""
                  style={{ margin: `2px 0 0 0` }}
                />
                <FeatureText>{item}</FeatureText>
              </SinglePlanFeature>
            ))}
          </PlanFeaturesContainer>
        </SinglePricingCard> */}
        <SinglePricingCard plan="standard">
          <TopSectionContainer>
            <PlanNameAndTagContainer>
              <PlanName plan="standard">STANDARD</PlanName>
              <PlanNameTag plan="standard">Time</PlanNameTag>
            </PlanNameAndTagContainer>
            <PlanPriceContainer>
              <PlanPrice>
                $
                {planInterval === "yearly"
                  ? planPriceYearly.standard
                  : planPriceMonthly.standard}
              </PlanPrice>
              <PlanPriceText>per user/month</PlanPriceText>
            </PlanPriceContainer>
            <PlanDescription plan="standard">
              Ideal for the in-house teams or general teams of any size & shape.
            </PlanDescription>
            <PlanSelectButton
              plan="standard"
              onClick={() => selectPlan("Standard", planInterval)}
            >
              Try Free For 10 Days
            </PlanSelectButton>
          </TopSectionContainer>
          <PlanFeaturesContainer margin="15px 0">
            {planFeatures.standard.map((item, index) => (
              <SinglePlanFeature key={index}>
                <FeatureTickIcon
                  src={tickBlack}
                  alt=""
                  style={{ margin: `2px 0 0 0` }}
                />
                <FeatureText>{item}</FeatureText>
              </SinglePlanFeature>
            ))}
          </PlanFeaturesContainer>
        </SinglePricingCard>
        {/* <SinglePricingCard plan="premium">
          <TopSectionContainer>
            <PlanNameAndTagContainer>
              <PlanName plan="premium">PREMIUM</PlanName>
              <PlanNameTag plan="premium">Field</PlanNameTag>
            </PlanNameAndTagContainer>
            <PlanPriceContainer>
              <PlanPrice>
                $
                {planInterval === "yearly"
                  ? planPriceYearly.premium
                  : planPriceMonthly.premium}
              </PlanPrice>
              <PlanPriceText>per user/month</PlanPriceText>
            </PlanPriceContainer>
            <PlanDescription plan="premium">
              Ideal for Mobile teams or a combination of in-house & Mobile
              teams.
            </PlanDescription>
            <PlanSelectButton
              plan="premium"
              onClick={() => selectPlan("Premium", planInterval)}
            >
              Try Free For 10 Days
            </PlanSelectButton>
          </TopSectionContainer>
          <EverythingInPrev plan="premium">
            <ArrowLeft src={arrowLeft} alt="" />
            <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
              Everything in Standard
            </FeatureText>
          </EverythingInPrev>
          <PlusAddonDiv>Plus Field Services Add On:</PlusAddonDiv>
          <PlanFeaturesContainer>
            {planFeatures.premium.map((item, index) => (
              <SinglePlanFeature key={index}>
                <FeatureTickIcon
                  src={tickPurple}
                  alt=""
                  style={{ margin: `2px 0 0 0` }}
                />
                <FeatureText>{item}</FeatureText>
              </SinglePlanFeature>
            ))}
          </PlanFeaturesContainer>
        </SinglePricingCard> */}
        <SinglePricingCard plan="elite">
          <TopSectionContainer>
            <PlanNameAndTagContainer>
              <PlanName plan="elite">ELITE</PlanName>
              <PlanNameTag plan="elite">Remote & Hybrid</PlanNameTag>
            </PlanNameAndTagContainer>
            <PlanPriceContainer>
              <PlanPrice>
                $
                {planInterval === "yearly"
                  ? planPriceYearly.elite
                  : planPriceMonthly.elite}
              </PlanPrice>
              <PlanPriceText>per user/month</PlanPriceText>
            </PlanPriceContainer>
            <PlanDescription plan="elite">
              Ideal for Remote Teams or a combination of in-house &
              remote teams.
            </PlanDescription>
            <PlanSelectButton
              plan="elite"
              onClick={() => selectPlan("Elite", planInterval)}
            >
              Try Free For 10 Days
            </PlanSelectButton>
          </TopSectionContainer>
          <EverythingInPrev plan="elite">
            <ArrowLeft src={arrowLeft} alt="" />
            <FeatureText style={{ marginLeft: "8px" }} fontSize="12px">
              Everything in Standard
            </FeatureText>
          </EverythingInPrev>
          <PlusAddonDiv>Plus RemoteTrack Add On:</PlusAddonDiv>
          <PlanFeaturesContainer>
            {planFeatures.elite.map((item, index) => (
              <SinglePlanFeature key={index}>
                <FeatureTickIcon
                  src={tickBlue}
                  alt=""
                  style={{ margin: `2px 0 0 0` }}
                />
                <FeatureText>{item}</FeatureText>
              </SinglePlanFeature>
            ))}
          </PlanFeaturesContainer>
        </SinglePricingCard>
      </PricingCardContainer>
    </PricingPlanContainer>
  );
};

export default PricingPlan;
