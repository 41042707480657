import { RoundImageTooltip } from "../../styledComponents/createProject";

const AssigneeTooltip = (props) => {
  return (
    <RoundImageTooltip>
      {props.toolTipText.length < 17
        ? props.toolTipText
        : `${props.toolTipText.slice(0, 13)}...`}
    </RoundImageTooltip>
  );
};

export default AssigneeTooltip;
