import React from "react";
import noData from "assets/img/no-data.png";
import newScheduleImage from "../../assets/img/common/schedule_launch_image.png";
import DailyScheduleTable from "./DailyScheduleTable";
import WeeklyScheduleTable from "./WeeklyScheduleTable";
import MonthlyScheduleTable from "./MonthlyScheduleTable";

const ScheduleTable = (props) => (
  <>
    {/* {props.scheduleList &&
      props.scheduleList.length > 0 &&
      props.time_interval === "daily" && (
        <DailyScheduleTable
          scheduleList={props.scheduleList}
          defaultDate={props.defaultDate}
          deactivateSchedule={props.deactivateSchedule}
          toggleScheduleModal={props.toggleScheduleModal}
          edit={props.edit}
        />
      )}
    {props.scheduleList &&
      props.scheduleList.length > 0 &&
      props.time_interval === "weekly" && (
        <WeeklyScheduleTable
          scheduleList={props.scheduleList}
          defaultDate={props.defaultDate}
          selectedDate={props.selectedDate}
          deactivateSchedule={props.deactivateSchedule}
          toggleScheduleModal={props.toggleScheduleModal}
          edit={props.edit}
        />
      )}
    {props.scheduleList &&
      props.scheduleList.length > 0 &&
      props.time_interval === "monthly" && (
        <MonthlyScheduleTable
          scheduleList={props.scheduleList}
          defaultDate={props.defaultDate}
          selectedDate={props.selectedDate}
          deactivateSchedule={props.deactivateSchedule}
          toggleScheduleModal={props.toggleScheduleModal}
          edit={props.edit}
        />
      )}
    {!props.scheduleList ||
      (props.scheduleList.length === 0 && ( */}
    <div className="d-flex flex-column align-items-center col-12">
      {!props.isLoading ? (
        <>
          <img
            src={newScheduleImage}
            alt="no-data"
            className="img-fluid my-5"
          />
          <p
            style={{
              fontSize: "20px",
              color: "#2f394e",
              fontWeight: "600",
            }}
          >
            We are launching a brand new schedule soon!
          </p>
        </>
      ) : null}
    </div>
    {/* ))} */}
  </>
);

export default ScheduleTable;
