import React, { useState, useEffect } from "react";
import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import { TabLeftGrid } from "../../styledComponents/members";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
  Container,
  CommonText,
  InputLabel,
} from "../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
  PrimaryButton,
} from "../../styledComponents/buttons";
import { TermsAndConditionsLink } from "../../styledComponents/authLayout";
import {
  ContentContainer,
  KeyCopyContainer,
  CopyButton,
  KeyCodeSection,
} from "./publicAPIstyles";

import linkIcon from "../../assets/img/icons/link.svg";
import ComponentCircleLoader from "components/Loaders/ComponentCircleLoader";

const PublicApi = ({
  isLoading,
  isCreateLoading,
  selectedOrganization,
  apiKeyCode,
  getPublicApiKey,
  createPublicApiKey,
}) => {
  const [isGetApiClicked, setIsGetApiClicked] = useState(false);
  const [URL, setURL] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedOrganization.role &&
      (selectedOrganization.role === "owner" ||
        selectedOrganization.role === "admin")
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getPublicApiKey(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  useEffect(() => {
    if (apiKeyCode) {
      setIsGetApiClicked(true);
      setURL(apiKeyCode);
    }
  }, [apiKeyCode]);

  const onClickButton = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      createPublicApiKey(payload);
    }
  };

  const copyTextFromElement = () => {
    setIsCopied(true);
    let element = document.getElementById("copyContent"); //select the element
    let elementText = element.textContent; //get the text content from the element
    copyText(elementText); //use the copyText function below
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className="content">
      {selectedOrganization &&
        selectedOrganization.role &&
        selectedOrganization.role !== "member" && (
          <>
            <PageTitle>Public API</PageTitle>
            <div id="copyContent" style={{ display: `none` }}>
              {apiKeyCode ? apiKeyCode : ``}
            </div>
            <Container style={{ maxWidth: `650px` }}>
              {isLoading ? (
                <ComponentCircleLoader />
              ) : (
                <ContentContainer>
                  <CommonText fontSize="16px" name title>
                    Click the button to get your API key
                  </CommonText>
                  <PrimaryButton onClick={() => onClickButton()}>
                    {isGetApiClicked ? `Update API Key` : `Get API Key`}
                  </PrimaryButton>
                  {isGetApiClicked && apiKeyCode && (
                    <InputWithLabelSection>
                      <InputLabel>API key</InputLabel>
                      <KeyCopyContainer>
                        <KeyCodeSection>{apiKeyCode}</KeyCodeSection>
                        <CopyButton onClick={() => copyTextFromElement()}>
                          {isCopied ? `Copied` : `Copy`}
                        </CopyButton>
                      </KeyCopyContainer>
                    </InputWithLabelSection>
                  )}
                  <CommonText>
                    <span>
                      <TermsAndConditionsLink
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://apploye.com/time-tracking-api/"
                      >
                        <img src={linkIcon} width="16px" alt="" />
                      </TermsAndConditionsLink>
                    </span>{" "}
                    <span style={{ margin: `0 2px 0 7px` }}>
                      {" "}
                      Find the API documentation{" "}
                    </span>
                    <TermsAndConditionsLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apploye.com/time-tracking-api/"
                    >
                      here
                    </TermsAndConditionsLink>
                  </CommonText>
                </ContentContainer>
              )}
            </Container>
          </>
        )}
    </div>
  );
};

export default PublicApi;
