import { useState, useEffect } from "react";

import { CardBody } from "reactstrap";

import { DashboardCard, ColorCircle } from "../../styledComponents/dashboard";

import {
  CommonFlex,
  CommonGrid,
  ColoredText,
  CommonImage,
} from "../../styledComponents/common";
import { CardText } from "./cardStyles";

import { toHHMMSS } from "../../utils/helper";

import TopUsersLoader from "../ContentLoader/TopUsersLoader";

import trophyIcon from "../../assets/img/icons/trophy.svg";
import clockIcon from "../../assets/img/icons/clock_icon.svg";
import noDataImg from "../../assets/img/common/nodata_top_employee.svg";

const colors = ["#73C0FF", "#CAB4FE", "#AFC0F9", "#61E5AC", "#748BA7"];

export const Header = ({ title }) => {
  return (
    <CommonFlex gap="15px">
      <CommonImage src={trophyIcon} alt="" size="21px" />
      <ColoredText primary semibold>
        {title}
      </ColoredText>
    </CommonFlex>
  );
};

const TopUsersActivityCard = (props) => {
  const [hoursData, setHoursData] = useState([]);
  useEffect(() => {
    setHoursData([]);
    return () => {
      setHoursData([]);
    };
  }, []);
  useEffect(() => {
    let data = [];
    if (props.usersData && props.usersData && props.usersData.length > 0) {
      props.usersData.map((item, index) => {
        data.push({
          color: colors[index],
          name: `${item.first_name}${
            item.last_name ? ` ${item.last_name}` : ``
          }`,
          timeLog: toHHMMSS(item.duration),
        });
      });
      setHoursData(data);
    } else if (
      props.usersData &&
      props.usersData &&
      props.usersData.length === 0
    ) {
      setHoursData([]);
    }
  }, [props.usersData]);

  return (
    <DashboardCard>
      <CardBody>
        {!props.isLoading && (
          <Header title="Top 5 Members Based On Time Logged" />
        )}
        {props.isLoading && hoursData.length === 0 && (
          <CommonFlex padding="0px 20px">
            <TopUsersLoader />
          </CommonFlex>
        )}
        {!props.isLoading && hoursData.length === 0 && (
          <div className="d-flex flex-column align-items-center col-12">
            <img src={noDataImg} alt="no-data" className="img-fluid my-4" />
            <p style={{ fontSize: "14px", color: "rgba(61, 77, 105, 0.7)" }}>
              There is no data to be displayed!
            </p>
          </div>
        )}
        {hoursData &&
          hoursData.length > 0 &&
          hoursData.map((item, index) => {
            return (
              <CommonFlex
                key={index}
                justifyContent="space-between"
                margin="15px 0px 15px 4px"
              >
                <CommonFlex gap="15px">
                  <ColorCircle style={{ background: item.color }} />
                  <CardText>{item.name}</CardText>
                </CommonFlex>
                <CommonGrid columns="20px auto" gap="0" alignItem="center">
                  <div>
                    <CommonImage src={clockIcon} alt="" size="20px" />
                  </div>
                  <div style={{ minWidth: "85px" }}>
                    <CardText margin="0 0 0 15px">{item.timeLog}</CardText>
                  </div>
                </CommonGrid>
              </CommonFlex>
            );
          })}
      </CardBody>
    </DashboardCard>
  );
};

export default TopUsersActivityCard;
