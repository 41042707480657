import { useState, useEffect, useRef, Fragment, useMemo } from "react";

import {
  AdditionalContainer,
  CollapseContainer,
  TitleContainer,
  IconTitleSection,
  CollapseRotate,
} from "../../../styledComponents/createProject";
import { CardTitle } from "../../../styledComponents/common";

import TasksTable from "../../Tasks/TasksListNew";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

import ProjectTask from "../../../assets/img/icons/project_task.svg";
import Collapse from "../../../assets/img/icons/collapse.svg";

const AddTasks = (props) => {
  const [visibility, setVisibility] = useState(0);

  const [newTask, setNewTask] = useState(null);

  const taskSectionRef = useRef(null);

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    addTasksActive,
    selectedOrganization,
    projectId,
    projectName,
    getProjectTaskList,
    getProjectAssigneeList,
    projectAssigneeIsLoading,
    projectTaskList,
    addTaskAssignee,
    addTask,
    deleteTask,
    editTask,
    deleteTaskAssignee,
    toggleAddTasks,
    taskListLoading,
    taskIsLoading,
    loadingTaskId,
    projectAssigneeList,
    taskAssigneeIsLoading,
    loadingTaskAssigneeId,

    // pagination
    taskCount,
    taskPageSize,
  } = props;

  useEffect(() => {
    if (addTasksActive) {
      if (selectedOrganization && selectedOrganization.id && projectId) {
        const payload = {
          organization_id: selectedOrganization.id,
          project_id: projectId,
          status: "to do",
        };
        getProjectTaskList(payload);
        getProjectAssigneeList(payload);
      }
    }
  }, [addTasksActive]);

  useMemo(() => {
    taskSectionRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, [projectTaskList]);

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [selectedOrganization]);

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id && projectId) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: projectId,
        status: "to do",
        page,
      };
      getProjectTaskList(payload);
    }
    setCurrentPageNumber(page);
  };

  const handleNewTask = (value) => {
    setNewTask(value);
  };

  const showAssignee = (state) => {
    setVisibility(state);
  };

  return (
    <AdditionalContainer
      active={addTasksActive}
      style={{
        borderTop: addTasksActive ? `1px solid #20bead` : `1px solid #c2cce1`,
      }}
    >
      <TitleContainer onClick={() => toggleAddTasks()}>
        <IconTitleSection>
          <div>
            <img src={ProjectTask} alt="addPeople" width="27px" />
          </div>
          <CardTitle fontSize="16px">Add Tasks</CardTitle>
        </IconTitleSection>
        <CollapseRotate active={addTasksActive}>
          <img src={Collapse} alt="collapse" width="100%" />
        </CollapseRotate>
      </TitleContainer>
      <CollapseContainer
        maxHeight="600px"
        overflow="auto"
        active={addTasksActive}
        addAssignee={visibility}
        style={{ padding: `0 0px 15px`, marginBottom: "20px" }}
        ref={taskSectionRef}
      >
        <>
          {taskCount && taskCount > 0 && taskPageSize ? (
            <TopPagination
              itemName="tasks"
              totalCount={taskCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => handlePageChange(page)}
              pageSize={taskPageSize}
              padding="0 30px"
            />
          ) : null}
          <TasksTable
            fromProject
            projectData={{ id: projectId, name: projectName }}
            tasksList={projectTaskList}
            selectedOrganization={selectedOrganization}
            projectAddTask={addTask}
            editTask={editTask}
            projectDeleteTask={deleteTask}
            getTaskList={getProjectTaskList}
            getProjectAssigneeList={getProjectAssigneeList}
            projectAssigneeList={projectAssigneeList}
            addTaskAssignee={addTaskAssignee}
            deleteTaskAssignee={deleteTaskAssignee}
            projectAssigneeIsLoading={projectAssigneeIsLoading}
            taskIsLoading={taskIsLoading}
            loadingTaskId={loadingTaskId}
            // loadingTaskProjectId={loadingTaskProjectId}
            taskAssigneeIsLoading={taskAssigneeIsLoading}
            loadingTaskAssigneeId={loadingTaskAssigneeId}
            taskListLoading={taskListLoading}
            newTask={newTask}
            handleNewTask={handleNewTask}
            visibilityChange={showAssignee}
          />
          {taskCount && taskCount > 0 && taskPageSize ? (
            <BottomPagination
              totalCount={taskCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => handlePageChange(page)}
              pageSize={taskPageSize}
            />
          ) : null}
        </>
      </CollapseContainer>
    </AdditionalContainer>
  );
};

export default AddTasks;
