import styled from "styled-components";

const getColor = (theme, index) => {
  switch (index % 3) {
    case 0:
      return theme.colors.main.purpleLight;
    case 1:
      return theme.colors.main.warningLight;
    case 2:
      return theme.colors.main.blueLight;
    default:
      return theme.colors.main.purpleLight;
  }
};

export const NotesListContainer = styled.div``;

export const SingleNoteContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || "250px auto"};
  grid-column-gap: ${({ gap }) => gap || "20px"};
  padding: 20px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  }
  &:first-child {
    padding: 0 0 20px;
  }
`;

export const TimeBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 28px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  background-color: ${({ theme, index }) => getColor(theme, index)};
  margin-top: 25px;
`;

export const SingleSection = styled.div``;

export const CreatedByDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  height: 26px;
  padding: 0px 12px;
  border-radius: ${({ theme }) => theme.borderRadii.button};
  font-size: 12px;
  background: ${({ theme }) => theme.colors.main.blue};
  color: ${({ theme }) => theme.colors.main.white};
  margin: 15px 0 0;
`;
