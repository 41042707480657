import { useState, useEffect } from "react";

import moment from "moment";
import { toHHMMSS, getHours } from "../../../utils/helper";

import {
  WeeklyDetailsTableContainer,
  WeeklyDetailsTableHeadContainer,
  TableHeadText,
  TableHeadDateContainer,
  TableHeadDayText,
  TableHeadDate,
  WeeklyDetailsTableBodyContainer,
  TableBodySingleItemContainer,
  SingleItemUserDiv,
  SingleItemUserAvatar,
  SingleItemUserName,
  SingleItemTime,
  SingleItemTotal,
} from "../../../styledComponents/timesheet";
import {
  SingleAssigneeRoundImage,
  CenteredTooltip,
} from "../../../styledComponents/common";

import avatar5 from "../../../assets/img/avatar_5.svg";

import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import FirstRoundLetterComp from "../../../components/FirstRoundLetter/FirstRoundLetter";
const getDates = (date) => {
  const dateList = [];
  let startDate = moment(date).startOf("isoWeek");
  while (moment(startDate).isSameOrBefore(moment(date).endOf("isoWeek"))) {
    dateList.push(startDate);
    startDate = moment(startDate).add(1, "days");
  }
  return dateList;
};

const getTimes = (date) => {
  const times = [];
  let startDate = moment(date).startOf("isoWeek");
  while (moment(startDate).isSameOrBefore(moment(date).endOf("isoWeek"))) {
    times.push({
      date: startDate,
      hours: "0 h 00 m",
    });
    startDate = moment(startDate).add(1, "days");
  }
  return times;
};

const SingleRow = (props) => {
  const { memberData, handleGetDetails, getHours, setDetailsUserData, index } =
    props;

  return (
    <TableBodySingleItemContainer>
      <SingleItemUserDiv>
        <SingleAssigneeRoundImage>
          {memberData.avatar ? (
            <SingleItemUserAvatar src={memberData.avatar} alt="" />
          ) : (
            <FirstRoundLetterComp
              size="36px"
              fontSize="13px"
              text={memberData.name}
              backColor={index}
              color={memberData.color || null}
            />
          )}

          <CenteredTooltip left="calc(100% + 3px)" top="2px">
            {memberData.name}
          </CenteredTooltip>
        </SingleAssigneeRoundImage>

        {/* <SingleItemUserName>{memberData.name}</SingleItemUserName> */}
      </SingleItemUserDiv>
      {memberData.timesheet && memberData.timesheet.length > 0 && (
        <>
          {memberData.timesheet.map((timesheet, index) => (
            <SingleItemTime
              key={index}
              fontSize="13.5px"
              onClick={() => {
                if (timesheet.hours !== "0 h 00 m") {
                  handleGetDetails(timesheet.date, memberData.user_id);
                  setDetailsUserData({
                    id: memberData.user_id,
                    name: memberData.name,
                    avatar: memberData.avatar ?? null,
                    color: memberData.color ?? null,
                    date: timesheet.date,
                    totalTime: timesheet.hours,
                  });
                }
              }}
              hasTime={timesheet.hours !== "0 h 00 m"}
            >
              {timesheet.hours === "0 h 00 m" ? "-" : timesheet.hours}
            </SingleItemTime>
          ))}
          <SingleItemTime>
            <SingleItemTotal fontSize="13.5px">
              {memberData.durations
                ? getHours(
                    toHHMMSS(
                      memberData.durations
                        .slice(1)
                        .reduce(
                          (prev, cur) => cur + prev,
                          memberData.durations[0]
                        )
                    )
                  )
                : "0 h 00 m"}
            </SingleItemTotal>
          </SingleItemTime>
        </>
      )}
    </TableBodySingleItemContainer>
  );
};

const WeeklyAdminView = (props) => {
  const [timesheetsData, setTimesheetsData] = useState([]);
  const [dates, setDates] = useState([]);

  const {
    selectedDate,
    membersList,
    timesheetsList,

    getTimesheetDetails,
    detailsUserData,
    setDetailsUserData,
  } = props;

  useEffect(() => {
    setDates(getDates(selectedDate));
  }, []);

  useEffect(() => {
    setDates(getDates(selectedDate));
  }, [selectedDate]);

  useEffect(() => {
    if (membersList && membersList.length > 0 && selectedDate) {
      const timesheets = [];
      membersList.forEach((member) => {
        const memberData = {
          user_id: member.id,
          name: member.name,
          avatar: member.avatar || null,
          color: member.color || null,
        };
        const times = getTimes(selectedDate);
        if (timesheetsList?.hasOwnProperty(member.id)) {
          const durations = [];
          timesheetsList[member.id].forEach((timesheet) => {
            durations.push(timesheet.duration);
            times.some((item) => {
              if (moment(item.date).format("YYYY-MM-DD") === timesheet.date) {
                item.hours = getHours(toHHMMSS(timesheet.duration));
                return true;
              }
              return false;
            });
            if (
              detailsUserData &&
              detailsUserData.id === member.id &&
              detailsUserData.date &&
              moment(detailsUserData.date).format("YYYY-MM-DD") ===
                timesheet.date
            ) {
              setDetailsUserData({
                ...detailsUserData,
                totalTime: getHours(toHHMMSS(timesheet.duration)),
              });
            }
          });
          memberData.durations = durations;
          memberData.timesheet = [...times];
        } else {
          memberData.timesheet = times;
        }
        timesheets.push({ ...memberData });
      });
      timesheets.sort((a, b) => b.name.localeCompare(a.name));
      setTimesheetsData([
        ...timesheets.sort((a, b) => a.name.localeCompare(b.name)),
      ]);
    } else if (membersList && membersList.length === 0) {
      // no data
      setTimesheetsData([]);
    }
  }, [timesheetsList, membersList]);

  return (
    <WeeklyDetailsTableContainer>
      {timesheetsData && timesheetsData.length > 0 ? (
        <>
          <WeeklyDetailsTableHeadContainer>
            <TableHeadDateContainer>
              <TableHeadText margin="0 0 0 0">Members</TableHeadText>
              <TableHeadText
                fontSize="16px"
                fontWeight="600"
                margin="5px 0 0 0"
                color="#3d4d69"
              >
                {timesheetsData.length}
              </TableHeadText>
            </TableHeadDateContainer>
            {dates &&
              dates.length > 0 &&
              dates.map((date, index) => (
                <TableHeadDateContainer key={index}>
                  <TableHeadDayText>{date.format("ddd")}</TableHeadDayText>
                  <TableHeadDate>{date.format("DD")}</TableHeadDate>
                </TableHeadDateContainer>
              ))}
            <TableHeadText>Total Hours</TableHeadText>
          </WeeklyDetailsTableHeadContainer>
          <WeeklyDetailsTableBodyContainer>
            {timesheetsData.map((member, index) => (
              <SingleRow
                key={index}
                index={index}
                memberData={member}
                getHours={getHours}
                handleGetDetails={getTimesheetDetails}
                setDetailsUserData={setDetailsUserData}
              />
            ))}
          </WeeklyDetailsTableBodyContainer>
        </>
      ) : timesheetsData && timesheetsData.length === 0 ? (
        <NoDataComponent title="There is no timesheet for the selected week" />
      ) : null}
    </WeeklyDetailsTableContainer>
  );
};

export default WeeklyAdminView;
