import { connect } from "react-redux";

import CurrentlyTracking from "./CurrentlyTracking";

import {
  getLiveMembersList,
  getLastFiveMinutesActiveList,
  takeInstantScreenshot,
} from "../liveFeedActions";

const mapStateToProps = (state) => ({
  isLoading: state.liveFeed.isLoading,
  memberListLoading: state.liveFeed.memberListLoading,
  buttonLoading: state.liveFeed.buttonLoading,
  liveMembersList: state.liveFeed.liveMembersList,
  lastFiveMinActiveList: state.liveFeed.lastFiveMinActiveList,
  selectedOrganization: state.organization.selectedOrganization,
});

const mapDispatchToProps = (dispatch) => ({
  getLiveMembersList: (payload) => dispatch(getLiveMembersList(payload)),
  getLastFiveMinutesActiveList: (payload) =>
    dispatch(getLastFiveMinutesActiveList(payload)),
    takeInstantScreenshot: (payload) => dispatch(takeInstantScreenshot(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentlyTracking);
