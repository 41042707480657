import React, { useState } from "react";
import moment from "moment-timezone";

import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";

import { ColoredButton } from "../../../styledComponents/buttons";
import {
  PageTitle,
  Container,
  ContentHeader,
  CommonGrid,
} from "../../../styledComponents/common";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import BackButtonComponent from "../../../components/BackButtonComponent/BackButtonComponent";
import RightSlidingPanel from "../../../components/RightSlidingPanel/RightSlidingPanel";

import NoLeave from "../../../assets/img/no-leave-request-artwork.svg";
import LeftArrow from "../../../assets/img/icons/arrow_left_white.svg";
import RightArrow from "../../../assets/img/icons/arrow_right_white.svg";

import LeaveTable from "../LeaveTable";
import {
  CalendarContainer,
  CalendarToolbarContainer,
  MonthPickerContainer,
  MonthChangeButton,
  MonthChangeIcon,
  MonthPickerInput,
} from "./leaveCalendarStyles";
import TimesheetDetails from "custom_modules/Timesheet/TimesheetDetailsPanel";
import { TimesheetContainer } from "styledComponents/timesheet";

import { timesheetsData } from "./leaveCalendarStyles";

moment.locale("en", {
  week: {
    dow: 1,
    day: 4,
  },
});

const localizer = momentLocalizer(moment);

const CustomMonthPickerInput = ({ value, onClick }) => (
  <MonthPickerInput onClick={onClick}>{value}</MonthPickerInput>
);

const CustomToolbar = (props) => {
  const { timesheetsData, goToBack, goToNext, onMonthSelect, selectedDate } =
    props;

  return (
    <CalendarToolbarContainer>
      <MonthPickerContainer>
        <MonthChangeButton onClick={() => goToBack()}>
          <MonthChangeIcon src={LeftArrow} alt="" />
        </MonthChangeButton>
        <DatePicker
          selected={moment(selectedDate).toDate()}
          onChange={(date) => onMonthSelect(date)}
          customInput={<CustomMonthPickerInput />}
          dateFormat="MMMM, yyyy"
          showMonthYearPicker
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport",
            },
          }}
        />
      </MonthPickerContainer>
    </CalendarToolbarContainer>
  );
};

const Monthly = (props) => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [leaveData, setLeaveData] = useState(null);

  const [selectedProject, setSelectedProject] = useState("");
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [timesheetsData, setTimesheetsData] = useState(null);

  // details panel
  const [detailsUserData, setDetailsUserData] = useState(null);
  const [detailsPanelIsOpen, setDetailsPanelIsOpen] = useState(false);

  const {
    isLoading,

    selectedOrganization,
    profileData,

    clearTimesheetList,
    getOtherTimesheets,
    timesheetsList,

    getDailyTimesheets,
    dailyTimesheetLoading,
    dailyTimesheets,
    leaveList,
  } = props;

  useEffect(() => {
    return () => {
      clearTimesheetList();
    };
  }, []);

  const goToNext = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    const date = moment(selectedDate).add(1, "M").format("YYYY-MM-DD");
    const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
    const endDate = moment(data).endOf("month").format("YYYY-MM-DD");
    setSelectedDate(date);

    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };

      getOtherTimesheets(payload);
    }
  };

  const goToBack = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    const date = moment(selectedDate).subtract(1, "M").format("YYYY-MM-DD");
    const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
    const endDate = moment(date).endOf("month").format("YYYY-MM-DD");
    setSelectedDate(date);

    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };

      getOtherTimesheets(payload);
    }
  };

  const onMonthSelect = (date) => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    const startDate = moment(date).format("YYYY-MM-DD");
    const endDate = moment(date).endOf("month").format("YYYY-MM-DD");

    setSelectedDate(date);

    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate,
        end_date: endDate,
      };
      getOtherTimesheets(payload);
    }
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleSetUserDate = (date, hours) => {
    if (date && hours) {
      setDetailsUserData({
        id: selectedMember?.id ?? localStorage.getItem("user_id"),
        name:
          selectedMember?.name ??
          (profileData?.id
            ? `${profileData.first_name} ${profileData.last_name}`.trim()
            : null),
        avatar: selectedMember
          ? selectedMember.avatar ?? null
          : profileData?.avatar ?? null,
        color: selectedMember
          ? selectedMember.color ?? null
          : profileData?.color ?? null,
        date,
        totalTime: hours,
      });
    }
  };

  return (
    <div className="content">
      <RightSlidingPanel
        isOpen={detailsPanelIsOpen}
        closePanel={() => toogleDetailsPanel(false)}
        width="500px"
      >
        {dailyTimesheetLoading ? (
          <ComponentCircleLoader padding="35ch 0" />
        ) : (
          <>
            <TimesheetDetails
              timesheetsList={dailyTimesheets}
              userDate={detailsUserData}
              timezone={selectedTimezone && selectedTimezone.timezone}
              fromPage="other"
            />
          </>
        )}
      </RightSlidingPanel>
      <CommonGrid alignItem="center">
        <PageTitle>Leave Management</PageTitle>
      </CommonGrid>
      <TimesheetFilter
        page="monthly"
        selecteddate={moment(selectedDate)}
        selectedMember={selectedMember}
        clearMember={clearMember}
        selectedOrganization={selectedOrganization}
        selectedTimezone={selectedTimezone}
      />

      <TimesheetContainer>
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <CalendarContainer>
            <Calendar
              localizer={localizer}
              events={timesheetsData ? timesheetsData.timesheets : []}
              startAccessor="start"
              endAccessor="end"
              date={moment(selectedDate).toDate()}
              onNavigate={() => {}}
              views={["month"]}
              formats={{ dataFormat: "D" }}
              component={{
                event: (event) => {
                  return (
                    <TimeSpan
                      event={event}
                      selectedMember={selectedMember}
                      handleSetUserData={handleSetUserDate}
                    />
                  );
                },

                toolbar: () => {
                  return (
                    <CustomToolbar
                      timesheetsData={timesheetsDate}
                      goToNext={goToNext}
                      goToBack={goToBack}
                      onMonthSelect={onMonthSelect}
                      selectedDate={selectedDate}
                    />
                  );
                },
              }}
              tooltipAccessor={() => null}
              eventPropGetter={() => {
                return {
                  className: "monthly-timesheets-time",
                };
              }}
            />
          </CalendarContainer>
        )}
      </TimesheetContainer>
    </div>
  );
};

const LeaveCalendar = (props) => {
  const isLoading = false;
  const leaveData = ["Zakaria"];

  const selectedDate = "2023-02-09";

  return (
    <div className="content">
      <PageTitle>Leave Management</PageTitle>
      {/* <LeaveFilter onTabSwitch={onTabSwitch} tabSelect={tabSelect} /> */}
      <ContentHeader margin="30px 0 0 0">
        <Link to="/user/attendance/leave" style={{ textDecoration: "none" }}>
          <BackButtonComponent
            //   onClick={() => props.history.push("/user/projects")}
            subTitle="Back to all leaves"
          />
        </Link>

        <CommonGrid justifyContent="start" alignment="center" gap="3px">
          <ColoredButton type="black">Apply Leave</ColoredButton>
        </CommonGrid>
      </ContentHeader>
      <Container
        style={{ padding: `30px`, marginTop: `20px`, background: `#FCFDFE` }}
      >
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {leaveData && leaveData.length > 0 ? (
              // leaveData.map((leave, index) => (
              //   <LeaveTable
              //     leave={leave}
              //     index={index}
              //     key={index}
              //     tabSelect={tabSelect}
              //   />
              // ))
              <CalendarContainer>
                <Calendar
                  localizer={localizer}
                  events={[]}
                  startAccessor="start"
                  endAccessor="end"
                  date={moment(selectedDate).toDate()}
                  onNavigate={() => {}}
                  views={["month"]}
                  culture="en-GB"
                  formats={{ dataFormat: "D" }}
                  tooltipAccessor={() => null}
                  eventPropGetter={() => {
                    return {
                      className: "monthly-timesheets-time",
                    };
                  }}
                />
              </CalendarContainer>
            ) : (
              <NoDataComponent
                title={
                  "Your members seem very committed to their work, no leave request here"
                }
                imageSrc={NoLeave}
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default LeaveCalendar;
