import { connect } from "react-redux";

// Component
import TimeActivity from "./TimeActivity";

import { getReportNotes } from "../reportsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  averageActivity: state.report.averageActivity,
  weeklyData: state.report.weeklyData,
  isWeeklyDataLoading: state.report.isWeeklyDataLoading,
  isLoading: state.report.isLoading,
  reportNotesLoading: state.report.reportNotesLoading,
  noData: state.report.noData,
  notes: state.report.notes,
});

const mapDispatchToProps = (dispatch) => ({
  getReportNotes: (payload) => dispatch(getReportNotes(payload)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TimeActivity);
