import { LightgalleryItem } from "react-lightgallery";

import { InstantScreenshotThumbnail } from "./instantScreenshotsStyles";

import NoImage from "../../../assets/img/instant-screenshot-missing.svg";
import PendingImage from "../../../assets/img/instant-screenshot-pending.svg";

const PhotoItem = ({ item, group }) => {
  if (item.screenshots.length) {
    return item.screenshots.map((screenshot, i) => {
      return (
        <LightgalleryItem
          src={screenshot.screenshot}
          key={i}
          group={group}
          subHtml={`<div class="col-xs-12 col-md-4 screenshot-desc-col" style="min-height: 35px">
                      <div class="row">
                        <p class="screenshot-desc-title">Member Name: </p>
                        <p class="screenshot-desc-text">${item.member_name}</p>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 screenshot-desc-col" style="min-height: 35px">
                      <div class="row" style="justify-content: center;">
                        <p class="screenshot-desc-title">Screenshot Taken At: </p>
                        <p class="screenshot-desc-text">${item.taken_time}</p>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md-4 screenshot-desc-col" style="min-height: 35px">
                      <div class="row" style="justify-content: flex-end">
                        <p class="screenshot-desc-title">Requested By: </p>
                        <p class="screenshot-desc-text">${item.requested_by}</p>
                      </div>
                    </div>`}
        >
          {i === 0 ? (
            <InstantScreenshotThumbnail
              src={screenshot.thumbnail}
              alt="screenshot"
              width="100%"
              style={{ cursor: "pointer" }}
            />
          ) : null}
        </LightgalleryItem>
      );
    });
  } else {
    return (
      <InstantScreenshotThumbnail
        src={item.status && item.status === "pending" ? PendingImage : NoImage}
        alt="no-image"
        width="100%"
        style={{ cursor: "not-allowed" }}
      />
    );
  }
};

export default PhotoItem;
