import { REDEEM_DISCOUNT_CODE } from "modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function redeem(value) {
  return {
    type: REDEEM_DISCOUNT_CODE,
    payload: value
  };
}

export const actions = {
  redeem
};
