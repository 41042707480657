import styled from "styled-components";

export const ClockInOutTableHeader = styled.div`
  display: grid;
  grid-template-columns: 24% 18% 18% 18% 18%;
  grid-column-gap: 8px;
  justify-content: start;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 200px 150px;
    grid-column-gap: 10px;
  }
`;

export const ClockInOutTableRow = styled.div`
  display: grid;
  grid-template-columns: 24% 18% 18% 18% 18%;
  grid-column-gap: 8px;
  justify-content: start;
  padding: 0 20px;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 250px 200px 200px 150px;
    grid-column-gap: 10px;
  }
`;
