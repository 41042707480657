import React from "react";
import { Row, Col, FormGroup, Label, Button } from "reactstrap";
import ReactDatetime from "react-datetime";
import Select from "../../components/ReactSelectDropdown";
import DropdownStyles from "../../components/DropdownStyle/DropdownStyle";
import moment from "moment";

const ScheduleHeader = props => (
  <Row style={{ marginBottom: "25px" }}>
    <Col xl={6}>
      <Row>
        <Col md={3}>
          <FormGroup>
            <Label>Project</Label>
            <Select
              isSearchable
              value={props.currentProject}
              options={props.projects}
              onChange={e => {
                props.handleChange(e, "project_id");
              }}
              className="text-left"
              styles={DropdownStyles()}
            />
          </FormGroup>
        </Col>
        <Col md={3} className="text-left">
          <FormGroup>
            <Label>Type</Label>
            <Select
              isSearchable={false}
              onChange={e => props.handleChange(e, "time_interval")}
              value={props.time_interval}
              options={props.scheduleTypes}
              styles={DropdownStyles()}
            />
          </FormGroup>
        </Col>
        <Col md={4} className="text-left">
          <FormGroup>
            <Label>Team Member</Label>
            <Select
              isSearchable
              onChange={e => {
                props.handleChange(e, "user_id");
              }}
              options={props.userList}
              value={props.selectedMember ? props.selectedMember : ""}
              styles={DropdownStyles()}
            />
          </FormGroup>
        </Col>
        <Col md={2} className="text-right">
          <Button
            outline
            className="custom-header-button border-none btn-spec-hover"
            style={{
              marginTop: "29px",
              height: "38px",
              marginLeft: "-10px",
              width: "80px"
            }}
            onClick={() => props.getScheduleList()}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </Col>
    <Col xl={6}>
      <Row className="text-xl-right">
        <Col md={2} lg={3}>
          <Button
            className="custom-header-button button-time text-center btn-spec-hover"
            style={{
              marginTop: "29px",
              height: "38px",
              padding: "11px"
            }}
            onClick={() => {
              props.selectDate(props.currentDate);
            }}
          >
            Today
          </Button>
        </Col>
        <Col md={6} lg={5} style={{ textAlign: "left" }}>
          <Row
            style={{
              justifyContent: "flex-start",
              paddingRight: "inherit",
              paddingLeft: "inherit"
            }}
          >
            <Col md={12} style={{ padding: 0 }}>
              <FormGroup>
                <Label>Select Date</Label>
                <ReactDatetime
                  inputProps={{
                    className: "time_picker timesheet_time_picker",
                    placeholder: "Date Picker Here",
                    value: props.defaultDate,
                    readOnly: true,
                    id: "timePicker"
                  }}
                  defaultValue={moment(new Date())}
                  timeFormat={false}
                  onChange={e => {
                    props.selectDate(e);
                  }}
                  closeOnSelect
                />
                <label
                  htmlFor="timePicker"
                  className="calendar fa fa-calendar fa-2x"
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row
            style={{
              justifyContent: "flex-end"
            }}
          >
            <Col md={7} lg={12} className="text-right">
              <Button
                style={{
                  marginTop: "29px",
                  height: "38px",
                  width: "auto",
                  padding: "0 10px",
                  marginLeft: "0"
                }}
                className="custom-header-button btn-spec-hover"
                onClick={() => props.toggleScheduleModal()}
                disabled={
                  props.organization &&
                  (props.organization.role !== "owner" &&
                    props.organization.role !== "admin")
                }
              >
                Add Schedule
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default ScheduleHeader;
