import chrome from "../../../assets/img/appIcons/chrome.svg" ;
import slack from "../../../assets/img/appIcons/slack.svg" ;
import skype from "../../../assets/img/appIcons/skype.svg" ;

const UrlList = [
    {
        name : "Google Chrome",
        icon : chrome
    },
    {
        name : "Slack",
        icon : slack
    },
    {
        name : "Skype",
        icon : skype
    },
];

export default UrlList;