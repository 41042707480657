import { connect } from "react-redux";

// Component
import ManualTime from "./ManualTime";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.report.isLoading,
  noData: state.report.noData,
  manualTimeData: state.report.manualTimeData,
});

const mapDispatchToProps = (dispatch) => ({});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ManualTime);
