import { connect } from "react-redux";

import PublicApi from "./PublicApi";

// Actions
import {
  getPublicApiKey,
  createPublicApiKey,
} from "./publicApiActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.publicApi.isLoading,
  isCreateLoading: state.publicApi.isCreateLoading,
  selectedOrganization: state.organization.selectedOrganization,
  apiKeyCode: state.publicApi.apiKeyCode,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getPublicApiKey: (details) => dispatch(getPublicApiKey({ ...details })),
  createPublicApiKey: (details) => dispatch(createPublicApiKey({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(PublicApi);