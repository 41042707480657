
import ContentLoader from "react-content-loader";

const TopUsersLoader = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={610}
      height={217}
      viewBox="0 0 610 217"
      backgroundColor="#e8eaee"
      foregroundColor="#f3f3f3"
      {...props}
    >
      <rect x="0.19" width="166.14" height="12.1" rx="6.05" />
      <rect x="37.96" y="50.15" width="91.2" height="12.1" rx="6.05" />
      <rect x="462.11" y="44.94" width="144.2" height="12.1" rx="6.05" />
      <rect x="462.11" y="83.93" width="144.2" height="12.1" rx="6.05" />
      <rect x="462.11" y="122.91" width="144.2" height="12.1" rx="6.05" />
      <rect x="462.11" y="161.9" width="144.2" height="12.1" rx="6.05" />
      <rect x="462.11" y="200.89" width="144.2" height="12.1" rx="6.05" />
      <rect x="37.96" y="87.86" width="91.37" height="12.1" rx="6.05" />
      <rect x="37.96" y="126.08" width="90.96" height="12.1" rx="6.05" />
      <rect x="37.96" y="164.08" width="91.37" height="12.1" rx="6.05" />
      <rect x="37.96" y="202.08" width="91.13" height="12.1" rx="6.05" />
      <circle cx="7.03" cy="56.2" r="7.03" />
      <circle cx="7.03" cy="93.91" r="7.03" />
      <circle cx="7.03" cy="132.13" r="7.03" />
      <circle cx="7.03" cy="170.13" r="7.03" />
      <circle cx="7.06" cy="208.13" r="7.03" />
    </ContentLoader>
  );
};

export default TopUsersLoader;
