import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const AuthPageContainer = styled.div`
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  background: #f7fafb;
  display: grid;
  grid-template-columns: 45% 32%;
  justify-content: space-around;
  @media screen and (max-width: 900px) {
    grid-template-columns: 45% 45%;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const AuthPageLeftContainer = styled.div`
  display: grid;
  height: 80vh;
  grid-template-rows: 10vh auto auto;
  grid-row-gap: 4vh;
  align-self: flex-start;
  padding-left: 10%;
  margin-top: 10vh;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
    grid-row-gap: 0;
    padding: 0 5%;
    height: auto;
  }
`;
export const LogoImg = styled.img`
  @media screen and (max-width: 768px) {
    justify-self: center;
  }
  @media screen and (max-width: 576px) {
    width: 45%;
  }
`;
export const AuthPageArtwork = styled.img`
  margin-left: -15%;
  @media screen and (max-width: 900px) {
    margin-top: -45px;
  }
  @media screen and (max-width: 768px) {
    /* width: 80%;
    justify-self: center; */
    display: none;
  }
`;
export const SloganText = styled.div`
  color: ${({ theme }) => theme.colors.main.textMain};
  font-size: 32px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  @media screen and (max-width: 900px) {
    font-size: 28px;
  }
  @media screen and (max-width: 768px) {
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    font-size: 24px;
  }
`;

export const AuthPageRightContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  margin: 5vh 0;
  @media screen and (max-width: 768px) {
    margin: 40px 0;
  }
`;
export const AuthCard = styled.div`
  max-width: 520px;
  min-height: 80vh;
  align-self: center;
  background: ${({ theme }) => theme.colors.main.white};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  box-shadow: 0px 40px 99px #527ab533;
  padding: 75px 7% 25px;
  animation: ${(props) => (props.from ? `grow 1s 1` : `none`)};

  @keyframes grow {
    0% {
      min-height: 65vh;
    }
    100% {
      min-height: 80vh;
    }
  }
  @media screen and (max-width: 768px) {
    max-width: 80%;
    min-height: auto;
    margin-bottom: 50px;
    width: 80%;
    justify-self: center;
  }
  @media screen and (max-width: 576px) {
    max-width: 90%;
    width: 90%;
    padding: 50px 5% 25px;
  }
`;

export const AuthCardTitle = styled.div`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.textMain};
  padding: 0 15px;
`;
export const AuthCardSubtitle = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.main.labelText};
  padding: 0 15px;
`;

export const LoginTabContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 210px;
  height: 45px;
  padding: 4px;
  background: #ddf1ee;
  border-radius: 25px;
`;
export const LoginActiveTab = styled.div`
  width: 50%;
  text-align: center;
  border-radius: 25px;
  background: ${({ theme }) => theme.colors.main.primary};
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.white};
  padding: 7px 20px;
  border: none;
  cursor: pointer;
`;
export const LoginInactiveTab = styled(Link)`
  width: 50%;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.main.textSecondary};
  padding: 7px 20px;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.main.textMain};
    text-decoration: none;
  }
`;

export const AuthForm = styled.form`
  width: 100%;
  margin-top: ${({ page }) => (page === "signUp" ? "4rem" : "10rem")};
  padding: 0px 15px;
  @media screen and (max-width: 1400px) {
    margin-top: ${({ page }) => (page === "signUp" ? "2rem" : "4rem")};
  }
`;
export const AuthFormAlert = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.main.error};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  text-align: left;
`;

export const AuthInputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const ShowPasswordIcon = styled.img`
  width: ${(props) => (props.active ? "32px" : "25px")};
  position: absolute;
  cursor: pointer;
  right: 10px;
  bottom: ${(props) => (props.active ? "-1px" : "-2px")};
  transition: width 0.3s ease-in-out;
`;

export const ForgotPasswordDiv = styled.div`
  display: flex;
  justify-content: ${(props) => (props.alert ? "space-between" : "flex-end")};
  margin-top: ${({ marginTop }) => marginTop || "10px"};
`;
export const ForgotPasswordText = styled(Link)`
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${({ theme }) => theme.colors.main.labelText};
  &:hover {
    color: ${({ theme }) => theme.colors.main.textSecondary};
    text-decoration: none;
  }
`;

export const AuthSubmitButton = styled.button`
  background: ${({ theme }) => theme.button.primary.bgColor};
  height: 50px;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.button.primary.textColor};
  border-radius: ${({ theme }) => theme.button.borderRadius};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  margin-top: ${({ marginTop }) => marginTop || "45px"};
  &:disabled {
    background-color: ${({ disabledBgColor, theme }) =>
      disabledBgColor || theme.button.primary.disabledBgColor};
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.button.primary.hoverBgColor};
      }
    `}
`;

export const GoogleSignInButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 56px;
  width: 100%;
  border: 1px solid #e4e7eb;
  background: #fff;
  margin-top: 25px;
  border-radius: 6px;
`;

export const GoogleIcon = styled.img`
  height: 21px;
  width: 21px;
`;

export const GoogleButtonText = styled.span`
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
  color: rgba(47, 57, 78, 0.6);
`;

export const SignUpNameContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TermsAndConditionsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 49px;
  width: 100%;
  max-width: 520px;
  background: #f7fafb;
  bottom: 0;
  border-radius: 0px 0px 10px 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.main.labelText};
  text-align: center;
  padding: 12px 15px;
`;
export const TermsAndConditionsLink = styled.a`
  color: ${({ theme }) => theme.colors.main.blue};
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.hover.blue};
  }
  &:visited {
    color: ${({ theme }) => theme.colors.hover.blue};
  }
`;
