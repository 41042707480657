import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../styledComponents/members";
import { CommonText } from "../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../styledComponents/buttons";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";

const ProjectArchiveModal = ({
  isOpen,
  toggle,
  projectType,
  handleArchive,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `33vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonText style={{ paddingLeft: `15px` }}>
          {`Are you sure you want to ${
            projectType === "archived" ? "unar" : "ar"
          }chive this project?`}
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            type={projectType !== "archived" && "delete"}
            padding={isLoading ? "0 10px" : "0 20px"}
            onClick={() =>
              handleArchive(
                projectType === "archived" ? "unarchive" : "archive"
              )
            }
          >
            {isLoading ? (
              <ButtonTextLoader
                loadingText={
                  projectType === "archived" ? "Unarchiving" : "Archiving"
                }
                fontSize="13px"
              />
            ) : projectType === "archived" ? (
              "Unarchive"
            ) : (
              "Archive"
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ProjectArchiveModal;
