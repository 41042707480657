import {
  GET_CLOCK_IN_OUT_LIST,
  GET_CLOCK_IN_OUT_LIST_SUCCESS,
  GET_CLOCK_IN_OUT_LIST_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
  EXPORT_ATTENDANCE,
  EXPORT_ATTENDANCE_SUCCESS,
  EXPORT_ATTENDANCE_FAILURE,
} from "../../modules/constants";

export const clockInOutListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const clockInOutListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    isLoading: false,
    clockInOutListCount: data.data.results?.length
      ? data.data.count
      : data.data.results?.length,
    clockInOutPageSize: data.data.page_size,
    clockInOutList: data.data.results,
  };
};
export const clockInOutListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    clockInOutListCount: null,
    clockInOutPageSize: null,
    clockInOutList: [],
  };
};
export const exportClockInOutListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};
export const exportClockInOutListRequestSuccessHandler = (state, action) => {
  const {
    payload: { data, start_date, end_date },
  } = action;
  if (data.status === 200) {
    try {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Clock_In/Out_report_${start_date}_${end_date}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log("🚀 ~ file: clockInOutReducer.js:56 ~ error:", error);
    }
  }
  return {
    ...state,
    isLoading: false,
  };
};

export const exportClockInOutListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...initialState,
  };
};

const ACTION_HANDLERS = {
  [GET_CLOCK_IN_OUT_LIST]: clockInOutListRequestHandler,
  [GET_CLOCK_IN_OUT_LIST_SUCCESS]: clockInOutListRequestSuccessHandler,
  [GET_CLOCK_IN_OUT_LIST_FAILURE]: clockInOutListRequestFailureHandler,
  [EXPORT_ATTENDANCE]: exportClockInOutListRequestHandler,
  [EXPORT_ATTENDANCE_SUCCESS]: exportClockInOutListRequestSuccessHandler,
  [EXPORT_ATTENDANCE_FAILURE]: exportClockInOutListRequestFailureHandler,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

const initialState = {
  isLoading: false,
  clockInOutList: [],

  // pagination
  clockInOutListCount: null,
  clockInOutPageSize: null,
};

export default function clockInOutReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
