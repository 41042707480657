import { Modal, ModalBody } from "reactstrap";
import { ModalButtonSection } from "../../../styledComponents/members";
import {
  CommonText,
  CommonFlex,
  CommonImage,
} from "../../../styledComponents/common";
import { PrimaryButton, WhiteButton } from "../../../styledComponents/buttons";

import warningIcon from "../../../assets/img/icons/alert.svg";

const ChangePaymentMethodModal = ({ isOpen, toggle, changePaymentMethod }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} style={{ marginTop: `25vh` }}>
      <ModalBody style={{ padding: `20px` }}>
        <CommonFlex>
          <CommonImage src={warningIcon} alt="" size="24px" />
          <CommonText style={{ paddingLeft: `10px` }}>
            Remember, after changing your payment method, you need to manually
            activate your subscription.
          </CommonText>
        </CommonFlex>

        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={() => toggle()}>
            Cancel
          </WhiteButton>
          <PrimaryButton onClick={() => changePaymentMethod()}>
            Change Payment Method
          </PrimaryButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default ChangePaymentMethodModal;
