import React, { Fragment } from "react";
import {
  StatusButton,
  AssigneeRoundImage,
} from "../../styledComponents/createProject";
import {
  TaskListContaier,
  AssigneeRoundImageSection,
} from "../../styledComponents/ProjectsNew";
import { CommonText } from "../../styledComponents/common";

import Avatar1 from "../../assets/img/avatar_1.svg";
import Avatar2 from "../../assets/img/avatar_2.svg";
import Avatar3 from "../../assets/img/avatar_3.svg";
import Avatar4 from "../../assets/img/avatar_4.svg";
import Avatar5 from "../../assets/img/avatar_5.svg";

const TaskList = () => {
  const taskList = [
    {
      id: 1,
      name: "Business Operational Meetings",
      date: "July 7, 2020",
      assigneeManage: [
        {
          id: 4,
          name: "Ricky Ponting",
          image: Avatar4,
        },
      ],
      status: 2,
    },
    {
      id: 2,
      name: "Photo Editing",
      date: "June 27, 2020",
      assigneeManage: [
        {
          id: 1,
          name: "John Smith",
          image: Avatar1,
        },
        {
          id: 2,
          name: "Kevin Peter",
          image: Avatar2,
        },
        {
          id: 4,
          name: "Ricky Ponting",
          image: Avatar4,
        },
        {
          id: 5,
          name: "Steve Smith",
          image: Avatar5,
        },
        {
          id: 7,
          name: "David Warner",
          image: Avatar2,
        },
      ],
      status: 0,
    },
    {
      id: 3,
      name: "UI/UX issues",
      date: "May 19, 2020",
      assigneeManage: [
        {
          id: 3,
          name: "Adam Gilli",
          image: Avatar3,
        },
        {
          id: 5,
          name: "Steve Smith",
          image: Avatar5,
        },
        {
          id: 7,
          name: "David Warner",
          image: Avatar2,
        },
      ],
      status: 1,
    },
  ];

  return (
    <TaskListContaier>
      <CommonText title name>
        Task Name
      </CommonText>
      <CommonText title name>
        Created On
      </CommonText>
      <CommonText title name>
        Assignees
      </CommonText>
      <CommonText title name>
        Status
      </CommonText>
      {taskList.map((task, index) => (
        <Fragment key={index}>
          <CommonText name> {task.name} </CommonText>
          <CommonText name> {task.date} </CommonText>
          <AssigneeRoundImageSection
            position={task.assigneeManage.length - 1}
            style={{ marginRight: `auto` }}
          >
            {task.assigneeManage.length > 3 && (
              <AssigneeRoundImage
                index={0}
                style={{ backgroundColor: `#C2CCE1` }}
              >
                {`+${task.assigneeManage.length - 3}`}
              </AssigneeRoundImage>
            )}
            {task.assigneeManage
              .filter((person, index) => index < 3)
              .map((filteredPerson, index) => (
                <AssigneeRoundImage
                  key={index}
                  index={task.assigneeManage.length < 4 ? index : index + 1}
                >
                  {
                    <img
                      src={filteredPerson.image}
                      alt="addPeople"
                      width="100%"
                    />
                  }
                </AssigneeRoundImage>
              ))}
          </AssigneeRoundImageSection>
          <StatusButton color={task.status}>
            {task.status === 0
              ? `Open`
              : task.status === 1
              ? `In Progress`
              : `Completed`}
          </StatusButton>
        </Fragment>
      ))}
    </TaskListContaier>
  );
};

export default TaskList;
