import React from "react";
import { Row, Col, Card, CardHeader, CardBody, Table } from "reactstrap";
import moment from "moment/moment";

let lastDate = undefined;

const Elemant = (props, month, merged, schedule_id = null, edit = null) => {
  let arr = [];

  const day = props.date;
  const start = props.start_time.slice(0, 2);
  const end = props.end_time.slice(0, 2);
  let startTime = `${start} am`;
  let endTime = `${end} am`;
  if (start > 12) {
    startTime = `${start - 12} pm`;
  } else {
    if (start[0] === "0") {
      startTime = `${start[1]} am`;
    }
  }
  if (end > 12) {
    endTime = `${end - 12} pm`;
  } else {
    if (end[0] === "0") {
      endTime = `${end[1]} am`;
    }
  }

  let flag = true;

  month.forEach((monthday, index) => {
    let monDayDateObj = new Date(monthday);
    if (moment(monthday).format("YYYY-MM-DD") === day) {
      arr.push(
        <td
          style={{
            background: "#20bead",
            textAlign: "center",
            color: "#fff",
            width: "3%"
          }}
          key={index}
          onClick={e => edit(schedule_id)}
          className="btn-spec-hover"
        >
          {" "}
          {`${startTime} - ${endTime}`}{" "}
        </td>
      );
      flag = false;
    } else if (flag && ((lastDate && monDayDateObj > lastDate) || !lastDate)) {
      arr.push(
        <td
          className={merged ? "border-top-none" : undefined}
          key={index}
          style={{ width: "3%" }}
        />
      );
    }
  });

  lastDate = new Date(day);

  return arr;
};

class MonthlyScheduleTable extends React.Component {
  render() {
    const { selectedDate, scheduleList, edit } = this.props;
    const monthHeader = [];
    const monthValue = [];
    let date = new Date(selectedDate);

    date.setDate(date.getDate());
    let momentDate = moment(date);
    let futureDate = moment(momentDate).add(31, "days");
    monthHeader.push(moment(momentDate).format("DD"));
    monthValue.push(moment(momentDate).format("YYYY-MM-DD"));

    for (let i = 0; i <= 30; i++) {
      date.setDate(date.getDate() + 1);
      let momentDate = moment(date);
      monthHeader.push(moment(momentDate).format("DD"));
      monthValue.push(moment(momentDate).format("YYYY-MM-DD"));
    }

    return (
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col md={4}>
                  <h4 style={{ margin: "0" }}>Monthly Schedule</h4>
                </Col>
                <Col
                  md={{ size: "3", offset: "5" }}
                  style={{ textAlign: "right" }}
                >
                  {`${momentDate.format(
                    "DD MMMM, YYYY - "
                  )} ${futureDate.format("DD MMMM, YYYY")}`}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table responsive style={{ marginTop: "20px" }}>
                <thead
                  style={{
                    borderTop: "1px solid #dcd7d7",
                    borderBottom: "1px solid #dcd7d7"
                  }}
                >
                  <tr>
                    <th>People</th>
                    {monthHeader.map((item, index) => (
                      <th key={index}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {scheduleList.map((schedule, index) => {
                    return (
                      <>
                        {schedule.schedules.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{schedule.user.fullname}</td>
                              {(lastDate = undefined)}
                              {item.map((item, index) => {
                                return (
                                  <>
                                    {Elemant(
                                      item,
                                      monthValue,
                                      false,
                                      item.schedule.id,
                                      edit
                                    )}
                                  </>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default MonthlyScheduleTable;
