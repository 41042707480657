import { connect } from "react-redux";

// Component
import AddTeam from "./AddTeam";

// Actions
import {
  addTeam,
  clearCreatedTeamId,
  getTeamDetails,
  getTeamMembersList,
  clearTeamMembersList,
  addTeamMember,
  updateTeamMember,
  removeTeamMember,
  getTeamProjectsList,
} from "../teamsActions";
import {
  getOrganizationMembersList,
  getProjectShortList,
  addTeamToProject,
} from "../../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => {
  return {
    isLoading: state.team.isLoading,
    projectListIsLoading: state.project.shortListIsLoading,
    projectIsLoading: state.project.isLoading,
    memberListIsLoading: state.project.memberShortListIsLoading,
    teamMemberIsLoading: state.team.teamMemberIsLoading,
    loadingMemberId: state.team.loadingMemberId,
    selectedOrganization: state.organization.selectedOrganization,
    orgMembersList: state.project.orgMembersList,
    createdTeamId: state.team.createdTeamId,
    teamDetails: state.team.teamDetails,
    teamMembersList: state.team.teamMembersList,
    teamProjectsList: state.team.teamProjectsList,
    projectList: state.project.projectShortList,
  };
};

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  addTeam: (details) => dispatch(addTeam({ ...details })),
  clearCreatedTeamId: () => dispatch(clearCreatedTeamId()),
  getTeamDetails: (details) => dispatch(getTeamDetails({ ...details })),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getProjectShortList: (details) =>
    dispatch(getProjectShortList({ ...details })),
  getTeamMembersList: (details) => dispatch(getTeamMembersList({ ...details })),
  clearTeamMembersList: () => dispatch(clearTeamMembersList()),
  addTeamMember: (details) => dispatch(addTeamMember({ ...details })),
  updateTeamMember: (details) => dispatch(updateTeamMember({ ...details })),
  removeTeamMember: (details) => dispatch(removeTeamMember({ ...details })),
  getTeamProjectsList: (details) =>
    dispatch(getTeamProjectsList({ ...details })),
  assignProject: (details) => dispatch(addTeamToProject({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(AddTeam);
