import { connect } from "react-redux";

import Payroll from "./Payroll";

//Actions
import {
  getPayablesData,
  sendOneTimePayment,
  sendHourlyPayment,
  getHourlyPaymentHistory,
  getOnetimePaymentHistory,
  clearOneTimePaymentData,
  exportPayablesData,
} from "./payrollActions";
import { getOrganizationMembersList } from "../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  paymentIsLoading: state.payroll.paymentIsLoading,
  payablesIsLoading: state.payroll.payablesIsLoading,
  paymentHistoryIsLoading: state.payroll.paymentHistoryIsLoading,
  selectedOrganization: state.organization.selectedOrganization,
  payablesData: state.payroll.payablesData,
  hourlyPaymentHistory: state.payroll.hourlyPaymentHistory,
  onetimePaymentHistory: state.payroll.onetimePaymentHistory,
  oneTimePaymentData: state.payroll.oneTimePaymentData,
  projectsList: state.project.projectShortList,
  organizationMembersList: state.project.orgMembersList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getPayablesData: (details) => dispatch(getPayablesData({ ...details })),
  sendOneTimePayment: (details) => dispatch(sendOneTimePayment({ ...details })),
  sendHourlyPayment: (details) => dispatch(sendHourlyPayment({ ...details })),
  getHourlyPaymentHistory: (details) =>
    dispatch(getHourlyPaymentHistory({ ...details })),
  getOnetimePaymentHistory: (details) =>
    dispatch(getOnetimePaymentHistory({ ...details })),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  clearOneTimePaymentData: () => dispatch(clearOneTimePaymentData()),
  exportPayablesData: (params) => dispatch(exportPayablesData(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payroll);
