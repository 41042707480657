import { CommonText } from "../../styledComponents/common";
import { OrgDiv, ProfileOrgDiv } from "./profileStyles";

const colorList = [
  { color: "#708CEB" },
  { color: "#9A96F9" },
  { color: "#76C794" },
  { color: "#F8A14B" },
];

const UserOrganization = (props) => {
  const { organizations } = props;

  return (
    <div>
      <CommonText fontSize="16px" title>
        Organizations
      </CommonText>
      <CommonText margin="8px 0 0 0" $label fontWeight="500">
        Your Organizations
      </CommonText>
      <OrgDiv>
        {organizations &&
          organizations.map((org, index) => {
            if (org.is_active) {
              return (
                <ProfileOrgDiv
                  bgColor={org.color || colorList[index % 4].color}
                >
                  {org.name}
                </ProfileOrgDiv>
              );
            } else {
              return null;
            }
          })}
      </OrgDiv>
    </div>
  );
};
export default UserOrganization;
