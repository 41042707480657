import { all, call, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

import {
  GET_SCHEDULE_LIST,
  GET_SCHEDULE_LIST_SUCCESS,
  GET_SCHEDULE_LIST_FAILURE,
  DEACTIVATE_SCHEDULE,
  DEACTIVATE_SCHEDULE_SUCCESS,
  DEACTIVATE_SCHEDULE_FAILURE,
  ADD_SCHEDULE,
  ADD_SCHEDULE_SUCCESS,
  ADD_SCHEDULE_FAILURE,
  UPDATE_SCHEDULE_LIST,
  UPDATE_SCHEDULE_LIST_SUCCESS,
  UPDATE_SCHEDULE_LIST_FAILURE,
  GET_SCHEDULE,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAILURE
} from "../../modules/constants";

//Handle shcedule list request
export function* scheduleListRequest({ payload }) {
  try {
    let url = "schedules/";
    const { defaultData } = payload;
    const response = yield defaultApi(url, "POST", defaultData);
    yield put({
      type: GET_SCHEDULE_LIST_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: GET_SCHEDULE_LIST_FAILURE,
      payload: err
    });
  }
}

export function* deactivateScheduleRequest({ payload }) {
  try {
    const { schedule_id } = payload;
    let url = `schedule_deactivate/`;
    const response = yield defaultApi(url, "PATCH", { schedule_id });
    if (response.status >= 200 && response.status < 300) {
      yield call(scheduleListRequest, { payload });
    }
    yield put({
      type: DEACTIVATE_SCHEDULE_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: DEACTIVATE_SCHEDULE_FAILURE,
      payload: err
    });
  }
}

export function* addScheduleRequest({ payload }) {
  try {
    let url = "schedule/";
    const { defaultData, ...details } = payload;
    const response = yield defaultApi(url, "POST", details);
    if (response.status >= 200 && response.status < 300) {
      yield call(scheduleListRequest, { payload });
    }
    yield put({
      type: ADD_SCHEDULE_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: ADD_SCHEDULE_FAILURE,
      payload: err
    });
  }
}

export function* updateScheduleRequest({ payload }) {
  try {
    const { defaultData, id, ...data } = payload;
    let url = `schedule/${id}/`;
    const response = yield defaultApi(url, "PATCH", data);
    yield call(scheduleListRequest, { payload });
    yield put({
      type: UPDATE_SCHEDULE_LIST_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: UPDATE_SCHEDULE_LIST_FAILURE,
      payload: err
    });
  }
}

export function* selectedScheduleRequest({ payload }) {
  try {
    let url = `schedule/${payload.id}/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_SCHEDULE_SUCCESS,
      payload: { data: response }
    });
  } catch (err) {
    yield put({
      type: GET_SCHEDULE_FAILURE,
      payload: err
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_SCHEDULE_LIST, scheduleListRequest),
    takeLatest(DEACTIVATE_SCHEDULE, deactivateScheduleRequest),
    takeLatest(ADD_SCHEDULE, addScheduleRequest),
    takeLatest(UPDATE_SCHEDULE_LIST, updateScheduleRequest),
    takeLatest(GET_SCHEDULE, selectedScheduleRequest)
  ]);
}
