import {
  GET_ROUTE_MAP_LOCATIONS,
  GET_ROUTE_MAP_LOCATIONS_SUCCESS,
  GET_ROUTE_MAP_LOCATIONS_FAILURE,
} from "../../../modules/constants";

import { createNotification } from "../../../modules/notificationManager";

// const getLocationData = (timesheetsList) => {
//   let userwiseTimesheets = [];
//   timesheetsList.forEach((timesheet) => {
//     if (timesheet.locations && timesheet.locations.length > 0) {
//       const timesheetData = {
//         id: timesheet.id,
//         project: timesheet.project,
//         task: timesheet.task,
//         startTimestamp: timesheet.start_timestamp,
//         endTimestamp: timesheet.end_timestamp,
//         duration: timesheet.end_timestamp - timesheet.start_timestamp,
//         locations: timesheet.locations.sort(
//           (a, b) => a.timestamp - b.timestamp
//         ),
//       };

//       const currentData = JSON.parse(JSON.stringify(userwiseTimesheets));

//       let hasData = false;

//       currentData.some((user) => {
//         if (user.id === timesheet.user.id) {
//           user.timesheets.push(timesheetData);
//           hasData = true;
//           return true;
//         }
//         return false;
//       });

//       if (hasData) {
//         userwiseTimesheets = [...currentData];
//       } else {
//         userwiseTimesheets.push({
//           id: timesheet.user.id,
//           user: timesheet.user,
//           timesheets: [timesheetData],
//         });
//       }
//     }
//   });

//   return userwiseTimesheets;
// };

export const handleRouteMapLocationsRequest = (state, action) => {
  return {
    ...state,
    locationsIsLoading: true,
  };
};
export const handleRouteMapLocationsRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data.status === 200 && data.data) {
    const timesheetsList = JSON.parse(JSON.stringify(data.data));
    // const locationDataMap = new Map();
    // const locationData = getLocationData(timesheetsList);

    return {
      ...state,
      locationsIsLoading: false,
      routeMapLocations: timesheetsList,
    };
  }
  return {
    ...state,
    locationsIsLoading: false,
    routeMapLocations: [],
  };
};
export const handleRouteMapLocationsRequestFailure = (state, action) => {
  return {
    ...state,
    locationsIsLoading: false,
    routeMapLocations: [],
  };
};

const ACTION_HANDLERS = {
  [GET_ROUTE_MAP_LOCATIONS]: handleRouteMapLocationsRequest,
  [GET_ROUTE_MAP_LOCATIONS_SUCCESS]: handleRouteMapLocationsRequestSuccess,
  [GET_ROUTE_MAP_LOCATIONS_FAILURE]: handleRouteMapLocationsRequestFailure,
};

const initialState = {
  locationsIsLoading: false,

  routeMapLocations: [],

  // pagination
  paginationSize: 10,
};

export default function routeMapReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
