import {
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT,
  GET_URL_USAGE_REPORT,
  GET_APP_USAGE_REPORT,
  GET_MANUAL_TIME_REPORT,
  GET_APP_TIME_INFO_REPORT,
  GET_REPORT_NOTES,
  GET_WEEKLY_ACTIVITY_AND_TIME_REPORT,
  GET_CUSTOM_REPORT,
  CREATE_CUSTOM_REPORT,
  DELETE_CUSTOM_REPORT,
  GET_CUSTOM_REPORT_DETAILS,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getAppUsageReport(value) {
  return {
    type: GET_APP_USAGE_REPORT,
    payload: value,
  };
}

export function getUrlUsageReport(value) {
  return {
    type: GET_URL_USAGE_REPORT,
    payload: value,
  };
}

export function getDayWiseActivityAndTimeReport(value) {
  return {
    type: GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT,
    payload: value,
  };
}

export function getWeeklyActivityAndTimeReport(value) {
  return {
    type: GET_WEEKLY_ACTIVITY_AND_TIME_REPORT,
    payload: value,
  };
}

export function getCustomReportList(value) {
  return {
    type: GET_CUSTOM_REPORT,
    payload: value,
  };
}
export function createCustomReport(value) {
  return {
    type: CREATE_CUSTOM_REPORT,
    payload: value,
  };
}
export function deleteCustomReports(value) {
  return {
    type: DELETE_CUSTOM_REPORT,
    payload: value,
  };
}
export function getCustomReportDetails(value) {
  return {
    type: GET_CUSTOM_REPORT_DETAILS,
    payload: value,
  };
}
export function getManualTimeReport(value) {
  return {
    type: GET_MANUAL_TIME_REPORT,
    payload: value,
  };
}

export function getAppTimeInfoReport(value) {
  return {
    type: GET_APP_TIME_INFO_REPORT,
    payload: value,
  };
}

export function getReportNotes(value) {
  return {
    type: GET_REPORT_NOTES,
    payload: value,
  };
}
