import { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Modal, ModalBody } from "reactstrap";
//import GSAPLoader from "./testLoader";

const CommonLoader = ({ open }) => {
  return (
    <Modal className="custom-loader" isOpen={open}>
      <ModalBody>
        <Loader type="Bars" color="#20bead" height={50} width={50} />
        {/* <GSAPLoader /> */}
      </ModalBody>
    </Modal>
  );
};

export default CommonLoader;
