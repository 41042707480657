import { connect } from "react-redux";

// Component
import TimesheetCustomRange from "./TimesheetCustomRange";
// Actions
import {
  getDailyTimesheets,
  getOtherTimesheets,
  clearTimesheetList,
} from "../timesheetActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  isLoading: state.timesheet.isLoading,
  dailyTimesheetLoading: state.timesheet.dailyTimesheetLoading,

  dailyTimesheets: state.timesheet.dailyList,
  timesheetsList: state.timesheet.list,

  selectedOrganization: state.organization.selectedOrganization,
  profileData: state.profile.profileData,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDailyTimesheets: (payload) => dispatch(getDailyTimesheets(payload)),
  getOtherTimesheets: (payload) => dispatch(getOtherTimesheets(payload)),
  clearTimesheetList: () => dispatch(clearTimesheetList()),
});

// connect states and dispatchers with components
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetCustomRange);
