import { useState, useEffect, Fragment } from "react";
import { Modal, ModalBody } from "reactstrap";
import { CommonText, CommonGrid } from "../../../styledComponents/common";
import { PrimaryButton, WhiteButton } from "../../../styledComponents/buttons";
import {
  PopupInnerBox,
  NotifyBox,
  CancelCross,
  SaveTag,
} from "../../../styledComponents/billing";
import { DiscountTextAndTagDiv } from "../subscriptionStyles";
import cross from "../../../assets/img/icons/cross_black.svg";

import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";

const MonthlySection = ({
  confirmPayment,
  toggle,
  toggleInterval,
  totalSeats,
  discountPercentage,
  discountPlan,
  discountedTotal,
  isLoading,
}) => {
  return (
    <Fragment>
      <PopupInnerBox
        monthly
        style={{ marginTop: `20px`, padding: `15px 17px` }}
      >
        <CommonText name title>
          {totalSeats} Users
        </CommonText>
        <DiscountTextAndTagDiv>
          <CommonText name>Billed ${discountedTotal}/month</CommonText>
          {discountPercentage &&
          discountPercentage > 0 &&
          discountPlan &&
          discountPlan.interval === "monthly" ? (
            <SaveTag style={{ marginLeft: "8px" }}>
              After {discountPercentage}% discount
            </SaveTag>
          ) : null}
        </DiscountTextAndTagDiv>
      </PopupInnerBox>
      <PopupInnerBox
        style={{
          color: `#20BEAD`,
          padding: `12px 30px`,
          marginTop: `10px`,
          textAlign: `center`,
        }}
      >
        Save 50% on yearly plan
      </PopupInnerBox>
      <CommonText style={{ marginTop: `20px` }} fontSize="13px">
        You can save 50% of your total budget when you Switch to a yearly plan.
      </CommonText>
      <PrimaryButton
        height="46px"
        style={{ marginTop: `20px` }}
        width="100%"
        onClick={() => {
          toggle();
          toggleInterval();
        }}
      >
        Switch to Yearly Plan
      </PrimaryButton>
      <WhiteButton
        type="cancel"
        margin="15px 0 0 0"
        height="46px"
        onClick={() => confirmPayment()}
      >
        {isLoading ? (
          <ButtonTextLoader
            loadingText="Processing"
            fontSize="13px"
            fontWeight="500"
            color="#3d4d69"
          />
        ) : (
          "Continue Payment"
        )}
      </WhiteButton>
    </Fragment>
  );
};

const YearlySection = ({
  confirmPayment,
  toggle,
  planData,
  totalSeats,
  discountPercentage,
  discountPlan,
  discountedTotal,
  isLoading,
}) => {
  const [monthlyBill, setMonthlyBill] = useState(0);

  useEffect(() => {
    if (planData && planData.amounts && totalSeats > 0) {
      const bill = totalSeats * planData.amounts.monthlyAmount * 12;
      setMonthlyBill(bill);
    }
  }, [planData, totalSeats]);
  return (
    <Fragment>
      <CommonText name title fontSize="16px">
        Confirm Yearly Subscription
      </CommonText>
      <CommonGrid columns="1fr 1fr" gap="20px" margin="20px 0">
        <PopupInnerBox monthly>
          <CommonText title>Monthly</CommonText>
          <CommonText fontSize="12px">{totalSeats} Users</CommonText>
          <CommonText fontSize="12px">
            Billed ${monthlyBill.toFixed(2)}/year
          </CommonText>
        </PopupInnerBox>
        <PopupInnerBox>
          <NotifyBox>Saved 50%</NotifyBox>
          <CommonText name title>
            Yearly
          </CommonText>
          <CommonText name fontSize="12px">
            {totalSeats} Users
          </CommonText>
          <CommonText name fontSize="12px">
            Billed ${discountedTotal}/year
          </CommonText>
          {discountPercentage &&
          discountPercentage > 0 &&
          discountPlan &&
          discountPlan.interval === "yearly" ? (
            <SaveTag style={{ fontSize: "11px", padding: "4px 10px" }}>
              After {discountPercentage}% discount
            </SaveTag>
          ) : null}
        </PopupInnerBox>
      </CommonGrid>
      {/* <CommonText fontSize="12px">
        Pre-purchased seats will be billed by yearly rate. Each user you add
        after your yearly plan starts will be billed at a monthly rate of $
        {planData &&
          planData.amounts &&
          planData.amounts.monthlyAmount.toFixed(2)}
        / month
      </CommonText> */}
      {/* <PrimaryButton
        height="46px"
        style={{ marginTop: `20px` }}
        width="100%"
        onClick={() => toggle()}
      >
        Add More Users
      </PrimaryButton> */}

      <PrimaryButton
        height="46px"
        style={{ marginTop: `20px` }}
        width="100%"
        onClick={() => confirmPayment()}
      >
        {isLoading ? (
          <ButtonTextLoader
            loadingText="Processing"
            fontSize="13px"
            fontWeight="500"
            color="#3d4d69"
          />
        ) : (
          "Continue Payment"
        )}
      </PrimaryButton>
      {/* <WhiteButton
        type="cancel"
        margin="15px 0 0 0"
        height="46px"
        onClick={() => confirmPayment()}
      >
        {isLoading ? (
          <ButtonTextLoader
            loadingText="Processing"
            fontSize="13px"
            fontWeight="500"
            color="#3d4d69"
          />
        ) : (
          "Continue Payment"
        )}
      </WhiteButton> */}
    </Fragment>
  );
};

const PaymentModal = ({
  isOpen,
  toggle,
  planInterval,
  confirmPayment,
  toggleInterval,
  planData,
  totalSeats,
  discountPercentage,
  discountPlan,
  discountedTotal,
  isLoading,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{
        marginTop: planInterval && planInterval === "monthly" ? `8vh` : "15vh",
        maxWidth: `385px`,
      }}
    >
      <ModalBody style={{ padding: `35px 25px 30px 25px` }}>
        <CancelCross onClick={toggle}>
          <img src={cross} alt="cancel" style={{ width: `30px` }} />
        </CancelCross>
        {planInterval && planInterval === "monthly" ? (
          <MonthlySection
            confirmPayment={confirmPayment}
            toggle={toggle}
            toggleInterval={toggleInterval}
            totalSeats={totalSeats}
            discountPercentage={discountPercentage}
            discountPlan={discountPlan}
            discountedTotal={discountedTotal}
            isLoading={isLoading}
          />
        ) : (
          <YearlySection
            confirmPayment={confirmPayment}
            toggle={toggle}
            toggleInterval={toggleInterval}
            planData={planData}
            totalSeats={totalSeats}
            discountPercentage={discountPercentage}
            discountPlan={discountPlan}
            discountedTotal={discountedTotal}
            isLoading={isLoading}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default PaymentModal;
