import {
  VerticalBarTrack,
  VerticalBarTrain,
} from "../../styledComponents/common";

const VerticalProgressBar = ({
  percent,
  marginTop,
  height,
  borderRadius,
  width,
  red,
  yellow,
  green,
}) => (
  <VerticalBarTrack
    percent={percent}
    marginTop={marginTop}
    width={width}
    height={height}
    borderRadius={borderRadius}
  >
    <VerticalBarTrain
      percent={percent}
      borderRadius={borderRadius}
      height={height}
      red={red}
      yellow={yellow}
      green={green}
    />
  </VerticalBarTrack>
);
export default VerticalProgressBar;
