import styled, { keyframes } from "styled-components";

import { Modal, ModalBody } from "reactstrap";

export const GreetingsModal = styled(Modal)`
  max-width: 580px;
  padding-top: 0 !important;
  .modal-content {
    border-radius: 14px;
    overflow-y: auto;
  }
`;

export const GreetingsModalBody = styled(ModalBody)`
  position: relative;
  border-radius: 14px;
  padding: 30px 0px 0px 0px;
`;

export const ModalCrossIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const GreetingsTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #20bead;
  text-align: center;
`;

export const GreetingsSubtitle = styled.div`
  font-size: 13px;
  color: rgba(47, 57, 78, 0.7);
  text-align: center;
  margin-top: 10px;
`;

export const VideoAndDownloadContainer = styled.div`
  display: flex;
  flex-direction: ${({ role }) =>
    role === "owner" ? `column` : `column-reverse`};
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 257px;
  padding: 0px 62px;
  margin: 30px 0px 30px 0px;
`;
const buttonRipple = keyframes`
  from {
    border: 0px solid rgba(32, 190, 173, 0.3);
  }
  to {
    border: 28px solid rgba(32, 190, 173, 0);
  }
`;
export const PlayButtonAnimation = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: ${buttonRipple} 1.5s ease infinite;
  padding: 30px;
`;
export const VideoPlayButton = styled.div`
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background: #20bead;
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
        hue-rotate(328deg) brightness(102%) contrast(101%);
    }
  }
`;
export const PlayIcon = styled.img`
  margin-left: 5px;
`;

export const AppDownloadContainer = styled.div`
  width: 100%;
  height: 150px;
  padding: 25px 100px;
  background: ${({ role }) => (role === "owner" ? "#1F2939" : "#fff")};
  border-radius: ${({ role }) => role === "owner" && `14px 14px 0px 0px`};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AppDownloadTitle = styled.div`
  font-size: 13px;
  color: ${({ role }) => (role === "owner" ? "#fff" : "rgba(47,57,78,0.7)")};
  text-align: center;
`;
export const DownloadIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
`;

export const DownloadIconDiv = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 4px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${({ role }) =>
    `1px solid ${role === "owner" ? "#324158" : "#e4e7eb"}`};
  cursor: pointer;
  &:hover {
    border: 1px solid #20bead;
  }
`;
export const DownloadIcon = styled.img`
  width: 30px;
`;
