export const colors = {
  textMain: "#1f2939",
  textSecondary: "#3d4d69",
  labelText: "rgba(61, 77, 105, 0.7)",
  placeholderText: "rgba(61, 77, 105, 0.7)",

  primary: "#20bead",
  primaryLight: "#eaf9f7",
  primaryDisabled: "#dce2ef",

  success: "#42be87",
  successMedium: "#d4f4ee",
  successLight: "#f0fbf9",

  warning: "#fab242",
  warningMedium: "#fad59d",
  warningLight: "#fdf3e4",

  error: "#fe5969",
  errorMedium: "#ffacb4",
  errorLight: "#fff6f7",
  errorDisabled: "#ffc7cc",

  purple: "#6f7aff",
  purpleMedium: "#9a96f9",
  purpleLight: "#d9d7fc",

  blue: "#4d94fb",
  blueMedium: "#98c3ff",
  blueLight: "#d2e4fe",

  gray: "#7f9dbf", // mark as paid
  grayMedium: "#9eafc2", // save as draft
  grayLight: "#becbdb",

  // plan gray color
  planGray: "#6686a2",
  playGrayLight: "#eaf2f9",

  blackButton: "#465065",
  focusShadow: "#eaf9f7",

  white: "#fff",
  whiteBg: "#fcfdfe",
};

export const hoverColors = {
  primary: "#1dac9c",
  error: "#ed5160",
  warning: "#f09b16",
  purpleMedium: "#8985ea",
  blue: "#3f86f0",
  gray: "#6d8caf",
  grayMedium: "#8ea1b5",
  planGray: "#617f9a",
  blackButton: "#1f2939",
};

export const borderColors = {
  primary: colors.primary,
  secondary: "#c2cce1",
  secondaryLight: "#e4e7eb",
  blackButton: "#1f2939",
  error: colors.error,
};

export const hoverBorderColors = {
  secondaryLight: "#88e0d7",
};
