import { useState } from "react";
import { LightgalleryItem } from "react-lightgallery";
import noImg from "../../../assets/img/no-image.png";
import {
  SSimg,
  MagnifyIcon,
  NoteButton,
  NoteDeleteSectionInPhoto,
} from "../../../styledComponents/Activity";

import noScreenShot from "../../../assets/img/screenshot_2.svg";
import Bean from "../../../assets/img/icons/delete.svg";
import magnify from "../../../assets/img/icons/magnifying-glass-plus.svg";
import Note from "../../../assets/img/icons/add_note.svg";
import { WhiteButton, ColoredButton } from "../../../styledComponents/buttons";
import { CommonImage } from "../../../styledComponents/common";
import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

function PhotoItem({
  item,
  group,
  toggleDeleteScreenshot,
  onAddNoteClick,
  selectedOrganization,
  screenshotBlur,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [imageId, setImageId] = useState(null);

  const handleMouseEnter = (id) => {
    setIsHovered(true);
    setImageId(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setImageId(null);
  };

  if (item.screenshots.length) {
    return item.screenshots.map((i, k) => {
      return (
        <LightgalleryItem
          src={i.screenshot || noImg}
          key={k}
          group={group}
          subHtml={`<div class="col-xs-12 col-md-4 screenshot-desc-col">
                      <div class="row">
                        <p class="screenshot-desc-title">Project: </p>
                        <p class="screenshot-desc-text">${
                          item.project && item.project.name
                        }</p>
                      </div>
                      <div class="row screenshot-desc-row-mobile">
                        <p class="screenshot-desc-title">Task: </p>
                        <p class="screenshot-desc-text">${
                          item.task && item.task.name
                            ? item.task.name
                            : `No task`
                        }</p>
                      </div>
                    </div>
                      <div class="col-md-4 col-xs-12  screenshot-desc-col">
                      <div class="row" style="justify-content: center;">
                        <p class="screenshot-desc-title">Activity Level: </p>
                        <p class="screenshot-desc-text">${
                          item.activity && item.activity.activity
                            ? parseInt(item.activity.activity)
                            : 0
                        } %</p>
                      </div>
                      ${
                        item.activity && item.activity.screen_count
                          ? `<div class="row screenshot-desc-row-mobile" style="justify-content: center;">
                        <p class="screenshot-desc-title">Screen: </p>
                        <p class="screenshot-desc-text">${i.screen_number} of ${item.activity.screen_count}</p>
                      </div>`
                          : ``
                      }
                    </div>
                    <div class="col-md-4 col-xs-12 screenshot-desc-col">
                      <div class="row" style="justify-content: flex-end">
                        <p class="screenshot-desc-title">Time Range: </p>
                        <p class="screenshot-desc-text">${item.start_timestamp.format(
                          "LT"
                        )} -${" "}
                        ${item.end_timestamp.format("LT")}</p>
                      </div>
                      ${
                        i.screenshot_timestamp
                          ? `<div class="row screenshot-desc-row-mobile" style="justify-content: flex-end;">
                        <p class="screenshot-desc-title">Screenshot Taken At: </p>
                        <p class="screenshot-desc-text">${i.screenshot_timestamp.format(
                          "LT"
                        )}</p>
                      </div>`
                          : ``
                      }
                    </div>`}
        >
          {k === 0 && (
            <div
              onMouseEnter={() => handleMouseEnter(k)}
              onMouseLeave={handleMouseLeave}
              style={{ position: "relative" }}
            >
              <SSimg
                key={k}
                src={i.thumbnail || noScreenShot}
                alt="screenshot"
                width="100%"
                style={{ cursor: "pointer", width: "100%", height: "auto" }}
                screenshotBlur={screenshotBlur}
                isHovered={k == imageId && isHovered}
              />

              {k == imageId && isHovered && (
                <>
                  <MagnifyIcon>
                    <CommonImage
                      src={magnify}
                      alt="Magnify"
                      width="26px"
                      height="26px"
                    />
                  </MagnifyIcon>
                  <NoteDeleteSectionInPhoto>
                    {(selectedOrganization &&
                      selectedOrganization.role &&
                      selectedOrganization.role !== "member") ||
                    (selectedOrganization &&
                      selectedOrganization.configuration &&
                      selectedOrganization.configuration
                        .user_delete_screenshot) ? (
                      <WhiteButton
                        type="delete"
                        height="28px"
                        width="28px"
                        padding="3px"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the image's onClick from being triggered
                          toggleDeleteScreenshot();
                        }}
                      >
                        <CommonImage src={Bean} alt="" width="16px" />
                      </WhiteButton>
                    ) : null}
                    {/* <ColoredButton
                      onClick={(e) => {
                        e.stopPropagation(); 
                        onAddNoteClick();
                      }}
                      type="gray"
                      height="40px"
                    >
                      Add Note
                    </ColoredButton> */}
                    <NoteButton
                      // type="delete"
                      height="28px"
                      width="28px"
                      padding="3px"
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddNoteClick();
                      }}
                    >
                      <CommonImage src={Note} alt="" width="16px" />
                    </NoteButton>
                  </NoteDeleteSectionInPhoto>
                </>
              )}
            </div>
          )}
        </LightgalleryItem>
      );
    });
  } else {
    return (
      <LightgalleryItem
        src={noImg}
        group={group}
        subHtml={`<div class="col-md-4 col-xs-12  screenshot-desc-col">
                      <div class="row">
                        <p class="screenshot-desc-title">Project: </p>
                        <p class="screenshot-desc-text">${
                          item.project && item.project.name
                        }</p>
                      </div>
                      <div class="row screenshot-desc-row-mobile">
                        <p class="screenshot-desc-title">Task: </p>
                        <p class="screenshot-desc-text">${
                          item.task && item.task.name
                            ? item.task.name
                            : `No task`
                        }</p>
                      </div>
                    </div>
                    ${
                      // item.client_app === "desktop" ?
                      `<div class="col-md-4 col-xs-12  screenshot-desc-col">
                      <div class="row" style="justify-content: center;">
                        <p class="screenshot-desc-title">Activity Level: </p>
                        <p class="screenshot-desc-text">${
                          item.activity && item.activity.activity
                            ? parseInt(item.activity.activity)
                            : 0
                        } %</p>
                      </div>
                    </div>
                    <div class="col-md-4 col-xs-12  screenshot-desc-col">
                      <div class="row" style="justify-content: flex-end">
                        <p class="screenshot-desc-title">Time Range: </p>
                        <p class="screenshot-desc-text">${item.start_timestamp.format(
                          "LT"
                        )} -${" "}
                        ${item.end_timestamp.format("LT")}</p>
                      </div>
                    </div>`
                      // : `<div class="col-md-8 col-xs-12  screenshot-desc-col">
                      //     <div class="row" style="justify-content: flex-end; text-align: left;">
                      //       <p class="screenshot-desc-text">This timeframe is from mobile. To view location info plase visit the Locations page</p>
                      //     </div>
                      //    </div>`
                    }
                  `}
      >
        <div
          onMouseEnter={() => handleMouseEnter("last")}
          onMouseLeave={handleMouseLeave}
          style={{ position: "relative" }}
        >
          <SSimg
            src={noScreenShot}
            alt="no"
            width="100%"
            style={{ cursor: "pointer", width: "100%", height: "auto" }}
          />
          {imageId == "last" && isHovered && (
            <NoteDeleteSectionInPhoto>
              {(selectedOrganization &&
                selectedOrganization.role &&
                selectedOrganization.role !== "member") ||
              (selectedOrganization &&
                selectedOrganization.configuration &&
                selectedOrganization.configuration.user_delete_screenshot) ? (
                <WhiteButton
                  type="delete"
                  height="28px"
                  width="28px"
                  padding="3"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the image's onClick from being triggered
                    toggleDeleteScreenshot();
                  }}
                >
                  <img src={Bean} alt="" width="16px" />
                </WhiteButton>
              ) : null}
              {/* <ColoredButton
                onClick={() => onAddNoteClick()}
                type="gray"
                height="40px"
              >
                Add Note
              </ColoredButton> */}
              <WhiteButton
                height="28px"
                width="28px"
                padding="3"
                onClick={(e) => {
                  e.stopPropagation();
                  onAddNoteClick();
                }}
              >
                <CommonImage src={Note} alt="" width="16px" />
              </WhiteButton>
            </NoteDeleteSectionInPhoto>
          )}
        </div>
      </LightgalleryItem>
    );
  }
}

export default PhotoItem;
