import { connect } from "react-redux";

// Component
import TeamDetails from "./TeamDetailsNew";

// Actions
import {
  getTeamDetails,
  updateTeam,
  deleteTeam,
  getTeamMembersList,
  addTeamMember,
  updateTeamMember,
  removeTeamMember,
  getTeamProjectsList,
} from "../teamsActions";
import {
  getOrganizationMembersList,
  getProjectShortList,
  addTeamToProject,
} from "../../Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => {
  return {
    // loading
    isLoading: state.team.isLoading,
    teamMemberListLoading: state.team.memberListLoading,
    teamProjectListLoading: state.team.projectListLoading,
    memberListIsLoading: state.project.memberShortListIsLoading,
    projectListIsLoading: state.project.shortListIsLoading,
    projectIsLoading: state.project.isLoading,
    teamMemberIsLoading: state.team.teamMemberIsLoading,
    loadingMemberId: state.team.loadingMemberId,
    deleteTeamLoading: state.team.deleteTeamLoading,
    updateTeamLoading: state.team.updateTeamLoading,

    // data
    selectedOrganization: state.organization.selectedOrganization,
    orgMembersList: state.project.orgMembersList,
    teamDetails: state.team.teamDetails,
    teamMembersList: state.team.teamMembersList,
    teamProjectsList: state.team.teamProjectsList,
    projectList: state.project.projectShortList,

    // pagination
    teamMembersCount: state.team.teamMembersCount,
    teamMembersPageSize: state.team.teamMembersPageSize,
    teamProjectsCount: state.team.teamProjectsCount,
    teamProjectsPageSize: state.team.teamProjectsPageSize,
  };
};

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getTeamDetails: (details) => dispatch(getTeamDetails({ ...details })),
  updateTeam: (details) => dispatch(updateTeam({ ...details })),
  deleteTeam: (details) => dispatch(deleteTeam({ ...details })),
  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getProjectShortList: (details) =>
    dispatch(getProjectShortList({ ...details })),
  getTeamMembersList: (details) => dispatch(getTeamMembersList({ ...details })),
  addTeamMember: (details) => dispatch(addTeamMember({ ...details })),
  updateTeamMember: (details) => dispatch(updateTeamMember({ ...details })),
  removeTeamMember: (details) => dispatch(removeTeamMember({ ...details })),
  getTeamProjectsList: (details) =>
    dispatch(getTeamProjectsList({ ...details })),
  assignProject: (details) => dispatch(addTeamToProject({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(TeamDetails);
