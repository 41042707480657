import {
  REDEEM_LIMITED_TIME_CODE,
  REDEEM_LIMITED_TIME_CODE_SUCCESS,
  REDEEM_LIMITED_TIME_CODE_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------
export const handleRedeemRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    redirect: false,
    registerNeeded: false,
    errorMessage: null,
  };
};
export const handleRedeemRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  if (data.status === 201) {
    createNotification("success", "Code added successfully", 3000);
    return {
      ...state,
      isLoading: false,
      registerNeeded: false,
      invalidCodes: [],
      paddleCoupon: data.data.paddle_coupon ? data.data.paddle_coupon : null,
      discountedPlan: data.data.plan ? data.data.plan : null,
      redirect: true,
    };
  }
  return {
    ...state,
    registerNeeded: false,
    invalidCodes: [],
    paddleCoupon: null,
    discountedPlan: null,
    isLoading: false,
    redirect: false,
  };
};
export const handleRedeemRequestFailure = (state, action) => {
  const {
    payload: { response },
  } = action;
  if (response === undefined) {
    createNotification("error", "No internet connection found", 3000);
    return {
      ...state,
      isLoading: false,
      redirect: false,
    };
  }
  let invalid = [];
  if (
    response &&
    response.data &&
    response.data.codes &&
    response.data.codes.length > 0
  ) {
    invalid = response.data.codes;
  }
  let errorMessage = null;
  if (
    response &&
    response.data &&
    response.data.non_field_errors &&
    response.data.non_field_errors.length > 0
  ) {
    errorMessage = response.data.non_field_errors[0];
  }
  let registrationNeeded = false;
  if (response && response.data && response.data.email) {
    registrationNeeded = true;
  }
  return {
    ...state,
    isLoading: false,
    invalidCodes: invalid,
    errorMessage,
    registerNeeded: registrationNeeded,
    paddleCoupon: null,
    discountedPlan: null,
    redirect: false,
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [REDEEM_LIMITED_TIME_CODE]: handleRedeemRequest,
  [REDEEM_LIMITED_TIME_CODE_SUCCESS]: handleRedeemRequestSuccess,
  [REDEEM_LIMITED_TIME_CODE_FAILURE]: handleRedeemRequestFailure,
  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  redirect: false,
  isLoading: false,
  invalidCodes: [],
  registerNeeded: false,
  errorMessage: null,
  paddleCoupon: null,
  discountedPlan: null,
};

export default function resetPasswordReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
