import { useState, useEffect } from "react";
import { TableItem } from "../../../styledComponents/teams";
import {
  CommonGrid,
  CardTitle,
  TableText,
  CommonText,
} from "../../../styledComponents/common";
import {
  MemberSettingsSection,
  MembersTableHeadContainer,
  MembersTableContainer,
  MemberNameDiv,
  Input,
} from "./membersTableStyles";
import { ToggleButton, ToggleButtonRound } from "./activitySettingsStyles";
import {
  CommImageNameComp,
  CommFirstRoundLetterCompNew,
} from "../../Members/CommonComponents";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import InfoTooltip from "../../../components/Tooltip/InfoTooltip";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

import searchIcon from "../../../assets/img/icons/search.svg";

const MembersTable = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [searchTerm, setSearchText] = useState("");

  const {
    history,
    membersListLoading,
    selectedOrganization,
    membersList,
    memberSettingsChange,

    // pagination
    totalMembersCount,
    currentPageNumber,
    onPageChange,
  } = props;

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      const list = [];
      membersList.map((member) => {
        list.push({
          id: member.id,
          userId: member.user.id,
          name: `${member.user.first_name}${
            member.user.last_name ? ` ${member.user.last_name}` : ``
          }`,
          avatar: member.user?.avatar ?? null,
          color: member.user?.color ?? null,
          settings: member.should_track,
        });
      });
      setMemberList(list);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      const originalList = JSON.parse(JSON.stringify(membersList));
      if (searchTerm && searchTerm !== "") {
        let filteredList = [];
        originalList.forEach((e) => {
          let searchField;
          searchField = `${e.user.first_name}${
            e.user.last_name ? ` ${e.user.last_name}` : ``
          }`;

          if (
            searchTerm &&
            searchField
              .toLowerCase()
              .indexOf(searchTerm.toLowerCase().trim()) === -1
          ) {
            return;
          }
          if (selectedOrganization) {
            filteredList.push({
              id: e.id,
              name: `${e.user.first_name}${
                e.user.last_name ? ` ${e.user.last_name}` : ``
              }`,
              avatar: e.user?.avatar ?? null,
              color: e.user?.color ?? null,
              settings: e.should_track,
            });
          }
        });
        // console.log("Filter List :", filteredList);
        setMemberList([...filteredList]);
      } else if (searchTerm === "") {
        const list = [];
        originalList.map((member) => {
          list.push({
            id: member.id,
            name: `${member.user.first_name}${
              member.user.last_name ? ` ${member.user.last_name}` : ``
            }`,
            avatar: member.user?.avatar ?? null,
            color: member.user?.color ?? null,
            settings: member.should_track,
          });
        });
        setMemberList([...list]);
      }
    }
  }, [searchTerm]);

  const onTextChange = (event) => {
    if (event.target.value != null || event.target.value != undefined) {
      setSearchText(event.target.value);
    }
  };

  return (
    <MemberSettingsSection
      margin={
        selectedOrganization &&
        (selectedOrganization.role !== "owner" ||
          selectedOrganization.role !== "admin")
          ? "20px 0 0"
          : "0"
      }
    >
      {membersListLoading ? (
        <ComponentCircleLoader padding="10px 0" />
      ) : memberList && memberList.length > 0 ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Tracking Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={(e) => onTextChange(e)}
            />
          </CommonGrid>
          {totalMembersCount && totalMembersCount > 0 ? (
            <TopPagination
              itemName="members"
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => onPageChange(page)}
              pageSize={50}
              padding="0 40px"
            />
          ) : null}
          <MembersTableHeadContainer gap="2em">
            <TableItem>
              <TableText>Member Name</TableText>
            </TableItem>
            <TableItem>
              <InfoTooltip
                mainText="Control Tracking"
                toolTipText={`Control whether you want to track any specific employee or not. If you turn it off, screenshots, apps, urls and activity tracking of that member will be disabled.`}
                marginTop="0px"
                top="-115px"
                label
              />
            </TableItem>
          </MembersTableHeadContainer>
          {memberList.map((member, index) => (
            <MembersTableContainer key={index} gap="2em">
              <TableItem>
                <MemberNameDiv
                  onClick={() =>
                    history.push(`/user/member-details?id=${member.userId}`)
                  }
                >
                  <CommImageNameComp
                    imgSource={member.avatar}
                    name={member.name}
                    color={member.color}
                    index={index}
                    size="36px"
                  />
                </MemberNameDiv>
              </TableItem>
              <TableItem>
                <CommonGrid
                  columns="36px 30px"
                  gap="0.25em"
                  justifyContent="start"
                >
                  <ToggleButton
                    onClick={() => memberSettingsChange(member)}
                    active={member.settings}
                  >
                    <ToggleButtonRound active={member.settings} />
                  </ToggleButton>
                  <CommonText
                    $label={!member.settings}
                    fontWeight="500"
                    fontSize="13px"
                    margin="0"
                  >
                    {member.settings ? "ON" : "OFF"}
                  </CommonText>
                </CommonGrid>
              </TableItem>
            </MembersTableContainer>
          ))}
          {totalMembersCount && totalMembersCount > 0 ? (
            <BottomPagination
              totalCount={totalMembersCount}
              currentPage={currentPageNumber}
              onPageChange={(page) => onPageChange(page)}
              pageSize={50}
            />
          ) : null}
        </>
      ) : memberList && memberList.length === 0 && searchTerm ? (
        <>
          <CommonGrid columns="45% 55%" gap="2em" alignItem="center">
            <CardTitle fontSize="16px" margin="0 0 0 40px">
              Tracking Individual Member
            </CardTitle>
            <Input
              backgroundImage={searchIcon}
              type="text"
              value={searchTerm}
              placeholder="Search by member name..."
              onChange={(e) => onTextChange(e)}
            />
          </CommonGrid>
          <NoDataComponent
            title="No member found with this name"
            padding="30px 0"
            imageHeight="130px"
            imageWidth="130px"
            titleFontSize="16px"
          />
        </>
      ) : null}
    </MemberSettingsSection>
  );
};

export default MembersTable;
