import {
  CustomReportFeatureButtonContainer,
  FeatureCheckIconContainer,
} from "../customReportStyles";
import green_check_icon from " ../../assets/img/icons/green_check.svg";

const CustomReportFeatureButton = ({ data, updateSelection }) => {
  return (
    <CustomReportFeatureButtonContainer
      {...data}
      onClick={() => updateSelection({ ...data, selected: !data.selected })}
    >
      {data.selected && (
        <FeatureCheckIconContainer>
          <img src={green_check_icon} alt="assign" height={20} width={20} />
        </FeatureCheckIconContainer>
      )}
      {data.title}
    </CustomReportFeatureButtonContainer>
  );
};

export default CustomReportFeatureButton;
