import { connect } from "react-redux";

// Component
import ReportsFilter from "./ReportsFilter";

//Actions
import {
  getDayWiseActivityAndTimeReport,
  getManualTimeReport,
  getAppUsageReport,
  getUrlUsageReport,
  getAppTimeInfoReport,
  getWeeklyActivityAndTimeReport,
} from "./reportsActions";

import {
  getOrganizationMembersList,
  getProjectAssigneeList,
} from "../../custom_modules/Projects/projectsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.report.isLoading,
  organizationMembersList: state.project.orgMembersList,
  projectsList: state.project.projectShortList,
  projectAssigneeList: state.project.projectAssigneeList,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getDayWiseActivityAndTimeReport: (details) =>
    dispatch(getDayWiseActivityAndTimeReport(details)),
  getWeeklyActivityAndTimeReport: (details) =>
    dispatch(getWeeklyActivityAndTimeReport(details)),
  getManualTimeReport: (details) => dispatch(getManualTimeReport(details)),
  getAppUsageReport: (details) => dispatch(getAppUsageReport(details)),
  getUrlUsageReport: (details) => dispatch(getUrlUsageReport(details)),
  getAppTimeInfoReport: (details) => dispatch(getAppTimeInfoReport(details)),

  getOrganizationMembersList: (details) =>
    dispatch(getOrganizationMembersList({ ...details })),
  getProjectAssigneeList: (details) =>
    dispatch(getProjectAssigneeList({ ...details })),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilter);
