import React, { useState, useEffect } from "react";
import { FirstWordRound } from "styledComponents/common";

const colorList = [
  { color: "#789CFF" },
  { color: "#2C3E50" },
  { color: "#76C794" },
  { color: "#F8A14B" },
  { color: "#FE5969" },
];

const FirstRoundLetterComp = ({
  text,
  backColor = 0,
  size = "40px",
  fontSize = "14px",
  onClick,
  color,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    setSelectedIndex(backColor < 5 ? backColor : backColor % 5);

    const words = text.split(" ");
    const firstLetter = words[0]?.charAt(0).toUpperCase() || "";
    const secondLetter = words[1]?.charAt(0).toUpperCase() || "";
    setDisplayText(`${firstLetter}${secondLetter}`);
  }, [text, backColor]);

  return (
    <FirstWordRound
      size={size}
      fontSize={fontSize}
      backColor={color ? color : colorList[selectedIndex].color}
      onClick={onClick}
    >
      {displayText}
    </FirstWordRound>
  );
};

export default FirstRoundLetterComp;
