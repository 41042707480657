import {
  //new apis
  GET_DASHBOARD_COMPETITIVE_ANALYTICS,
  GET_DASHBOARD_ORGANIZATION_ANALYTICS,
  GET_DASHBOARD_WEEKLY_AGGREGATED_DATA,
  GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA,
  GET_DASHBOARD_TODAYS_ACTIVITY,
  GET_DASHBOARD_USER_ANALYTICS,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------

export function getDashboardCompetitiveAnalytics(value) {
  return {
    type: GET_DASHBOARD_COMPETITIVE_ANALYTICS,
    payload: value,
  };
}
export function getDashboardWeeklyAggregatedData(value) {
  return {
    type: GET_DASHBOARD_WEEKLY_AGGREGATED_DATA,
    payload: value,
  };
}
export function getDashboardLastWeekAggregatedData(value) {
  return {
    type: GET_DASHBOARD_LAST_WEEK_AGGREGATED_DATA,
    payload: value,
  };
}
export function getDashboardTodaysActivity(value) {
  return {
    type: GET_DASHBOARD_TODAYS_ACTIVITY,
    payload: value,
  };
}
export function getDashboardOrganizationAnalytics(value) {
  return {
    type: GET_DASHBOARD_ORGANIZATION_ANALYTICS,
    payload: value,
  };
}
export function getDashboardUserAnalytics(value) {
  return {
    type: GET_DASHBOARD_USER_ANALYTICS,
    payload: value,
  };
}
