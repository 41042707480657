import { useState, useRef } from "react";
import styled, { css } from "styled-components";
import arrowDown from "../../../assets/img/icons/arrow_down_accordion.svg";

const AccordionContainer = styled.div`
  margin: 40px 0 0;
  display: grid;
  grid-template-columns: 100%;
  /* grid-column-gap: 50px; */
  justify-content: center;
  padding: 20px 15px;

  @media (max-width: 767px) {
    grid-template-columns: auto;
    grid-row-gap: 40px;
  }
  @media (max-width: 500px) {
    padding: 20px 0;
  }
`;

const AccordTitleImageGrid = styled.div`
  display: grid;
  /* grid-template-rows: 225px 30%; */
  justify-content: center;
  grid-row-gap: 40px;
  @media (max-width: 767px) {
    grid-row-gap: 20px;
    justify-content: space-evenly;
  }
`;

const AccordTitleFlex = styled.div`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-row-gap: 25px;
  @media (max-width: 767px) {
    grid-row-gap: 5px;
    justify-content: space-evenly;
  }
`;
const AccordTitle = styled.div`
  font-size: 30px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.main.textMain};
  line-height: 1.15;
  @media (max-width: 767px) {
    font-size: 26px;
  }
`;

const ArrowUpDownIcon = styled.img`
  ${({ isActive }) =>
    isActive &&
    css`
      transform: rotate(180deg);
    `}
  transition: transform 0.6s ease;
  margin-bottom: 0;
`;

export const Accordion = (props) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <p className="accordion__title">{props.title}</p>
        {/* <PlusMinusCircle>{setActive !== "" ? `-` : `+`}</PlusMinusCircle> */}
        <ArrowUpDownIcon isActive={setActive !== ""} src={arrowDown} alt="" />
        <div
          ref={content}
          style={{ maxHeight: `${setHeight}` }}
          className="accordion__content"
        >
          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </div>
      </div>
    </div>
  );
};

export const PricingAccordion = () => (
  <AccordionContainer>
    <AccordTitleImageGrid>
      <AccordTitleFlex>
        <AccordTitle>Frequently Asked Questions</AccordTitle>
      </AccordTitleFlex>
    </AccordTitleImageGrid>
    <div style={{ display: "grid", gridRowGap: "15px" }}>
      <Accordion
        title="Do you track keystrokes?"
        content="We just count the keystrokes for activity calculation. We do not track any data from the key pressed."
      />
      <Accordion
        title="Can I plan for an extensive team?"
        content="Yes, you may. If you have more than 300 members, please contact us for further negotiation."
      />
      <Accordion
        title="Do you provide a refund facility?"
        content="Yes, we do. For getting a refund, please let us know before the first 30 days. You won’t be refunded after the time limit mentioned above.
  "
      />
      <Accordion
        title="Can I send an invitation to my clients?"
        content="Sure, you can invite clients as much as you want without any bill or charge.
  "
      />
      {/* <Accordion
        title="Can I get a demo?"
        content="Please click here to get our demo page."
      /> */}
      <Accordion
        title="What kind of payment methods do you accept?"
        content="We use Paddle for our payment gateway."
      />
      <Accordion
        title="How can I get support for any problem from the Apploye team?
  "
        content="You can contact us through support@apploye.com or use the chat option to get support.
  "
      />
    </div>
  </AccordionContainer>
);
