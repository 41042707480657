import { useEffect, useRef, useState } from "react";
import {
  ShowMoreAssigneeList,
  AssignListText,
} from "../../styledComponents/common";

const ProjectListPopup = ({ projectList }) => {
  const moreRef1 = useRef(null);
  const [autoPlacement, setAutoPlacement] = useState(null);
  const [mouseY, setMouseY] = useState(0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const GAP = 28; // Same gap for above and below
  const POPUP_HEIGHT = 300; // as max-height: 300px in ShowMoreAssigneeList styled component

  // Update mouse Y position
  const handleMouseMove = (event) => {
    setMouseY(event.clientY);
  };

  // Update window height on resize
  const handleResize = () => {
    setWindowHeight(window.innerHeight);
  };

  useOutsideClickHandler(moreRef1);

  function useOutsideClickHandler(moreRef1) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (moreRef1.current && !moreRef1.current.contains(event.target)) {
          // setMoreAssignee(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [moreRef1]);
  }

  // Set up event listeners for mousemove and resize
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Determine placement of the popup based on mouse position and window height
  useEffect(() => {
    if (mouseY > 0) {
      // Ensure mouse position is available before calculating
      const mouseYFromBottom = windowHeight - mouseY;
      if (mouseYFromBottom + POPUP_HEIGHT + GAP < windowHeight) {
        setAutoPlacement("above");
      } else {
        setAutoPlacement("below");
      }
    }
  }, [mouseY, windowHeight]);

  // Render only if placement is calculated
  return (
    autoPlacement && (
      <ShowMoreAssigneeList
        ref={moreRef1}
        top={autoPlacement === "below" ? `${GAP}px` : "unset"}
        bottom={autoPlacement === "above" ? `${GAP}px` : "unset"}
        left="-100px"
        columns="auto"
        autoPlacement={autoPlacement}
      >
        {projectList.map((project, index) => (
          <AssignListText style={{ padding: "5px" }} key={index}>
            {project.name}
          </AssignListText>
        ))}
      </ShowMoreAssigneeList>
    )
  );
};

export default ProjectListPopup;
