import { connect } from "react-redux";

// Component
// import Schedule from './Schedules.jsx';
import Schedule from "./Schedules.jsx";
// Actions
import {
  getScheduleRequest,
  deactivateScheduleRequest,
  addScheduleRequest,
  updateScheduleRequest,
  getSelectedScheduleRequest,
  resetSelectedScheduleRequest,
} from "./scheduleActions";
import { getOrganizationMembers } from "../Organization/OrganizationActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  scheduleList: state.schedule.list,
  organizationList: state.organization,
  organization: state.organization.selectedOrganization,
  projectList: state.project.list,
  taskList: state.task.list,
  selectedSchedule: state.schedule.selectedSchedule,
  isLoading: state.schedule.isLoading,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getScheduleRequest: (details) => dispatch(getScheduleRequest({ ...details })),
  getOrganizationMembers: (details) =>
    dispatch(getOrganizationMembers({ ...details })),
  deactivateScheduleRequest: (details) =>
    dispatch(deactivateScheduleRequest({ ...details })),
  addScheduleRequest: (details) => dispatch(addScheduleRequest({ ...details })),
  updateScheduleRequest: (details) =>
    dispatch(updateScheduleRequest({ ...details })),
  getSelectedScheduleRequest: (details) =>
    dispatch(getSelectedScheduleRequest({ ...details })),
  resetSelectedScheduleRequest: () => dispatch(resetSelectedScheduleRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
