import { useState, useEffect } from "react";

import { TeamProjectsContainer, TeamProjectsTableRow } from "../teamsStyles";
import { TableItem } from "../../../styledComponents/teams";
import { TableText } from "../../../styledComponents/common";
import { ProjectListStatusBadge } from "../../Projects/projectsStyles";
import { SecondaryButton } from "../../../styledComponents/buttons";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";
import DropdownLoader from "../../../components/DropdownLoader/DropdownLoader";

import TopPagination from "../../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../../components/Pagination/BottomPagination/BottomPagination";

const AssignedProjects = (props) => {
  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    selectedOrganization,
    teamId,
    teamProjectListLoading,

    getTeamProjectsList,
    projectsList,
    history,
    searchTerm,

    // pagination
    projectsCount,
    pageSize,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
    }
  }, [selectedOrganization]);

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id && teamId) {
      const payload = {
        organization_id: selectedOrganization.id,
        team_id: teamId,
        page,
      };
      getTeamProjectsList(payload);
    }
    setCurrentPageNumber(page);
  };

  if (teamProjectListLoading) {
    return <ComponentCircleLoader padding="12vh 0" />;
  }
  if (projectsList && projectsList.length === 0) {
    return (
      <NoDataComponent
        title={
          searchTerm
            ? "No project found with this name!"
            : "No project is assigned to this team!"
        }
      />
    );
  }

  return (
    <>
      {projectsCount && projectsCount > 0 && pageSize ? (
        <TopPagination
          itemName="projects"
          totalCount={projectsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
          padding="0 30px"
          margin="0 0 15px"
        />
      ) : null}
      <TeamProjectsContainer>
        {projectsList && projectsList.length > 0 && (
          <>
            <TeamProjectsTableRow>
              <TableItem>
                <TableText>Project Name</TableText>
              </TableItem>
              <TableItem>
                <TableText>Project Code</TableText>
              </TableItem>
              <TableItem>
                <TableText>Status</TableText>
              </TableItem>
              <TableItem></TableItem>
            </TeamProjectsTableRow>
            {projectsList.map((project, index) => {
              return (
                <TeamProjectsTableRow key={index}>
                  <TableItem>
                    <TableText name>{project.name}</TableText>
                  </TableItem>
                  <TableItem>
                    <TableText name>{project.code || "-"}</TableText>
                  </TableItem>
                  <TableItem>
                    <ProjectListStatusBadge status={project.status}>
                      {project.status}
                    </ProjectListStatusBadge>
                  </TableItem>
                  <TableItem
                    onClick={() =>
                      history.push(`/user/project-details?id=${project.id}`)
                    }
                  >
                    <SecondaryButton height="36px">
                      View Details
                    </SecondaryButton>
                  </TableItem>
                </TeamProjectsTableRow>
              );
            })}
          </>
        )}
      </TeamProjectsContainer>
      {projectsCount && projectsCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={projectsCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </>
  );
};

export default AssignedProjects;
