import { connect } from "react-redux";

import JobSiteDetails from "./JobSiteDetailsSection";

import {
  addJobSite,
  updateJobSite,
  addJobSiteAssignee,
  removeJobSiteAssignee,
  clearJobSiteDetails,
} from "../jobSitesActions";
import { getOrganizationMembersList } from "../../../Projects/projectsActions";

const mapStateToProps = (state) => ({
  selectedOrganization: state.organization.selectedOrganization,
  orgMembersList: state.project.orgMembersList,
  memberListIsLoading: state.project.memberShortListIsLoading,

  jobSitesList: state.jobSites.jobSitesList,
  jobSiteDetails: state.jobSites.JobSiteDetails,
  addOrUpdateJobSiteLoading: state.jobSites.addOrUpdateJobSiteLoading,
  assigneeIsLoading: state.jobSites.assigneeIsLoading,
  loadingAssigneeId: state.jobSites.loadingAssigneeId,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationMembersList: (payload) =>
    dispatch(getOrganizationMembersList(payload)),
  getJobSitesList: (payload) => dispatch(getJobSitesList(payload)),
  addJobSite: (payload) => dispatch(addJobSite(payload)),
  updateJobSite: (payload) => dispatch(updateJobSite(payload)),
  addJobSiteAssignee: (payload) => dispatch(addJobSiteAssignee(payload)),
  removeJobSiteAssignee: (payload) => dispatch(removeJobSiteAssignee(payload)),
  clearJobSiteDetails: () => dispatch(clearJobSiteDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSiteDetails);
