import { useState, useEffect, Fragment } from "react";
import { CommonText, TableText, CommonGrid } from "styledComponents/common";
import {
  TableDateContainer,
  TimeActivityTableRow,
  ColoredLine,
  TableItem,
  TableTitleItem,
  ColoredBox,
} from "styledComponents/reports";
import moment from "moment";
import { getHourMin } from "../../../utils/helper";

import RoundImageNameComp from "../../../components/RoundImageName/RoundImageNameComp";
import avatar5 from "../../../assets/img/avatar_5.svg";

const ManualTimeTable = ({ report, index }) => {
  const getShortReason = (reason) => {
    if (reason !== "") {
      let short = reason;
      short = short.split(" ").splice(0, 7).join(" ");
      if (reason > short) {
        return `${short}...`;
      } else {
        return short;
      }
    }
  };

  return (
    <div style={{ marginTop: `20px` }}>
      <CommonText>
        {moment(Object.keys(report)[0]).format("D MMM, YYYY")}
      </CommonText>
      <TableDateContainer index={index}>
        <Fragment>
          <TimeActivityTableRow manual title>
            <TableTitleItem manual>
              <TableText>Member</TableText>
            </TableTitleItem>
            <TableTitleItem manual>
              <TableText>Project</TableText>
            </TableTitleItem>
            <TableTitleItem manual>
              <TableText>Task</TableText>
            </TableTitleItem>
            <TableTitleItem manual>
              <TableText>Change in Timesheet</TableText>
            </TableTitleItem>
            <TableTitleItem manual>
              <TableText>Reason</TableText>
            </TableTitleItem>
          </TimeActivityTableRow>
          <ColoredLine />
          {report[Object.keys(report)[0]] &&
            report[Object.keys(report)[0]].length > 0 &&
            report[Object.keys(report)[0]].map((info, index) => (
              <TimeActivityTableRow manual key={index}>
                <TableItem manual>
                  <CommonGrid justifyContent="start" alignItem="center">
                    <RoundImageNameComp
                      imgSource={info.member_avatar}
                      name={info.member_name}
                      index={index}
                      imageSize="32px"
                      color={info.member_color}
                    />
                    <ColoredBox type="edit">
                      <div style={{ fontWeight: "600" }}>
                        {info?.member_id === info?.updated_by
                          ? `Edited by member`
                          : `Edited by ${info.edited_by_name}`}
                      </div>
                      <div style={{ fontWeight: "600" }}>
                        {" "}
                        {`on ${moment(info.updated_at).format(
                          "D-M-YYYY"
                        )}`}{" "}
                      </div>
                    </ColoredBox>
                  </CommonGrid>
                </TableItem>
                <TableItem manual>
                  <CommonText>{info.project_name}</CommonText>
                </TableItem>
                <TableItem manual>
                  <CommonText>
                    {info.task_name === null ? `-` : `${info.task_name}`}
                  </CommonText>
                </TableItem>
                <TableItem style={{ justifyContent: `start` }} manual>
                  <ColoredBox type={info.type_of_change}>
                    {info.type_of_change === "add"
                      ? `Added ${getHourMin(info.old_time)}`
                      : info.type_of_change === ("delete" || "update")
                      ? `${info.type_of_change}d ${getHourMin(info.old_time)}`
                      : `${info.type_of_change}ted ${getHourMin(
                          info.deleted_time
                        )}`}
                  </ColoredBox>
                </TableItem>
                <TableItem manual>
                  <CommonText>{getShortReason(info.reason)}</CommonText>
                </TableItem>
              </TimeActivityTableRow>
            ))}
        </Fragment>
      </TableDateContainer>
    </div>
  );
};

export default ManualTimeTable;
