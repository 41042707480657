import { connect } from "react-redux";

import TimesheetApprovalSettings from "./TimesheetApprovalSettings";

import {
  getMemberwiseTimesheetApprovalSettings,
  updateMemberwiseTimesheetApprovalSettings,
  updateOrganizationTimesheetApprovalSettings,
} from "../timesheetSettingsActions";

const mapStateToProps = (state) => ({
  isLoading: state.timesheetSettings.isLoading,
  orgApprovalTurnOnLoading: state.timesheetSettings.orgApprovalTurnOnLoading,
  orgApprovalTurnOffLoading: state.timesheetSettings.orgApprovalTurnOffLoading,

  selectedOrganization: state.organization.selectedOrganization,

  memberwiseTimesheetApprovalSettings:
    state.timesheetSettings.memberwiseTimesheetApprovalSettings,

  // pagination
  totalMembersCount: state.timesheetSettings.totalMembersCount,
});

const mapDispatchToProps = (dispatch) => ({
  getMemberwiseTimesheetApprovalSettings: (payload) =>
    dispatch(getMemberwiseTimesheetApprovalSettings(payload)),
  updateMemberwiseTimesheetApprovalSettings: (payload) =>
    dispatch(updateMemberwiseTimesheetApprovalSettings(payload)),
  updateOrganizationTimesheetApprovalSettings: (payload) =>
    dispatch(updateOrganizationTimesheetApprovalSettings(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimesheetApprovalSettings);
