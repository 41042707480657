import { useState, useEffect } from "react";
import queryString from "query-string";
import MembersTable from "./MembersTable";
import { TabLeftGrid } from "../../styledComponents/members";
import {
  PageTitle,
  HeaderContainer,
  InputWithLabelSection,
  FilterLabel,
  InputField,
} from "../../styledComponents/common";
import {
  TabButtonContainer,
  TabButton,
  ColoredButtonWithIcon,
  ButtonIcon,
} from "../../styledComponents/buttons";
import MemberDeleteModal from "./MemberDeleteModal";
import DeleteInvitationModal from "./DeleteInvitationModal";

import Plus from "../../assets/img/icons/plus_white.svg";

import SearchWithButton from "../../components/Search/SearchWithButton";

import TopPagination from "../../components/Pagination/TopPagination/TopPagination";
import BottomPagination from "../../components/Pagination/BottomPagination/BottomPagination";

const Members = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [invitedMembersList, setInvitedMembersList] = useState([]);
  const [tabSelect, setTabSelect] = useState("active");

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleteInvitationModalVisible, setIsDeleteInvitationModalVisible] =
    useState(false);
  const [memberDeleteId, setMemberDeleteId] = useState("");
  const [invitationDeleteId, setInvitationDeleteId] = useState("");
  const [roleValue, setRoleValue] = useState({
    value: "1",
    label: "All Roles",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchedTerm, setCurrentSearchedTerm] = useState("");

  // pagination
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  const {
    selectedOrganization,
    getMembersList,
    location,
    history,
    getInvitedMembersList,
    membersList,
    removeMember,
    deleteMemberInvitation,
    deleteMemberLoading,
    deleteInvitationLoading,
    inviteMember,
    isLoading,
    updateMemberwiseTimesheetApprovalSettings,

    // pagination
    totalMembersCount,
    pageSize,
  } = props;

  const RoleOptions = [
    { value: "1", label: "All Roles" },
    { value: "2", label: "Specific Roles" },
    { value: "3", label: "Test Project" },
  ];

  useEffect(() => {
    // if (selectedOrganization && selectedOrganization.id) {
    //   const payload = {
    //     organization_id: selectedOrganization.id,
    //   };
    //   getMembersList(payload);
    // }
    if (location.search) {
      let queryParsed = queryString.parse(location.search);
      if (queryParsed.tab && queryParsed.tab === "invited") {
        setTabSelect("invited");
        // if (selectedOrganization && selectedOrganization.id) {
        //   const payload = {
        //     organization_id: selectedOrganization.id,
        //   };
        //   getInvitedMembersList(payload);
        // }
      }
    }
  }, []);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setCurrentPageNumber(1);
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getMembersList(payload);
      if (tabSelect && tabSelect === "invited") {
        getInvitedMembersList(payload);
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      if (tabSelect && tabSelect === "invited") {
        // getInvitedMembersList(payload);
        setSearchTerm("");
        setCurrentSearchedTerm("");
      }
    }
  }, [tabSelect]);

  useEffect(() => {
    if (membersList && membersList.length > 0) {
      setMemberList(membersList);
    } else if (membersList && membersList.length === 0) {
      setMemberList([]);
    }
  }, [membersList]);

  useEffect(() => {
    if (props.invitedMembersList && props.invitedMembersList.length > 0) {
      setInvitedMembersList(props.invitedMembersList);
    } else if (
      props.invitedMembersList &&
      props.invitedMembersList.length === 0
    ) {
      setInvitedMembersList([]);
    }
  }, [props.invitedMembersList]);

  const onTabSwitch = (value) => {
    if (value === "invited") {
      if (selectedOrganization && selectedOrganization.id) {
        const payload = {
          organization_id: selectedOrganization.id,
        };
        getInvitedMembersList(payload);
      }
    }
    setTabSelect(value);
  };

  const handleSearchByName = () => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      tabSelect &&
      tabSelect === "active"
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      if (searchTerm && searchTerm.trim()) {
        payload.name = searchTerm.toLowerCase().trim();
        setCurrentSearchedTerm(searchTerm);
      } else {
        setCurrentSearchedTerm("");
      }
      getMembersList(payload);
    }
  };
  const handleClearSearch = () => {
    if (
      currentSearchedTerm &&
      selectedOrganization &&
      selectedOrganization.id
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        page: currentPageNumber,
      };
      getMembersList(payload);
    }
    setTimeout(() => {
      setSearchTerm("");
      setCurrentSearchedTerm("");
    }, 100);
  };

  const handlePageChange = (page) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        page,
      };

      if (tabSelect && tabSelect === "invited") {
        getInvitedMembersList(payload);
      } else {
        getMembersList(payload);
      }
    }
    setCurrentPageNumber(page);
  };

  const handleDeleteMember = () => {
    if (selectedOrganization && selectedOrganization.id && memberDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        user_id: memberDeleteId,
      };
      removeMember(payload);
      setTimeout(() => {
        setIsDeleteModalVisible(!isDeleteModalVisible);
      }, 300);
    }
  };

  const handleDeleteInvitation = () => {
    if (selectedOrganization && selectedOrganization.id && invitationDeleteId) {
      const payload = {
        organization_id: selectedOrganization.id,
        invitation_id: invitationDeleteId,
      };
      deleteMemberInvitation(payload);
      setTimeout(() => {
        setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
      }, 1200);
    }
  };

  const toggleDeleteModal = (memberId) => {
    if (!isDeleteModalVisible) {
      setMemberDeleteId(memberId);
    }
    setIsDeleteModalVisible(!isDeleteModalVisible);
  };

  const toggleInvitationDeleteModal = (id) => {
    if (!isDeleteInvitationModalVisible) {
      setInvitationDeleteId(id);
    }
    setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible);
  };

  const onSearchTermChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };
  const onRoleChange = (e) => {
    setRoleValue(e);
  };

  return (
    <div className="content">
      <MemberDeleteModal
        isOpen={isDeleteModalVisible}
        toggle={() => setIsDeleteModalVisible(!isDeleteModalVisible)}
        handleDelete={handleDeleteMember}
        isLoading={deleteMemberLoading}
      />
      <DeleteInvitationModal
        isOpen={isDeleteInvitationModalVisible}
        toggle={() =>
          setIsDeleteInvitationModalVisible(!isDeleteInvitationModalVisible)
        }
        handleDelete={handleDeleteInvitation}
        isLoading={deleteInvitationLoading}
      />
      <PageTitle>Members</PageTitle>
      {selectedOrganization && selectedOrganization.role !== "member" && (
        <HeaderContainer>
          <TabLeftGrid
            columns={
              selectedOrganization.role !== "owner" &&
              selectedOrganization.role !== "admin"
                ? "300px"
                : "auto 80%"
            }
          >
            {selectedOrganization.plan &&
            selectedOrganization.plan.name !== "Solo" ? (
              <>
                {selectedOrganization.role === "owner" ||
                selectedOrganization.role === "admin" ? (
                  <InputWithLabelSection>
                    <FilterLabel>Type</FilterLabel>
                    <TabButtonContainer>
                      <TabButton
                        onClick={() => onTabSwitch("active")}
                        selected={tabSelect}
                        index={"active"}
                      >
                        Active
                      </TabButton>
                      <TabButton
                        onClick={() => onTabSwitch("invited")}
                        selected={tabSelect}
                        index={"invited"}
                      >
                        Invited
                      </TabButton>
                    </TabButtonContainer>
                  </InputWithLabelSection>
                ) : null}

                {tabSelect && tabSelect === "active" && (
                  <SearchWithButton
                    itemName="Member"
                    searchTermName="member name"
                    searchInput={searchTerm}
                    onInputChange={onSearchTermChange}
                    handleSearch={handleSearchByName}
                    handleClearSearch={handleClearSearch}
                  />
                )}
              </>
            ) : null}

            {/* <InputWithLabelSection>
                <FilterLabel>Search Member</FilterLabel>
                <InputField
                  type="text"
                  placeholder={`Search member by ${
                    tabSelect === "active" ? "name" : "email"
                  }...`}
                  value={searchTerm}
                  onChange={(e) => onSearchTermChange(e)}
                />
              </InputWithLabelSection> */}
          </TabLeftGrid>

          {selectedOrganization &&
          (selectedOrganization.role === "owner" ||
            selectedOrganization.role === "admin") ? (
            <ColoredButtonWithIcon
              onClick={() => history.push("/user/member-invitation")}
            >
              <ButtonIcon src={Plus} alt="" />
              Invite Member
            </ColoredButtonWithIcon>
          ) : null}
        </HeaderContainer>
      )}

      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <TopPagination
          itemName="members"
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}

      <MembersTable
        selectedOrganization={selectedOrganization}
        tabSelect={tabSelect}
        membersList={memberList}
        invitedMembersList={invitedMembersList}
        inviteMember={inviteMember}
        history={history}
        toggleDeleteModal={toggleDeleteModal}
        toggleInvitationDeleteModal={toggleInvitationDeleteModal}
        searchTerm={searchTerm}
        isLoading={isLoading}
        updateMemberwiseTimesheetApprovalSettings={
          updateMemberwiseTimesheetApprovalSettings
        }
      />
      {totalMembersCount && totalMembersCount > 0 && pageSize ? (
        <BottomPagination
          totalCount={totalMembersCount}
          currentPage={currentPageNumber}
          onPageChange={(page) => handlePageChange(page)}
          pageSize={pageSize}
        />
      ) : null}
    </div>
  );
};

export default Members;
